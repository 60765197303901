<template>
  <br />
  <br />
  <h2 class="text-center my-2">VIEW BALANCES FOR : {{retailClient.firstLastName}}</h2>
  <br />
  <br />
<div v-if="retailClient.hasHadClientTcc === true || retailClient.hasClientTcc === true">
    <div class="fluid grid">
        <div class="col-12 md:col-4 lg:col-4">
            <Card class="shadow-5 text-center d-block mx-auto" id="cash">
                <template class="text-center" #title>
                <i class="pi pi-wallet" id="cardLogo"></i>
                </template>
                <template  #content>
                <router-link :to="'/dailyCashBalance/' + retailClient.id" style="color: #e2e7e5; text-decoration: none">
                CASH
                </router-link>
                </template>
            </Card>
        </div>
        <div class="col-12 md:col-4 lg:col-4">
            <Card class="shadow-5 text-center d-block mx-auto" id="sdaDisabled">
                <template class="text-center" #title>
                <i class="pi pi-file" id="cardLogo"></i>
                </template>
                <template  #content>
                <a style="color: #e2e7e5; text-decoration: none">
                SDA
                </a>
                </template>
            </Card>
        </div>
        <div class="col-12 md:col-4 lg:col-4">
            <Card class="shadow-5 text-center d-block mx-auto" id="tcc">
                <template class="text-center" #title>
                <i class="pi pi-folder-open" id="cardLogo"></i>
                </template>
                <template  #content>
                <router-link :to="'/clientTccRemainder/' + retailClient.id" style="color: #e2e7e5; text-decoration: none">
                TCC
                </router-link>
                </template>
            </Card>
        </div>
    </div>
    </div>
  <div v-else>
    <div class="fluid grid">
        <div class="col-12 md:col-4 lg:col-4">
            <Card class="shadow-5 text-center d-block mx-auto" id="cash">
                <template class="text-center" #title>
                <i class="pi pi-wallet" id="cardLogo"></i>
                </template>
                <template  #content>
                <router-link :to="'/dailyCashBalance/' + retailClient.id" style="color: #e2e7e5; text-decoration: none">
                CASH
                </router-link>
                </template>
            </Card>
        </div>
            <div class="col-12 md:col-4 lg:col-4">
                <Card class="shadow-5 text-center d-block mx-auto" id="sda">
                    <template class="text-center" #title>
                    <i class="pi pi-file" id="cardLogo"></i>
                    </template>
                    <template  #content>
                    <router-link :to="'/clientSdaRemainder/' + retailClient.id" style="color: #e2e7e5; text-decoration: none">
                    SDA
                    </router-link>
                    </template>
                </Card>
            </div>
            <div class="col-12 md:col-4 lg:col-4">
                <Card class="shadow-5 text-center d-block mx-auto" id="tccDisabled">
                    <template class="text-center" #title>
                    <i class="pi pi-folder-open" id="cardLogo"></i>
                    </template>
                    <template  #content>
                    <a style="color: #e2e7e5; text-decoration: none">
                    TCC
                    </a>
                    </template>
                </Card>
            </div>
        </div>
    </div>
    <hr class="mx-4 shadow-2"/>
    <div>
        <Button class="l-2 mx-4 shadow-4 p-button-lg"
        icon="pi pi-step-backward"
        label="BACK&nbsp;"
        id="submitButton"
        @click.prevent="$router.back()" />
    </div>
</template>

<script>
import { getRetailClient } from '@/gateway'

export default {
  name: 'BalancesHomeView',
  inheritAttrs: false,
  data () {
    return {
      retailClient: {}
    }
  },
  methods: {
    async init () {
      const response = await getRetailClient({ id: this.id })
      if (response) {
        this.retailClient = response.result
      }
    }
  },
  mounted () {
    this.id = this.$route.params.id
    this.init()
  }
}

</script>

<style lang="scss" scoped>
#cash {
 @include cardComponent($midDarkBlue)
};
#sda {
 @include cardComponent($middleBlue)
};
#sdaDisabled {
 @include cardComponent($midddleBlueDisabled)
};
#tcc {
 @include cardComponent($lightMidBlue)
};
#tccDisabled {
 @include cardComponent($lightBlueDisabled)
};
.p-card.p-component.p-card-content {
 font-size: 24px;
};
.p-grid {
  margin: 1rem;
  padding: 1rem;
};
</style>
