<template>
<br />
    <h2 class="text-center my-2">TRADE QUOTES</h2>
  <div>
    <Button icon="pi pi-info" class="p-button-rounded p-button-lg mx-4 shadow-5" @click="toggle" />
    <OverlayPanel ref="op" appendTo="body" :showCloseIcon="true" id="overlay_panel" style="width: 20rem" :breakpoints="{'960px': '75vw'}">
      <p>Use the <i class="pi pi-sort-alt"></i> to filter results.</p>
      <p>Use <i class="pi pi-sort-amount-up-alt"></i> or <i class="pi pi-sort-amount-down-alt"></i> to change the sort order.</p>
      <p>To filter by multiple columns use Ctrl + Click on the second filter column.</p>
      <p>Use the <i class="pi pi-filter"></i> to search for a specific client. You can search on multiple columns.</p>
      </OverlayPanel>
  </div>
  <br />
  <div class="fluid mx-3 shadow-2">
    <DataTable :value="tradeQuotes"
                dataKey="id" :rowHover="true"
                stripedRows responsiveLayout="stack"
                showGridlines
                breakpoint="1024px"
                :paginator="true"
                :rows="8"
                v-model:filters="filters1"
                filterDisplay="menu"
                class="datatable-retailClient">
        <Column field="firstLastName" header="Client" :sortable="true" headerStyle="width: 3vw">
          <template #filter="{filterModel}">
              <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by Name"/>
          </template>
        </Column>
        <Column field="partnerName" header="Partner" :sortable="true" headerStyle="width: 1vw">
          <template #filter="{filterModel}">
              <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by Partner"/>
          </template>
        </Column>
        <Column field="tradeType" header="Trade Type" headerStyle="width: 1vw"></Column>
        <Column field="crossCurrency" header="Currency" headerStyle="width: 1vw"></Column>
        <Column field="initialSendTime" header="Sent At" headerStyle="width: 5vw"></Column>
        <Column field="adminName" header="Reply By" :sortable="true" headerStyle="width: 1vw"></Column>
        <Column field="quoteStatus" header="Status" :sortable="true" headerStyle="width: 3vw" :filterMenuStyle="{'width':'14rem'}" style="min-width:4vw">
            <template #body="{data}">
                <span :class="'retailClient-badge status-' + data.quoteStatus">{{data.quoteStatus}}</span>
            </template>
            <template #filter="{filterModel}">
                <Dropdown v-model="filterModel.value" :options="quoteStatuses" placeholder="Select Status" class="p-column-filter" :showClear="true">
                </Dropdown>
            </template>
        </Column>
        <Column field="doesHaveChat" header="Has Chat" headerStyle="width: 2vw">
          <template #body="{data}">
              <span :class="'retailClient-badge status-' + data.doesHaveChat">{{data.doesHaveChat}}</span>
          </template>
        </Column>
        <Column header="View" headerStyle="width: 2vw;">
            <template #body="{data}">
              <div v-if="data.acHasReplied === false">
                <router-link class="p-button d-block mx-1 px-1 shadow-1" :to="'/tradeQuoteReply/' + data.id"
                              style="color: #E6E6F0; background-color: #d2161e!important; text-decoration: none;
                                    display: flex; justify-content: center;">
                  <i class="pi pi-reply" style="fontSize: 1rem"></i>&nbsp;REPLY
                </router-link>
              </div>
              <div v-else-if="data.quoteStatus === 'accepted'">
                <router-link class="p-button d-block mx-1 px-1 shadow-1" :to="'/tradeQuoteToTraded/' + data.id"
                              style="color: #E6E6F0; background-color: #063a6a!important; text-decoration: none;
                                    display: flex; justify-content: center;">
                  <i class="pi pi-user-edit" style="fontSize: 1rem"></i>&nbsp;&nbsp;VIEW
                </router-link>
              </div>
              <div v-else-if="data.quoteStatus === 'traded'">
                <router-link class="p-button d-block mx-1 px-1 shadow-1" :to="'/tradeQuoteHasTraded/' + data.id"
                              style="color: #E6E6F0; background-color: #063a6a!important; text-decoration: none;
                                    display: flex; justify-content: center;">
                  <i class="pi pi-user-edit" style="fontSize: 1rem"></i>&nbsp;&nbsp;VIEW
                </router-link>
              </div>
              <div v-else-if="data.quoteStatus === 'declined'">
                <router-link class="p-button d-block mx-1 px-1 shadow-1" :to="'/tradeQuoteDeclinedView/' + data.id"
                              style="color: #E6E6F0; background-color: #063a6a!important; text-decoration: none;
                                    display: flex; justify-content: center;">
                  <i class="pi pi-user-edit" style="fontSize: 1rem"></i>&nbsp;&nbsp;VIEW
                </router-link>
              </div>
              <div v-else-if="data.quoteStatus === 'complete'">
                <router-link class="p-button d-block mx-1 px-1 shadow-1" :to="'/tradeQuoteHasCompleted/' + data.id"
                              style="color: #E6E6F0; background-color: #063a6a!important; text-decoration: none;
                                    display: flex; justify-content: center;">
                  <i class="pi pi-user-edit" style="fontSize: 1rem"></i>&nbsp;&nbsp;VIEW
                </router-link>
              </div>
              <div v-else-if="data.quoteStatus === 'expired'">
                <router-link class="p-button d-block mx-1 px-1 shadow-1" :to="'/tradeQuoteHasExpired/' + data.id"
                              style="color: #E6E6F0; background-color: #063a6a!important; text-decoration: none;
                                    display: flex; justify-content: center;">
                  <i class="pi pi-user-edit" style="fontSize: 1rem"></i>&nbsp;&nbsp;VIEW
                </router-link>
              </div>
              <div v-else>
                <router-link class="p-button d-block mx-1 px-1 shadow-1" :to="'/tradeQuoteView/' + data.id"
                              style="color: #E6E6F0; background-color: #063a6a!important; text-decoration: none;
                                    display: flex; justify-content: center;">
                  <i class="pi pi-user-edit" style="fontSize: 1rem"></i>&nbsp;VIEW
                </router-link>
              </div>
            </template>
        </Column>
    </DataTable>
  </div>
    <Dialog class="deleteModal" header="UNAUTHORISED ACCESS" v-model:visible="openErrorModal" :modal="true">
        <div class="confirmation-content">
            <span>User not authorised to view this information.</span>
            <br />
                  Please contact the system administrator.
        </div>
    </Dialog>
</template>

<script>
import { getAllTradeQuotes } from '@/gateway'
import { FilterMatchMode, FilterOperator } from 'primevue/api'

export default {
  name: 'QuotesView',
  props: ['quoteTriggerMount'],
  inheritAttrs: false,
  data () {
    return {
      tradeQuotes: [],
      quoteStatuses: [
        'Accepted',
        'Completed',
        'Declined',
        'Expired',
        'Pending',
        'Queued',
        'Traded'
      ],
      filters1: null,
      openErrorModal: false
    }
  },
  methods: {
    async init () {
      const response = await getAllTradeQuotes()
      if (response) {
        if (response.message === 'Unauthorised User') {
          this.openErrorModal = true
        }
        else {
          this.tradeQuotes = response.result
        }
      }
    },
    initFilters1 () {
      this.filters1 = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        firstLastName: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
        partnerName: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
        quoteStatus: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] }
      }
    },
    toggle (event) {
      this.$refs.op.toggle(event)
    }
  },
  created () {
    this.initFilters1()
  },
  watch: {
    quoteTriggerMount: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.init()
      }
    }
  },
  mounted () {
    this.init()
  }
}
</script>

<style lang="scss" scoped>
::v-deep() {
  .p-datatable .p-datatable-thead > tr > th {
    background-color: $middleBlue!important;
    color: $textColor;
    border: 1px solid $borderColour!important;
    font-size: 18px;
    font-weight: 600;
  }
  .p-datatable .p-datatable-tbody > tr > td {
    padding: .4rem .4rem;
    color: $darkTextColour;
    font-family:'Poppins', sans-serif;
    font-weight: 400;
  }
  .p-paginator-current {
        margin-right: auto;
    }
  .retailClient-badge {
      @include statusBadge
  };
  #disabled {
    background: $textColor!important;
    color: $middleBlue!important;
  };
  #searchtext {
    min-width: 40vw;
  };
};
</style>
