<template>
  <br />
    <h2 class="text-center my-2">ADD NEW PARTNER USER</h2>
  <br />
  <div class="mx-4">
    <div class="formgrid grid">
        <div class="col-12 md:col-6 lg:col-6">
            <h5>First Name <a style="color: #d2161e">*</a></h5>
            <InputText
            type="text"
            autocomplete="nope"
            class="inputfield w-full"
            v-model="user.firstName"
            @blur="v$.user.firstName.$touch"/>
            <div v-if="v$.user.firstName.$error"><h5 style="color: #d2161e">* First Name is required</h5></div>
        </div>
        <div class="col-12 md:col-6 lg:col-6">
            <h5>Surname</h5>
            <InputText
            type="text"
            class="inputfield w-full"
            v-model="user.lastName"/>
        </div>
    </div>
     <div class=" formgrid grid">
        <div class="col-12 md:col-6 lg:col-6">
            <h5>Email Address <a style="color: #d2161e">*</a></h5>
            <InputText
            type="text"
            autocomplete="nope"
            class="inputfield w-full"
            v-model="user.email"
            @blur="v$.user.email.$touch"/>
            <span v-if="v$.user.email.$error">
                <h5 style="color: #d2161e">*&nbsp;{{ v$.user.email.$errors[0].$message }}</h5>
            </span>
        </div>
    </div>
    <br />
    <br />
     <div class="formgrid grid">
        <div class="col-12">
            <div class="col-12 md:col-6 lg:col-6">
                <div class="field-checkbox">
                    <Checkbox id="MutiplePartners"
                    v-model="userHasMultiplePartners"
                    :binary="true" />
                    <label for="MutiplePartners"><b>Do you want to allocate multiple Partners to this User?</b></label>
                </div>
            </div>
        </div>
    </div>
    <br />
    <transition name="fade">
    <div v-if="userHasMultiplePartners == false">
        <div class=" formgrid grid">
            <div class="col-12 md:col-6 lg:col-6">
            <h5>Single Partner Name <a style="color: #d2161e">*</a></h5>
                <Dropdown v-model="user.partnerId"
                id="partnerDisplayName"
                :options="partners"
                optionLabel="text"
                optionValue="value"
                :showClear="true"
                appendTo="self"
                class="inputfield w-full"
                placeholder="Select Partner"
                @blur="v$.user.partnerId.$touch"/>
                <div v-if="v$.user.partnerId.$error"><h5 style="color: #d2161e">* Partner Name is required</h5></div>
            </div>
        </div>
    </div>
    </transition>
    <transition name="fade">
    <div v-if="userHasMultiplePartners == true">
        <div class=" formgrid grid">
            <div class="col-12 md:col-12 lg:col-6">
            <h5>Multiple Partner Names <a style="color: #d2161e">*</a></h5>
                <MultiSelect v-model="user.partnerIds"
                    :options="partners"
                    optionLabel="text"
                    optionValue="value"
                    :showClear="true"
                    display="chip"
                    appendTo="self"
                    class="inputfield w-full"
                    placeholder="Select Multiple Partners"
                    @blur="v$.user.partnerIds.$touch"/>
                <div v-if="v$.user.partnerIds.$error"><h5 style="color: #d2161e">* Partner Names are required</h5></div>
            </div>
        </div>
    </div>
    </transition>
    <div v-if='success'>
      <Dialog class="successModal" header="SUCCESS" id="successModal" v-model:visible="displayConfirmation" :modal="true" style="min-width: 25rem">
          <div class="confirmation-content">
              <span><p>New FxLink Partner User created.</p>
              <hr />
              <h5>Dear <b>{{user.firstName}}&nbsp;{{user.lastName}}</b>, welcome to FxLink.</h5>
              <h5>Here are your login details to access the <b>{{partnerName}}</b> profile:</h5>
              <span v-if="partnerNames.length > 0">
                <h5>This profile does have access to multiple Partner Profiles:</h5>
                <h5>After logging in you can click on the PROFILES button to select a different profile.</h5>
                <h5>Profile access for : <b>{{partnerNames}}</b></h5>
              </span>
              <h5><b>FxLink URL</b> : <u>https://www.fxlink.co.za/</u></h5>
              <h5><b>Username</b> : {{user.email}}</h5>
              <h5><b>Password</b> : {{passwordText}}</h5></span>
              <h5>Please use Google Chrome or Mozilla Firefox to access FxLink.</h5>
              <h5>FxLink uses Multi-Factor Authentication (MFA) to enhance the protection and privacy of your client's personal details.</h5>
              <h5>If you do not have it on your mobile device already, please download the Google Authenticator (GA) app from the App Store for iOS users,
                  or the Play Store for Android users.</h5>
              <h5>The login window will prompt you to scan the QR code to link GA to FxLink,
                  which will only be required once.</h5>
              <h5>Thereafter when you login to FxLink you will get the OTP code from GA.</h5>
              <h5>You can find more information on the INFO page.</h5>
              <hr />
              <p>Copy the details and email via a secure channel to the new user.</p>
              <p>NB! Do not close before you have copied as the password is not stored!</p>
          </div>
          <template #footer>
            <div class="flex justify-content-between">
              <router-link class="p-button d-block mr-4 px-auto" :to="'/usersHome'" style="background-color: #014f7e!important; text-decoration: none;
                                  text-decoration: none; width: 40%; justify-content: center;">
                <i class="pi pi-step-backward" style="fontSize: 1rem"></i> &nbsp;&nbsp;BACK
              </router-link>
              <router-link class="p-button d-block mx-2 px-auto" :to="'/usersView'"  style="background-color: #0185b7!important; text-decoration: none
                                  text-decoration: none; width: 40%; justify-content: center;">
                <i class="pi pi-user-edit" style="fontSize: 1rem"></i>&nbsp;&nbsp;USERS
              </router-link>
            </div>
          </template>
      </Dialog>
    </div>
    <br />
    <div class="flex justify-content-between ">
        <div>
            <Button class="l-2 m-3 mx-2 shadow-4 p-button-lg"
            icon="pi pi-step-backward"
            label="BACK&nbsp;"
            id="submitButton"
            @click.prevent="$router.back()" />
        </div>
          <div>
            <Button class="l-2 m-3 mx-2 shadow-4 p-button-lg "
            icon="pi pi-upload"
            label="SUBMIT"
            id="backButton"
            :loading="submitUser"
            @click.prevent="saveNewUser" />
        </div>
    </div>
</div>
</template>

<script>
import { createUser, getPartners } from '@/gateway'
import useVuelidate from '@vuelidate/core'
import { required, email, helpers, requiredIf } from '@vuelidate/validators'

export default {
  name: 'UsersAdd',
  setup: () => ({ v$: useVuelidate() }),
  inheritAttrs: false,
  data () {
    return {
      user: {
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        partnerId: 0,
        partnerIds: [],
        partnerNames: [],
        userName: '',
        partnerName: '',
        isAuthenticated: false,
        passwordText: ''
      },
      partners: [],
      userHasMultiplePartners: false,
      displayPopi: false,
      isNewUserValid: false,
      submitUser: false,
      success: false,
      displayConfirmation: true,
      passwordText: '',
      partnerName: '',
      partnerNames: [],
      cleanPartnerNames: []
    }
  },
  methods: {
    async saveNewUser () {
      console.log('user creating', this.user)
      const isNewUserValid = await this.v$.user.$validate()
      console.log('user creating1', this.user)
      if (!isNewUserValid) return
      console.log('user creating2', this.user)
      this.submitUser = true
      console.log('user creating3', this.user)
      const response = await createUser({ user: this.user })
      console.log('user creating', this.user)
      if (response) {
        this.passwordText = response.passwordText
        this.partnerName = response.partnerName
        this.partnerNames = response.partnerNames.toString().replace('[', '').replace('"', '').replace(/[,]/g, ', ')
        this.submituser = false
        this.success = true
      }
    },
    async init () {
      const cr = await getPartners()
      this.partners = []
      if (cr) {
        cr.result.forEach(item => {
          this.partners.push({
            key: item.id,
            text: item.shortName,
            value: item.id
          })
        })
      }
    }
  },
  validations () {
    return {
      user: {
        firstName: { required },
        email: {
          required: helpers.withMessage('Email Address is required', required),
          email: helpers.withMessage('This is not a valid Email Address format', email)
        },
        partnerId: { required: requiredIf(this.userHasMultiplePartners === false) },
        partnerIds: { required: requiredIf(this.userHasMultiplePartners === true) }
      }
    }
  },
  async mounted () {
    this.init()
  }
}
</script>

<style>

</style>
