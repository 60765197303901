<template>
  <br />
  <br />
  <h2 class="text-center my-2">VIEW ALL CLIENT BALANCES</h2>
  <br />
  <br />
    <div class="fluid grid">
        <div class="col-12 md:col-4 lg:col-4">
            <Card class="shadow-5 text-center d-block mx-auto" id="cash">
                <template class="text-center" #title>
                <i class="pi pi-wallet" style="fontSize: 2.5rem"></i>
                </template>
                <template  #content>
                <router-link to="/allDailyCashBalance" style="color: #e2e7e5; text-decoration: none">
                CASH
                </router-link>
                </template>
            </Card>
        </div>
        <div class="col-12 md:col-4 lg:col-4">
            <Card class="shadow-5 text-center d-block mx-auto" id="sda">
                <template class="text-center" #title>
                <i class="pi pi-file" style="fontSize: 2.5rem"></i>
                </template>
                <template  #content>
                <router-link to="/allSdaRemainder" style="color: #e2e7e5; text-decoration: none">
                SDA
                </router-link>
                </template>
            </Card>
        </div>
        <div class="col-12 md:col-4 lg:col-4">
            <Card class="shadow-5 text-center d-block mx-auto" id="tcc">
                <template class="text-center" #title>
                <i class="pi pi-folder-open" style="fontSize: 2.5rem"></i>
                </template>
                <template  #content>
                <router-link to="/allTccRemainder" style="color: #e2e7e5; text-decoration: none">
                TCC
                </router-link>
                </template>
            </Card>
        </div>
    </div>
    <hr class="mx-4 shadow-2"/>
    <div>
        <Button class="l-2 m-2 mx-4 shadow-4 p-button-lg"
        icon="pi pi-step-backward"
        label="BACK&nbsp;"
        id="submitButton"
        @click.prevent="$router.back()" />
    </div>
</template>

<script>

export default {
  name: 'AllBalancesHomeView',
  inheritAttrs: false
}

</script>

<style lang="scss" scoped>
#cash {
 @include cardComponent($midDarkBlue)
};
#sda {
 @include cardComponent($middleBlue)
};
#sdaDisabled {
 @include cardComponent($midddleBlueDisabled)
};
#tcc {
 @include cardComponent($lightMidBlue)
};
#tccDisabled {
 @include cardComponent($lightBlueDisabled)
};
.p-card.p-component.p-card-content {
 font-size: 24px;
};
.p-grid {
  margin: 1rem;
  padding: 1rem;
};
</style>
