/** @format */
import { createStore } from 'vuex'
import { getSessionInfo, monthlyReport, getPartners } from '@/gateway'

const actions = {
  async initialLoad (context) {
    if (localStorage.getItem('ss-tok')) {
      const response = await getSessionInfo()
      context.commit('sessionInfo', response)
      context.commit('partnerId', response.partnerId)
    }
  },
  async loadSettings ({ commit }) {
    const session = await getSessionInfo()
    commit('sessionInfo', session)
    commit('partnerId', session.partnerid)
  }
}

const mutations = {
  sessionInfo (state, user) {
    state.user.id = user.userAuthId // user.id;
    state.user.partnerId = user.partnerId
    state.user.partnerName = user.partnerName
    state.user.partnerIds = user.partnerIds
    state.user.isAuthenticated = user.isAuthenticated
    state.user.username = user.userName
    state.user.email = user.email
    state.user.displayName = user.displayName
    state.user.roles = user.roles
    state.user.permissions = user.permissions
  },
  partnerId (state, config) {
    state.partnerId = config
  },
  async loadReport (state) {
    state.reportLoading = true
    state.monthlyReportResponse = await monthlyReport({
      startDate: state.reportStartDate.toJSON(),
      endDate: state.reportEndDate.toJSON()
    })
    state.reportLoading = false
  },
  changeReportStartDate (state, date) {
    state.reportStartDate = date
  },
  changeReportEndDate (state, date) {
    state.reportEndDate = date
  },
  async loadPartners (state) {
    const response = await getPartners()
    state.partners = response.result
  },
  selectPartner (state, partner) {
    state.selectedPartner = partner
  }
}

export const getters = {
  isAuthenticated: state => state.user.isAuthenticated === true,
  reportDate: (state) => state.reportEndDate.toLocaleDateString('en-GB', { month: 'short', year: 'numeric' }),
  tradesPerPartnerPerMonth: (state) => (state.monthlyReportResponse !== null) ? state.monthlyReportResponse.tradesPerPartnerPerMonth : [[]],
  tradesPerMonth: (state) => (state.monthlyReportResponse !== null) ? state.monthlyReportResponse.tradesPerMonth : [],
  tradesPerYear: (state) => (state.monthlyReportResponse !== null) ? state.monthlyReportResponse.tradesPerYear : [],
  profitPerPartnerPerMonth: (state) => (state.monthlyReportResponse !== null) ? state.monthlyReportResponse.profitPerPartnerPerMonth : [[]],
  profitBreakDownPerMonth: (state) => (state.monthlyReportResponse !== null) ? state.monthlyReportResponse.profitBreakDownPerMonth : [[]],
  profitBreakDownPerYear: (state) => (state.monthlyReportResponse !== null) ? state.monthlyReportResponse.profitBreakDownPerYear : [[]],
  zarNominalPerMonth: (state) => (state.monthlyReportResponse !== null) ? state.monthlyReportResponse.zarNominalPerMonth : [],
  zarNominalThreeMonthMovingAverage: (state) => (state.monthlyReportResponse !== null) ? state.monthlyReportResponse.zarNominalThreeMonthMovingAverage : [],
  zarNominalPerYear: (state) => (state.monthlyReportResponse !== null) ? state.monthlyReportResponse.zarNominalPerYear : [],
  partners: (state) => (state.partners !== null) ? [{ id: -1, name: 'All' }].concat(state.partners.map(x => { return { id: x.id, name: x.shortName } })) : []
}
export const store = createStore({
  state: {
    user: {
      id: null,
      email: '',
      displayName: '',
      userName: '',
      isAuthenticated: false,
      partnerId: -1,
      partnerIds: [],
      partnername: '',
      roles: [],
      permissions: []
    },
    partnerId: -1,
    loading: false,
    monthlyReportResponse: null,
    reportLoading: true,
    reportStartDate: new Date(2019, 5),
    reportEndDate: new Date(new Date(Date.now()).getFullYear(), new Date(Date.now()).getMonth()),
    partners: null,
    selectedPartner: { id: -1, name: 'All' }
  },
  mutations: mutations,
  actions: actions,
  getters: getters
})
