<template>
  <br />
  <h2 class="text-center my-3">EDIT FORM 25 DETAILS FOR : {{ form25.firstLastName }}</h2>
  <br />
  <Panel class="mx-4 shadow-4" header="PAYMENT INSTRUCTION DETAILS" :toggleable="true" :collapsed="false">
  <template #end>
      <button class="panel-header-icon link mr-2" @click="toggle"></button>
  </template>
  <h4 class="text-center"><u>PAYMENT DETAILS</u></h4>
  <div class=" formgrid grid">
      <div class="col-12 md:col-6 lg:col-6">
        <h5>Payment Date</h5>
          <Calendar
          p-datepicker
          v-model="form25.paymentDate"
          :showIcon="true"
          appendTo="self"
          class="inputfield w-full"
          dateFormat="yy-mm-dd" />
      </div>
      <div class="col-12 md:col-6 lg:col-6">
          <h5>Client Account Number</h5>
          <InputText
          type="text"
          class="inputfield w-full"
          v-model="form25.transferAccountNumber"/>
      </div>
   </div>
   <div class="formgrid grid">
      <div class="col-12 md:col-6 lg:col-6">
        <h5>Amount in ZAR</h5>
            <InputNumber
              v-model="form25.randAmount"
               mode="decimal"
              :minFractionDigits="2"
              :maxFractionDigits="2"
              class="inputfield w-full" />
      </div>
      <div class="col-12 md:col-6 lg:col-6">
          <h5>Amount in words</h5>
          <InputText
          type="text"
          class="inputfield w-full"
          v-model="form25.randAmountInWords"/>
      </div>
  </div>
  <br />
  <hr />
  <h4 class="text-center"><u>BENFICIARY DETAILS</u></h4>
  <div class="formgrid grid">
    <div class="col-12 md:col-12 lg:col-6">
        <div class="field-checkbox">
            <Checkbox id="billerPay"
            v-model="form25.billerPayment"
            :binary="true" />
            <label for="billerPay">Biller Payment</label>
        </div>
      </div>
  </div>
  <div class="formgrid grid">
      <div class="col-12 md:col-6 lg:col-6">
        <h5>Beneficiary Name</h5>
          <InputText
          type="text"
          class="inputfield w-full"
          v-model="form25.beneficiaryName"/>
      </div>
  </div>
   <div class="formgrid grid">
      <div class="col-12 md:col-12 lg:col-12">
        <h5>Beneficiary Address</h5>
          <InputText
          type="text"
          class="inputfield w-full"
          v-model="form25.beneficiaryAddress"/>
      </div>
  </div>
   <div class="formgrid grid">
      <div class="col-12 md:col-6 lg:col-6">
        <h5>Beneficiary Account Number</h5>
          <InputText
          type="text"
          class="inputfield w-full"
          v-model="form25.beneficiaryAccount"/>
      </div>
      <div class="col-12 md:col-6 lg:col-6">
        <h5>Account Type</h5>
          <Dropdown v-model="form25.accountType"
          :options="accountTypes"
          optionLabel="text"
          optionValue="value"
          :showClear="true"
          appendTo="self"
          class="inputfield w-full"
          placeholder="Please Select" />
      </div>
  </div>
   <div class="formgrid grid">
      <div class="col-12 md:col-6 lg:col-6">
        <h5>Bank Name</h5>
          <InputText
          type="text"
          class="inputfield w-full"
          v-model="form25.bankName"/>
      </div>
      <div class="col-12 md:col-6 lg:col-6">
          <h5>Branch Name</h5>
          <InputText
          type="text"
          class="inputfield w-full"
          v-model="form25.branchName"/>
      </div>
  </div>
   <div class="formgrid grid">
      <div class="col-12 md:col-6 lg:col-6">
        <h5>Bank Code</h5>
          <InputText
          type="text"
          class="inputfield w-full"
          v-model="form25.bankCode"/>
      </div>
      <div class="col-12 md:col-6 lg:col-6">
          <h5>Beneficiary Reference</h5>
          <InputText
          type="text"
          class="inputfield w-full"
          v-model="form25.beneficiaryRef"/>
      </div>
  </div>
   <div class="formgrid grid">
      <div class="col-12 md:col-6 lg:col-6">
        <h5>STP</h5>
          <Dropdown v-model="form25.stp"
          :options="stps"
          optionLabel="text"
          optionValue="value"
          :showClear="true"
          appendTo="self"
          class="inputfield w-full"
          placeholder="Please Select" />
      </div>
      <div class="col-12 md:col-6 lg:col-6">
        <h5>Proof of Payment Required?</h5>
          <Dropdown v-model="form25.proofOfPayment"
          :options="proofsOfPayment"
          optionLabel="text"
          optionValue="value"
          :showClear="true"
          appendTo="self"
          class="inputfield w-full"
          placeholder="Please Select" />
      </div>
  </div>
   <transition name="fade">
      <div v-if="form25.proofOfPayment == 'Yes'">
          <div class="formgrid grid">
              <div class="col-12 md:col-6 lg:col-6">
                <h5>Proof of Payment Email Address</h5>
                  <InputText
                  type="text"
                  class="inputfield w-full"
                  v-model="form25.popEmail"/>
              </div>
          </div>
      </div>
   </transition>
   <div class="formgrid grid">
      <div class="col-12 md:col-6 lg:col-6">
        <h5>Signed At</h5>
          <Dropdown v-model="form25.signedAt"
          :options="signedAts"
          optionLabel="text"
          optionValue="value"
          :showClear="true"
          appendTo="self"
          class="inputfield w-full"
          placeholder="Please Select" />
      </div>
      <div class="col-12 md:col-6 lg:col-6">
        <h5>Signatory</h5>
          <Dropdown v-model="form25.signatoryName"
          :options="signatoryNames"
          optionLabel="text"
          optionValue="value"
          :showClear="true"
          appendTo="self"
          class="inputfield w-full"
          placeholder="Please Select" />
      </div>
  </div>
   <br />
    <div class="formgrid grid">
      <div class="col-12 md:col-12 lg:col-6">
          <div class="field-checkbox">
              <Checkbox id="isThird"
              v-model="form25.isThirdPartyPayment"
              :binary="true" />
              <label for="isThird">Is this a ThirdParty Payment?</label>
          </div>
       </div>
    </div>
 </Panel>
 <br />
 <div class="flex justify-content-between ">
       <div>
        <Button class="l-2 m-3 mx-4 shadow-3 p-button-lg"
        icon="pi pi-step-backward"
        label="BACK&nbsp;"
        id="submitButton"
        @click.prevent="$router.back()" />
    </div>
    <div>
        <Button class="l-2 m-3 mx-4 shadow-3 p-button-lg "
        icon="pi pi-save"
        label="SAVE&nbsp;"
        id="backButton"
        @click.prevent="editClientForm25" />
    </div>
</div>
<div class="formgrid grid">
  <div class="col-12 md:col-6 lg:col-6">
    <div v-if='form25EditSuccess == true'>
    <Dialog class="successModal" header="SUCCESS" id="successModal" v-model:visible="displayConfirmation"  :modal="true">
        <div class="confirmation-content">
            <span>Form 25 Data Updated</span>
        </div>
        <template #footer>
            <a class="p-button d-block mx-2 px-2" @click.prevent="$router.push( {name: 'Form25View', params: { id: form25.clientId }})"
                style="background-color: #0185b7!important; text-decoration: none
                        text-decoration: none; min-width: 270px; justify-content: center;">
                <i class="pi pi-exclamation-triangle" style="fontSize: 1rem"></i>&nbsp;&nbsp;View Form 25
            </a>
        </template>
    </Dialog>
    </div>
    </div>
</div>
</template>

<script>
import { getForm25Edit, saveForm25, editForm25 } from '@/gateway'

export default {
  name: 'Form25Edit',
  inheritAttrs: false,
  data () {
    return {
      retailClient: {},
      messages: [],
      form25EditSuccess: false,
      displayConfirmation: true,
      form25: {},
      accountTypes: [
        { value: 'Savings', text: 'Savings' },
        { value: 'Current', text: 'Current' },
        { value: 'Trading', text: 'Trading' }
      ],
      stps: [
        { value: 'Yes', text: 'Yes' },
        { value: 'No', text: 'No' }
      ],
      proofsOfPayment: [
        { value: 'Yes', text: 'Yes' },
        { value: 'No', text: 'No' }
      ],
      signedAts: [
        { value: 'Cape Town', text: 'Cape Town' },
        { value: 'Johannesburg', text: 'Johannesburg' }
      ],
      signatoryNames: [
        { value: 'Alison Anne Barker', text: 'Alison' },
        { value: 'Amanda Colette Sampson', text: 'Amanda' },
        { value: 'Catherine Digby', text: 'Cathy' },
        { value: 'Lindy-Lee Davies', text: 'Lindy' }
      ]
    }
  },
  methods: {
    async init () {
      const response = await getForm25Edit({ id: this.id })
      if (response) this.form25 = response.result
    },
    async saveClientForm25 () {
      const response = await saveForm25({ form25: this.form25, retailClient: this.retailClient })
      if (response) {
        this.success = true
      }
    },
    async editClientForm25 () {
      const response = await editForm25({ form25: this.form25 })
      if (response) {
        this.form25EditSuccess = true
      }
    }
  },
  mounted () {
    this.id = this.$route.params.id
    this.init()
  }
}
</script>
