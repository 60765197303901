<template>
  <br />
  <h2 class="text-center m-2">UPLOAD TCC DOCUMENTS FOR: {{ tccDocs.clientName }} ~ FOR TCC ID : {{ tccDocs.tccId }}</h2>
  <br />
  <div class="fluid mx-3">
    <div class="formgrid grid mx-1">
        <div class="col-12 md:col-6 lg:col-6">
          <h5 class="mx-1"><b>Select TCC Document Upload Type</b></h5>
              <Dropdown v-model="tccDocsType"
              :options="tccDocsTypes"
              optionLabel="text"
              optionValue="value"
              :showClear="true"
              appendTo="self"
              class="inputfield w-full"
              placeholder="Please Select" />
        </div>
    </div>
    <br />
    <div class="formgrid grid">
    <transition name="fadeinstant">
      <div v-if="tccDocsType === 'Poa'" class="col-12 md:col-12 lg:col-6">
        <h5 class="mx-2"><b>Upload Power of Attorney</b></h5>
            <div class="flex justify-content-between mx-1 shadow-3" id="uploadBackground">
                <button class="file_upload" type="button">
                  <span class="btn_lbl"><i class="pi pi-file"></i>&nbsp;SELECT</span>
                  <span class="btn_colorlayer"></span>
                  <input type="file" name="fileupload" id="file_upload" accept="application/pdf, application/msword, image/*" @change="onFileChangedPowerOfAttorney" />
                </button>
              <Button
                  id="uploadButton"
                  icon="pi pi-upload"
                  @click="onFileUpload"
                  label="UPLOAD&nbsp;"/>
          </div>
      </div>
    </transition>
    <transition name="fadeinstant">
      <div v-if="tccDocsType === 'Fia'" class="col-12 md:col-12 lg:col-6">
        <h5 class="mx-2"><b>Upload FIA 001 Form</b></h5>
            <div class="flex justify-content-between mx-1 shadow-3" id="uploadBackground">
                <button class="file_upload" type="button">
                  <span class="btn_lbl"><i class="pi pi-file"></i>&nbsp;SELECT</span>
                  <span class="btn_colorlayer"></span>
                  <input type="file" name="fileupload" id="file_upload" accept="application/pdf, application/msword, image/*" @change="onFileChangedFia001" />
                </button>
              <Button
                  id="uploadButton"
                  icon="pi pi-upload"
                  @click="onFileUpload"
                  label="UPLOAD&nbsp;"/>
          </div>
      </div>
    </transition>
    <transition name="fadeinstant">
      <div v-if="tccDocsType === 'A&L'" class="col-12 md:col-12 lg:col-6">
        <h5 class="mx-2"><b>Upload Assets and Liabilities</b></h5>
            <div class="flex justify-content-between mx-1 shadow-3" id="uploadBackground">
                <button class="file_upload" type="button">
                  <span class="btn_lbl"><i class="pi pi-file"></i>&nbsp;SELECT</span>
                  <span class="btn_colorlayer"></span>
                  <input type="file" name="fileupload" id="file_upload" accept="application/pdf, application/msword, image/*" @change="onFileChangedAssetsAndLiabilities" />
                </button>
              <Button
                  id="uploadButton"
                  icon="pi pi-upload"
                  @click="onFileUpload"
                  label="UPLOAD&nbsp;"/>
          </div>
      </div>
    </transition>
    <transition name="fadeinstant">
      <div v-if="tccDocsType === 'Bank'" class="col-12 md:col-12 lg:col-6">
        <h5 class="mx-2"><b>Upload Bank Statements</b></h5>
            <div class="flex justify-content-between mx-1 shadow-3" id="uploadBackground">
                <button class="file_upload" type="button">
                  <span class="btn_lbl"><i class="pi pi-file"></i>&nbsp;SELECT</span>
                  <span class="btn_colorlayer"></span>
                  <input type="file" name="fileupload" id="file_upload" accept="application/pdf, application/msword, image/*" @change="onFileChangedBankStatements" />
                </button>
              <Button
                  id="uploadButton"
                  icon="pi pi-upload"
                  @click="onFileUpload"
                  label="UPLOAD&nbsp;"/>
          </div>
      </div>
    </transition>
    <transition name="fadeinstant">
      <div v-if="tccDocsType === 'Inh'" class="col-12 md:col-12 lg:col-6">
        <h5 class="mx-2"><b>Upload Inheritance</b></h5>
            <div class="flex justify-content-between mx-1 shadow-3" id="uploadBackground">
                <button class="file_upload" type="button">
                  <span class="btn_lbl"><i class="pi pi-file"></i>&nbsp;SELECT</span>
                  <span class="btn_colorlayer"></span>
                  <input type="file" name="fileupload" id="file_upload" accept="application/pdf, application/msword, image/*" @change="onFileChangedInheritance" />
                </button>
              <Button
                  id="uploadButton"
                  icon="pi pi-upload"
                  @click="onFileUpload"
                  label="UPLOAD&nbsp;"/>
          </div>
      </div>
    </transition>
    <transition name="fadeinstant">
      <div v-if="tccDocsType === 'Loan'" class="col-12 md:col-12 lg:col-6">
        <h5 class="mx-2"><b>Upload Loans</b></h5>
            <div class="flex justify-content-between mx-1 shadow-3" id="uploadBackground">
                <button class="file_upload" type="button">
                  <span class="btn_lbl"><i class="pi pi-file"></i>&nbsp;SELECT</span>
                  <span class="btn_colorlayer"></span>
                  <input type="file" name="fileupload" id="file_upload" accept="application/pdf, application/msword, image/*" @change="onFileChangedLoans" />
                </button>
              <Button
                  id="uploadButton"
                  icon="pi pi-upload"
                  @click="onFileUpload"
                  label="UPLOAD&nbsp;"/>
          </div>
      </div>
    </transition>
    <transition name="fadeinstant">
      <div v-if="tccDocsType === 'Inv'" class="col-12 md:col-12 lg:col-6">
        <h5 class="mx-2"><b>Upload Investment Portfolio</b></h5>
            <div class="flex justify-content-between mx-1 shadow-3" id="uploadBackground">
                <button class="file_upload" type="button">
                  <span class="btn_lbl"><i class="pi pi-file"></i>&nbsp;SELECT</span>
                  <span class="btn_colorlayer"></span>
                  <input type="file" name="fileupload" id="file_upload" accept="application/pdf, application/msword, image/*" @change="onFileChangedInvestments" />
                </button>
              <Button
                  id="uploadButton"
                  icon="pi pi-upload"
                  @click="onFileUpload"
                  label="UPLOAD&nbsp;"/>
          </div>
      </div>
    </transition>
    <transition name="fadeinstant">
      <div v-if="tccDocsType === 'Prop'" class="col-12 md:col-12 lg:col-6">
        <h5 class="mx-2"><b>Upload Sale of Property</b></h5>
            <div class="flex justify-content-between mx-1 shadow-3" id="uploadBackground">
                <button class="file_upload" type="button">
                  <span class="btn_lbl"><i class="pi pi-file"></i>&nbsp;SELECT</span>
                  <span class="btn_colorlayer"></span>
                  <input type="file" name="fileupload" id="file_upload" accept="application/pdf, application/msword, image/*" @change="onFileChangedProperty" />
                </button>
              <Button
                  id="uploadButton"
                  icon="pi pi-upload"
                  @click="onFileUpload"
                  label="UPLOAD&nbsp;"/>
          </div>
      </div>
    </transition>
    <transition name="fadeinstant">
      <div v-if="tccDocsType === 'Dist'" class="col-12 md:col-12 lg:col-6">
        <h5 class="mx-2"><b>Upload Distribution from Trust</b></h5>
            <div class="flex justify-content-between mx-1 shadow-3" id="uploadBackground">
                <button class="file_upload" type="button">
                  <span class="btn_lbl"><i class="pi pi-file"></i>&nbsp;SELECT</span>
                  <span class="btn_colorlayer"></span>
                  <input type="file" name="fileupload" id="file_upload" accept="application/pdf, application/msword, image/*" @change="onFileChangedDistribution" />
                </button>
              <Button
                  id="uploadButton"
                  icon="pi pi-upload"
                  @click="onFileUpload"
                  label="UPLOAD&nbsp;"/>
          </div>
      </div>
    </transition>
    <transition name="fadeinstant">
      <div v-if="tccDocsType === 'Don'" class="col-12 md:col-12 lg:col-6">
        <h5 class="mx-2"><b>Upload Donation</b></h5>
            <div class="flex justify-content-between mx-1 shadow-3" id="uploadBackground">
                <button class="file_upload" type="button">
                  <span class="btn_lbl"><i class="pi pi-file"></i>&nbsp;SELECT</span>
                  <span class="btn_colorlayer"></span>
                  <input type="file" name="fileupload" id="file_upload" accept="application/pdf, application/msword, image/*" @change="onFileChangedDonation" />
                </button>
              <Button
                  id="uploadButton"
                  icon="pi pi-upload"
                  @click="onFileUpload"
                  label="UPLOAD&nbsp;"/>
          </div>
      </div>
    </transition>
    <transition name="fadeinstant">
      <div v-if="tccDocsType === 'Misc'" class="col-12 md:col-12 lg:col-6">
        <h5 class="mx-2"><b>Upload Miscellaneous Document </b></h5>
            <div class="flex justify-content-between mx-1 shadow-3" id="uploadBackground">
                <button class="file_upload" type="button">
                  <span class="btn_lbl"><i class="pi pi-file"></i>&nbsp;SELECT</span>
                  <span class="btn_colorlayer"></span>
                  <input type="file" name="fileupload" id="file_upload" accept="application/pdf, application/msword, image/*" @change="onFileChangedMiscellaneous" />
                </button>
              <Button
                  id="uploadButton"
                  icon="pi pi-upload"
                  @click="onFileUpload"
                  label="UPLOAD&nbsp;"/>
          </div>
      </div>
    </transition>
    </div>
    <br />
    <br />
    <hr class="mx-3 shadow-2"/>
    <div class="formgrid grid">
        <div class="col-12 md:col-12 lg:col-6">
            <h5 class="flex justify-content-between"><span class="mt-1 ml-2">Power of Attorney Upload</span>
              <div v-if="tccDocs.hasPowerOfAttorneyFile" class="flex justify-content-between mx-1">
                <span class="mt-1 mr-2">File Uploaded On: {{ tccDocs.powerOfAttorneyFileUploadedDate}}</span>
                <i class="pi pi-check-circle mx-3" id="fileCheck"></i>
                  </div>
                <div v-else class="flex justify-content-between mx-1">
                  <span class="mt-1 mr-2">No File Uploaded</span>
                  <i class="pi pi-times-circle mx-3" id="fileCross"></i>
                </div>
            </h5>
        </div>
        <div class="col-12 md:col-12 lg:col-6">
            <h5 class="flex justify-content-between mx-2"><span class="mt-1 ml-2">FIA 001 Upload</span>
              <div v-if="tccDocs.hasFia001File" class="flex justify-content-between mx-1">
                <span class="mt-1 mr-2">File Uploaded On: {{ tccDocs.fia001FileUploadedDate}}</span>
                <i class="pi pi-check-circle mx-3" id="fileCheck"></i>
                  </div>
                <div v-else class="flex justify-content-between mx-1">
                  <span class="mt-1 mr-2">No File Uploaded</span>
                  <i class="pi pi-times-circle mx-3" id="fileCross"></i>
                </div>
            </h5>
        </div>
    </div>
    <hr class="mx-3 shadow-2"/>
    <div class="formgrid grid">
        <div class="col-12 md:col-12 lg:col-6">
            <h5 class="flex justify-content-between"><span class="mt-1 ml-2">Assets &amp; Liabilities Upload</span>
              <div v-if="tccDocs.hasAssetsAndLiabilitiesFile" class="flex justify-content-between mx-1">
                <span class="mt-1 mr-2">File Uploaded On: {{ tccDocs.assetsAndLiabilitiesFileUploadedDate}}</span>
                <i class="pi pi-check-circle mx-3" id="fileCheck"></i>
                  </div>
                <div v-else class="flex justify-content-between mx-1">
                  <span class="mt-1 mr-2">No File Uploaded</span>
                  <i class="pi pi-times-circle mx-3" id="fileCross"></i>
                </div>
            </h5>
        </div>
        <div class="col-12 md:col-12 lg:col-6">
            <h5 class="flex justify-content-between mx-2"><span class="mt-1 ml-2">Bank Statements Upload</span>
              <div v-if="tccDocs.hasBankStatementsFile" class="flex justify-content-between mx-1">
                <span class="mt-1 mr-2">File Uploaded On: {{ tccDocs.bankStatementsFileUploadedDate}}</span>
                <i class="pi pi-check-circle mx-3" id="fileCheck"></i>
                  </div>
                <div v-else class="flex justify-content-between mx-1">
                  <span class="mt-1 mr-2">No File Uploaded</span>
                  <i class="pi pi-times-circle mx-3" id="fileCross"></i>
                </div>
            </h5>
        </div>
    </div>
    <hr class="mx-3 shadow-2"/>
    <div class="formgrid grid">
        <div class="col-12 md:col-12 lg:col-6">
            <h5 class="flex justify-content-between"><span class="mt-1 ml-2">Inheritance Upload</span>
              <div v-if="tccDocs.hasInheritanceFile" class="flex justify-content-between mx-1">
                <span class="mt-1 mr-2">File Uploaded On: {{ tccDocs.inheritanceFileUploadedDate}}</span>
                <i class="pi pi-check-circle mx-3" id="fileCheck"></i>
                  </div>
                <div v-else class="flex justify-content-between mx-1">
                  <span class="mt-1 mr-2">No File Uploaded</span>
                  <i class="pi pi-times-circle mx-3" id="fileCross"></i>
                </div>
            </h5>
        </div>
        <div class="col-12 md:col-12 lg:col-6">
            <h5 class="flex justify-content-between mx-2"><span class="mt-1 ml-2">Loans Upload</span>
              <div v-if="tccDocs.hasLoansFile" class="flex justify-content-between mx-1">
                <span class="mt-1 mr-2">File Uploaded On: {{ tccDocs.loansFileUploadedDate}}</span>
                <i class="pi pi-check-circle mx-3" id="fileCheck"></i>
                  </div>
                <div v-else class="flex justify-content-between mx-1">
                  <span class="mt-1 mr-2">No File Uploaded</span>
                  <i class="pi pi-times-circle mx-3" id="fileCross"></i>
                </div>
            </h5>
        </div>
    </div>
        <hr class="mx-3 shadow-2"/>
    <div class="formgrid grid">
        <div class="col-12 md:col-12 lg:col-6">
            <h5 class="flex justify-content-between"><span class="mt-1 ml-2">Investments Portfolio Upload</span>
              <div v-if="tccDocs.hasInvestmentsFile" class="flex justify-content-between mx-1">
                <span class="mt-1 mr-2">File Uploaded On: {{ tccDocs.investmentsFileUploadedDate}}</span>
                <i class="pi pi-check-circle mx-3" id="fileCheck"></i>
                  </div>
                <div v-else class="flex justify-content-between mx-1">
                  <span class="mt-1 mr-2">No File Uploaded</span>
                  <i class="pi pi-times-circle mx-3" id="fileCross"></i>
                </div>
            </h5>
        </div>
        <div class="col-12 md:col-12 lg:col-6">
            <h5 class="flex justify-content-between mx-2"><span class="mt-1 ml-2">Sale of Property Upload</span>
              <div v-if="tccDocs.hasPropertyFile" class="flex justify-content-between mx-1">
                <span class="mt-1 mr-2">File Uploaded On: {{ tccDocs.propertyFileUploadedDate}}</span>
                <i class="pi pi-check-circle mx-3" id="fileCheck"></i>
                  </div>
                <div v-else class="flex justify-content-between mx-1">
                  <span class="mt-1 mr-2">No File Uploaded</span>
                  <i class="pi pi-times-circle mx-3" id="fileCross"></i>
                </div>
            </h5>
        </div>
    </div>
    <hr class="mx-3 shadow-2"/>
    <div class="formgrid grid">
        <div class="col-12 md:col-12 lg:col-6">
            <h5 class="flex justify-content-between"><span class="mt-1 ml-2">Trust Distribution Upload</span>
              <div v-if="tccDocs.hasDistributionFile" class="flex justify-content-between mx-1">
                <span class="mt-1 mr-2">File Uploaded On: {{ tccDocs.distributionFileUploadedDate}}</span>
                <i class="pi pi-check-circle mx-3" id="fileCheck"></i>
                  </div>
                <div v-else class="flex justify-content-between mx-1">
                  <span class="mt-1 mr-2">No File Uploaded</span>
                  <i class="pi pi-times-circle mx-3" id="fileCross"></i>
                </div>
            </h5>
        </div>
        <div class="col-12 md:col-12 lg:col-6">
            <h5 class="flex justify-content-between mx-2"><span class="mt-1 ml-2">Donation Upload</span>
              <div v-if="tccDocs.hasDonationFile" class="flex justify-content-between mx-1">
                <span class="mt-1 mr-2">File Uploaded On: {{ tccDocs.donationFileUploadedDate}}</span>
                <i class="pi pi-check-circle mx-3" id="fileCheck"></i>
                  </div>
                <div v-else class="flex justify-content-between mx-1">
                  <span class="mt-1 mr-2">No File Uploaded</span>
                  <i class="pi pi-times-circle mx-3" id="fileCross"></i>
                </div>
            </h5>
        </div>
    </div>
    <hr class="mx-3 shadow-2"/>
    <div class="formgrid grid">
        <div class="col-12 md:col-12 lg:col-6">
            <h5 class="flex justify-content-between"><span class="mt-1 ml-2">Miscellaneous File Upload</span>
              <div v-if="tccDocs.hasMiscellaneousFile" class="flex justify-content-between mx-1">
                <span class="mt-1 mr-2">File Uploaded On: {{ tccDocs.miscellaneousFileUploadedDate}}</span>
                <i class="pi pi-check-circle mx-3" id="fileCheck"></i>
                  </div>
                <div v-else class="flex justify-content-between mx-1">
                  <span class="mt-1 mr-2">No File Uploaded</span>
                  <i class="pi pi-times-circle mx-3" id="fileCross"></i>
                </div>
            </h5>
        </div>
    </div>
    <hr class="mx-3 shadow-2"/>
  </div>
    <div v-if="formatErrorModal">
      <Dialog class="deleteModal" header="INVALID FILE FORMAT" v-model:visible="displayConfirmation">
          <div class="confirmation-content">
              <span>This file format is not accepted.</span>
              <br />
                    <p>Valid formats include:</p>
                    <p>.pdf, .docx, .doc, .jpg, .jpeg,</p>
                    <p>.jfif, .png, .webp, .heif, .heic and .avif</p>
          </div>
          <template #footer>
              <a class="p-button d-block mr-2 px-auto" @click.prevent="reloadPage"
                style="float: left; background-color: #014f7e!important;
                      text-decoration: none; justify-content: center;">
                <i class="pi pi-upload" style="fontSize: 1rem"></i> &nbsp;&nbsp;Upload Again
              </a>
          </template>
      </Dialog>
    </div>
      <div class="flex justify-content-between mx-2">
        <div>
            <Button class="l-2 m-3 mx-4 shadow-4 p-button-lg"
            icon="pi pi-step-backward"
            label="BACK&nbsp;"
            id="submitButton"
            @click.prevent="$router.push( {name: 'TccDocsHome', params: { id: tccDocs.tccId }})" />
        </div>
          <div>
            <Button class="l-2 m-3 mx-4 shadow-4 p-button-lg "
            icon="pi pi-download"
            label="DOWNLOADS"
            id="backButton"
            @click.prevent="$router.push( {name: 'TccDocsDownloads', params: { id: tccDocs.tccId }})" />
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import { getClientTccDocs, uploadTccDocs } from '@/gateway'

export default {
  name: 'TccDocsUploads',
  inheritAttrs: false,
  data () {
    return {
      displayConfirmation: true,
      tccDocs: {
        miscellaneousFileDisplayName: ''
      },
      selectedFilePowerOfAttorney: null,
      selectedFileFia001: null,
      selectedFileAssetsAndLiabilities: null,
      selectedFileBankStatements: null,
      selectedFileInheritance: null,
      selectedFileLoans: null,
      selectedFileInvestments: null,
      selectedFileProperty: null,
      selectedFileDistribution: null,
      selectedFileDonation: null,
      selectedFileMiscellaneous: null,
      tccDocsType: 'Poa',
      tccDocsTypes: [
        { value: 'Poa', text: 'Power of Attorney' },
        { value: 'Fia', text: 'FIA 001 Form' },
        { value: 'A&L', text: 'Assets and Liabilities' },
        { value: 'Bank', text: 'Bank Statements' },
        { value: 'Inh', text: 'Inheritance' },
        { value: 'Loan', text: 'Loans' },
        { value: 'Inv', text: 'Investment Portfolio' },
        { value: 'Prop', text: 'Sale of Property' },
        { value: 'Dist', text: 'Distribution from Trust' },
        { value: 'Don', text: 'Donation' },
        { value: 'Misc', text: 'Miscellaneous' }
      ],
      formatErrorModal: false
    }
  },
  methods: {
    async init () {
      const response = await getClientTccDocs({ id: this.id })
      if (response) {
        this.tccDocs = response.result
      }
    },
    onFileChanged (event) {
      this.selectedFile = event.target.files[0]
    },
    onFileChangedPowerOfAttorney (event) {
      this.selectedFilePowerOfAttorney = event.target.files[0]
    },
    onFileChangedFia001 (event) {
      this.selectedFileFia001 = event.target.files[0]
    },
    onFileChangedAssetsAndLiabilities (event) {
      this.selectedFileAssetsAndLiabilities = event.target.files[0]
    },
    onFileChangedBankStatements (event) {
      this.selectedFileBankStatements = event.target.files[0]
    },
    onFileChangedInheritance (event) {
      this.selectedFileInheritance = event.target.files[0]
    },
    onFileChangedLoans (event) {
      this.selectedFileLoans = event.target.files[0]
    },
    onFileChangedInvestments (event) {
      this.selectedFileInvestments = event.target.files[0]
    },
    onFileChangedProperty (event) {
      this.selectedFileProperty = event.target.files[0]
    },
    onFileChangedDistribution (event) {
      this.selectedFileDistribution = event.target.files[0]
    },
    onFileChangedDonation (event) {
      this.selectedFileDonation = event.target.files[0]
    },
    onFileChangedMiscellaneous (event) {
      this.selectedFileMiscellaneous = event.target.files[0]
    },
    async onFileUpload () {
      if (this.selectedFilePowerOfAttorney) {
        const response = await uploadTccDocs({
          selectedFile: this.selectedFilePowerOfAttorney,
          fileName: this.tccDocs.powerOfAttorneyFileName,
          fileType: 'Power of Attorney',
          id: this.id
        })
        if (response.message === 'Invalid Format') {
          this.formatErrorModal = true
        } else {
          this.reloadPage()
          this.tccDocsType = 'Fia'
        }
      }
      if (this.selectedFileFia001) {
        const response = await uploadTccDocs({
          selectedFile: this.selectedFileFia001,
          fileName: this.tccDocs.fia001FileName,
          fileType: 'FIA 001',
          id: this.id
        })
        if (response.message === 'Invalid Format') {
          this.formatErrorModal = true
        } else {
          this.reloadPage()
          this.tccDocsType = 'A&L'
        }
      }
      if (this.selectedFileAssetsAndLiabilities) {
        const response = await uploadTccDocs({
          selectedFile: this.selectedFileAssetsAndLiabilities,
          fileName: this.tccDocs.assetsAndLiabilitiesFileName,
          fileType: 'Assets & Liabilities',
          id: this.id
        })
        if (response.message === 'Invalid Format') {
          this.formatErrorModal = true
        } else {
          this.reloadPage()
          this.tccDocsType = 'Bank'
        }
      }
      if (this.selectedFileBankStatements) {
        const response = await uploadTccDocs({
          selectedFile: this.selectedFileBankStatements,
          fileName: this.tccDocs.bankStatementsFileName,
          fileType: 'Bank Statements',
          id: this.id
        })
        if (response.message === 'Invalid Format') {
          this.formatErrorModal = true
        } else {
          this.reloadPage()
          this.tccDocsType = 'Inh'
        }
      }
      if (this.selectedFileInheritance) {
        const response = await uploadTccDocs({
          selectedFile: this.selectedFileInheritance,
          fileName: this.tccDocs.inheritanceFileName,
          fileType: 'Inheritance',
          id: this.id

        })
        if (response.message === 'Invalid Format') {
          this.formatErrorModal = true
        } else {
          this.reloadPage()
          this.tccDocsType = 'Loan'
        }
      }
      if (this.selectedFileLoans) {
        const response = await uploadTccDocs({
          selectedFile: this.selectedFileLoans,
          fileName: this.tccDocs.loansFileName,
          fileType: 'Loans',
          id: this.id
        })
        if (response.message === 'Invalid Format') {
          this.formatErrorModal = true
        } else {
          this.reloadPage()
          this.tccDocsType = 'Inv'
        }
      }
      if (this.selectedFileInvestments) {
        const response = await uploadTccDocs({
          selectedFile: this.selectedFileInvestments,
          fileName: this.tccDocs.investmentsFileName,
          fileType: 'Investments',
          id: this.id
        })
        if (response.message === 'Invalid Format') {
          this.formatErrorModal = true
        } else {
          this.reloadPage()
          this.tccDocsType = 'Prop'
        }
      }
      if (this.selectedFileProperty) {
        const response = await uploadTccDocs({
          selectedFile: this.selectedFileProperty,
          fileName: this.tccDocs.propertyFileName,
          fileType: 'Property',
          id: this.id
        })
        if (response.message === 'Invalid Format') {
          this.formatErrorModal = true
        } else {
          this.reloadPage()
          this.tccDocsType = 'Dist'
        }
      }
      if (this.selectedFileDistribution) {
        const response = await uploadTccDocs({
          selectedFile: this.selectedFileDistribution,
          fileName: this.tccDocs.distributionFileName,
          fileType: 'Distribution',
          id: this.id
        })
        if (response.message === 'Invalid Format') {
          this.formatErrorModal = true
        } else {
          this.reloadPage()
          this.tccDocsType = 'Don'
        }
      }
      if (this.selectedFileDonation) {
        const response = await uploadTccDocs({
          selectedFile: this.selectedFileDonation,
          fileName: this.tccDocs.donationFileName,
          fileType: 'Donation',
          id: this.id

        })
        if (response.message === 'Invalid Format') {
          this.formatErrorModal = true
        } else {
          this.reloadPage()
          this.tccDocsType = 'Misc'
        }
      }
      if (this.selectedFileMiscellaneous) {
        const response = await uploadTccDocs({
          selectedFile: this.selectedFileMiscellaneous,
          fileName: this.tccDocs.miscellaneousFileName,
          fileType: 'Miscellaneous',
          id: this.id
        })
        if (response.message === 'Invalid Format') {
          this.formatErrorModal = true
        } else {
          this.reloadPage()
          this.tccDocsType = 'Poa'
        }
      }
    },
    toggle (event) {
      this.$refs.op.toggle(event)
    },
    async reloadPage () {
      this.$router.push({ name: 'ReloadSpinnerTccDocs', params: { id: this.$route.params.id } })
    }
  },
  mounted () {
    this.id = this.$route.params.id
    this.init()
  }
}
</script>

<style lang="scss" scoped>
#uploadBackground {
 @include uploadBackground($uploadBackgroundColor)
};
#fileButton {
  background-color: $midDarkBlue!important;
  color: $textColor;
  font-size: 1.25rem;
  border: 2px solid;
  border-color: $borderColour;
  display: flex;
  justify-content: right;
};
#uploadButton {
  background-color: $lightMidBlue!important;
  min-width: 8rem;
  color: $textColor;
  font-size: 1.25rem;
  height: 2.5rem;
  border: solid 1px $borderColour!important;
  display: flex;
  justify-content: right;
};
.file_upload {
  position: relative;
  min-width: 8rem;
  height: 2.5rem;
  text-align: center;
  color: $textColor;
  line-height: 25px;
  background-color: $midDarkBlue!important;
  border: solid 1px $borderColour;
  font-family: $fontFamily;
  font-size: 1.25rem;
  font-family: Poppins!important;
  font-weight: 400!important;
  border-radius: 4px;
}
a.file_upload {
  display: inline-block;
}
.file_upload .btn_lbl {
  position: relative;
  z-index: 2;
  pointer-events: none;
}
.file_upload .btn_colorlayer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: $midDarkBlue!important;;
  z-index: 1;
  pointer-events: none;
}
@-moz-document url-prefix() {
    .file_upload input[type="file"] {
    position: absolute;
    top: 3px;
    left: -78px!important;
    font-weight: 600;
    margin-left: 100%;
    margin-top: 3px;
    color: $darkTextColour;
    outline: none;
    font-size: 1rem;;
    font-family: $fontFamily;
    height: 2.5rem;
  };
}
.file_upload input[type="file"] {
  position: absolute;
  top: 3px;
  left: -102px;
  font-weight: 600;
  margin-left: 100%;
  margin-top: 3px;
  color: $darkTextColour;
  outline: none;
  font-size: 1rem;;
  font-family: $fontFamily;
  height: 2.5rem;
};
@media screen and (max-width: 767px) {
  .file_upload input[type="file"] {
     color: transparent!important;
};
}
#fileCheck {
  color: $successGreen!important;
  font-size: 1.65rem;
  margin-top: 0.15rem;
};
#fileCross {
  color: $queuedBackground!important;
  font-size: 1.8rem;
  margin-top: 0.1rem;
};
</style>
