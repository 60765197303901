<template>
  <br />
  <h2 class="text-center my-2">CLIENT PROFILE FOR : {{ retailClient.firstLastName }}</h2>
  <br />
  <Panel class="mx-3 shadow-4" header="DETAILS" :toggleable="true" :collapsed="true">
  <template #icons>
      <button class="p-panel-header-icon p-link p-mr-2" @click="toggle"></button>
  </template>
  <div class="fluid mx-2 shadow-2">
    <DataTable :value="retailClients"
                showGridlines
                responsiveLayout="stack"
                breakpoint="1024px"
                class="p-datatable-retailClient">
        <Column field="id" header="ID" headerStyle="width: 1vw"></Column>
        <Column field="partnerName" header="Partner" headerStyle="width: 5vw"></Column>
        <Column field="emailAddress" header="Email" headerStyle="width: 11vw"></Column>
        <Column field="contactNumber1" header="Contact" headerStyle="width: 5vw"></Column>
        <Column field="idNumber" header="ID Number" headerStyle="width: 6vw"></Column>
        <Column field="taxRefNumber" header="Tax Number" headerStyle="width: 5vw"></Column>
        <Column field="clientStatus" header="Status" headerStyle="width: 5vw">
          <template #body="{data}">
            <div v-if="data.status === 'processing' && data.progressOne === true && data.progressTwo === true && data.progressThree === true">
              <span :class="'retailClient-badge status-' + data.status"  v-tooltip.bottom="{value:'75% : Forms Received', class: 'progress'}">{{data.status}}</span>
            </div>
            <div v-else-if="data.status === 'processing' && data.progressOne === true && data.progressTwo === true">
              <span :class="'retailClient-badge status-' + data.status"  v-tooltip.bottom="{value:'50% : Risk Rating', class: 'progress'}">{{data.status}}</span>
            </div>
             <div v-else-if="data.status === 'processing' && data.progressOne === true">
              <span :class="'retailClient-badge status-' + data.status"  v-tooltip.bottom="{value:'25% : Data Entered', class: 'progress'}">{{data.status}}</span>
            </div>
            <div v-else>
              <span :class="'retailClient-badge status-' + data.status">{{data.status}}</span>
            </div>
          </template>
        </Column>
        <Column header="Edit" headerStyle="width: 4vw;">
            <template #body="{data}">
              <div v-if="retailClient.isEditing === true">
               <a class="p-button d-block mx-1 px-1 shadow-1" id="disabled" :disabled="true"
                  style="background-colour:#E6E6F0!important; color: #045889!important; text-decoration: none;
                         display: flex; justify-content: center;">
                 <i class="pi pi-user-edit" style="fontSize: 1rem"></i>&nbsp;&nbsp;EDITING
              </a>
              </div>
              <div v-else>
                <router-link class="p-button d-block mx-auto px-auto" :to="'/EditClient/' + data.id"  style="color: #e2e7e5; text-decoration: none;
                                    display: flex; justify-content: center;">
                  <i class="pi pi-user-edit" style="fontSize: 1rem"></i>&nbsp;&nbsp;EDIT
                </router-link>
              </div>
            </template>
        </Column>
        <Column header="Activation" headerStyle="width: 4vw;">
            <template #body>
              <div v-if="retailClient.status == 'deactivated'">
                 <a class="p-button-raised d-block mx-auto px-auto" disabled="disabled"  @click="openDelete"  style="color: #e2e7e5; background: #d2161e!important;  text-decoration: none;
                                   display: flex; justify-content: center;">
                 <i class="pi pi-eye-slash" style="fontSize: 1rem"></i>&nbsp;&nbsp;DEACTIVATE
              </a>
              </div>
              <div v-else-if="retailClient.status != 'deactivated'">
               <a class="p-button d-block mx-auto px-auto" @click="openDeactivate"  style="color: #e2e7e5; background: #d2161e!important;  text-decoration: none;
                                   display: flex; justify-content: center;">
                 <i class="pi pi-eye-slash" style="fontSize: 1rem"></i>&nbsp;&nbsp;DEACTIVATE
              </a>
              </div>
            </template>
        </Column>
    </DataTable>
  </div>
  </Panel>
  <br />
  <Panel class="mx-3 shadow-4" header="KYC, FORMS &amp; FICA" :toggleable="true" :collapsed="true">
    <template #icons>
        <button class="p-panel-header-icon p-link p-mr-2" @click="toggle"></button>
    </template>
    <div class="fluid grid">
      <div class="col-12 md:col-12 lg:col-4">
        <div v-if="retailClient.isSanctionsCheckComplete == false">
          <Card class="shadow-5 text-center d-block mx-auto" id="rMatrix">
              <template class="text-center" #title>
                <i class="pi pi-exclamation-triangle" id="cardLogo"></i>
              </template>
              <template  #content>
                <a @click="openSanctionsCheck" style="color: #e2e7e5; text-decoration: none; cursor: pointer;">
                RISK
                </a>
              </template>
          </Card>
        </div>
        <div v-else>
          <div v-if="retailClient.hasFicaUpload == true">
              <Card class="shadow-5 text-center d-block mx-auto" id="rMatrix">
                <template class="text-center" #title>
                  <i class="pi pi-exclamation-triangle" id="cardLogo"></i>
                </template>
                <template  #content>
                  <router-link :to="'/clientRiskMatrix/' + retailClient.id" style="color: #e2e7e5; text-decoration: none">
                    RISK
                  </router-link>
                </template>
            </Card>
          </div>
          <div v-else>
              <Card class="shadow-5 text-center d-block mx-auto" id="rMatrix">
                  <template class="text-center" #title>
                  <i class="pi pi-exclamation-triangle" id="cardLogo"></i>
                  </template>
                  <template  #content>
                    <a @click="saveClientFica" style="color: #e2e7e5; text-decoration: none; cursor: pointer;">
                      RISK
                    </a>
                  </template>
              </Card>
          </div>
        </div>
      </div>
        <div class="col-12 md:col-12 lg:col-4">
          <div v-if="retailClient.higherRiskClientsChecksDone === false">
            <Card class="shadow-5 text-center d-block mx-auto" id="formsDisabled" v-tooltip.right="'Risk Matrix not yet completed'">
                <template class="text-center" #title>
                  <i class="pi pi-cloud-download" id="cardLogo"></i>
                </template>
                <template  #content>
                  <a style="color: #e2e7e5; text-decoration: none">
                  FORMS
                  </a>
                </template>
            </Card>
        </div>
        <div v-else>
            <Card class="shadow-5 text-center d-block mx-auto" id="forms">
                <template class="text-center" #title>
                  <i class="pi pi-cloud-download" id="cardLogo"></i>
                </template>
                <template  #content>
                  <router-link :to="'/clientFormsView/' + retailClient.id" style="color: #e2e7e5; text-decoration: none">
                  FORMS
                  </router-link>
                </template>
            </Card>
        </div>
      </div>
      <div class="col-12 md:col-12 lg:col-4">
          <Card class="shadow-5 text-center d-block mx-auto" id="fica">
              <template class="text-center" #title>
                <i class="pi pi-cloud-upload" id="cardLogo"></i>
              </template>
              <template  #content>
                <router-link :to="'/ficaHome/' + retailClient.id" style="color: #e2e7e5; text-decoration: none">
                FICA
                </router-link>
              </template>
          </Card>
      </div>
    </div>
  </Panel>
  <br />
  <Panel class="mx-3 shadow-4" header="TCC, TRADES &amp; BALANCES" :toggleable="true" :collapsed="true">
  <template #icons>
      <button class="p-panel-header-icon p-link mr-2" @click="toggle"></button>
  </template>
  <div class="fluid grid">
      <div class="col-12 md:col-12 lg:col-4">
        <Card class="shadow-5 text-center d-block mx-auto" id="tcc">
            <template class="text-center" #title>
              <i class="pi pi-folder-open" id="cardLogo"></i>
            </template>
            <template  #content>
              <router-link :to="'/tccClientView/' + retailClient.id" style="color: #e2e7e5; text-decoration: none">
              TCC
              </router-link>
            </template>
        </Card>
      </div>
       <div class="col-12 md:col-12 lg:col-4">
        <Card class="shadow-5 text-center d-block mx-auto" id="trades">
            <template class="text-center" #title>
              <i class="pi pi-globe" id="cardLogo"></i>
            </template>
            <template  #content>
              <router-link :to="'/clientTradesView/' + retailClient.id" style="color: #e2e7e5; text-decoration: none">
              TRADES
              </router-link>
            </template>
        </Card>
      </div>
      <div class="col-12 md:col-12 lg:col-4">
        <Card class="shadow-5 text-center d-block mx-auto" id="balances">
            <template class="text-center" #title>
              <i class="pi pi-chart-bar" id="cardLogo"></i>
            </template>
            <template  #content>
              <router-link :to="'/balancesHomeView/' + retailClient.id" style="color: #e2e7e5; text-decoration: none">
              BALANCES
              </router-link>
            </template>
        </Card>
      </div>
  </div>
    </Panel>
    <div>
    <Button class="l-2 m-4 mx-3 shadow-3 p-button-lg"
    icon="pi pi-step-backward"
    label="BACK&nbsp;"
    id="submitButton"
    @click.prevent="$router.push( {name: 'ClientListView' })" />
   </div>
   <!-- Success and Delete Modals -->
    <Dialog class="deleteModal" header="CONFIRM DEACTIVATION" v-model:visible="openDeactivateModal" :modal="true">
        <div class="confirmation-content">
            <span>Are You Sure You Want To Deactivate This Client?</span>
            <br />
                  Client cannot be activated from FxHub, please contact
                  <br />
                  the system administrator to re-activate a client profile.
        </div>
        <template #footer>
          <div class="flex justify-content-between">
            <a class="p-button d-block mr-2 px-auto" @click="closeDeactivate"  style="background-color: #014f7e!important;
                      text-decoration: none; width: 40%; justify-content: center;">
              <i class="pi pi-step-backward" style="fontSize: 1rem"></i> &nbsp;&nbsp;Close
            </a>
            <a class="p-button d-block mx-2 px-auto" @click.prevent="deactivateRetailClient"  style="background-color: #d2161e!important;
                      text-decoration: none; width: 40%; justify-content: center;">
              <i class="pi pi-eye-slash" style="fontSize: 1rem"></i>&nbsp;&nbsp;Deactivate
            </a>
          </div>
        </template>
    </Dialog>
    <div class="formgrid grid">
      <div class="col-12 md:col-6 lg:col-6">
        <div v-if='success'>
        <Dialog class="successModal" header="SUCCESS" id="successModal" v-model:visible="displayConfirmation" :modal="true">
            <div class="confirmation-content">
                <span>Client Deactivated From FxHub</span>
            </div>
            <template #footer>
              <div class="flex justify-content-between">
                <router-link class="p-button d-block mr-2 px-auto" :to="'/clientHome'"  style="background-color: #014f7e!important; text-decoration: none;
                                    text-decoration: none; width: 40%; justify-content: center;">
                  <i class="pi pi-home" style="fontSize: 1rem"></i> &nbsp;Back
                </router-link>
                <router-link class="p-button d-block mx-2 px-auto" :to="'/clientListView'"  style="background-color: #0185b7!important; text-decoration: none
                                    text-decoration: none; width: 40%; justify-content: center;">
                  <i class="pi pi-users" style="fontSize: 1rem"></i>&nbsp;View
                </router-link>
              </div>
            </template>
        </Dialog>
        </div>
      </div>
    </div>
     <div class=" formgrid grid">
      <div class="col-12 md:col-6 lg:col-6">
        <Dialog header="PEP &amp; SANCTIONS CHECK" v-model:visible="displaySanctionsCheck" :modal="true">
            <div class="confirmation-content">
                <span>
                  <div>
                    <h5>Screening Results</h5>
                    <Dropdown v-model="retailClient.sanctionsScreen"
                    :options="sanctionsScreens"
                    optionLabel="text"
                    optionValue="value"
                    class="inputfield w-full"
                    placeholder="Please Select"/>
                </div>
             </span>
            </div>
            <template #footer>
              <div class="flex justify-content-between">
                <a class="p-button d-block mr-2 px-auto" @click="closeSanctionsCheck"  style="background-color: #014f7e!important;
                          text-decoration: none; width: 40%; justify-content: center;">
                  <i class="pi pi-step-backward" style="fontSize: 1rem"></i>&nbsp;&nbsp;Back
                </a>
                <a class="p-button d-block mx-2 px-auto" @click.prevent="saveSanctionsCheck" style="background-color: #0185b7!important;
                          text-decoration: none; width: 40%; justify-content: center;">
                  <i class="pi pi-upload" style="fontSize: 1rem"></i>&nbsp;&nbsp;Submit
                </a>
              </div>
            </template>
        </Dialog>
      </div>
    </div>
     <div class="formgrid grid">
      <div class="col-12 md:col-6 lg:col-6">
        <div v-if='sanctionsCheckSuccess'>
        <Dialog class="successModal" header="SUCCESS" id="successModal" v-model:visible="displayConfirmation"  :modal="true">
            <div class="confirmation-content">
                <span>Sanctions Check Saved</span>
            </div>
            <template class="justify-content-center mx-4" #footer>
                <a class="p-button d-block mx-auto px-2" @click="submitSanctionsScreen"  style="background-color: #0185b7!important;
                                    text-decoration: none; min-width: 100%; justify-content: center;">
                  <i class="pi pi-exclamation-triangle" style="fontSize: 1rem"></i>&nbsp;&nbsp;Create Risk Matrix
                </a>
            </template>
        </Dialog>
        </div>
      </div>
    </div>
</template>

<script>
// @ is an alias to /src
import { getRetailClient, deactivateClient, createRiskMatrix, sanctionsCheckResult, createClientFica } from '@/gateway'

export default {
  name: 'ClientView',
  inheritAttrs: false,
  data () {
    return {
      retailClients: [],
      retailClient: {},
      messages: [],
      riskRating: [],
      success: false,
      riskMatrixSuccess: false,
      sanctionsCheckSuccess: false,
      displayConfirmation: true,
      displaySanctionsCheck: false,
      openDeactivateModal: false,
      sanctionsScreens: [
        { value: 'None', text: 'None' },
        { value: 'Minor', text: 'Minor' },
        { value: 'PEP', text: 'PEP' },
        { value: 'Sanctions', text: 'Sanctions' }
      ],
      fica: {
        id: 0,
        clientId: 0,
        clientName: '',
        identityDocumentFileName: '',
        identityDocumentFileDisplayName: '',
        identityDocumentFileUploadedDate: null,
        hasIdentityDocumentFile: false,
        proofOfResidenceFileName: '',
        proofOfResidenceFileDisplayName: '',
        proofOfResidenceFileUploadedDate: null,
        hasProofOfResidenceFile: false,
        powerOfAttorneyFileName: '',
        powerOfAttorneyFileDisplayName: '',
        powerOfAttorneyFileUploadedDate: null,
        hasPowerOfAttorneyFile: false,
        marriageCertificateFileName: '',
        marriageCertificateFileDisplayName: '',
        marriageCertificateFileUploadedDate: null,
        hasMarriageCertificateFile: false,
        clientImageFileName: '',
        clientImageFileDisplayName: '',
        clientImageFileUploadedDate: null,
        hasClientImageFile: false,
        miscellaneousOneFileName: '',
        miscellaneousOneFileDisplayName: '',
        miscellaneousOneFileUploadedDate: null,
        hasMiscellaneousOneFile: false,
        timImageFileName: '',
        timFileDisplayName: '',
        timFileUploadedDate: null,
        hasTimFile: false,
        pepImageFileName: '',
        pepFileDisplayName: '',
        pepFileUploadedDate: null,
        hasPepFile: false
      }
    }
  },
  methods: {
    toggle (event) {
      this.$refs.menu.toggle(event)
    },
    toggled (event) {
      this.$refs.op.toggle(event)
    },
    openSanctionsCheck () {
      this.displaySanctionsCheck = true
    },
    closeSanctionsCheck () {
      this.displaySanctionsCheck = false
    },
    openDeactivate () {
      this.openDeactivateModal = true
    },
    closeDeactivate () {
      this.openDeactivateModal = false
    },
    async init () {
      const response = await getRetailClient({ id: this.id })
      if (response) {
        this.retailClient = response.result
        this.retailClients.push(this.retailClient)
      }
    },
    async deactivateRetailClient (id) {
      this.openDeactivateModal = false
      const response = await deactivateClient({ retailClient: this.retailClient })
      if (response) {
        this.success = true
      }
    },
    async submitSanctionsScreen () {
      this.displaySanctionsCheck = false
      const response = await createRiskMatrix({ retailClient: this.retailClient })
      if (response) {
        this.riskMatrixSuccess = true
        this.$router.push('/clientRiskMatrix/' + this.id)
      }
    },
    async saveSanctionsCheck () {
      this.displaySanctionsCheck = false
      const response = await sanctionsCheckResult({ retailClient: this.retailClient })
      if (response) {
        this.sanctionsCheckSuccess = true
      }
    },
    async saveClientFica () {
      const response = await createClientFica({ fica: this.fica, retailClient: this.retailClient })
      if (response) {
        this.$router.push('/clientRiskMatrix/' + this.retailClient.id)
      }
    }
  },
  mounted () {
    this.id = this.$route.params.id
    this.init()
  }
}
</script>

<style lang="scss" scoped>
::v-deep() {
  .p-datatable .p-datatable-thead > tr > th {
    background-color: $middleBlue!important;
    color: $textColor!important;
    border: 1px solid $darkTextColour!important;
    font-size: 18px;
    font-weight: 600;
  }
  .p-datatable .p-datatable-tbody > tr > td {
    background-color: $clientViewTableBackground;
    border: 1px solid $darkTextColour!important;
    font-family:'Poppins', sans-serif;
    font-weight: 400;
    padding: .5rem .5rem;
  }
    .retailClient-badge {
      @include statusBadge
  };
  #forms {
  @include cardComponent($middleBlue)
  };
  #fica {
  @include cardComponent($lightMidBlue)
  };
  #rMatrix {
  @include cardComponent($midDarkBlue)
  };
  #tcc {
  @include cardComponent($midDarkBlue)
  };
  #trades {
  @include cardComponent($middleBlue)
  };
  #balances {
  @include cardComponent($lightMidBlue)
  };
  #formsDisabled {
  @include cardComponent($midddleBlueDisabled)
  };
  #disabled {
    background: $textColor!important;
    color: $middleBlue!important;
  };
  .p-card.p-component.p-card-content {
  font-size: 24px;
  };
  .p-grid {
    margin: 1rem;
    padding: 1rem;
  };
};
</style>
