<template>
  <br />
  <h2 class="text-center my-2">CLIENT TCC DOCUMENTS FOR : {{ tccDocs.clientName }}  ~ FOR TCC ID : {{ tccDocs.tccId }}</h2>
  <br />
  <div class=" fluid mx-1" id="mobileView">
      <div v-if="tccDocs.hasPowerOfAttorneyFile">
          <Button class="p-button d-block mx-3 py-3 px-auto" :loading="loadingPoA" @click.prevent="downloadTccDocs('Power of Attorney')"
                  style="color: #e2e7e5; background: #062d5d!important; text-decoration: none;
                  display: flex; justify-content: center; width: 93%;">
                  <i class="pi pi-cloud-download mx-1" style="fontSize: 1rem"></i>&nbsp;Attorney
          </Button>
      </div>
        <div v-else>
          <a class="p-button d-block mx-3 py-3 px-auto" :disabled="true"
                  style="color: #e2e7e5; background: rgba(6, 45, 107, 0.8)!important; text-decoration: none;
                  display: flex; justify-content: center; width: 93%;"
                  icon="pi pi-cloud-download">No File Uploaded
          </a>
        </div>
      <br />
      <div v-if="tccDocs.hasFia001File">
            <Button class="p-button d-block mx-3 py-3 px-auto" :loading="loadingFia" @click.prevent="downloadTccDocs('FIA 001')"
                    style="color: #e2e7e5; background: #063a6a!important; text-decoration: none;
                    display: flex; justify-content: center; width: 93%;">
                    <i class="pi pi-cloud-download mx-1" style="fontSize: 1rem"></i>&nbsp;FIA 001
            </Button>
        </div>
         <div v-else>
            <a class="p-button d-block mx-3 py-3 px-auto" :disabled="true"
                    style="color: #e2e7e5; background: rgba(6, 45, 93, 0.7)!important; text-decoration: none;
                    display: flex; justify-content: center; width: 93%;"
                    icon="pi pi-cloud-download">No File Uploaded
            </a>
         </div>
        <br />
        <div v-if="tccDocs.hasAssetsAndLiabilitiesFile">
            <Button class="p-button d-block mx-3 py-3 px-auto" :loading="loadingAandL" @click.prevent="downloadTccDocs('Assets & Liabilities')"
                    style="color: #e2e7e5; background: #036495!important; text-decoration: none;
                    display: flex; justify-content: center; width: 93%">
                    <i class="pi pi-cloud-download mx-1" style="fontSize: 1rem"></i>&nbsp;Assets &amp; Liabilities
            </Button>
        </div>
         <div v-else>
            <a class="p-button d-block mx-3 py-3 px-auto" :disabled="true"
                    style="color: #e2e7e5; background: rgba(4, 88, 137, 0.7)!important; text-decoration: none;
                    display: flex; justify-content: center; width: 93%;"
                    icon="pi pi-cloud-download">No File Uploaded
            </a>
         </div>
        <br />
        <div v-if="tccDocs.hasBankStatementsFile">
            <Button class="p-button d-block mx-3 py-3 px-auto" :loading="loadingBank" @click.prevent="downloadTccDocs('Bank Statements')"
                    style="color: #e2e7e5; background: #0185b7!important; text-decoration: none;
                    display: flex; justify-content: center; width: 93%;">
                    <i class="pi pi-cloud-download mx-1" style="fontSize: 1rem"></i>&nbsp;Bank Statements
            </Button>
        </div>
         <div v-else>
            <a class="p-button d-block mx-3 py-3 px-auto" :disabled="true"
                    style="color: #e2e7e5; background: rgba(1, 133, 183, 0.7)!important; text-decoration: none;
                    display: flex; justify-content: center; width: 93%;"
                    icon="pi pi-cloud-download">No File Uploaded
            </a>
         </div>
        <br />
        <div v-if="tccDocs.hasInheritanceFile">
            <Button class="p-button d-block mx-3 py-3 px-auto" :loading="loadingInh"  @click.prevent="downloadTccDocs('Inheritance')"
                    style="color: #e2e7e5; background: #062d5d!important;  text-decoration: none;
                    display: flex; justify-content: center; width: 93%;">
                    <i class="pi pi-cloud-download mx-1" style="fontSize: 1rem"></i>&nbsp;Inheritance
            </Button>
        </div>
         <div v-else>
            <a class="p-button d-block mx-3 py-3 px-auto" :disabled="true"
                    style="color: #e2e7e5; background: rgba(6, 45, 107, 0.8)!important; text-decoration: none;
                    display: flex; justify-content: center; width: 93%;"
                    icon="pi pi-cloud-download">No File Uploaded
            </a>
         </div>
        <br />
        <div v-if="tccDocs.hasLoansFile">
            <Button class="p-button d-block mx-3 py-3 px-auto" :loading="loadingLoan" @click.prevent="downloadTccDocs('Loans')"
                    style="color: #e2e7e5; background: #063a6a!important; text-decoration: none;
                    display: flex; justify-content: center; width: 93%">
                    <i class="pi pi-cloud-download mx-1" style="fontSize: 1rem"></i>&nbsp;Loans
            </Button>
        </div>
         <div v-else>
            <a class="p-button d-block mx-3 py-3 px-auto" :disabled="true"
                    style="color: #e2e7e5; background: rgba(6, 45, 93, 0.7)!important; text-decoration: none;
                    display: flex; justify-content: center; width: 93%;"
                    icon="pi pi-cloud-download">No File Uploaded
            </a>
         </div>
         <br />
      <div v-if="tccDocs.hasInvestmentsFile">
          <Button class="p-button d-block mx-3 py-3 px-auto" :loading="loadingInv" @click.prevent="downloadTccDocs('Investments')"
                  style="color: #e2e7e5; background: #036495!important; text-decoration: none;
                  display: flex; justify-content: center; width: 93%;">
                  <i class="pi pi-cloud-download mx-1" style="fontSize: 1rem"></i>&nbsp;Investments
          </Button>
      </div>
        <div v-else>
          <a class="p-button d-block mx-3 py-3 px-auto" :disabled="true"
                  style="color: #e2e7e5; background: rgba(4, 88, 137, 0.7)!important; text-decoration: none;
                  display: flex; justify-content: center; width: 93%;"
                  icon="pi pi-cloud-download">No File Uploaded
          </a>
        </div>
      <br />
      <div v-if="tccDocs.hasPropertyFile">
            <Button class="p-button d-block mx-3 py-3 px-auto" :loading="loadingProp" @click.prevent="downloadTccDocs('Property')"
                    style="color: #e2e7e5; background: #0185b7!important; text-decoration: none;
                    display: flex; justify-content: center; width: 93%;">
                    <i class="pi pi-cloud-download mx-1" style="fontSize: 1rem"></i>&nbsp;Property
            </Button>
        </div>
         <div v-else>
            <a class="p-button d-block mx-3 py-3 px-auto" :disabled="true"
                    style="color: #e2e7e5; background: rgba(1, 133, 183, 0.7)!important; text-decoration: none;
                    display: flex; justify-content: center; width: 93%;"
                    icon="pi pi-cloud-download">No File Uploaded
            </a>
         </div>
        <br />
        <div v-if="tccDocs.hasDistributionFile">
            <Button class="p-button d-block mx-3 py-3 px-auto" :loading="loadingDist" @click.prevent="downloadTccDocs('Distribution')"
                    style="color: #e2e7e5; background: #062d5d!important; text-decoration: none;
                    display: flex; justify-content: center; width: 93%">
                    <i class="pi pi-cloud-download mx-1" style="fontSize: 1rem"></i>&nbsp;Distribution
            </Button>
        </div>
         <div v-else>
            <a class="p-button d-block mx-3 py-3 px-auto" :disabled="true"
                    style="color: #e2e7e5; background: rgba(6, 45, 107, 0.8)!important; text-decoration: none;
                    display: flex; justify-content: center; width: 93%;"
                    icon="pi pi-cloud-download">No File Uploaded
            </a>
         </div>
        <br />
        <div v-if="tccDocs.hasDonationFile">
            <Button class="p-button d-block mx-3 py-3 px-auto" :loading="loadingDon" @click.prevent="downloadTccDocs('Donation')"
                    style="color: #e2e7e5; background: #063a6a!important; text-decoration: none;
                    display: flex; justify-content: center; width: 93%">
                    <i class="pi pi-cloud-download mx-1" style="fontSize: 1rem"></i>&nbsp;Donation
            </Button>
        </div>
         <div v-else>
            <a class="p-button d-block mx-3 py-3 px-auto" :disabled="true"
                    style="color: #e2e7e5; background: rgba(6, 45, 93, 0.7)!important; text-decoration: none;
                    display: flex; justify-content: center; width: 93%;"
                    icon="pi pi-cloud-download">No File Uploaded
            </a>
         </div>
        <br />
        <div v-if="tccDocs.hasMiscellaneousFile">
            <Button class="p-button d-block mx-3 py-3 px-auto" :loading="loadingMisc" @click.prevent="downloadTccDocs('Miscellaneous')"
                    style="color: #e2e7e5; background: #036495!important; text-decoration: none;
                    display: flex; justify-content: center; width: 93%">
                    <i class="pi pi-cloud-download mx-1" style="fontSize: 1rem"></i>&nbsp;{{ tccDocs.miscellaneousFileDisplayName }}
            </Button>
        </div>
         <div v-else>
            <a class="p-button d-block mx-3 py-3 px-auto" :disabled="true"
                    style="color: #e2e7e5; background: rgba(4, 88, 137, 0.7)!important; text-decoration: none;
                    display: flex; justify-content: center; width: 93%;"
                    icon="pi pi-cloud-download">No File Uploaded
            </a>
         </div>
     <br />
  </div>

<div id="fullScreenView">
  <div class="flex flex-wrap mx-4 my-3 shadow-2 md:align-items-center sm:align-items-center" style="background: #AAAAAF; max-width: 100vw">
    <table class="">
        <thead id="thead">
            <tr>
                <th class="text-center px-2" style="width: 25vw"> ATTORNEY</th>
                <th class="text-center px-2" style="width: 25vw"> FIA 001</th>
                <th class="text-center px-2" style="width: 25vw"> A &amp; L </th>
                <th class="text-center px-2" style="width: 25vw"> BANK</th>
            </tr>
        </thead>
            <tbody id="tbodyDark">
                <tr>
                    <td>
                      <div v-if="tccDocs.hasPowerOfAttorneyFile">
                          <a class="p-button d-block mx-3 py-3 px-auto" :loading="loadingPoA" @click.prevent="downloadTccDocs('Power of Attorney')"
                                    style="color: #e2e7e5; background: #062d5d!important;  text-decoration: none;
                                    display: flex; justify-content: center;">
                                  <i class="pi pi-cloud-download" style="fontSize: 1rem"></i>&nbsp;&nbsp;Power of Attorney
                          </a>
                      </div>
                       <div v-else>
                          <a class="p-button d-block mx-3 py-3 px-auto"
                                      style="color: #e2e7e5; background: rgba(6, 45, 107, 0.8)!important;  text-decoration: none;
                                      display: flex; justify-content: center;">
                                      &nbsp;&nbsp;No File Uploaded
                          </a>
                      </div>
                    </td>
                    <td>
                      <div v-if="tccDocs.hasFia001File">
                          <a class="p-button d-block mx-3 py-3 px-auto" :loading="loadingFia" @click.prevent="downloadTccDocs('FIA 001')"
                                      style="color: #e2e7e5; background: #063a6a!important;  text-decoration: none;
                                      display: flex; justify-content: center;">
                                  <i class="pi pi-cloud-download" style="fontSize: 1rem"></i>&nbsp;&nbsp;FIA 001
                          </a>
                      </div>
                       <div v-else>
                          <a class="p-button d-block mx-3 py-3 px-auto"
                                      style="color: #e2e7e5; background: rgba(6, 45, 93, 0.7)!important;  text-decoration: none;
                                      display: flex; justify-content: center;">
                                      &nbsp;&nbsp;No File Uploaded
                          </a>
                      </div>
                    </td>
                    <td>
                      <div v-if="tccDocs.hasAssetsAndLiabilitiesFile">
                        <a class="p-button d-block mx-3 py-3 px-auto" :loading="loadingAandL" @click.prevent="downloadTccDocs('Assets & Liabilities')"
                                  style="color: #e2e7e5; background: #036495!important;  text-decoration: none;
                                  display: flex; justify-content: center;">
                               <i class="pi pi-cloud-download" style="fontSize: 1rem"></i>&nbsp;&nbsp;Assets &amp; Liabilities
                        </a>
                      </div>
                       <div v-else>
                          <a class="p-button d-block mx-3 py-3 px-auto"
                                      style="color: #e2e7e5; background: rgba(4, 88, 137, 0.7)!important;  text-decoration: none;
                                      display: flex; justify-content: center;">
                                      &nbsp;&nbsp;No File Uploaded
                          </a>
                      </div>
                    </td>
                    <td>
                      <div v-if="tccDocs.hasBankStatementsFile">
                          <a class="p-button d-block mx-3 py-3 px-auto" :loading="loadingBank" @click.prevent="downloadTccDocs('Bank Statements')"
                                    style="color: #e2e7e5; background: #0185b7!important;  text-decoration: none;
                                    display: flex; justify-content: center;">
                                  <i class="pi pi-cloud-download" style="fontSize: 1rem"></i>&nbsp;&nbsp;Bank Statements
                          </a>
                      </div>
                       <div v-else>
                          <a class="p-button d-block mx-3 py-3 px-auto"
                                      style="color: #e2e7e5; background: rgba(1, 133, 183, 0.7)!important;  text-decoration: none;
                                      display: flex; justify-content: center;">
                                      &nbsp;&nbsp;No File Uploaded
                          </a>
                      </div>
                    </td>
                </tr>
            </tbody>
    </table>
</div>
<br />
<br />
<div class="flex flex-wrap mx-4 my-3 shadow-2 md:align-items-center sm:align-items-center" style="background: #AAAAAF; max-width: 100vw">
    <table class="">
        <thead id="thead" >
            <tr>
                <th class="text-center px-2" style="width: 25vw"> INHERITANCE</th>
                <th class="text-center px-2" style="width: 25vw"> LOANS</th>
                <th class="text-center px-2" style="width: 25vw"> INVESTMENTS</th>
                <th class="text-center px-2" style="width: 25vw"> PROPERTY</th>
            </tr>
        </thead>
            <tbody id="tbodyDark">
                <tr>
                    <td>
                      <div v-if="tccDocs.hasInheritanceFile">
                          <a class="p-button d-block mx-3 py-3 px-auto" :loading="loadingInh" @click.prevent="downloadTccDocs('Inheritance')"
                                    style="color: #e2e7e5; background: #062d5d!important;  text-decoration: none;
                                    display: flex; justify-content: center;">
                                  <i class="pi pi-cloud-download" style="fontSize: 1rem"></i>&nbsp;&nbsp;Inheritance
                          </a>
                      </div>
                       <div v-else>
                            <a class="p-button d-block mx-3 py-3 px-auto"
                                        style="color: #e2e7e5; background: rgba(6, 45, 107, 0.8)!important;  text-decoration: none;
                                        display: flex; justify-content: center;">
                                        &nbsp;&nbsp;No File Uploaded
                            </a>
                      </div>
                    </td>
                    <td>
                      <div v-if="tccDocs.hasLoansFile">
                        <a class="p-button d-block mx-3 py-3 px-auto" :loading="loadingLoan" @click.prevent="downloadTccDocs('Loans')"
                                  style="color: #e2e7e5; background: #063a6a!important;  text-decoration: none;
                                  display: flex; justify-content: center;">
                                <i class="pi pi-cloud-download" style="fontSize: 1rem"></i>&nbsp;&nbsp;Loans
                        </a>
                      </div>
                       <div v-else>
                            <a class="p-button d-block mx-3 py-3 px-auto"
                                        style="color: #e2e7e5; background: rgba(6, 45, 93, 0.7)!important;  text-decoration: none;
                                        display: flex; justify-content: center;">
                                        &nbsp;&nbsp;No File Uploaded
                            </a>
                      </div>
                    </td>
                    <td>
                      <div v-if="tccDocs.hasInvestmentsFile">
                          <a class="p-button d-block mx-3 py-3 px-auto" :loading="loadingInv" @click.prevent="downloadTccDocs('Investments')"
                                    style="color: #e2e7e5; background: #036495!important;  text-decoration: none;
                                    display: flex; justify-content: center;">
                                <i class="pi pi-cloud-download" style="fontSize: 1rem"></i>&nbsp;&nbsp;Investments
                        </a>
                      </div>
                       <div v-else>
                            <a class="p-button d-block mx-3 py-3 px-auto"
                                        style="color: #e2e7e5; background: rgba(4, 88, 137, 0.7)!important;  text-decoration: none;
                                        display: flex; justify-content: center;">
                                        &nbsp;&nbsp;No File Uploaded
                            </a>
                      </div>
                    </td>
                    <td>
                      <div v-if="tccDocs.hasPropertyFile">
                          <a class="p-button d-block mx-3 py-3 px-auto" :loading="loadingProp" @click.prevent="downloadTccDocs('Property')"
                                    style="color: #e2e7e5; background: #0185b7!important;  text-decoration: none;
                                    display: flex; justify-content: center;">
                                <i class="pi pi-cloud-download" style="fontSize: 1rem"></i>&nbsp;&nbsp;Property
                        </a>
                      </div>
                       <div v-else>
                            <a class="p-button d-block mx-3 py-3 px-auto"
                                        style="color: #e2e7e5; background: rgba(1, 133, 183, 0.7)!important;  text-decoration: none;
                                        display: flex; justify-content: center;">
                                        &nbsp;&nbsp;No File Uploaded
                            </a>
                      </div>
                    </td>
                </tr>
            </tbody>
      </table>
  </div>
  <br />
<div class="flex flex-wrap mx-4 my-3 shadow-2 md:align-items-center sm:align-items-center" style="background: #AAAAAF; max-width: 100vw">
    <table class="">
        <thead id="thead" >
            <tr>
                <th class="text-center px-2" style="width: 25vw"> DISTRIBUTION</th>
                <th class="text-center px-2" style="width: 25vw"> DONATION</th>
                <th class="text-center px-2" style="width: 25vw"> MISCELLANEOUS</th>
                <th class="text-center px-2" style="width: 25vw"> </th>
            </tr>
        </thead>
            <tbody id="tbodyDark">
                <tr>
                    <td>
                      <div v-if="tccDocs.hasDistributionFile">
                          <a class="p-button d-block mx-3 py-3 px-auto" :loading="loadingDist" @click.prevent="downloadTccDocs('Distribution')"
                                    style="color: #e2e7e5; background: #062d5d!important;  text-decoration: none;
                                    display: flex; justify-content: center;">
                                  <i class="pi pi-cloud-download" style="fontSize: 1rem"></i>&nbsp;&nbsp;Distribution
                          </a>
                      </div>
                       <div v-else>
                            <a class="p-button d-block mx-3 py-3 px-auto"
                                        style="color: #e2e7e5; background: rgba(6, 45, 107, 0.8)!important;  text-decoration: none;
                                        display: flex; justify-content: center;">
                                        &nbsp;&nbsp;No File Uploaded
                            </a>
                      </div>
                    </td>
                    <td>
                      <div v-if="tccDocs.hasDonationFile">
                        <a class="p-button d-block mx-3 py-3 px-auto" :loading="loadingImage" @click.prevent="downloadTccDocs('Donation')"
                                  style="color: #e2e7e5; background: #063a6a!important;  text-decoration: none;
                                  display: flex; justify-content: center;">
                                <i class="pi pi-cloud-download" style="fontSize: 1rem"></i>&nbsp;&nbsp;Donation
                        </a>
                      </div>
                       <div v-else>
                            <a class="p-button d-block mx-3 py-3 px-auto"
                                        style="color: #e2e7e5; background: rgba(6, 45, 93, 0.7)!important;  text-decoration: none;
                                        display: flex; justify-content: center;">
                                        &nbsp;&nbsp;No File Uploaded
                            </a>
                      </div>
                    </td>
                    <td>
                      <div v-if="tccDocs.hasMiscellaneousFile">
                          <a class="p-button d-block mx-3 py-3 px-auto" :loading="loadingMisc" @click.prevent="downloadTccDocs('Miscellaneous')"
                                    style="color: #e2e7e5; background: #036495!important;  text-decoration: none;
                                    display: flex; justify-content: center;">
                                <i class="pi pi-cloud-download" style="fontSize: 1rem"></i>&nbsp;&nbsp;{{ tccDocs.miscellaneousFileDisplayName }}
                        </a>
                      </div>
                       <div v-else>
                            <a class="p-button d-block mx-3 py-3 px-auto"
                                        style="color: #e2e7e5; background: rgba(4, 88, 137, 0.7)!important;  text-decoration: none;
                                        display: flex; justify-content: center;">
                                        &nbsp;&nbsp;No File Uploaded
                            </a>
                      </div>
                    </td>
                    <td>
                    </td>
                </tr>
            </tbody>
      </table>
  </div>
</div>
<div>
  <Button class="l-2 m-3 mx-4 shadow-3 p-button-lg"
  icon="pi pi-step-backward"
  label="BACK&nbsp;"
  id="submitButton"
  @click.prevent="$router.push( {name: 'TccDocsHome', params: { id: tccDocs.tccId }})"/>
</div>
</template>

<script>
import { getClientTccDocs, tccDocsDownload } from '@/gateway'

export default {
  name: 'TccDocsDownloads',
  inheritAttrs: false,
  data () {
    return {
      tccDocs: {},
      loadingPoA: false,
      loadingFia: false,
      loadingAandL: false,
      loadingBank: false,
      loadingInh: false,
      loadingLoan: false,
      loadingInv: false,
      loadingProp: false,
      loadingDist: false,
      loadingDon: false,
      loadingMisc: false
    }
  },
  methods: {
    async init () {
      const response = await getClientTccDocs({ id: this.id })
      if (response) {
        this.tccDocs = response.result
      }
    },
    base64ToArrayBuffer (base64) {
      var binaryString = window.atob(base64)
      var binaryLen = binaryString.length
      var bytes = new Uint8Array(binaryLen)
      for (var i = 0; i < binaryLen; i++) {
        var ascii = binaryString.charCodeAt(i)
        bytes[i] = ascii
      }
      return bytes
    },
    async downloadTccDocs (formType) {
      if (formType === 'Power of Attorney') { this.loadingPoA = true }
      else if (formType === 'FIA 001') { this.loadingFia = true }
      else if (formType === 'Assets & Liabilities') { this.loadingAandL = true }
      else if (formType === 'Bank Statements') { this.loadingBank = true }
      else if (formType === 'Inheritance') { this.loadingInh = true }
      else if (formType === 'Loans') { this.loadingLoan = true }
      else if (formType === 'Investments') { this.loadingInv = true }
      else if (formType === 'Property') { this.loadingProp = true }
      else if (formType === 'Distribution') { this.loadingDist = true }
      else if (formType === 'Donation') { this.loadingDon = true }
      else if (formType === 'Miscellaneous') { this.loadingMisc = true }
      const response = await tccDocsDownload({
        formType: formType,
        id: this.tccDocs.tccId
      })
      if (response) {
        var byteArray = this.base64ToArrayBuffer(response.data)
        const url = window.URL.createObjectURL(
          new Blob([byteArray], { type: 'application/octet-stream' })
        )
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', response.fileName)
        document.body.appendChild(link)
        this.loadingPoA = false
        this.loadingFia = false
        this.loadingAandL = false
        this.loadingBank = false
        this.loadingInh = false
        this.loadingLoan = false
        this.loadingInv = false
        this.loadingProp = false
        this.loadingDist = false
        this.loadingDon = false
        this.loadingMisc = false
        link.click()
      }
    }
  },
  mounted () {
    this.id = this.$route.params.id
    this.init()
  }
}
</script>

<style lang="scss" scoped>
::v-deep() {
  .p-datatable .p-datatable-thead > tr > th {
    background-color: $middleBlue!important;
    color: $textColor;
    border: 1px solid $borderColour!important;
    font-size: 18px;
    font-weight: 600;
  }
  .p-datatable .p-datatable-tbody > tr > td {
    padding: .4rem .4rem;
    color: $darkTextColour;
    font-family:'Poppins', sans-serif;
    font-weight: 400;
  };
  #thead {
    background: $middleBlue;
    height: 9vh;
    color: $textColor;
    font-size: 18px;
    font-weight: 600;
    font-family: Segoe UI Semibold;
  };
  #tbodyLight {
    height: 6vh;
    background-color: $backgroundColour;
    color: $darkTextColour;
    font-family:'Poppins', sans-serif;
    font-weight: 400;
  };
    #tbodyDark {
    height: 12vh;
    background-color: $whiteSmoke;
    color: $darkTextColour;
    font-family:'Poppins', sans-serif;
    font-weight: 400;
  };
};
  @media screen and (max-width:1023px) {
    #fullScreenView {
      display: none!important;
    }
  };
    @media screen and (min-width:1024px) {
    #mobileView {
      display: none!important;
    }
  };
</style>
