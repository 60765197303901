<template>
<br />
<br />
<h4 class="text-center mx-3">Please select a Date Range for the Client Trades Report</h4>
<br />
<br />
<div class="fluid mx-3">
    <div class=" formgrid grid">
        <div class="col-12 md:col-6 lg:col-6">
        <h5>Start Date</h5>
            <Calendar
            v-model="startDate"
            :showIcon="true"
            :monthNavigator="true"
            :yearNavigator="true"
            yearRange="2019:2023"
            :minDate="minStartDate"
            :maxDate="maxStartDate"
            appendTo="self"
            class="inputfield w-full"
            dateFormat="dd-mm-yy"
            @blur="v$.startDate.$touch"/>
            <div v-if="v$.startDate.$error"><h5 style="color: #d2161e">* Start Date is required</h5></div>
        </div>
        <div class="col-12 md:col-6 lg:col-6">
        <h5>End Date</h5>
            <Calendar
            v-model="endDate"
            :showIcon="true"
            :monthNavigator="true"
            :yearNavigator="true"
            yearRange="2019:2023"
            :minDate="minStartDate"
            :maxDate="maxStartDate"
            appendTo="self"
            class="inputfield w-full"
            dateFormat="dd-mm-yy"
            @blur="v$.endDate.$touch" />
            <div v-if="v$.endDate.$error"><h5 style="color: #d2161e">* End Date is required</h5></div>
       </div>
    </div>
  <br />
</div>
  <div class="flex justify-content-between ">
        <div>
          <Button class="l-2 m-3 mx-4 shadow-3 p-button-lg"
          icon="pi pi-step-backward"
          label="BACK&nbsp;"
          id="submitButton"
          @click.prevent="$router.back()" />
      </div>
      <div>
          <Button class="l-2 m-3 mx-4 shadow-3 p-button-lg "
          icon="pi pi-file-excel"
          label="DOWNLOAD&nbsp;"
          id="excelButton"
          :loading="loadingExcel"
          @click.prevent="downloadTradesExcel" />
      </div>
  </div>

</template>

<script>
import { getAllTrades, downloadTradesToExcel } from '@/gateway'
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'

export default {
  name: 'AllTradesFileDownload',
  setup: () => ({ v$: useVuelidate() }),
  inheritAttrs: false,
  data () {
    return {
      trades: [],
      startDate: null,
      endDate: null,
      minStartDate: null,
      maxStartDate: null,
      minEndDate: null,
      maxEndDate: null,
      areThereInputDates: false,
      loadingExcel: false
    }
  },
  methods: {
    async init () {
      const response = await getAllTrades()
      if (response) {
        this.trades = response.result
      }
    },
    async openDateRange () {
      this.displayDateRange = true
    },
    toggle (event) {
      this.$refs.op.toggle(event)
    },
    async downloadTradesExcel () {
      const areThereInputDates = await this.v$.$validate()
      if (!areThereInputDates) return
      this.loadingExcel = true
      const response = await downloadTradesToExcel({ startDate: this.startDate.toJSON(), endDate: this.endDate.toJSON() })
      if (response) {
        var byteArray = this.base64ToArrayBuffer(response.data)
        const url = window.URL.createObjectURL(
          new Blob([byteArray], { type: 'application/octet-stream' })
        )
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', response.fileName)
        document.body.appendChild(link)
        this.loadingExcel = false
        this.displayDateRange = false
        link.click()
      }
    },
    base64ToArrayBuffer (base64) {
      var binaryString = window.atob(base64)
      var binaryLen = binaryString.length
      var bytes = new Uint8Array(binaryLen)
      for (var i = 0; i < binaryLen; i++) {
        var ascii = binaryString.charCodeAt(i)
        bytes[i] = ascii
      }
      return bytes
    }
  },
  created () {
    const today = new Date()
    const minStartingDate = new Date('19-07-2019')
    const minStartDate = new Date()
    minStartDate.setDate(minStartingDate.getDate())
    const maxStartingDate = new Date('20-07-2019')
    const maxStartDate = new Date()
    maxStartDate.setDate(maxStartingDate.getDate())
    const maxEndDate = new Date()
    maxEndDate.setDate(today.getDate() - 1)
  },
  validations () {
    return {
      startDate: { required },
      endDate: { required }
    }
  },
  mounted () {
    this.init()
  }
}
</script>

<style lang="scss" scoped>
::v-deep() {
  .p-button-label {
    flex: none;
  }
};
</style>
