<template>
<br />
    <h2 class="text-center my-2">RESEARCH ARTICLES</h2>
  <div>
    <Button icon="pi pi-info" class="p-button-rounded p-button-lg mx-4 shadow-5" @click="toggle" />
    <OverlayPanel ref="op" appendTo="body" :showCloseIcon="true" id="overlay_panel" style="width: 20rem" :breakpoints="{'960px': '75vw'}">
      <p>Use the <i class="pi pi-sort-alt"></i> to filter results.</p>
      <p>Use <i class="pi pi-sort-amount-up-alt"></i> or <i class="pi pi-sort-amount-down-alt"></i> to change the sort order.</p>
      <p>To filter by multiple columns use Ctrl + Click on the second filter column.</p>
      <p>Use the <i class="pi pi-filter"></i> to search for a specific client. You can search on multiple columns.</p>
      </OverlayPanel>
  </div>
  <br />
  <div class="fluid mx-3 shadow-2">
    <DataTable :value="research"
                dataKey="id" :rowHover="true"
                stripedRows responsiveLayout="stack"
                showGridlines
                breakpoint="1024px"
                :paginator="true"
                :rows="8"
                v-model:filters="filters1"
                filterDisplay="menu"
                class="datatable-retailClient">
        <Column field="id" header="ID" headerStyle="width: 1vw"></Column>
        <Column field="fileCategory" header="Category" :sortable="true" headerStyle="width: 3vw" :filterMenuStyle="{'width':'14rem'}" style="min-width:4vw">
            <template #filter="{filterModel}">
                <Dropdown v-model="filterModel.value" :options="fileCategories" placeholder="Select File Category" class="p-column-filter" :showClear="true">
                </Dropdown>
            </template>
        </Column>
        <Column field="fileDisplayName" header="Article Name" :sortable="true" headerStyle="width: 14vw">
            <template #filter="{filterModel}">
              <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by Name"/>
            </template>
        </Column>
        <Column field="fileDate" header="Published Date" :sortable="true" headerStyle="width: 4vw">
            <template #filter="{filterModel}">
              <InputText type="date" v-model="filterModel.value" class="p-column-filter" placeholder="Search by Date"/>
            </template></Column>
        <Column header="Download" headerStyle="width: 3vw;">
            <template #body="{data}">
                <a class="p-button d-block mx-1 px-1 shadow-1"
                        @click.prevent="downloadResearchFile(data.id)"
                        style="background-colour:#E6E6F0; color: #E6E6F0; text-decoration: none;
                         display: flex; justify-content: center;">
                 <i class="pi pi-download" style="fontSize: 1rem"></i>&nbsp;&nbsp;DOWNLOAD
              </a>
            </template>
        </Column>
         <Column header="Delete" headerStyle="width: 3vw;">
            <template #body="{data}">
                <a class="p-button d-block mx-1 px-1 shadow-1"
                        id="deleteButton"
                        @click.prevent="deleteResearchFile(data.id)"
                        style="background-colour: #d2161e!important; color: #E6E6F0; text-decoration: none;
                         display: flex; justify-content: center;">
                 <i class="pi pi-trash" style="fontSize: 1rem"></i>&nbsp;&nbsp;DELETE
              </a>
            </template>
        </Column>
    </DataTable>
  </div>
  <div>
    <Button class="l-2 m-4 mx-3 shadow-4 p-button-lg"
    icon="pi pi-step-backward"
    label="BACK&nbsp;"
    id="submitButton"
    @click.prevent="$router.push( {name: 'ResearchHome'})" />
    </div>
     <div class=" formgrid grid">
      <div class="col-12 md:col-6 lg:col-6">
        <div v-if='deleteResearchSuccess'>
        <Dialog class="successModal" header="SUCCESS" v-model:visible="displayConfirmation">
            <div class="confirmation-content">
                <span>Research File Deleted</span>
            </div>
            <template #footer>
                <router-link class="p-button d-block mx-2 px-auto" :to="'/ResearchHome'"
                 style="background-color: #0185b7!important; text-decoration: none; display: flex; justify-content: center; width: 50%">
                  <i class="pi pi-step-backward" style="fontSize: 1rem"></i>&nbsp;&nbsp;Back
                </router-link>
            </template>
        </Dialog>
        </div>
      </div>
    </div>
</template>

<script>
import { getResearch, downloadResearch, deleteResearch } from '@/gateway'
import { FilterMatchMode, FilterOperator } from 'primevue/api'

export default {
  name: 'ResearchView',
  inheritAttrs: false,
  data () {
    return {
      research: [],
      fileCategories: [
        'Decoder',
        'Exchange',
        'Macro Papers',
        'Swift File'
      ],
      filters1: null,
      deleteResearchSuccess: false,
      displayConfirmation: true
    }
  },
  methods: {
    async init () {
      const response = await getResearch({ id: this.research.id })
      if (response) {
        this.research = response.result
      }
    },
    base64ToArrayBuffer (base64) {
      var binaryString = window.atob(base64)
      var binaryLen = binaryString.length
      var bytes = new Uint8Array(binaryLen)
      for (var i = 0; i < binaryLen; i++) {
        var ascii = binaryString.charCodeAt(i)
        bytes[i] = ascii
      }
      return bytes
    },
    async downloadResearchFile (fileId) {
      const response = await downloadResearch({
        fileId: fileId,
        id: this.research.id
      })
      if (response) {
        var byteArray = this.base64ToArrayBuffer(response.data)
        const url = window.URL.createObjectURL(
          new Blob([byteArray], { type: 'application/octet-stream' })
        )
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', response.fileName)
        document.body.appendChild(link)
        link.click()
      }
    },
    async deleteResearchFile (fileId) {
      const response = await deleteResearch({
        fileId: fileId,
        id: this.research.id
      })
      if (response) {
        this.deleteResearchSuccess = true
      }
    },
    initFilters1 () {
      this.filters1 = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        fileDisplayName: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
        fileDate: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
        fileCategory: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] }
      }
    },
    toggle (event) {
      this.$refs.op.toggle(event)
    }
  },
  created () {
    this.initFilters1()
  },
  mounted () {
    this.init()
  }
}
</script>

<style lang="scss" scoped>
::v-deep() {
  .p-datatable .p-datatable-thead > tr > th {
    background-color: $middleBlue!important;
    color: $textColor;
    border: 1px solid $borderColour!important;
    font-size: 18px;
    font-weight: 600;
  }
  .p-datatable .p-datatable-tbody > tr > td {
    padding: .4rem .4rem;
    color: $darkTextColour;
    font-family:'Poppins', sans-serif;
    font-weight: 400;
  }
  .p-paginator-current {
        margin-right: auto;
    }
  .retailClient-badge {
      @include statusBadge
  };
  #disabled {
    background: $textColor!important;
    color: $middleBlue!important;
  };
  #searchtext {
    margin-left: 1rem!important;
    min-width: 40vw;
  };
  #deleteButton {
    background-color: $queuedBackground!important;
  };
};
</style>
