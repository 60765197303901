<template>
  <br />
  <h2 class="text-center my-2">TCC MANAGEMENT FOR : {{ retailClient.firstLastName }}</h2>
  <br />
  <div v-if="retailClient.hasHadClientTcc == true">
    <h4 class="text-center my-2">This Client had a previous TCC</h4>
  </div>
  <div v-else-if="retailClient.hasClientTcc == false && retailClient.hasClientTccPending == true">
    <h4 class="text-center my-2">This Client has a pending TCC</h4>
  </div>
  <div v-else-if="retailClient.hasClientTcc == false && retailClient.hasClientTccPending == false">
    <h4 class="text-center my-2">This Client does not have a current TCC</h4>
  </div>
  <div v-else-if="retailClient.hasClientTcc == true">
    <h4 class="text-center my-2">This Client does have a current TCC</h4>
  </div>
  <br />
  <br />
  <div class="fluid grid">
    <div class="col-12 md:col-6 lg:col-6">
        <Card class="shadow-3 text-center d-block mx-auto" id="newClient">
            <template class="text-center" #title>
              <i class="pi pi-plus-circle" id="cardLogo"></i>
            </template>
            <template  #content>
              <router-link :to="'/add/' + retailClient.id" style="color: #e2e7e5; text-decoration: none">
              ADD NEW
              </router-link>
            </template>
        </Card>
    </div>
    <div class="col-12 md:col-6 lg:col-6">
      <div v-if="retailClient.hasHadClientTcc == true">
        <Card class="shadow-3 text-center d-block mx-auto disabled" id="viewClients">
            <template class="text-center" #title>
              <i class="pi pi-list" id="cardLogo"></i>
            </template>
            <template  #content>
                <router-link :to="'/tccView/' + retailClient.id" style="color: #e2e7e5; text-decoration: none">
                VIEW TCC
                </router-link>
            </template>
        </Card>
     </div>
     <div v-else-if="retailClient.hasClientTcc == false && retailClient.hasClientTccPending == true">
          <Card class="shadow-3 text-center d-block mx-auto" id="viewClients">
              <template class="text-center" #title>
                <i class="pi pi-list" id="cardLogo"></i>
              </template>
              <template  #content>
                <router-link :to="'/tccView/' + retailClient.id" style="color: #e2e7e5; text-decoration: none">
                VIEW TCC
                </router-link>
              </template>
          </Card>
      </div>
       <div v-else-if="retailClient.hasClientTcc == false && retailClient.hasClientTccPending == false">
          <Card class="shadow-3 text-center d-block mx-auto" id="viewTccDisabled">
              <template class="text-center" #title>
                <i class="pi pi-list" id="cardLogo"></i>
              </template>
              <template  #content>
              <a style="color: #e2e7e5; text-decoration: none">
              VIEW TCC
              </a>
              </template>
          </Card>
      </div>
       <div v-else-if="retailClient.hasClientTcc == true">
          <Card class="shadow-3 text-center d-block mx-auto" id="viewClients">
              <template class="text-center" #title>
                <i class="pi pi-list" id="cardLogo"></i>
              </template>
              <template  #content>
                <router-link :to="'/tccView/' + retailClient.id" style="color: #e2e7e5; text-decoration: none">
                VIEW TCC
                </router-link>
              </template>
          </Card>
      </div>
    </div>
  </div>
  <hr class="mx-4 shadow-2"/>
  <div>
    <Button class="l-2 mx-4 shadow-3 p-button-lg"
    icon="pi pi-step-backward"
    label="BACK&nbsp;"
    id="submitButton"
    @click.prevent="$router.push( {name: 'ClientView', params: { id: retailClient.id }})" />
 </div>
</template>

<script>
import { getRetailClient } from '@/gateway'
export default {
  name: 'TccClientView',
  inheritAttrs: false,
  data () {
    return {
      retailClient: {}
    }
  },
  methods: {
    async init () {
      const response = await getRetailClient({ id: this.id })
      if (response) {
        this.retailClient = response.result
      }
    }
  },
  mounted () {
    this.id = this.$route.params.id
    this.init()
  }
}
</script>

<style lang="scss" scoped>
#newClient {
 @include cardComponent($midDarkBlue)
};
#fromPartner {
 @include cardComponent($middleBlue)
};
#viewClients {
 @include cardComponent($lightMidBlue)
};
#manageTccDisabled {
 @include cardComponent($midddleBlueDisabled)
};
#viewTccDisabled {
 @include cardComponent($lightBlueDisabled)
};
.p-card.p-component.p-card-content {
 font-size: 24px;
};
.p-grid {
  margin: 1rem;
  padding: 1rem;
};
</style>
