<template>
  <div class="combo-box">
    <div style="width: 100%; height: 30px;">
      <TextInput
        :text="text"
        @input="onInput"
        @fi="onFi"
        @fo="onFo"
      >
        <template #left-icon>
          <div class="pi pi-search"></div>
        </template>
        <template #right-icon>
          <div :class="'pi' + (showDropdown ? ' pi-angle-up' : ' pi-angle-down')"></div>
        </template>
      </TextInput>
    </div>
    <div
      v-if="showDropdown"
      class="dropdown"
    >
      <div
        class="item"
        v-for="(record, index) in filteredRecords"
        :key="index"
        @mousedown="onMouseDown(record)"
      >
        {{record.name}}
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, defineProps, defineEmits, computed, watch } from 'vue'
import TextInput from '@/components/TextInput'

const props = defineProps({
  records: Array,
  selected: Object
})

const text = ref(props.selected.name)

const filteredRecords = computed(() => {
  return props.records.filter(x => x.name.toLowerCase().includes(text.value.toLowerCase()))
})

const emit = defineEmits(['select'])

const showDropdown = ref(false)

function onInput (payload) {
  text.value = payload.text
}

function onFi () {
  text.value = ''
  showDropdown.value = true
}

function onFo () {
  text.value = props.selected.name
  showDropdown.value = false
}

function onMouseDown (record) {
  emit('select', { record: record })
}

watch(() => { return props.selected.name }, () => {
  text.value = props.selected.name
})
</script>

<script>
export default {
  name: 'ComboBox',
  inheritAttrs: false
}
</script>

<style scoped>
.item {
  font-size: 12px;
  padding: 5px;
}

.item:hover {
  background-color: #E5E7EB;
  cursor: pointer;
}

.dropdown {
  width: 100%;
  height: 200px;
  margin-top: 2px;
  padding-left: 1px;
  padding-right: 1px;
  background-color: white;
  display: flex;
  flex-direction: column;
  border-radius: 0.25rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  overflow-y: scroll;
  overflow-x: hidden;
  z-index: 10;
}

.combo-box {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-y: visible;
  position: absolute;
}

.pi-search, .pi-angle-down, .pi-angle-up {
  color: #4B5563;
  font-size: 12px;
}
</style>
