<template>
  <br />
  <h2 class="text-center my-2">REQUEST A TRADE QUOTE</h2>
  <br />
  <br />
  <Card class="shadow-5" id="tradesCard">
            <template #content>
                <div>
                    <h5>Select Client</h5>
                    <Dropdown v-model="tradeQuotes.clientId"
                    :options="retailClient"
                    optionLabel="text"
                    optionValue="value"
                    :showClear="true"
                    appendTo="self"
                    class="inputfield w-full"
                    placeholder="Please Select"/>
                </div>
                <div>
                    <h5>Select Trade Type</h5>
                    <Dropdown v-model="tradeQuotes.tradeType"
                    :options="tradeTypes"
                    optionLabel="text"
                    optionValue="value"
                    :showClear="true"
                    appendTo="self"
                    class="inputfield w-full"
                    placeholder="Please Select"/>
                </div>
                <div>
                    <h5>Select Currency</h5>
                    <Dropdown v-model="tradeQuotes.tradeCurrency"
                    :options="currencies"
                    optionLabel="text"
                    optionValue="value"
                    :showClear="true"
                    appendTo="self"
                    class="inputfield w-full"
                    placeholder="Please Select"/>
                </div>
                <div>
                    <h5>Select Trade Settlement</h5>
                    <Dropdown v-model="tradeQuotes.tradeTiming"
                    :options="tradeTimes"
                    optionLabel="text"
                    optionValue="value"
                    :showClear="true"
                    appendTo="self"
                    class="inputfield w-full"
                    placeholder="Please Select"/>
                </div>
                <div>
                    <h5>Trade Amount<i>&nbsp;(optional)</i></h5>
                    <InputNumber
                    mode="decimal"
                    locale="en-US"
                    :minFractionDigits="2"
                    class="inputfield w-full"
                    v-model="tradeQuotes.tradeAmount"/>
                </div>
                 <div>
                    <h5>Additional Comments<i>&nbsp;(optional)</i></h5>
                    <TextArea
                    id="textArea"
                    rows="5"
                    :autoResize="true"
                    class="inputfield w-full"
                    v-model="tradeQuotes.senderComment"/>
                </div>
            </template>
            <template #footer>
                <router-link class="p-button d-block mr-2 mb-2 px-auto" :to="'/quotesHome'"  style="background-color: #063a6a!important; text-decoration: none;
                                     width: 40%; justify-content: center;">
                  <i class="pi pi-step-backward" style="fontSize: 1rem"></i> &nbsp;&nbsp;Back
                </router-link>
                <router-link class="p-button d-block mb-2 px-auto" :to="'/clientListView'"  style="background-color: #0185b7!important; text-decoration: none;
                                     width: 40%; justify-content: center; float: right;">
                  <i class="pi pi-send" style="fontSize: 1rem"></i>&nbsp;&nbsp;Send
                </router-link>
            </template>
            <br />
        </Card>
</template>

<script>
import { getPartnerRetailClients } from '@/gateway'

export default {
  name: 'NewQuote',
  inheritAttrs: false,
  data () {
    return {
      tradeQuotes: {
        id: 0,
        partnerId: 0,
        userId: 0,
        userName: '',
        adminId: 0,
        adminName: '',
        clientId: 0,
        firstLastName: '',
        isCrossTrade: false,
        tradeType: '',
        crossCurrency: '',
        tradeCurrency: '',
        tradeTiming: '',
        tradeAmount: 0,
        quotedRate: 0.00,
        senderComment: '',
        receiverComment: '',
        initialSendTime: null,
        initialReplyTime: null,
        quoteStatus: '',
        quoteAccepted: false,
        quoteAcceptedTime: null,
        riskStatus: ''
      },
      tradeTypes: [
        { value: 'Buy ZAR', text: 'Buy ZAR' },
        { value: 'Sell ZAR', text: 'Sell ZAR' }
      ],
      currencies: [
        { value: 'USD', text: 'USD' },
        { value: 'EUR', text: 'EUR' },
        { value: 'GBP', text: 'GBP' },
        { value: 'JPY', text: 'JPY' },
        { value: 'CNY', text: 'CNY' },
        { value: 'AUD', text: 'AUD' },
        { value: 'CAD', text: 'CAD' },
        { value: 'CHF', text: 'CHF' },
        { value: 'NZD', text: 'NZD' },
        { value: 'HKD', text: 'HKD' },
        { value: 'MUR', text: 'MUR' },
        { value: 'DKK', text: 'DKK' },
        { value: 'NOK', text: 'NOK' },
        { value: 'SGD', text: 'SGD' },
        { value: 'SEK', text: 'SEK' },
        { value: 'BWP', text: 'BWP' },
        { value: 'LSL', text: 'LSL' },
        { value: 'SZL', text: 'SZL' }
      ],
      crossCurrencies: [
        { value: 'USD', text: 'USD' },
        { value: 'EUR', text: 'EUR' },
        { value: 'GBP', text: 'GBP' },
        { value: 'JPY', text: 'JPY' },
        { value: 'CNY', text: 'CNY' },
        { value: 'AUD', text: 'AUD' },
        { value: 'CAD', text: 'CAD' },
        { value: 'CHF', text: 'CHF' },
        { value: 'NZD', text: 'NZD' },
        { value: 'HKD', text: 'HKD' },
        { value: 'MUR', text: 'MUR' },
        { value: 'DKK', text: 'DKK' },
        { value: 'NOK', text: 'NOK' },
        { value: 'SGD', text: 'SGD' },
        { value: 'SEK', text: 'SEK' },
        { value: 'BWP', text: 'BWP' },
        { value: 'LSL', text: 'LSL' },
        { value: 'SZL', text: 'SZL' }
      ],
      tradeTimes: [
        { value: 'Normal', text: 'Normal (T+2)' },
        { value: 'Next Day', text: 'Next Day' },
        { value: 'GTC', text: 'GTC' }
      ],
      retailClient: []
    }
  },
  methods: {
    async init () {
      const cr = await getPartnerRetailClients()
      this.retailClient = []
      if (cr) {
        cr.result.forEach(item => {
          this.retailClient.push({
            key: item.id,
            text: item.firstLastName,
            value: item.id
          })
        })
      }
    }
  },
  async mounted () {
    this.init()
  }
}
</script>

<style lang="scss" scoped>
#textArea {
  border: solid 1px;
  border-color: $lightBorderColor;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 16px;
  color: $darkTextColour;
  border-radius: 4px;
};
#textArea:focus {
  outline: solid 0.5px;
  outline-color: #0d6efd;
}
@media screen and (max-width: 767px) {
#tradesCard {
    display: block;
    margin-left: auto;
    margin-right: auto;
    border: solid 1px!important;
    border-color: $midDarkBlue!important;
    width: 90%
    }
};
@media screen and (min-width: 768px) and (max-width:1024px) {
   #tradesCard {
    display: block;
    margin-left: auto;
    margin-right: auto;
    border: solid 1px!important;
    border-color: $midDarkBlue!important;
    width: 70%
    };
};
@media screen and (min-width: 1025px) {
#tradesCard {
    display: block;
    margin-left: auto;
    margin-right: auto;
    border: solid 1px!important;
    border-color: $midDarkBlue!important;
    width: 35%
    }
};
</style>
