<template>
  <br />
  <br />
  <h2 class="text-center my-2">FICA DOCUMENTS FOR: {{ retailClient.firstLastName }}</h2>
  <br />
  <br />
  <div class="fluid grid">
    <div class="col-12 md:col-6 lg:col-6">
        <div v-if="retailClient.hasFicaUpload == true">
            <Card class="shadow-3 text-center d-block mx-auto" id="newClient">
                <template class="text-center" #title>
                <i class="pi pi-upload" id="cardLogo"></i>
                </template>
                <template  #content>
                <router-link :to="'/ficaUploadsUpdate/' + retailClient.id" style="color: #e2e7e5; text-decoration: none">
                UPLOADS
                </router-link>
                </template>
            </Card>
        </div>
        <div v-else>
            <Card class="shadow-3 text-center d-block mx-auto" id="newClient">
                <template class="text-center" #title>
                <i class="pi pi-upload" id="cardLogo"></i>
                </template>
                <template  #content>
                <a @click="saveClientFica" style="color: #e2e7e5; text-decoration: none; cursor: pointer;">
                UPLOADS
                </a>
                </template>
            </Card>
        </div>
    </div>
     <div class="col-12 md:col-6 lg:col-6">
        <Card class="shadow-3 text-center d-block mx-auto" id="fromPartner">
            <template class="text-center" #title>
              <i class="pi pi-download" id="cardLogo"></i>
            </template>
            <template  #content>
              <router-link :to="'/ficaDownloads/' + retailClient.id" style="color: #e2e7e5; text-decoration: none">
              DOWNLOADS
              </router-link>
            </template>
        </Card>
    </div>
  </div>
  <hr class="mx-4 shadow-2"/>
    <div>
    <Button class="l-2 m-4 mx-4 shadow-3 p-button-lg"
    icon="pi pi-step-backward"
    label="BACK&nbsp;"
    id="submitButton"
    @click.prevent="$router.push( {name: 'ClientView', params: { id: retailClient.id }})" />
  </div>
</template>

<script>
import { getRetailClient, createClientFica } from '@/gateway'

export default {
  name: 'FicaHome',
  inheritAttrs: false,
  data () {
    return {
      retailClient: {},
      fica: {
        id: 0,
        clientId: 0,
        clientName: '',
        identityDocumentFileName: '',
        identityDocumentFileDisplayName: '',
        identityDocumentFileUploadedDate: null,
        hasIdentityDocumentFile: false,
        proofOfResidenceFileName: '',
        proofOfResidenceFileDisplayName: '',
        proofOfResidenceFileUploadedDate: null,
        hasProofOfResidenceFile: false,
        powerOfAttorneyFileName: '',
        powerOfAttorneyFileDisplayName: '',
        powerOfAttorneyFileUploadedDate: null,
        hasPowerOfAttorneyFile: false,
        marriageCertificateFileName: '',
        marriageCertificateFileDisplayName: '',
        marriageCertificateFileUploadedDate: null,
        hasMarriageCertificateFile: false,
        clientImageFileName: '',
        clientImageFileDisplayName: '',
        clientImageFileUploadedDate: null,
        hasClientImageFile: false,
        miscellaneousOneFileName: '',
        miscellaneousOneFileDisplayName: '',
        miscellaneousOneFileUploadedDate: null,
        hasMiscellaneousOneFile: false,
        timFileName: '',
        timFileDisplayName: '',
        timFileUploadedDate: null,
        hasTimFile: false,
        pepFileName: '',
        pepFileDisplayName: '',
        pepFileUploadedDate: null,
        hasPepFile: false,
        sourceOfFundsFileName: '',
        sourceOfFundsFileDisplayName: '',
        sourceOfFundsFileUploadedDate: null,
        hasSourceOfFundsFile: false,
        managementSignOffFileName: '',
        managementSignOffFileDisplayName: '',
        managementSignOffFileUploadedDate: null,
        hasManagementSignOffFile: false
      }
    }
  },
  methods: {
    async init () {
      const response = await getRetailClient({ id: this.id })
      if (response) {
        this.retailClient = response.result
      }
    },
    async saveClientFica () {
      const response = await createClientFica({ fica: this.fica, retailClient: this.retailClient })
      if (response) {
        this.$router.push('/ficaUploadsUpdate/' + this.retailClient.id)
      }
    }
  },
  mounted () {
    this.id = this.$route.params.id
    this.init()
  }
}
</script>

<style lang="scss" scoped>
#newClient {
 @include cardComponent($midDarkBlue)
};
#fromPartner {
 @include cardComponent($lightMidBlue)
};
.p-card.p-component.p-card-content {
 font-size: 24px;
};
.p-grid {
  margin: 1rem;
  padding: 1rem;
};
</style>
