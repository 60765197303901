<template>
  <div class="bg">
  </div>
  <div class="loginFrame scale-in-center">
    <Card class="shadow-8" id="loginCard">
              <template #title>
                  <a id="loginHeader">Welcome to FxHub</a>
              </template>
              <template #subtitle>
                  <a id="loginSubHeader">Powered by</a>
              </template>
              <template #content>
                <img src="~@/assets/ACFXSolutionsLogo.png" style="width: 15rem; height: 12rem  display: block; margin-top: 2rem; margin-bottom: -1rem" />
                <br />
              </template>
            <template #footer>
                <router-link to="/clientHome" id="loginButton" class="p-button d-block mx-auto px-auto shadow-5">
                LOG IN
                </router-link>
            </template>
        </Card>
  </div>

</template>

<script>
// @ is an alias to /src

export default {
  name: 'Home',
  inheritAttrs: false,
  data () {
    return {

    }
  }
}
</script>
<style lang="scss" scoped>
body, html {
  height: 100%;
}
@media screen and (min-width: 320px) and (max-width: 767px) {
  .bg {
  background-image: url('~@/assets/FxHubBackgroundImageSmall.jpg');
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  };
  .loginFrame {
  width: 18rem;
  height: 25rem!important;
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin-bottom: auto;
  margin-top: 12rem;
  margin-left: auto;
  margin-right: auto;
 };
 #loginHeader {
  color: $darkBlue!important;
  font-size: 1.5rem!important;
  font-weight: 500!important;
  font-family: Poppins;
 };
 #loginCard {
  text-align: center;
  background-color: transparent;
  font-family: $fontFamily;
 };
#loginSubHeader {
  color: $darkBlue!important;
  font-size: 1.2rem!important;
  font-weight: 500!important;
  font-family: Poppins;
 };
#loginText {
  color: $darkBlue!important;
  font-size: 1.2rem!important;
  font-weight: 300!important;
  font-family: Poppins;
 };
#loginButton {
    color: $textColor!important;
    background: $lightBlueDisabled!important;
    height: 2.5rem;
    font-size: 1.3rem!important;
    font-weight: 500!important;
    font-family: Raleway;
    border: 2px;
    border-color: $dark;
    text-decoration: none;
    display: flex;
    justify-content: center;
 };
};
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .bg {
  background-image: url('~@/assets/FxHubBackgroundImageMedium.jpg');
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  };
  .loginFrame {
  width: 32rem;
  height: 35rem!important;
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin-bottom: auto;
  margin-top: 20rem;
  margin-left: auto;
  margin-right: auto;
 };
 #loginHeader {
  color: $darkBlue!important;
  font-size: 1.8rem!important;
  font-weight: 500!important;
  font-family: Poppins;
 };
 #loginCard {
  text-align: center;
  background-color: transparent;
  font-family: $fontFamily;
 };
#loginSubHeader {
  color: $darkBlue!important;
  font-size: 1.2rem!important;
  font-weight: 500!important;
  font-family: Poppins;
 };
#loginText {
  color: $darkBlue!important;
  font-size: 1.2rem!important;
  font-weight: 300!important;
  font-family: Poppins;
 };
#loginButton {
    color: $textColor!important;
    background: $lightBlueDisabled!important;
    height: 2.5rem;
    font-size: 1.3rem!important;
    font-weight: 500!important;
    font-family: Raleway;
    border: 2px;
    border-color: $dark;
    text-decoration: none;
    display: flex;
    justify-content: center;
 };
};
@media screen and (min-width: 1025px) and (max-width: 1366px)  {
  .bg {
  background-image: url('~@/assets/FxHubBackgroundImageLarge.jpg');
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  };
  .loginFrame {
  width: 20rem;
  height: 28rem;
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin-bottom: auto;
  margin-top: 8rem;
  margin-left: auto;
  margin-right: auto;
 };
 #loginHeader {
  color: $darkBlue!important;
  font-size: 1.8rem!important;
  font-weight: 500!important;
  font-family: Poppins;
 };
 #loginCard {
  text-align: center;
  background-color: transparent;
  font-family: $fontFamily;
 };
#loginSubHeader {
  color: $darkBlue!important;
  font-size: 1.2rem!important;
  font-weight: 500!important;
  font-family: Poppins;
 };
#loginText {
  color: $darkBlue!important;
  font-size: 1.2rem!important;
  font-weight: 300!important;
  font-family: Poppins;
 };
#loginButton {
    color: $textColor!important;
    background: $lightBlueDisabled!important;
    height: 2.5rem;
    font-size: 1.3rem!important;
    font-weight: 500!important;
    font-family: Raleway;
    border: 2px;
    border-color: $dark;
    text-decoration: none;
    display: flex;
    justify-content: center;
 };
};
@media screen and (min-width: 1367px)  {
  .bg {
  background-image: url('~@/assets/FxHubBackgroundImageExtraLarge.jpg');
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  };
  .loginFrame {
  width: 20rem;
  height: 30rem;
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin-bottom: auto;
  margin-top: 15rem;
  margin-left: auto;
  margin-right: auto;
 };
 #loginHeader {
  color: $darkBlue!important;
  font-size: 1.8rem!important;
  font-weight: 500!important;
  font-family: Poppins;
 };
 #loginCard {
  text-align: center;
  background-color: transparent;
  font-family: $fontFamily;
 };
#loginSubHeader {
  color: $darkBlue!important;
  font-size: 1.2rem!important;
  font-weight: 500!important;
  font-family: Poppins;
 };
#loginText {
  color: $darkBlue!important;
  font-size: 1.2rem!important;
  font-weight: 300!important;
  font-family: Poppins;
 };
#loginButton {
    color: $textColor!important;
    background: $lightBlueDisabled!important;
    height: 3rem;
    font-size: 1.5rem!important;
    font-weight: 500!important;
    font-family: Raleway;
    border: 2px;
    border-color: $dark;
    text-decoration: none;
    display: flex;
    justify-content: center;
 };
};
.scale-in-center {
   -webkit-animation: scale-in-center 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) 1s both;
    animation: scale-in-center 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) 1s both;
};
@-webkit-keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
}
@keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
}

</style>
