<template>
  <div class="date-selector">
    <div style="width: 100%; height: 30px;">
      <TextInput
        :text="text"
        :read-only="true"
        :maintain-focus="focus"
        @fi="onFi"
      >
        <template #left-icon>
          <div class="pi pi-calendar"></div>
        </template>
      </TextInput>
    </div>
    <div
      v-if="showDropdown"
      class="dropdown"
    >
      <div style="width: 100%; height: 100%;">
        <div style="width: 100%; height: 40px; display: flex; flex-direction: row; align-items: center; gap: 4px;">
          <div
            :class="leftArrowClass"
            :style="{ color: (year === props.min.getFullYear() ? '#F3F4F6' : '#4B5563') }"
            @click="previousYear"
          ></div>
          <div class="year-indicator">
            {{year}}
          </div>
          <div
            :class="rightArrowClass"
            :style="{ color: (year === props.max.getFullYear() ? '#F3F4F6' : '#4B5563') }"
            @click="nextYear"
          ></div>
        </div>
        <div style="width: 100%; height: 150px;">
          <div class="month-row">
            <div
              v-for="(month, index) in months.slice(0, 4)"
              :key="index"
              :class="monthClasses[index]"
              @mousedown="onMouseDown(index)"
            >
              {{month}}
            </div>
          </div>
          <div class="month-row">
            <div
              v-for="(month, index) in months.slice(4, 8)"
              :key="index + 4"
              :class="monthClasses[index + 4]"
              @mousedown="onMouseDown(index + 4)"
            >
              {{month}}
            </div>
          </div>
          <div class="month-row">
            <div
              v-for="(month, index) in months.slice(8, 12)"
              :key="index + 8"
              :class="monthClasses[index + 8]"
              @mousedown="onMouseDown(index + 8)"
            >
              {{month}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import TextInput from '@/components/TextInput.vue'
import { ref, defineProps, defineEmits, computed, watch } from 'vue'

const emit = defineEmits(['select'])

const props = defineProps({
  min: Date,
  max: Date,
  date: Date,
  period: String
})

const text = ref(props.date.toLocaleDateString('en-GB', { month: 'short', year: 'numeric' }))
const showDropdown = ref(false)
const year = ref(props.date.getFullYear())
const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
const focus = ref(false)

const monthClasses = computed(() => {
  return months.map((x, i) => {
    if (props.period === 'Quarterly') {
      if ((i + 1) % 3 !== 0) {
        return 'disabled-month'
      }
    } else if (props.period === 'Yearly') {
      if ((i + 1) % 12 !== 0) {
        return 'disabled-month'
      }
    }
    if (year.value === props.date.getFullYear() && i === props.date.getMonth()) {
      return 'selected-month'
    } else if (year.value !== props.min.getFullYear() && year.value !== props.max.getFullYear()) {
      return 'month'
    } else if (year.value === props.min.getFullYear()) {
      if (i >= props.min.getMonth()) {
        return 'month'
      }
    } else if (year.value === props.max.getFullYear()) {
      if (i <= props.max.getMonth()) {
        return 'month'
      }
    }
    return 'disabled-month'
  })
})

const leftArrowClass = computed(() => {
  if (year.value === props.min.getFullYear()) {
    return 'disabled-left-arrow pi pi-angle-left'
  }
  return 'left-arrow pi pi-angle-left'
})

const rightArrowClass = computed(() => {
  if (year.value === props.max.getFullYear()) {
    return 'disabled-right-arrow pi pi-angle-right'
  }
  return 'right-arrow pi pi-angle-right'
})

function onMouseDown (index) {
  emit('select', { date: new Date(year.value, index) })
  showDropdown.value = false
  focus.value = false
}

function onFi () {
  showDropdown.value = true
  focus.value = true
}

function previousYear () {
  if (year.value > props.min.getFullYear()) {
    year.value--
  }
}

function nextYear () {
  if (year.value < props.max.getFullYear()) {
    year.value++
  }
}

watch(() => { return props.date }, () => {
  text.value = props.date.toLocaleDateString('en-GB', { month: 'short', year: 'numeric' })
})
</script>

<script>
export default {
  name: 'DateSelector',
  inheritAttrs: false
}
</script>

<style scoped>
.date-selector {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-y: visible;
  position: absolute;
}

.dropdown {
  width: 100%;
  height: 200px;
  margin-top: 2px;
  padding: 5px;
  background-color: white;
  display: flex;
  flex-direction: column;
  border-radius: 0.25rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  overflow: hidden;
  z-index: 10;
}

.disabled-left-arrow, .disabled-right-arrow, .left-arrow, .right-arrow {
  width: 20%;
  height: 100%;
  border-radius: 0.25rem;
}

.disabled-left-arrow:hover, .disabled-right-arrow:hover {
  cursor: not-allowed;
}

.left-arrow:hover, .right-arrow:hover {
  background-color: #F3F4F6;
  cursor: pointer;
}

.pi-angle-left, .pi-angle-right {
  font-size: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.year-indicator {
  color: #4B5563;
  width: 60%;
  height: 100%;
  background-color: #F3F4F6;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 0.25rem;
}

.month-row {
  width: 100%;
  height: 33.3%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 4px 4px 4px;
  gap: 6px;
}

.month, .disabled-month, .selected-month {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  border-radius: 0.25rem;
}

.month, .selected-month {
  color: #4B5563;
}

.disabled-month {
  color: #F3F4F6;
}

.selected-month {
  background-color: #F3F4F6;
}

.month:hover {
  cursor: pointer;
  background-color: #F3F4F6;
}

.disabled-month:hover {
  cursor: not-allowed;
}

.selected-month:hover {
  cursor: pointer;
}

.pi-calendar {
  color: #4B5563;
  font-size: 14px;
}
</style>
