<template>
  <br />
  <br />
  <h2 class="text-center my-2">ALL CLIENTS HOME</h2>
  <br />
  <br />
  <div class="fluid grid">
    <div class="col-12 md:col-6 lg:col-6">
        <Card class="shadow-5 text-center d-block mx-auto" id="newClient">
            <template class="text-center" #title>
              <i class="pi pi-list" id="cardLogo"></i>
            </template>
            <template  #content>
              <router-link to="/allTccView" style="color: #e2e7e5; text-decoration: none">
              TCC
              </router-link>
            </template>
        </Card>
    </div>
    <div class="col-12 md:col-6 lg:col-6">
        <Card class="shadow-5 text-center d-block mx-auto" id="fromPartner">
            <template class="text-center" #title>
            <i class="pi pi-globe" id="cardLogo"></i>
            </template>
            <template  #content>
            <router-link to="/allTradesView" style="color: #e2e7e5; text-decoration: none">
            TRADES
            </router-link>
            </template>
        </Card>
    </div>
  </div>
  <br />
  <div class="fluid grid">
    <div class="col-12 md:col-6 lg:col-6">
      <Card class="shadow-5 text-center d-block mx-auto" id="fromPartner">
          <template class="text-center" #title>
          <i class="pi pi-chart-bar" id="cardLogo"></i>
          </template>
          <template  #content>
          <router-link to="/allBalancesHomeView" style="color: #e2e7e5; text-decoration: none">
          BALANCES
          </router-link>
          </template>
      </Card>
    </div>
    <div class="col-12 md:col-6 lg:col-6">
        <Card class="shadow-5 text-center d-block mx-auto" id="viewRisk">
            <template class="text-center" #title>
                <i class="pi pi-exclamation-triangle" id="cardLogo"></i>
            </template>
            <template  #content>
                <router-link to="/allRiskView" style="color: #e2e7e5; text-decoration: none">
                RISK
                </router-link>
            </template>
        </Card>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'AllHome',
  inheritAttrs: false
}
</script>

<style lang="scss" scoped>
#newClient {
 @include cardComponent($midDarkBlue)
};
#fromPartner {
 @include cardComponent($midLightBlue)
};
#viewClients {
 @include cardComponent($lightDarkBlue)
};
#viewRisk {
 @include cardComponent($lightBlue)
};
.p-card.p-component.p-card-content {
 font-size: 24px;
};
.p-grid {
  margin: 1rem;
  padding: 1rem;
};
</style>
