<template>
  <div style="width: 100vw; height: 100vh; display: flex; flex-direction: row;">
    <SideBar :items="items"/>
    <div
      v-if="!store.state.reportLoading"
      style="width: 100%; height: 100%;"
    >
      <Clients v-if="id === 'clients'"/>
      <Trades v-else-if="id === 'trades'"/>
      <Profit v-else-if="id === 'profit'"/>
      <Tcc v-else-if="id === 'tcc'"/>
      <ZarFlows v-else-if="id === 'zar-flows'"/>
    </div>
    <div
      v-else
      style="display: flex; align-items: center; justify-content: center; width: 100%; height: 100%;"
    >
      <div style="width: 100px; height: 100px;">
        <Loader/>
      </div>
    </div>
  </div>
</template>

<script setup>
import { store } from '@/store'
import { onBeforeMount, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import Clients from '@/views/report/Clients.vue'
import Trades from '@/views/report/Trades.vue'
import Profit from '@/views/report/Profit.vue'
import Tcc from '@/views/report/Tcc.vue'
import ZarFlows from '@/views/report/ZarFlows.vue'
import Loader from '@/components/Loader.vue'
import SideBar from '@/components/SideBar'

const route = useRoute()
const router = useRouter()
const items = [
  {
    text: 'Clients',
    to: '/report?id=clients'
  },
  {
    text: 'Trades',
    to: '/report?id=trades'
  },
  {
    text: 'Profit',
    to: '/report?id=profit'
  },
  {
    text: 'TCC',
    to: '/report?id=tcc'
  },
  {
    text: 'ZAR Flows',
    to: '/report?id=zar-flows'
  }
]
const id = ref('')

watch(route, () => {
  if (route.query.id) {
    id.value = route.query.id
  }
})

onBeforeMount(() => {
  router.push('/report?id=clients')
  id.value = 'clients'
  store.commit('loadPartners')
  store.commit('loadReport')
})
</script>

<script>
export default {
  name: 'Report',
  inheritAttrs: false
}
</script>

<style scoped>

</style>
