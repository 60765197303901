<template>
<br />
    <h2 class="text-center my-2">CLIENT TCC VIEW</h2>
<br />
<div class="fluid mx-3 shadow-2">
    <DataTable :value="clientTcc"
                showGridlines
                responsiveLayout="stack"
                breakpoint="1024px"
                :rows="8"
                class="p-datatable-retailClient">
        <Column field="id" header="TCC ID" headerStyle="width: 2vw"></Column>
        <Column field="firstLastName" header="Name" headerStyle="width: 5vw"></Column>
        <Column field="tccValue" header="Value (ZAR)" headerStyle="width: 4vw">
                <template #body="{data}">
                    {{formatCurrency(data.tccValue)}}
                </template>
        </Column>
        <Column field="tccPin" header="Pin" headerStyle="width: 4vw"></Column>
        <Column field="tccIssuer" header="Issuer" headerStyle="width: 4vw"></Column>
        <Column field="issueDate" header="Issued" headerStyle="width: 4vw"></Column>
        <Column field="expiryDate" header="Expiry" headerStyle="width: 4vw"></Column>
        <Column field="applicationStatus" header="Status" headerStyle="width: 4vw">
                <template #body="{data}">
                    <span :class="'retailClient-badge status-' + data.applicationStatus">{{data.applicationStatus}}</span>
                </template>
        </Column>
        <Column header="Edit" headerStyle="width: 4vw;">
            <template #body="{data}">
              <div v-if="data.applicationStatus == 'In-Process'">
                <router-link class="p-button-raised d-block mx-auto px-auto" disabled="disabled" :to="'/ClientView/id'" style="color: #e2e7e5; text-decoration: none;
                                    display: flex; justify-content: center;">
                  <i class="pi pi-user-edit" style="fontSize: 1rem"></i>&nbsp;&nbsp;EDIT
                </router-link>
              </div>
              <div v-else-if="data.applicationStatus != 'In-Process'">
                <router-link class="p-button d-block mx-auto px-auto" :to="'/manage/' + data.id"  style="color: #e2e7e5; text-decoration: none;
                                    display: flex; justify-content: center;">
                  <i class="pi pi-user-edit" style="fontSize: 1rem"></i>&nbsp;&nbsp;EDIT
                </router-link>
              </div>
            </template>
        </Column>
        <Column header="Docs" headerStyle="width: 4vw;">
            <template #body="{data}">
                <router-link class="p-button d-block mx-auto px-auto" :to="'/TccDocsHome/' + data.id" style="color: #e2e7e5; background-color: #0185b7!important;
                                    text-decoration: none; display: flex; justify-content: center;">
                  <i class="pi pi-user-edit" style="fontSize: 1rem"></i>&nbsp;&nbsp;DOCS
                </router-link>
            </template>
        </Column>
    </DataTable>
  </div>
  <br />
    <div>
        <Button class="l-2 mt-1 mb-4 mx-3 shadow-3 p-button-lg"
        icon="pi pi-step-backward"
        label="BACK&nbsp;"
        id="submitButton"
         @click.prevent="$router.back()" />
    </div>
</template>

<script>
import { getClientTccs } from '@/gateway'

export default {
  name: 'TccView',
  inheritAttrs: false,
  data () {
    return {
      clientTcc: [],
      clientTccUpdateSuccess: false,
      displayConfirmation: true
    }
  },
  methods: {
    async init () {
      const response = await getClientTccs({ id: this.id })
      if (response) {
        this.clientTcc = response.result
      }
    },
    formatCurrency (value) {
      return value.toLocaleString('en-US', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 })
    }
  },
  mounted () {
    this.id = this.$route.params.id
    this.init()
  }
}
</script>

<style lang="scss" scoped>
::v-deep() {
  .p-datatable .p-datatable-thead > tr > th {
    background-color: $middleBlue!important;
    color: $textColor!important;
    border: 1px solid $darkBlue!important;
    font-size: 18px;
    font-weight: 600;
  }
  .p-datatable .p-datatable-tbody > tr > td {
    background-color: $clientViewTableBackground;
    border: 1px solid $darkBlue!important;
    font-family:'Poppins', sans-serif;
    font-weight: 400;
    padding: .5rem .5rem;
  }
    .retailClient-badge {
      @include statusBadge
  };
};
</style>
