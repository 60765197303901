<template>
<br />
    <h2 class="text-center my-2">MANAGE TCC APPLICATION PROCESS FOR : {{ clientTcc.firstLastName}} </h2>
<br />
<div class="fluid mx-4">
  <div class="formgrid grid">
    <div class="col-12 md:col-6 lg:col-6">
      <h5 class="text-left">TCC Amount in ZAR</h5>
        <InputNumber
        mode="decimal"
        :minFractionDigits="2"
        readonly
        v-model="clientTcc.tccValue"
        class="inputfield w-full"/>
    </div>
      <div class="col-12 md:col-6 lg:col-6">
        <h5 class="text-left">TCC Issuer</h5>
          <Dropdown v-model="clientTcc.tccIssuer"
          :options="issuers"
          optionLabel="text"
          optionValue="value"
          :showClear="true"
          appendTo="self"
          class="inputfield w-full"
          placeholder="Please Select" />
    </div>
</div>
<div class="formgrid grid">
    <div class="col-12 md:col-6 lg:col-6">
      <h5 class="text-left">Application Status</h5>
        <Dropdown v-model="clientTcc.applicationStatus"
        :options="statuses"
        optionLabel="text"
        optionValue="value"
        :showClear="true"
        appendTo="self"
        class="inputfield w-full"
        placeholder="Please Select" />
    </div>
</div>
<div class="formgrid grid">
    <div class="col-12 md:col-12 lg:col-12">
        <h5 class="text-left">Comments</h5>
        <Textarea
            v-model="clientTcc.comments"
            rows="5"
            cols="30"
            class="inputfield w-full"/>
    </div>
</div>
<div class="formgrid grid">
  <div class="col-12 md:col-6 lg:col-6">
    <h5 class="text-left">Initial Application Date</h5>
          <Calendar
          p-datepicker
          disabled
          v-model="clientTcc.initialSend"
          class="inputfield w-full"
          dateFormat="yy-mm-dd" />
  </div>
  <div class="col-12 md:col-6 lg:col-6">
    <h5 class="text-left">Last Updated Date</h5>
          <Calendar
          p-datepicker
          disabled
          v-model="clientTcc.updatedAt"
          class="inputfield w-full"
          dateFormat="yy-mm-dd" />
  </div>
</div>
<div class="formgrid grid">
  <div class="col-12 md:col-6 lg:col-6">
    <h5 class="text-left">Time Elapsed Since Initial Application (in Days)</h5>
      <InputText
      type="text"
      readonly
      v-model="clientTcc.timeElapsed"
      class="inputfield w-full"/>
  </div>
</div>
<transition name="fade">
  <div v-if="clientTcc.applicationStatus == 'issued' || clientTcc.applicationStatus == 'emigration_pin'">
    <hr class="mx-2"/>
    <div class="formgrid grid">
      <div class="col-12 md:col-6 lg:col-6">
        <h5 class="text-left">TCC/Emigration Pin</h5>
          <InputText
          type="text"
          v-model="clientTcc.tccPin"
          class="inputfield w-full"/>
      </div>
    </div>
    <div class="formgrid grid">
        <div class="col-12 md:col-6 lg:col-6">
          <h5>Issue Date</h5>
            <Calendar
            v-model="clientTcc.issueDate"
            :showIcon="true"
            appendTo="self"
            class="inputfield w-full"
            dateFormat="yy-mm-dd"/>
        </div>
        <div class="col-12 md:col-6 lg:col-6">
          <h5>Expiry Date</h5>
            <Calendar
            v-model="clientTcc.expiryDate"
            :monthNavigator="true"
            :yearNavigator="true"
             yearRange="2020:2099"
            :showIcon="true"
            appendTo="self"
            class="inputfield w-full"
            dateFormat="yy-mm-dd" />
        </div>
    </div>
  </div>
</transition>
<hr class="mx-2"/>
<br />
    <div class="formgrid grid">
      <div class="col-12 md:col-6 lg:col-6">
        <h5 class="text-left">TCC Charges</h5>
          <InputNumber
          mode="decimal"
          :minFractionDigits="2"
          v-model="clientTcc.charges"
          class="inputfield w-full"/>
      </div>
        <div class="col-12 md:col-6 lg:col-6">
          <h5>Charges Date</h5>
            <Calendar
            v-model="clientTcc.chargesDate"
            :monthNavigator="true"
            :yearNavigator="true"
             yearRange="2020:2099"
            :showIcon="true"
            appendTo="self"
            class="inputfield w-full"
            dateFormat="dd-mm-yy" />
      </div>
  </div>
  <div class="formgrid grid">
    <div class="col-12 md:col-6 lg:col-6">
      <h5 class="text-left">Charges Invoice</h5>
        <InputText
        type="text"
        v-model="clientTcc.chargesInvoice"
        class="inputfield w-full"/>
    </div>
    <div class="col-12 md:col-6 lg:col-6">
      <h5 class="text-left">Charges Type</h5>
        <Dropdown v-model="clientTcc.chargesType"
        :options="chargesTypes"
        optionLabel="text"
        optionValue="value"
        :showClear="true"
        appendTo="self"
        class="inputfield w-full"
        placeholder="Please Select" />
    </div>
  </div>
  <div class="formgrid grid">
    <div class="col-12 md:col-6 lg:col-6">
      <h5 class="text-left">Partner Name</h5>
        <InputText
        type="text"
        readonly
        v-model="clientTcc.partnerName"
        class="inputfield w-full"/>
    </div>
  </div>
</div>
<hr class="mx-5"/>
<div class="flex justify-content-between mx-2">
    <div>
        <Button class="l-2 mt-1 mb-4 mx-4 shadow-3 p-button-lg"
        icon="pi pi-step-backward"
        label="BACK&nbsp;"
        id="submitButton"
        @click.prevent="$router.back()" />
    </div>
    <div>
        <Button class="l-2 mt-1 mb-4  mx-4 shadow-3 p-button-lg "
        icon="pi pi-upload"
        label="UPDATE"
        id="backButton"
        @click.prevent="editClientTcc" />
    </div>
</div>
<div class="formgrid grid">
  <div class="col-12 md:col-6 lg:col-6">
    <div v-if='clientTccUpdateSuccess'>
    <Dialog class="successModal" header="SUCCESS" id="successModal" v-model:visible="displayConfirmation" :modal="true">
        <div class="confirmation-content">
            <span>TCC Application Updated</span>
        </div>
        <template #footer>
            <router-link class="p-button d-block mx-auto px-auto" :to="'/tccView/' + clientTcc.clientId"  style="background-color: #0185b7!important; text-decoration: none
                                text-decoration: none; min-width: 100%; justify-content: center;">
              <i class="pi pi-list" style="fontSize: 1rem"></i>&nbsp;&nbsp;VIEW
            </router-link>
        </template>
    </Dialog>
    </div>
  </div>
</div>
</template>

<script>
import { getClientTcc, updateClientTcc } from '@/gateway'

export default {
  name: 'Manage',
  inheritAttrs: false,
  data () {
    return {
      clientTcc: {},
      retailClient: {},
      clientTccUpdateSuccess: false,
      displayConfirmation: true,
      issuers: [
        { value: 'Acredo', text: 'Acredo' },
        { value: 'Eton', text: 'Eton' },
        { value: 'Jaco', text: 'Jaco' },
        { value: 'Independent', text: 'Independent' }
      ],
      statuses: [
        { value: 'processing', text: 'Processing' },
        { value: 'with_Client', text: 'With Client' },
        { value: 'with_Issuer', text: 'With Issuer' },
        { value: 'with_Sars', text: 'With SARS' },
        { value: 'emigration', text: 'Emigration' },
        { value: 'issued', text: 'Issued' },
        { value: 'emigration_pin', text: 'Emigration Pin' },
        { value: 'complete', text: 'Complete' },
        { value: 'expired', text: 'Expired' },
        { value: 'cancelled', text: 'Cancelled' },
        { value: 'non_Compliant', text: 'Non-Compliant' }
      ],
      chargesTypes: [
        { value: 'AC1', text: 'AC1' },
        { value: 'Client Paid', text: 'Client Paid' },
        { value: 'Paid in Rate', text: 'Paid in Rate' },
        { value: 'Claimed from Partner', text: 'Claimed from Partner' }
      ]
    }
  },
  methods: {
    async init () {
      const response = await getClientTcc({ id: this.id })
      if (response) this.clientTcc = response.result
    },
    async editClientTcc () {
      const response = await updateClientTcc({ clientTcc: this.clientTcc })
      if (response) {
        this.clientTccUpdateSuccess = true
      }
    }
  },
  mounted () {
    this.id = this.$route.params.id
    this.init()
  }
}
</script>
