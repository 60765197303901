<template>
<br />
    <h2 class="text-center my-2">PARTNER USERS</h2>
<br />
  <div>
    <Button icon="pi pi-info" class="p-button-rounded p-button-lg mx-4 shadow-5" @click="toggle" />
    <OverlayPanel ref="op" appendTo="body" :showCloseIcon="true" id="overlay_panel" style="width: 20rem" :breakpoints="{'960px': '75vw'}">
      <p>Use the <i class="pi pi-sort-alt"></i> to filter results.</p>
      <p>Use <i class="pi pi-sort-amount-up-alt"></i> or <i class="pi pi-sort-amount-down-alt"></i> to change the sort order.</p>
      <p>To filter by multiple columns use Ctrl + Click on the second filter column.</p>
      <p>Use the <i class="pi pi-filter"></i> to search for a specific user. You can search on multiple columns.</p>
      </OverlayPanel>
  </div>
  <br />
  <div class="fluid mx-3 shadow-2">
    <DataTable :value="user"
                dataKey="id" :rowHover="true"
                stripedRows responsiveLayout="stack"
                showGridlines
                breakpoint="1024px"
                :paginator="true"
                :rows="8"
                v-model:filters="filters1"
                filterDisplay="menu"
                class="datatable-retailClient">
        <Column field="nickname" header="User Name" :sortable="true" headerStyle="width: 3vw">
          <template #filter="{filterModel}">
              <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by Name"/>
          </template>
        </Column>
        <Column field="primaryEmail" header="User Email" :sortable="true" headerStyle="width: 3vw">
          <template #filter="{filterModel}">
              <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by Email"/>
          </template>
        </Column>
        <Column field="phoneNumber" header="Contact" headerStyle="width: 3vw">
          <template #filter="{filterModel}">
              <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by Number"/>
          </template>
        </Column>
        <Column field="partnerName" header="Partner Access" :sortable="true" headerStyle="width: 3vw">
          <template #filter="{filterModel}">
              <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by Partner"/>
          </template>
        </Column>
        <Column header="Multiple Logins" headerStyle="width: 2vw">
        <template #body="{data}">
                <span :class="'retailClient-badge status-' + data.hasMultipleLogins">{{data.hasMultipleLogins}}</span>
        </template>
        </Column>
        <Column header="View" headerStyle="width: 2vw;">
            <template #body="{data}">
                <router-link class="p-button d-block mx-1 px-1 shadow-1" :to="'/usersEdit/' + data.id"
                              style="color: #E6E6F0; background-color: #063a6a!important; text-decoration: none;
                                    display: flex; justify-content: center;">
                  <i class="pi pi-user-edit" style="fontSize: 1rem"></i>&nbsp;&nbsp;EDIT
                </router-link>
            </template>
        </Column>
        <Column header="Delete" headerStyle="width: 2vw;">
            <template #body="{data}">
              <Button class="p-button d-block px-auto" :loading="loadingDeleteUser" @click="deleteUser(data.email)"
                style="color: #e2e7e5; background: #d2161e!important; text-decoration: none;
                display: flex; justify-content: center; width: 93%;"
                icon="pi pi-eye-slash"
                label="&nbsp;DELETE"/>
            </template>
        </Column>
    </DataTable>
  </div>
  <div>
      <Button class="l-2 m-4 mx-3 shadow-4 p-button-lg"
      icon="pi pi-step-backward"
      label="BACK&nbsp;"
      id="submitButton"
      @click.prevent="$router.push( {name: 'UsersHome' })"/>
  </div>
  <div v-if='deleteSuccess'>
    <Dialog class="successModal" header="SUCCESS" id="successModal" v-model:visible="displayConfirmation" :modal="true">
        <div class="confirmation-content">
            <span>Partner User Deleted From FxHub</span>
        </div>
        <template #footer>
          <div class="flex justify-content-between">
            <router-link class="p-button d-block mx-2 px-auto" :to="'/usersHome'"  style="background-color: #0185b7!important; text-decoration: none
                                text-decoration: none; width: 90%; justify-content: center;">
              <i class="pi pi-users" style="fontSize: 1rem"></i>&nbsp;Users Home
            </router-link>
          </div>
        </template>
    </Dialog>
  </div>
</template>

<script>
import { getUsers, deletePartnerUser } from '@/gateway'
import { FilterMatchMode, FilterOperator } from 'primevue/api'

export default {
  name: 'UsersView',
  inheritAttrs: false,
  data () {
    return {
      user: [],
      filters1: null,
      openErrorModal: false,
      openDeactivateModal: false,
      deleteSuccess: false,
      displayConfirmation: true,
      email: '',
      loadingDeleteUser: false
    }
  },
  methods: {
    async init () {
      const response = await getUsers()
      if (response) {
        this.user = response.result
      }
    },
    async deleteUser (email) {
      this.loadingDeleteUser = true
      const response = await deletePartnerUser({ email: email })
      if (response) {
        this.loadingDeleteUser = false
        this.deleteSuccess = true
      }
    },
    initFilters1 () {
      this.filters1 = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        nickname: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
        primaryEmail: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
        partnerName: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
        phoneNumber: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] }
      }
    },
    toggle (event) {
      this.$refs.op.toggle(event)
    },
    openDeactivate () {
      this.openDeactivateModal = true
    },
    closeDeactivate () {
      this.openDeactivateModal = false
    }
  },
  created () {
    this.initFilters1()
  },
  mounted () {
    this.init()
  }
}
</script>

<style lang="scss" scoped>
::v-deep() {
  .p-datatable .p-datatable-thead > tr > th {
    background-color: $middleBlue!important;
    color: $textColor;
    border: 1px solid $borderColour!important;
    font-size: 18px;
    font-weight: 600;
  }
  .p-datatable .p-datatable-tbody > tr > td {
    padding: .4rem .4rem;
    color: $darkTextColour;
    font-family:'Poppins', sans-serif;
    font-weight: 400;
  }
  .p-paginator-current {
        margin-right: auto;
    }
  .retailClient-badge {
      @include statusBadge
  };
  #disabled {
    background: $textColor!important;
    color: $middleBlue!important;
  };
  #searchtext {
    min-width: 40vw;
  };
};
</style>
