<template>
<br />
    <h2 class="text-center my-2">ORDER BOOK</h2>
<br />
  <div>
    <Button icon="pi pi-info" class="p-button-rounded p-button-lg mx-4 shadow-5" @click="toggle" />
    <OverlayPanel ref="op" appendTo="body" :showCloseIcon="true" id="overlay_panel" style="width: 20rem" :breakpoints="{'960px': '75vw'}">
      <p>Use the <i class="pi pi-sort-alt"></i> to filter results.</p>
      <p>Use <i class="pi pi-sort-amount-up-alt"></i> or <i class="pi pi-sort-amount-down-alt"></i> to change the sort order.</p>
      <p>To filter by multiple columns use Ctrl + Click on the second filter column.</p>
      <p>Use the <i class="pi pi-filter"></i> to search for a specific client. You can search on multiple columns.</p>
      </OverlayPanel>
  </div>
  <br />
  <div class="fluid mx-3 shadow-2">
    <DataTable :value="orderBook"
                dataKey="id" :rowHover="true"
                stripedRows responsiveLayout="stack"
                showGridlines
                breakpoint="1024px"
                :paginator="true"
                :rows="8"
                v-model:filters="filters1"
                filterDisplay="menu"
                class="datatable-retailClient">
        <Column field="partnerName" header="Partner" :sortable="true" headerStyle="width: 3vw">
          <template #filter="{filterModel}">
              <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by Partner"/>
          </template>
        </Column>
        <Column field="clientName" header="Client" :sortable="true" headerStyle="width: 4vw">
          <template #filter="{filterModel}">
              <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by Name"/>
          </template>
        </Column>
        <Column field="datePlaced" header="Date" :sortable="true" dateFormat="dd.mm.yyyy" headerStyle="width: 4vw">
          <template #filter="{filterModel}">
              <InputText type="date" v-model="filterModel.value" class="p-column-filter" placeholder="Search by Date"/>
          </template>
        </Column>
        <Column field="orderType" header="Trade" headerStyle="width: 2vw"></Column>
        <Column field="currencyType" header="Currency" headerStyle="width: 1vw"></Column>
        <Column field="currencyAmount" header="Amount" dataType="numeric" headerStyle="width: 3vw">
               <template #body="{data}">
                    {{formatCurrency(data.currencyAmount)}}
                </template>
        </Column>
        <Column field="tradeLevel" header="Rate" dataType="numeric" headerStyle="width: 3vw">
               <template #body="{data}">
                    {{formatRate(data.tradeLevel)}}
                </template>
        </Column>
        <Column field="zarNominal" header="ZAR" dataType="numeric" headerStyle="width: 3vw">
               <template #body="{data}">
                    {{formatCurrency(data.zarNominal)}}
                </template>
        </Column>
        <Column field="traderName" header="Trader" headerStyle="width: 2vw"></Column>
        <Column field="orderStatus" header="Status" :sortable="true" headerStyle="width: 3vw" :filterMenuStyle="{'width':'14rem'}" style="min-width:4vw">
          <template #body="{data}">
              <span :class="'retailClient-badge status-' + data.orderStatus">{{data.orderStatus}}</span>
          </template>
          <template #filter="{filterModel}">
              <Dropdown v-model="filterModel.value" :options="statuses" placeholder="Select Status" class="p-column-filter" :showClear="true">
              </Dropdown>
          </template>
        </Column>
        <Column header="Edit" headerStyle="width: 3vw;">
            <template #body="{data}">
               <router-link class="p-button d-block mx-1 px-1 shadow-1" :to="'/ordersEdit/' + data.id"
                            style="color: #E6E6F0; background-color: #063a6a!important; text-decoration: none;
                                   display: flex; justify-content: center;">
                 <i class="pi pi-fw pi-book" style="fontSize: 1rem"></i>&nbsp;&nbsp;EDIT
              </router-link>
            </template>
        </Column>
    </DataTable>
  </div>
    <Dialog class="deleteModal" header="UNAUTHORISED ACCESS" v-model:visible="openErrorModal" :modal="true">
        <div class="confirmation-content">
            <span>User not authorised to view this information.</span>
            <br />
                  Please contact the system administrator.
        </div>
        <template #footer>
            <a class="p-button d-block mr-2 px-auto" @click.prevent="$router.push( {name: 'OrdersHome' })"
              style="float: left; background-color: #014f7e!important;
                     text-decoration: none; justify-content: center;">
              <i class="pi pi-step-backward" style="fontSize: 1rem"></i> &nbsp;&nbsp;Back to Order Book Home
            </a>
        </template>
    </Dialog>
  <div>
    <Button class="l-2 m-4 mx-3 shadow-3 p-button-lg"
    icon="pi pi-step-backward"
    label="BACK&nbsp;"
    id="submitButton"
    @click.prevent="$router.push( {name: 'OrdersHome' })" />
 </div>
</template>

<script>
import { getOrderBookList } from '@/gateway'
import { FilterMatchMode, FilterOperator } from 'primevue/api'

export default {
  name: 'OrdersView',
  inheritAttrs: false,
  data () {
    return {
      orderBook: [],
      filters1: null,
      openErrorModal: false,
      toLocaleString: '',
      statuses: [
        'gtc',
        'filled',
        'cancelled',
        'expired',
        'amended'
      ]
    }
  },
  methods: {
    async init () {
      const response = await getOrderBookList()
      if (response.message === 'Unauthorised User') {
        this.openErrorModal = true
      } else {
        this.orderBook = response.result
      }
    },
    initFilters1 () {
      this.filters1 = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        partnerName: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
        clientName: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
        datePlaced: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
        orderStatus: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] }
      }
    },
    toggle (event) {
      this.$refs.op.toggle(event)
    },
    formatCurrency (value) {
      return value.toLocaleString('en-US', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 })
    },
    formatRate (value1) {
      return value1.toLocaleString('en-US', { style: 'decimal', maximumFractionDigits: 4, minimumFractionDigits: 4 })
    }
  },
  created () {
    this.initFilters1()
  },
  mounted () {
    this.init()
  }
}
</script>

<style lang="scss" scoped>
::v-deep() {
  .p-datatable .p-datatable-thead > tr > th {
    background-color: $middleBlue!important;
    color: $textColor;
    border: 1px solid $borderColour!important;
    font-size: 18px;
    font-weight: 600;
  }
  .p-datatable .p-datatable-tbody > tr > td {
    padding: .4rem .4rem;
    color: $darkTextColour;
    font-family:'Poppins', sans-serif;
    font-weight: 400;
  }
  .p-paginator-current {
        margin-right: auto;
    }
  .retailClient-badge {
      @include statusBadge
  };
  #disabled {
    background: $textColor!important;
    color: $middleBlue!important;
  };
  #searchtext {
    min-width: 40vw;
  };
};
</style>
