<template>
  <br />
  <h2 class="text-center my-2">CLIENT TCC BALANCE</h2>
  <br />
  <div v-if="displayEmptyRemainder == false">
    <div class="fluid mx-3 shadow-3">
        <DataTable :value="tccRemainder"
                    dataKey="id" :rowHover="true"
                    stripedRows responsiveLayout="stack"
                    showGridlines
                    breakpoint="1024px"
                    :paginator="true"
                    :rows="8"
                    class="datatable-retailClient">
            <Column field="clientId" header="ID" headerStyle="width: 1vw"></Column>
            <Column field="firstLastName" header="Client" headerStyle="width: 4vw"></Column>
            <Column field="partnerName" header="Partner" headerStyle="width: 3vw"></Column>
            <Column field="cifNumber" header="CIF No" headerStyle="width: 2vw"></Column>
            <Column field="tccValue" header="Total TCC Value" dataType="numeric" headerStyle="width: 3vw">
               <template #body="{data}">
                    {{formatCurrency(data.tccValue)}}
                </template>
            </Column>
            <Column field="tccRemainders" header="TCC Remainder" dataType="numeric" headerStyle="width: 3vw">
               <template #body="{data}">
                    {{formatCurrency(data.tccRemainders)}}
                </template>
            </Column>
            <Column field="applicationStatus" header="TCC Status" headerStyle="width: 3vw">
                <template #body="{data}">
                    <span :class="'retailClient-badge status-' + data.applicationStatus">{{data.applicationStatus}}</span>
                </template>
            </Column>
            <Column field="daRemainder" header="SDA Remainder" dataType="numeric" headerStyle="width: 3vw">
              <template #body="{data}">
                <div v-if="data.expiredPreviousYear === true">
                      <span>0.00</span>
                </div>
                <div v-else>
                      {{formatCurrency(data.daRemainder)}}
                </div>
              </template>
            </Column>
        </DataTable>
     </div>
  </div>
   <div v-else>
    <h3 class="text-center my-2">NO TCC BALANCE FOUND FOR THIS CLIENT</h3>
    <br />
    <hr class="mx-4 shadow-2"/>
   </div>
   <br />
  <div>
    <Button class="l-2 m-2 mx-3 shadow-4 p-button-lg"
    icon="pi pi-step-backward"
    label="BACK&nbsp;"
    id="submitButton"
    @click.prevent="$router.back()" />
  </div>
</template>

<script>
import { getClientTccRemainder } from '@/gateway'

export default {
  name: 'ClientTccRemainder',
  inheritAttrs: false,
  data () {
    return {
      tccRemainder: [],
      displayEmptyRemainder: false
    }
  },
  methods: {
    async init () {
      const response = await getClientTccRemainder({ id: this.id })
      if (response.result.length !== 0) {
        this.tccRemainder = response.result
      } else {
        this.displayEmptyRemainder = true
      }
    },
    formatCurrency (value) {
      return value.toLocaleString('en-US', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 })
    }
  },
  mounted () {
    this.id = this.$route.params.id
    this.init()
  }
}
</script>

<style lang="scss" scoped>
::v-deep() {
  .p-datatable .p-datatable-thead > tr > th {
    background-color: $middleBlue!important;
    color: $textColor;
    border: 1px solid $borderColour!important;
    font-size: 18px;
    font-weight: 600;
  }
  .p-datatable .p-datatable-tbody > tr > td {
    padding: .4rem .4rem;
    color: $darkTextColour;
    font-family:'Poppins', sans-serif;
    font-weight: 400;
  }
  .p-paginator-current {
        margin-right: auto;
    }
  .retailClient-badge {
      @include statusBadge
  };
  #disabled {
    background: $textColor!important;
    color: $middleBlue!important;
  };
  #searchtext {
    margin-left: 1rem!important;
    min-width: 40vw;
  };
};
</style>
