/* eslint-disable space-before-function-paren */
/* jshint proto: true */
'use strict'
/* Options:
Date: 2021-01-15 10:24:59
Version: 5.60
Tip: To override a DTO option, remove "//" prefix before updating
BaseUrl: http://localhost:62001

//GlobalNamespace:
//AddServiceStackTypes: True
//AddResponseStatus: False
//AddImplicitVersion:
//AddDescriptionAsComments: True
//IncludeTypes:
//ExcludeTypes:
//DefaultImports:
*/
var __extends =
  (this && this.__extends) ||
  (function () {
    var extendStatics = function (d, b) {
      extendStatics =
        Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array &&
          function (d, b) {
            // eslint-disable-next-line no-proto
            d.__proto__ = b
          }) ||
        function (d, b) {
          // eslint-disable-next-line no-prototype-builtins
          for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]
        }
      return extendStatics(d, b)
    }
    return function (d, b) {
      extendStatics(d, b)
      function __() {
        this.constructor = d
      }
      d.prototype =
        b === null
          ? Object.create(b)
          // eslint-disable-next-line no-sequences
          : ((__.prototype = b.prototype), new __())
    }
  })()
exports.__esModule = true
// eslint-disable-next-line no-void
exports.GetAccessToken = exports.ConvertSessionToToken = exports.UnAssignRoles = exports.AssignRoles = exports.Authenticate = exports.UnAssignRole = exports.UnAssignAllPermissions = exports.AssignRole = exports.DeleteUser = exports.CreateUser = exports.GetUsers = exports.GetUserInfo = exports.UploadResearchFile = exports.UploadFicaFile = exports.SessionInfo = exports.CreateOrUpdateFund = exports.GetFund = exports.GetFunds = exports.CreateOrUpdateCompany = exports.GetCompany = exports.GetCompanies = exports.AddUser = exports.DeleteAuth0User = exports.SearchAuth0Users = exports.UpdateUserPassword = exports.GetAccessTokenResponse = exports.ConvertSessionToTokenResponse = exports.UnAssignRolesResponse = exports.AssignRolesResponse = exports.AuthenticateResponse = exports.UnAssignRoleResponse = exports.UnAssignAllPermissionsResponse = exports.AssignRoleResponse = exports.DeleteUserResponse = exports.RegisterResponse = exports.GetUsersResponse = exports.GetUserInfoResponse = exports.UploadResearchFile = exports.UploadFicaFileResponse = exports.UploadTccDocsResponse = exports.SessionInfoResponse = exports.CreateOrUpdateFundResponse = exports.GetFundResponse = exports.GetFundsResponse = exports.CreateOrUpdateCompanyResponse = exports.GetCompanyResponse = exports.GetCompaniesResponse = exports.AddUserResponse = exports.DeleteAuth0UserResponse = exports.SearchAuth0UsersResponse = exports.UpdateUserPasswordResponse = exports.CustomUserAuth = exports.UserAuth = exports.FundView = exports.FundCommonProperties = exports.DecisionMaker = exports.Company = exports.DocumentUpload = exports.Auth0User = exports.ResponseStatus = exports.ResponseError = void 0
// @DataContract
var ResponseError = /** @class */ (function () {
  function ResponseError(init) {
    Object.assign(this, init)
  }
  return ResponseError
})()
exports.ResponseError = ResponseError
// @DataContract
var ResponseStatus = /** @class */ (function () {
  function ResponseStatus(init) {
    Object.assign(this, init)
  }
  return ResponseStatus
})()
exports.ResponseStatus = ResponseStatus
var Auth0User = /** @class */ (function () {
  function Auth0User(init) {
    Object.assign(this, init)
  }
  return Auth0User
})()
exports.Auth0User = Auth0User
var DocumentUpload = /** @class */ (function () {
  function DocumentUpload(init) {
    Object.assign(this, init)
  }
  return DocumentUpload
})()
exports.DocumentUpload = DocumentUpload
var Company = /** @class */ (function () {
  function Company(init) {
    Object.assign(this, init)
  }
  return Company
})()
exports.Company = Company
var DecisionMaker = /** @class */ (function () {
  function DecisionMaker(init) {
    Object.assign(this, init)
  }
  return DecisionMaker
})()
exports.DecisionMaker = DecisionMaker
var FundCommonProperties = /** @class */ (function () {
  function FundCommonProperties(init) {
    Object.assign(this, init)
  }
  return FundCommonProperties
})()
exports.FundCommonProperties = FundCommonProperties
var FundView = /** @class */ (function (_super) {
  __extends(FundView, _super)
  function FundView(init) {
    var _this = _super.call(this, init) || this
    Object.assign(_this, init)
    return _this
  }
  return FundView
})(FundCommonProperties)
exports.FundView = FundView
var UserAuth = /** @class */ (function () {
  function UserAuth(init) {
    Object.assign(this, init)
  }
  return UserAuth
})()
exports.UserAuth = UserAuth
var CustomUserAuth = /** @class */ (function (_super) {
  __extends(CustomUserAuth, _super)
  function CustomUserAuth(init) {
    var _this = _super.call(this, init) || this
    Object.assign(_this, init)
    return _this
  }
  return CustomUserAuth
})(UserAuth)
exports.CustomUserAuth = CustomUserAuth
var UpdateUserPasswordResponse = /** @class */ (function () {
  function UpdateUserPasswordResponse(init) {
    Object.assign(this, init)
  }
  return UpdateUserPasswordResponse
})()
exports.UpdateUserPasswordResponse = UpdateUserPasswordResponse
var SearchAuth0UsersResponse = /** @class */ (function () {
  function SearchAuth0UsersResponse(init) {
    Object.assign(this, init)
  }
  return SearchAuth0UsersResponse
})()
exports.SearchAuth0UsersResponse = SearchAuth0UsersResponse
var DeleteAuth0UserResponse = /** @class */ (function () {
  function DeleteAuth0UserResponse(init) {
    Object.assign(this, init)
  }
  return DeleteAuth0UserResponse
})()
exports.DeleteAuth0UserResponse = DeleteAuth0UserResponse
var AddUserResponse = /** @class */ (function () {
  function AddUserResponse(init) {
    Object.assign(this, init)
  }
  return AddUserResponse
})()
exports.AddUserResponse = AddUserResponse
var GetCompaniesResponse = /** @class */ (function () {
  function GetCompaniesResponse(init) {
    Object.assign(this, init)
  }
  return GetCompaniesResponse
})()
exports.GetCompaniesResponse = GetCompaniesResponse
var GetCompanyResponse = /** @class */ (function () {
  function GetCompanyResponse(init) {
    Object.assign(this, init)
  }
  return GetCompanyResponse
})()
exports.GetCompanyResponse = GetCompanyResponse
var CreateOrUpdateCompanyResponse = /** @class */ (function () {
  function CreateOrUpdateCompanyResponse(init) {
    Object.assign(this, init)
  }
  return CreateOrUpdateCompanyResponse
})()
exports.CreateOrUpdateCompanyResponse = CreateOrUpdateCompanyResponse
var GetFundsResponse = /** @class */ (function () {
  function GetFundsResponse(init) {
    Object.assign(this, init)
  }
  return GetFundsResponse
})()
exports.GetFundsResponse = GetFundsResponse
var GetFundResponse = /** @class */ (function () {
  function GetFundResponse(init) {
    Object.assign(this, init)
  }
  return GetFundResponse
})()
exports.GetFundResponse = GetFundResponse
var CreateOrUpdateFundResponse = /** @class */ (function () {
  function CreateOrUpdateFundResponse(init) {
    Object.assign(this, init)
  }
  return CreateOrUpdateFundResponse
})()
exports.CreateOrUpdateFundResponse = CreateOrUpdateFundResponse
var SessionInfoResponse = /** @class */ (function () {
  function SessionInfoResponse(init) {
    Object.assign(this, init)
  }
  return SessionInfoResponse
})()
exports.SessionInfoResponse = SessionInfoResponse
var UploadResponse = /** @class */ (function () {
  function UploadResponse(init) {
    Object.assign(this, init)
  }
  return UploadResponse
})()
exports.UploadResponse = UploadResponse
var GetUserInfoResponse = /** @class */ (function () {
  function GetUserInfoResponse(init) {
    Object.assign(this, init)
  }
  return GetUserInfoResponse
})()
exports.GetUserInfoResponse = GetUserInfoResponse
var GetUsersResponse = /** @class */ (function () {
  function GetUsersResponse(init) {
    Object.assign(this, init)
  }
  return GetUsersResponse
})()
exports.GetUsersResponse = GetUsersResponse
// @DataContract
var RegisterResponse = /** @class */ (function () {
  function RegisterResponse(init) {
    Object.assign(this, init)
  }
  return RegisterResponse
})()
exports.RegisterResponse = RegisterResponse
var DeleteUserResponse = /** @class */ (function () {
  function DeleteUserResponse(init) {
    Object.assign(this, init)
  }
  return DeleteUserResponse
})()
exports.DeleteUserResponse = DeleteUserResponse
var AssignRoleResponse = /** @class */ (function () {
  function AssignRoleResponse(init) {
    Object.assign(this, init)
  }
  return AssignRoleResponse
})()
exports.AssignRoleResponse = AssignRoleResponse
var UnAssignAllPermissionsResponse = /** @class */ (function () {
  function UnAssignAllPermissionsResponse(init) {
    Object.assign(this, init)
  }
  return UnAssignAllPermissionsResponse
})()
exports.UnAssignAllPermissionsResponse = UnAssignAllPermissionsResponse
var UnAssignRoleResponse = /** @class */ (function () {
  function UnAssignRoleResponse(init) {
    Object.assign(this, init)
  }
  return UnAssignRoleResponse
})()
exports.UnAssignRoleResponse = UnAssignRoleResponse
// @DataContract
var AuthenticateResponse = /** @class */ (function () {
  function AuthenticateResponse(init) {
    Object.assign(this, init)
  }
  return AuthenticateResponse
})()
exports.AuthenticateResponse = AuthenticateResponse
// @DataContract
var AssignRolesResponse = /** @class */ (function () {
  function AssignRolesResponse(init) {
    Object.assign(this, init)
  }
  return AssignRolesResponse
})()
exports.AssignRolesResponse = AssignRolesResponse
// @DataContract
var UnAssignRolesResponse = /** @class */ (function () {
  function UnAssignRolesResponse(init) {
    Object.assign(this, init)
  }
  return UnAssignRolesResponse
})()
exports.UnAssignRolesResponse = UnAssignRolesResponse
// @DataContract
var ConvertSessionToTokenResponse = /** @class */ (function () {
  function ConvertSessionToTokenResponse(init) {
    Object.assign(this, init)
  }
  return ConvertSessionToTokenResponse
})()
exports.ConvertSessionToTokenResponse = ConvertSessionToTokenResponse
// @DataContract
var GetAccessTokenResponse = /** @class */ (function () {
  function GetAccessTokenResponse(init) {
    Object.assign(this, init)
  }
  return GetAccessTokenResponse
})()
exports.GetAccessTokenResponse = GetAccessTokenResponse
// @Route("/user/management/password")
var UpdateUserPassword = /** @class */ (function () {
  function UpdateUserPassword(init) {
    Object.assign(this, init)
  }
  UpdateUserPassword.prototype.createResponse = function () {
    return new UpdateUserPasswordResponse()
  }
  UpdateUserPassword.prototype.getTypeName = function () {
    return 'UpdateUserPassword'
  }
  return UpdateUserPassword
})()
exports.UpdateUserPassword = UpdateUserPassword
// @Route("/user/management/search")
var SearchAuth0Users = /** @class */ (function () {
  function SearchAuth0Users(init) {
    Object.assign(this, init)
  }
  SearchAuth0Users.prototype.createResponse = function () {
    return new SearchAuth0UsersResponse()
  }
  SearchAuth0Users.prototype.getTypeName = function () {
    return 'SearchAuth0Users'
  }
  return SearchAuth0Users
})()
exports.SearchAuth0Users = SearchAuth0Users
// @Route("/user/management/search")
var DeleteAuth0User = /** @class */ (function () {
  function DeleteAuth0User(init) {
    Object.assign(this, init)
  }
  DeleteAuth0User.prototype.createResponse = function () {
    return new DeleteAuth0UserResponse()
  }
  DeleteAuth0User.prototype.getTypeName = function () {
    return 'DeleteAuth0User'
  }
  return DeleteAuth0User
})()
exports.DeleteAuth0User = DeleteAuth0User
// @Route("/user/management/create")
var AddUser = /** @class */ (function () {
  function AddUser(init) {
    Object.assign(this, init)
  }
  AddUser.prototype.createResponse = function () {
    return new AddUserResponse()
  }
  AddUser.prototype.getTypeName = function () {
    return 'AddUser'
  }
  return AddUser
})()
exports.AddUser = AddUser
// @Route("/companies", "GET")
var GetCompanies = /** @class */ (function () {
  function GetCompanies(init) {
    Object.assign(this, init)
  }
  GetCompanies.prototype.createResponse = function () {
    return new GetCompaniesResponse()
  }
  GetCompanies.prototype.getTypeName = function () {
    return 'GetCompanies'
  }
  return GetCompanies
})()
exports.GetCompanies = GetCompanies
// @Route("/companies/{Id}", "GET")
var GetCompany = /** @class */ (function () {
  function GetCompany(init) {
    Object.assign(this, init)
  }
  GetCompany.prototype.createResponse = function () {
    return new GetCompanyResponse()
  }
  GetCompany.prototype.getTypeName = function () {
    return 'GetCompany'
  }
  return GetCompany
})()
exports.GetCompany = GetCompany
// @Route("/companies", "POST")
var CreateOrUpdateCompany = /** @class */ (function () {
  function CreateOrUpdateCompany(init) {
    Object.assign(this, init)
  }
  CreateOrUpdateCompany.prototype.createResponse = function () {
    return new CreateOrUpdateCompanyResponse()
  }
  CreateOrUpdateCompany.prototype.getTypeName = function () {
    return 'CreateOrUpdateCompany'
  }
  return CreateOrUpdateCompany
})()
exports.CreateOrUpdateCompany = CreateOrUpdateCompany
// @Route("/funds")
var GetFunds = /** @class */ (function () {
  function GetFunds(init) {
    Object.assign(this, init)
  }
  GetFunds.prototype.createResponse = function () {
    return new GetFundsResponse()
  }
  GetFunds.prototype.getTypeName = function () {
    return 'GetFunds'
  }
  return GetFunds
})()
exports.GetFunds = GetFunds
// @Route("/funds/{Id}", "GET")
var GetFund = /** @class */ (function () {
  function GetFund(init) {
    Object.assign(this, init)
  }
  GetFund.prototype.createResponse = function () {
    return new GetFundResponse()
  }
  GetFund.prototype.getTypeName = function () {
    return 'GetFund'
  }
  return GetFund
})()
exports.GetFund = GetFund
// @Route("/funds", "POST")
var CreateOrUpdateFund = /** @class */ (function () {
  function CreateOrUpdateFund(init) {
    Object.assign(this, init)
  }
  CreateOrUpdateFund.prototype.createResponse = function () {
    return new CreateOrUpdateFundResponse()
  }
  CreateOrUpdateFund.prototype.getTypeName = function () {
    return 'CreateOrUpdateFund'
  }
  return CreateOrUpdateFund
})()
exports.CreateOrUpdateFund = CreateOrUpdateFund
// @Route("/my-session")
var SessionInfo = /** @class */ (function () {
  function SessionInfo(init) {
    Object.assign(this, init)
  }
  SessionInfo.prototype.createResponse = function () {
    return new SessionInfoResponse()
  }
  SessionInfo.prototype.getTypeName = function () {
    return 'SessionInfo'
  }
  return SessionInfo
})()
exports.SessionInfo = SessionInfo
// @Route("/file/upload", "POST")
var UploadResearchFile = /** @class */ (function () {
  function UploadResearchFile(init) {
    Object.assign(this, init)
  }
  UploadResearchFile.prototype.createResponse = function () {
    return new UploadResearchFileResponse()
  }
  UploadResearchFile.prototype.getTypeName = function () {
    return 'UploadResearchFile'
  }
  return UploadResearchFile
})()
exports.UploadResearchFile = UploadResearchFile
// @Route("/file/upload", "POST")
var UploadFicaFile = /** @class */ (function () {
  function UploadFicaFile(init) {
    Object.assign(this, init)
  }
  UploadFicaFile.prototype.createResponse = function () {
    return new UploadFicaFileResponse()
  }
  UploadFicaFile.prototype.getTypeName = function () {
    return 'UploadFicaFile'
  }
  return UploadFicaFile
})()
exports.UploadFicaFile = UploadFicaFile
// @Route("/file/upload", "POST")
var UploadTccDocs = /** @class */ (function () {
  function UploadTccDocs(init) {
    Object.assign(this, init)
  }
  UploadTccDocs.prototype.createResponse = function () {
    return new UploadTccDocsResponse()
  }
  UploadTccDocs.prototype.getTypeName = function () {
    return 'UploadTccDocs'
  }
  return UploadTccDocs
})()
exports.UploadTccDocs = UploadTccDocs
// @Route("/userinfo")
var GetUserInfo = /** @class */ (function () {
  function GetUserInfo(init) {
    Object.assign(this, init)
  }
  GetUserInfo.prototype.createResponse = function () {
    return new GetUserInfoResponse()
  }
  GetUserInfo.prototype.getTypeName = function () {
    return 'GetUserInfo'
  }
  return GetUserInfo
})()
exports.GetUserInfo = GetUserInfo
// @Route("/users")
var GetUsers = /** @class */ (function () {
  function GetUsers(init) {
    Object.assign(this, init)
  }
  GetUsers.prototype.createResponse = function () {
    return new GetUsersResponse()
  }
  GetUsers.prototype.getTypeName = function () {
    return 'GetUsers'
  }
  return GetUsers
})()
exports.GetUsers = GetUsers
// @Route("/users/create", "POST")
var CreateUser = /** @class */ (function () {
  function CreateUser(init) {
    Object.assign(this, init)
  }
  CreateUser.prototype.createResponse = function () {
    return new RegisterResponse()
  }
  CreateUser.prototype.getTypeName = function () {
    return 'CreateUser'
  }
  return CreateUser
})()
exports.CreateUser = CreateUser
// @Route("/users/delete", "POST")
var DeleteUser = /** @class */ (function () {
  function DeleteUser(init) {
    Object.assign(this, init)
  }
  DeleteUser.prototype.createResponse = function () {
    return new DeleteUserResponse()
  }
  DeleteUser.prototype.getTypeName = function () {
    return 'DeleteUser'
  }
  return DeleteUser
})()
exports.DeleteUser = DeleteUser
// @Route("/users/assignRole", "POST")
var AssignRole = /** @class */ (function () {
  function AssignRole(init) {
    Object.assign(this, init)
  }
  AssignRole.prototype.createResponse = function () {
    return new AssignRoleResponse()
  }
  AssignRole.prototype.getTypeName = function () {
    return 'AssignRole'
  }
  return AssignRole
})()
exports.AssignRole = AssignRole
// @Route("/users/unassignallpermissions")
var UnAssignAllPermissions = /** @class */ (function () {
  function UnAssignAllPermissions(init) {
    Object.assign(this, init)
  }
  UnAssignAllPermissions.prototype.createResponse = function () {
    return new UnAssignAllPermissionsResponse()
  }
  UnAssignAllPermissions.prototype.getTypeName = function () {
    return 'UnAssignAllPermissions'
  }
  return UnAssignAllPermissions
})()
exports.UnAssignAllPermissions = UnAssignAllPermissions
// @Route("/users/unAssignRole", "POST")
var UnAssignRole = /** @class */ (function () {
  function UnAssignRole(init) {
    Object.assign(this, init)
  }
  UnAssignRole.prototype.createResponse = function () {
    return new UnAssignRoleResponse()
  }
  UnAssignRole.prototype.getTypeName = function () {
    return 'UnAssignRole'
  }
  return UnAssignRole
})()
exports.UnAssignRole = UnAssignRole
// @Route("/auth")
// @Route("/auth/{provider}")
// @Route("/authenticate")
// @Route("/authenticate/{provider}")
// @DataContract
var Authenticate = /** @class */ (function () {
  function Authenticate(init) {
    Object.assign(this, init)
  }
  Authenticate.prototype.createResponse = function () {
    return new AuthenticateResponse()
  }
  Authenticate.prototype.getTypeName = function () {
    return 'Authenticate'
  }
  return Authenticate
})()
exports.Authenticate = Authenticate
// @Route("/assignroles")
// @DataContract
var AssignRoles = /** @class */ (function () {
  function AssignRoles(init) {
    Object.assign(this, init)
  }
  AssignRoles.prototype.createResponse = function () {
    return new AssignRolesResponse()
  }
  AssignRoles.prototype.getTypeName = function () {
    return 'AssignRoles'
  }
  return AssignRoles
})()
exports.AssignRoles = AssignRoles
// @Route("/unassignroles")
// @DataContract
var UnAssignRoles = /** @class */ (function () {
  function UnAssignRoles(init) {
    Object.assign(this, init)
  }
  UnAssignRoles.prototype.createResponse = function () {
    return new UnAssignRolesResponse()
  }
  UnAssignRoles.prototype.getTypeName = function () {
    return 'UnAssignRoles'
  }
  return UnAssignRoles
})()
exports.UnAssignRoles = UnAssignRoles
// @Route("/session-to-token")
// @DataContract
var ConvertSessionToToken = /** @class */ (function () {
  function ConvertSessionToToken(init) {
    Object.assign(this, init)
  }
  ConvertSessionToToken.prototype.createResponse = function () {
    return new ConvertSessionToTokenResponse()
  }
  ConvertSessionToToken.prototype.getTypeName = function () {
    return 'ConvertSessionToToken'
  }
  return ConvertSessionToToken
})()
exports.ConvertSessionToToken = ConvertSessionToToken
// @Route("/access-token")
// @DataContract
var GetAccessToken = /** @class */ (function () {
  function GetAccessToken(init) {
    Object.assign(this, init)
  }
  GetAccessToken.prototype.createResponse = function () {
    return new GetAccessTokenResponse()
  }
  GetAccessToken.prototype.getTypeName = function () {
    return 'GetAccessToken'
  }
  return GetAccessToken
})()
exports.GetAccessToken = GetAccessToken

var UploadResearchFileResponse = /** @class */ (function () {
  function UploadResearchFileResponse(init) {
    Object.assign(this, init)
  }
  return UploadResearchFileResponse
})()
exports.UploadResearchFileResponse = UploadResearchFileResponse

var UploadFicaFileResponse = /** @class */ (function () {
  function UploadFicaFileResponse(init) {
    Object.assign(this, init)
  }
  return UploadFicaFileResponse
})()
exports.UploadFicaFileResponse = UploadFicaFileResponse

var UploadTccDocsResponse = /** @class */ (function () {
  function UploadTccDocsResponse(init) {
    Object.assign(this, init)
  }
  return UploadTccDocsResponse
})()
exports.UploadTccDocsResponse = UploadTccDocsResponse
