<template>
  <br />
  <h2 class="text-center my-2">PARTNER DASHBOARD FOR : {{partners.shortName}}</h2>
  <br />
  <div class="fluid grid">
    <div class="col-12 md:col-6 lg:col-6">
        <Card class="shadow-5 text-center d-block mx-auto" id="newClient">
            <template class="text-center" #title>
              <i class="pi pi-users" style="fontSize: 2.5rem"></i>
            </template>
            <template  #content>
              <router-link :to="'/partnerClientListView/' + partners.id" style="color: #e2e7e5; text-decoration: none">
              CLIENTS
              </router-link>
            </template>
        </Card>
    </div>
    <div class="col-12 md:col-6 lg:col-6">
        <Card class="shadow-5 text-center d-block mx-auto" id="tccView">
            <template class="text-center" #title>
              <i class="pi pi-list" style="fontSize: 2.5rem"></i>
            </template>
            <template  #content>
              <router-link :to="'/partnerTccView/' + partners.id" style="color: #e2e7e5; text-decoration: none">
              TCC
              </router-link>
            </template>
        </Card>
    </div>
  </div>
  <br />
  <div class="fluid grid">
     <div class="col-12 md:col-6 lg:col-6">
        <Card class="shadow-5 text-center d-block mx-auto" id="fromPartner">
            <template class="text-center" #title>
              <i class="pi pi-globe" style="fontSize: 2.5rem"></i>
            </template>
            <template  #content>
              <router-link :to="'/partnerTradesView/' + partners.id" style="color: #e2e7e5; text-decoration: none">
              TRADES
              </router-link>
            </template>
        </Card>
    </div>
    <div class="col-12 md:col-6 lg:col-6">
        <Card class="shadow-5 text-center d-block mx-auto" id="viewClients">
            <template class="text-center" #title>
              <i class="pi pi-chart-bar" style="fontSize: 2.5rem"></i>
            </template>
            <template  #content>
              <router-link :to="'/partnerBalancesHomeView/' + partners.id"  style="color: #e2e7e5; text-decoration: none">
              BALANCES
              </router-link>
            </template>
        </Card>
    </div>
  </div>
  <hr class="mx-4 shadow-2"/>
  <div>
    <Button class="l-2 m-2 mx-4 shadow-4 p-button-lg"
    icon="pi pi-step-backward"
    label="BACK&nbsp;"
    id="submitButton"
    @click.prevent="$router.back()" />
  </div>
</template>

<script>
import { getPartner } from '@/gateway'

export default {
  name: 'PartnerMainView',
  inheritAttrs: false,
  data () {
    return {
      partners: {}
    }
  },
  methods: {
    async init () {
      const response = await getPartner({ id: this.id })
      if (response) {
        this.partners = response.result
      }
    }
  },
  mounted () {
    this.id = this.$route.params.id
    this.init()
  }
}
</script>

<style lang="scss" scoped>
#newClient {
 @include cardComponent($midDarkBlue)
};
#tccView {
 @include cardComponent($midLightBlue)
};
#fromPartner {
 @include cardComponent($lightDarkBlue)
};
#viewClients {
 @include cardComponent($lightBlue)
};
.p-card.p-component.p-card-content {
 font-size: 24px;
};
.p-grid {
  margin: 1rem;
  padding: 1rem;
};
</style>
