<template>
  <br />
  <h2 class="text-center my-2">CLIENT RISK MATRIX FOR : {{ riskRating.firstLastName }}</h2>
  <br />
  <div class="fluid mx-2 shadow-2" id="mobileView">
      <table class="mobileTable">
        <thead id="thead" >
            <tr>
                <th class="text-left pl-2" style="width: 50vw">Factors</th>
                <th class="text-left pl-2" style="width: 35vw">Level</th>
                <th class="text-left pl-2" style="width: 15vw">Score</th>
            </tr>
        </thead>
         <tbody id="tbodyLight">
            <tr>
                <td class="text-left pl-2"> Client Type </td>
                <td class="text-left pl-2"> Low </td>
                <td class="text-left pl-2"> 0 </td>
            </tr>
        </tbody>
         <tbody id="tbodyDark">
            <tr>
                <td class="text-left pl-2"> Country </td>
                <td class="text-left pl-2"> {{riskRating.nationalityRiskLevelDescription}} </td>
                <td class="text-left pl-2"> {{riskRating.nationalityRiskLevelScore }} </td>
            </tr>
        </tbody>
         <tbody id="tbodyLight">
            <tr>
                <td class="text-left pl-2"> Country </td>
                <td class="text-left pl-2"> {{riskRating.countryOfResidenceRiskLevelDescription}} </td>
                <td class="text-left pl-2"> {{riskRating.countryOfResidenceRiskLevelScore}} </td>
            </tr>
        </tbody>
         <tbody id="tbodyDark">
            <tr>
                <td class="text-left pl-2"> Occupation </td>
                <td class="text-left pl-2"> {{riskRating.occupationRiskLevelDescription}} </td>
                <td class="text-left pl-2"> {{riskRating.occupationRiskLevelScore }} </td>
            </tr>
        </tbody>
         <tbody id="tbodyLight">
            <tr>
                <td class="text-left pl-2"> Source of Funds </td>
                <td class="text-left pl-2"> {{riskRating.sourceOfFundsRiskLevelDescription}} </td>
                <td class="text-left pl-2"> {{riskRating.sourceOfFundsRiskLevelScore}} </td>
            </tr>
        </tbody>
         <tbody id="tbodyDark">
            <tr>
                <td class="text-left pl-2"> Source of Wealth </td>
                <td class="text-left pl-2"> {{riskRating.sourceOfWealthRiskLevelDescription}} </td>
                <td class="text-left pl-2"> {{riskRating.sourceOfWealthRiskLevelScore }} </td>
            </tr>
        </tbody>
         <tbody id="tbodyLight">
            <tr>
                <td class="text-left pl-2"> Bank Account </td>
                <td class="text-left pl-2"> {{riskRating.accountDomicileRiskLevelDescription}} </td>
                <td class="text-left pl-2"> {{riskRating.accountDomicileRiskLevelScore}} </td>
            </tr>
        </tbody>
         <tbody id="tbodyDark">
            <tr>
                <td class="text-left pl-2"> Bank Account </td>
                <td class="text-left pl-2"> {{riskRating.thirdPartyRiskLevelDescription}} </td>
                <td class="text-left pl-2"> {{riskRating.thirdPartyRiskLevelScore }} </td>
            </tr>
        </tbody>
         <tbody id="tbodyLight">
            <tr>
                <td class="text-left pl-2"> Investment Amount </td>
                <td class="text-left pl-2"> {{riskRating.investmentValueRiskLevelDescription}} </td>
                <td class="text-left pl-2"> {{riskRating.investmentValueRiskLevelScore}} </td>
            </tr>
        </tbody>
         <tbody id="tbodyDark">
            <tr>
                <td class="text-left pl-2"> Sanctions Check </td>
                <td class="text-left pl-2"> {{riskRating.sanctionsScreenRiskLevelDescription}} </td>
                <td class="text-left pl-2"> {{riskRating.sanctionsScreenRiskLevelScore }} </td>
            </tr>
        </tbody>
         <tbody id="tbodyLight">
            <tr>
                <td class="text-left pl-2"><b> Total </b></td>
                <td class="text-left pl-2"><b> {{riskRating.sumScoreDescription}} </b></td>
                <td class="text-left pl-2"><b> {{riskRating.sumScore}} </b></td>
            </tr>
        </tbody>
      </table>
    </div>
  <div class="fluid mx-4 shadow-2" style="background: #AAAAAF" id="fullScreenView">
    <table class="">
        <thead id="thead" >
            <tr>
                <th class="text-left pl-2" style="width: 15vw"> Risk Factors</th>
                <th class="text-left pl-2" style="width: 25vw"> Description</th>
                <th class="text-left pl-2" style="width: 34vw"> Result</th>
                <th class="text-left pl-2" style="width: 13vw"> Risk Level</th>
                <th class="text-left pl-2" style="width: 13vw"> Score</th>
            </tr>
        </thead>
         <tbody id="tbodyLight">
            <tr>
                <td class="text-left pl-2"> Client Type </td>
                <td class="text-left pl-2"> Nature of Client</td>
                <td class="text-left pl-2"> Individual</td>
                <td class="text-left pl-2"> Low </td>
                <td class="text-left pl-2"> 0 </td>
            </tr>
        </tbody>
         <tbody id="tbodyDark">
            <tr>
                <td class="text-left pl-2"> Country </td>
                <td class="text-left pl-2"> Nationality/Country of Incorporation</td>
                <td class="text-left pl-2"> {{riskRating.nationality}}</td>
                <td class="text-left pl-2"> {{riskRating.nationalityRiskLevelDescription}} </td>
                <td class="text-left pl-2"> {{riskRating.nationalityRiskLevelScore }} </td>
            </tr>
        </tbody>
         <tbody id="tbodyLight">
            <tr>
                <td class="text-left pl-2"> Country </td>
                <td class="text-left pl-2"> Country of Residence</td>
                <td class="text-left pl-2"> {{riskRating.countryOfResidence}}</td>
                <td class="text-left pl-2"> {{riskRating.countryOfResidenceRiskLevelDescription}} </td>
                <td class="text-left pl-2"> {{riskRating.countryOfResidenceRiskLevelScore}} </td>
            </tr>
        </tbody>
         <tbody id="tbodyDark">
            <tr>
                <td class="text-left pl-2"> Occupation </td>
                <td class="text-left pl-2"> Occupational Field</td>
                <td class="text-left pl-2"> {{riskRating.occupation}}</td>
                <td class="text-left pl-2"> {{riskRating.occupationRiskLevelDescription}} </td>
                <td class="text-left pl-2"> {{riskRating.occupationRiskLevelScore }} </td>
            </tr>
        </tbody>
         <tbody id="tbodyLight">
            <tr>
                <td class="text-left pl-2"> Source of Funds </td>
                <td class="text-left pl-2"> Source of Transaction Funds</td>
                <td class="text-left pl-2"> {{riskRating.sourceOfFunds}}</td>
                <td class="text-left pl-2"> {{riskRating.sourceOfFundsRiskLevelDescription}} </td>
                <td class="text-left pl-2"> {{riskRating.sourceOfFundsRiskLevelScore}} </td>
            </tr>
        </tbody>
         <tbody id="tbodyDark">
            <tr>
                <td class="text-left pl-2"> Source of Wealth </td>
                <td class="text-left pl-2"> Main Source of Net Worth</td>
                <td class="text-left pl-2"> {{riskRating.sourceOfWealth}}</td>
                <td class="text-left pl-2"> {{riskRating.sourceOfWealthRiskLevelDescription}} </td>
                <td class="text-left pl-2"> {{riskRating.sourceOfWealthRiskLevelScore }} </td>
            </tr>
        </tbody>
         <tbody id="tbodyLight">
            <tr>
                <td class="text-left pl-2"> Bank Account </td>
                <td class="text-left pl-2"> Bank Account Domicile</td>
                <td class="text-left pl-2"> {{riskRating.accountDomicile}}</td>
                <td class="text-left pl-2"> {{riskRating.accountDomicileRiskLevelDescription}} </td>
                <td class="text-left pl-2"> {{riskRating.accountDomicileRiskLevelScore}} </td>
            </tr>
        </tbody>
         <tbody id="tbodyDark">
            <tr>
                <td class="text-left pl-2"> Bank Account </td>
                <td class="text-left pl-2"> Bank Account for 3rd Party</td>
                <td class="text-left pl-2"> {{riskRating.thirdParty}}</td>
                <td class="text-left pl-2"> {{riskRating.thirdPartyRiskLevelDescription}} </td>
                <td class="text-left pl-2"> {{riskRating.thirdPartyRiskLevelScore }} </td>
            </tr>
        </tbody>
         <tbody id="tbodyLight">
            <tr>
                <td class="text-left pl-2"> Investment Amount </td>
                <td class="text-left pl-2"> Value of the Investment</td>
                <td class="text-left pl-2"> {{riskRating.investmentValue}}</td>
                <td class="text-left pl-2"> {{riskRating.investmentValueRiskLevelDescription}} </td>
                <td class="text-left pl-2"> {{riskRating.investmentValueRiskLevelScore}} </td>
            </tr>
        </tbody>
         <tbody id="tbodyDark">
            <tr>
                <td class="text-left pl-2"> Sanctions Check </td>
                <td class="text-left pl-2"> PEP/Sanctions/Adverse Media</td>
                <td class="text-left pl-2"> {{riskRating.sanctionsScreen}}</td>
                <td class="text-left pl-2"> {{riskRating.sanctionsScreenRiskLevelDescription}} </td>
                <td class="text-left pl-2"> {{riskRating.sanctionsScreenRiskLevelScore }} </td>
            </tr>
        </tbody>
         <tbody id="tbodyLight">
            <tr>
                <td class="text-left pl-2"><b> Total </b></td>
                <td class="text-left pl-2"> </td>
                <td class="text-left pl-2"> </td>
                <td>
                  <div v-if="riskRating.sumScoreDescription === 'Low'">
                    <td class="text-left pl-2" style="color: #107E01; font-size: 1.2rem"><b> {{riskRating.sumScoreDescription}} </b></td>
                  </div>
                  <div v-else-if="riskRating.sumScoreDescription === 'Medium'">
                    <td class="text-left pl-2" style="color: #FFD600; font-size: 1.2rem"><b> {{riskRating.sumScoreDescription}} </b></td>
                  </div>
                  <div v-else-if="riskRating.sumScoreDescription === 'High'">
                    <td class="text-left pl-2" style="color: #d2161e; font-size: 1.2rem"><b> {{riskRating.sumScoreDescription}} </b></td>
                  </div>
                  </td>
                  <td>
                  <div v-if="riskRating.sumScore < 26">
                    <td class="text-left pl-2" style="color: #107E01; font-size: 1.2rem"><b> {{riskRating.sumScore}} </b></td>
                  </div>
                  <div v-else-if="riskRating.sumScore >= 26 && riskRating.sumScore < 51">
                    <td class="text-left pl-2" style="color: #FFD600; font-size: 1.2rem"><b> {{riskRating.sumScore}} </b></td>
                  </div>
                  <div v-else-if="riskRating.sumScore >= 51">
                    <td class="text-left pl-2" style="color: #d2161e; font-size: 1.2rem"><b> {{riskRating.sumScore}} </b></td>
                  </div>
                </td>
            </tr>
        </tbody>
    </table>
    </div>
    <br />
    <hr class="mx-4"/>
    <h4 class="text-center"><u>COMMENTS &amp; RE-FICA DETAILS </u></h4>
      <div class="fluid mx-3">
        <div class="formgrid grid">
          <div class="col-12 md:col-12 lg:col-12">
              <h5 class="text-left">Comments</h5>
              <Textarea
                  v-model="riskRating.comments"
                  rows="5"
                  cols="30"
                  class="inputfield w-full"/>
              </div>
          </div>
            <div class="formgrid grid">
                <div class="col-12 md:col-6 lg:col-6">
                    <h5>Initial FICA Date</h5>
                    <InputText
                    type="text"
                    readonly
                    class="inputfield w-full"
                    v-model="riskRating.initialFicaDate"/>
                </div>
                <div class="col-12 md:col-6 lg:col-6">
                    <h5>Next Fica Date</h5>
                    <InputText
                    type="text"
                    readonly
                    class="inputfield w-full"
                    v-model="riskRating.nextFicaDate"/>
                </div>
            </div>
        </div>
        <br />
        <hr class="mx-4"/>
        <div class="fluid mx-2">
        <h4 class="text-center"><u>DOCUMENT UPLOADS &amp; DOWNLOADS </u></h4>
        <div class="formgrid grid mx-1">
          <div class="col-12 md:col-12 lg:col-6">
              <h5 class="flex justify-content-between ml-2 mr-1"><span class="mt-1 ml-2">Upload ID Check</span>
                <div v-if="fica.hasTimFile" class="flex justify-content-between mx-1">
                  <span class="mt-1 mr-2">File Uploaded On: {{ fica.timFileUploadedDate}}</span>
                  <i class="pi pi-check-circle mx-1" id="fileCheck"></i>
                    </div>
                  <div v-else class="flex justify-content-between mx-1">
                    <span class="mt-1 mr-2">No File Uploaded</span>
                    <i class="pi pi-times-circle mx-1" id="fileCross"></i>
                  </div>
              </h5>
              <div class="flex justify-content-between ml-2 mr-1 shadow-3" id="uploadBackground">
                    <button class="file_upload" type="button">
                      <span class="btn_lbl"><i class="pi pi-file"></i>&nbsp;SELECT</span>
                      <span class="btn_colorlayer"></span>
                      <input type="file" name="fileupload" id="file_upload" @change="onFileChangedTim" />
                    </button>
                  <Button
                      id="uploadButton"
                      icon="pi pi-upload"
                      @click="onFileUpload"
                      label="UPLOAD&nbsp;"/>
              </div>
          </div>
          <div class="col-12 md:col-12 lg:col-6">
              <h5 class="flex justify-content-between ml-2 mr-1"><span class="mt-1 ml-2">Upload PEP/Sanctions Check</span>
                <div v-if="fica.hasPepFile" class="flex justify-content-between mx-1">
                  <span class="mt-1 mr-2">File Uploaded On: {{ fica.pepFileUploadedDate}}</span>
                  <i class="pi pi-check-circle mx-1" id="fileCheck"></i>
                    </div>
                  <div v-else class="flex justify-content-between mx-1">
                    <span class="mt-1 mr-2">No File Uploaded</span>
                    <i class="pi pi-times-circle mx-1" id="fileCross"></i>
                  </div>
              </h5>
              <div class="flex justify-content-between ml-3 mr-1 shadow-3" id="uploadBackground">
                  <button class="file_upload" type="button">
                    <span class="btn_lbl"><i class="pi pi-file"></i>&nbsp;SELECT</span>
                    <span class="btn_colorlayer"></span>
                    <input type="file" name="fileupload" id="file_upload" @change="onFileChangedPep" />
                  </button>
                <Button
                    id="uploadButton"
                    icon="pi pi-upload"
                    @click="onFileUpload"
                    label="UPLOAD&nbsp;"/>
              </div>
            </div>
          </div>
        <br />
        <div class="formgrid grid mx-2">
          <div class="col-12 md:col-12 lg:col-6">
            <div v-if="fica.hasTimFile">
                <a class="p-button d-block mx-1 py-3 px-auto" :loading="loadingTim" @click.prevent="downloadFica('Identity Check')"
                            style="color: #e2e7e5; background: #062d5d!important;  text-decoration: none;
                            display: flex; justify-content: center;">
                        <i class="pi pi-cloud-download" style="fontSize: 1rem"></i>&nbsp;&nbsp;ID Check
                </a>
            </div>
              <div v-else>
                <a class="p-button d-block ml-3 p-3" :disabled="true"
                            style="color: #e2e7e5; background: rgba(6, 45, 93, 0.7)!important;  text-decoration: none;
                            display: flex; justify-content: center;">
                            &nbsp;&nbsp;No File Uploaded
                </a>
            </div>
          </div>
          <div class="col-12 md:col-12 lg:col-6">
            <div v-if="fica.hasPepFile">
                <a class="p-button d-block ml-3 p-3" :loading="loadingPep" @click.prevent="downloadFica('Pep-Sanctions Check')"
                        style="color: #e2e7e5; background: #045889!important; text-decoration: none;
                        display: flex; justify-content: center;">
                        <i class="pi pi-cloud-download mx-1" style="fontSize: 1rem"></i>&nbsp;PEP &amp; Sanctions Check
                </a>
            </div>
            <div v-else>
                <a class="p-button d-block ml-3 p-3" :disabled="true"
                        style="color: #e2e7e5; background: rgba(4, 88, 137, 0.7)!important; text-decoration: none;
                        display: flex; justify-content: center;"
                        icon="pi pi-cloud-download mx-1">No File Uploaded
                </a>
            </div>
          </div>
        </div>
      <br />
      <br />
      <div v-if="riskRating.sumScoreDescription === 'Medium'">
        <h4 class="text-center"><u>ADDITIONAL DOCUMENTS REQUIRED FOR MEDIUM RISK CLIENTS</u></h4>
        <div class="formgrid grid mx-1">
          <div class="col-12 md:col-12 lg:col-6">
              <h5 class="flex justify-content-between ml-2 mr-1"><span class="mt-1 ml-2">Upload Source of Funds Declaration</span>
                <div v-if="fica.hasSourceOfFundsFile" class="flex justify-content-between mx-1">
                  <span class="mt-1 mr-2">File Uploaded On: {{ fica.sourceOfFundsFileUploadedDate}}</span>
                  <i class="pi pi-check-circle mx-1" id="fileCheck"></i>
                    </div>
                  <div v-else class="flex justify-content-between mx-1">
                    <span class="mt-1 mr-2">No File Uploaded</span>
                    <i class="pi pi-times-circle mx-1" id="fileCross"></i>
                  </div>
              </h5>
              <div class="flex justify-content-between ml-2 mr-1 shadow-3" id="uploadBackground">
                    <button class="file_upload" type="button">
                      <span class="btn_lbl"><i class="pi pi-file"></i>&nbsp;SELECT</span>
                      <span class="btn_colorlayer"></span>
                      <input type="file" name="fileupload" id="file_upload" @change="onFileChangedSof" />
                    </button>
                  <Button
                      id="uploadButton"
                      icon="pi pi-upload"
                      @click="onFileUpload"
                      label="UPLOAD&nbsp;"/>
              </div>
          </div>
        </div>
        <br />
        <div class="formgrid grid mx-2">
          <div class="col-12 md:col-12 lg:col-6">
              <div v-if="fica.hasSourceOfFundsFile">
                  <a class="p-button d-block mx-1 py-3 px-auto" :loading="loadingSof" @click.prevent="downloadFica('Source of Funds Check')"
                              style="color: #e2e7e5; background: #0279ab!important;  text-decoration: none;
                              display: flex; justify-content: center;">
                          <i class="pi pi-cloud-download" style="fontSize: 1rem"></i>&nbsp;&nbsp;Source of Funds Declaration
                  </a>
              </div>
                <div v-else>
                  <a class="p-button d-block ml-2 mr-1 p-3" :disabled="true"
                              style="color: #e2e7e5; background: rgba(4, 88, 137, 0.7)!important;  text-decoration: none;
                              display: flex; justify-content: center;">
                              &nbsp;&nbsp;No File Uploaded
                  </a>
              </div>
          </div>
        </div>
      </div>
      <br>
        <div v-if="riskRating.sumScoreDescription === 'High'">
          <h4 class="text-center"><u>ADDITIONAL DOCUMENTS REQUIRED FOR HIGH RISK CLIENTS</u></h4>
          <div class="formgrid grid mx-1">
            <div class="col-12 md:col-12 lg:col-6">
              <h5 class="flex justify-content-between ml-2 mr-1"><span class="mt-1 ml-2">Upload Source of Funds Declaration</span>
                <div v-if="fica.hasSourceOfFundsFile" class="flex justify-content-between mx-1">
                  <span class="mt-1 mr-2">File Uploaded On: {{ fica.sourceOfFundsFileUploadedDate}}</span>
                  <i class="pi pi-check-circle mx-1" id="fileCheck"></i>
                    </div>
                  <div v-else class="flex justify-content-between mx-1">
                    <span class="mt-1 mr-2">No File Uploaded</span>
                    <i class="pi pi-times-circle mx-1" id="fileCross"></i>
                  </div>
              </h5>
              <div class="flex justify-content-between ml-2 mr-1 shadow-3" id="uploadBackground">
                    <button class="file_upload" type="button">
                      <span class="btn_lbl"><i class="pi pi-file"></i>&nbsp;SELECT</span>
                      <span class="btn_colorlayer"></span>
                      <input type="file" name="fileupload" id="file_upload" @change="onFileChangedSof" />
                    </button>
                  <Button
                      id="uploadButton"
                      icon="pi pi-upload"
                      @click="onFileUpload"
                      label="UPLOAD&nbsp;"/>
              </div>
            </div>
              <div class="col-12 md:col-12 lg:col-6">
                <h5 class="flex justify-content-between ml-2 mr-1"><span class="mt-1 ml-2">Upload Management Sign Off</span>
                  <div v-if="fica.hasManagementSignOffFile" class="flex justify-content-between mx-1">
                    <span class="mt-1 mr-2">File Uploaded On: {{ fica.managementSignOffFileUploadedDate}}</span>
                  <i class="pi pi-check-circle mx-1" id="fileCheck"></i>
                    </div>
                  <div v-else class="flex justify-content-between mx-1">
                    <span class="mt-1 mr-2">No File Uploaded</span>
                    <i class="pi pi-times-circle mx-1" id="fileCross"></i>
                  </div>
                </h5>
                <div class="flex justify-content-between ml-3 mr-1 shadow-3" id="uploadBackground">
                    <button class="file_upload" type="button">
                      <span class="btn_lbl"><i class="pi pi-file"></i>&nbsp;SELECT</span>
                      <span class="btn_colorlayer"></span>
                      <input type="file" name="fileupload" id="file_upload" @change="onFileChangedSignOff" />
                    </button>
                  <Button
                      id="uploadButton"
                      icon="pi pi-upload"
                      @click="onFileUpload"
                      label="UPLOAD&nbsp;"/>
              </div>
          </div>
        </div>
        <br />
        <div class="formgrid grid mx-2">
            <div class="col-12 md:col-12 lg:col-6">
                <div v-if="fica.hasSourceOfFundsFile">
                    <a class="p-button d-block mx-1 py-3 px-auto" :loading="loadingSof" @click.prevent="downloadFica('Source of Funds Check')"
                                style="color: #e2e7e5; background: #044e7f!important;  text-decoration: none;
                                display: flex; justify-content: center;">
                            <i class="pi pi-cloud-download" style="fontSize: 1rem"></i>&nbsp;&nbsp;Source of Funds Declaration
                    </a>
                </div>
                  <div v-else>
                    <a class="p-button d-block ml-2 mr-1 p-3" :disabled="true"
                                style="color: #e2e7e5; background: rgba(4, 88, 137, 0.7)!important;  text-decoration: none;
                                display: flex; justify-content: center;">
                                &nbsp;&nbsp;No File Uploaded
                    </a>
                </div>
            </div>
            <div class="col-12 md:col-12 lg:col-6">
              <div v-if="fica.hasManagementSignOffFile">
                  <a class="p-button d-block ml-3 p-3" :loading="loadingSign" @click.prevent="downloadFica('Sign Off Check')"
                          style="color: #e2e7e5; background: #0185b7!important; text-decoration: none;
                          display: flex; justify-content: center;">
                          <i class="pi pi-cloud-download mx-1" style="fontSize: 1rem"></i>&nbsp;Management Sign Off
                  </a>
              </div>
              <div v-else>
                  <a class="p-button d-block ml-3 p-3" :disabled="true"
                          style="color: #e2e7e5; background: rgba(1, 133, 183, 0.5)!important; text-decoration: none;
                          display: flex; justify-content: center;">
                          &nbsp;&nbsp;No File Uploaded
                  </a>
              </div>
            </div>
        </div>
      </div>
    </div>
    <br>
    <hr class="mx-4"/>
    <div v-if='sanctionsCheckSuccess'>
      <Dialog class="successModal" header="SUCCESS" id="successModal" v-model:visible="displayConfirmation"  :modal="true">
          <div class="confirmation-content">
              <span>Sanctions Check Saved</span>
          </div>
          <template #footer>
              <a class="p-button d-block mx-auto px-2" @click="submitSanctionsScreen"  style="background-color: #0185b7!important; text-decoration: none
                                  text-decoration: none; min-width: 100%; justify-content: center;">
                <i class="pi pi-exclamation-triangle" style="fontSize: 1rem"></i>&nbsp;&nbsp;Update Risk Matrix
              </a>
          </template>
      </Dialog>
      </div>
      <div v-if='updateRiskSuccess'>
        <Dialog class="successModal" header="SUCCESS" id="successModal" v-model:visible="displayConfirmation"  :modal="true">
            <div class="confirmation-content">
                <span>Risk Matrix Updated</span>
            </div>
            <template #footer>
                <router-link class="p-button d-block mx-auto px-2" :to="'/ClientView/' + riskRating.clientId"  style="background-color: #0185b7!important; text-decoration: none
                                    text-decoration: none; min-width: 100%; justify-content: center;">
                  <i class="pi pi-user" style="fontSize: 1rem"></i>&nbsp;&nbsp;View Client
                </router-link>
            </template>
        </Dialog>
        </div>
        <div v-if='riskMatrixCompleted'>
        <Dialog class="successModal" header="SUCCESS" id="successModal" v-model:visible="displayConfirmation"  :modal="true">
            <div class="confirmation-content">
                <span>Risk Matrix Completed</span>
            </div>
            <template #footer>
                <router-link class="p-button d-block mx-auto px-2" :to="'/ClientView/' + riskRating.clientId"  style="background-color: #0185b7!important; text-decoration: none
                                    text-decoration: none; min-width: 100%; justify-content: center;">
                  <i class="pi pi-user" style="fontSize: 1rem"></i>&nbsp;&nbsp;View Client
                </router-link>
            </template>
        </Dialog>
        </div>
        <div class=" formgrid grid">
          <div class="col-12 md:col-6 lg:col-6">
            <Dialog header="PEP &amp; SANCTIONS CHECK" v-model:visible="displaySanctionsCheck" :modal="true" style="min-height: 23rem">
                <div class="confirmation-content" style="overflow-y: visible!important">
                    <span>
                      <div style="min-height: 16rem">
                        <h5>Screening Results</h5>
                        <Dropdown v-model="riskRating.sanctionsScreen"
                        :options="sanctionsScreens"
                        optionLabel="text"
                        optionValue="value"
                        appendTo="self"
                        class="inputfield w-full"
                        placeholder="Please Select"/>
                    </div>
                </span>
                </div>
                <template #footer>
                <div class="flex justify-content-between">
                  <a class="p-button d-block mr-2 px-auto" @click.prevent="closeSanctionsCheck"  style="background-color: #014f7e!important;
                            text-decoration: none; width: 40%; justify-content: center;">
                    <i class="pi pi-step-backward" style="fontSize: 1rem"></i>&nbsp;&nbsp;Back
                  </a>
                  <a class="p-button d-block mx-2 px-auto" @click.prevent="saveSanctionsCheck" style="background-color: #0185b7!important;
                            text-decoration: none; width: 40%; justify-content: center;">
                    <i class="pi pi-upload" style="fontSize: 1rem"></i>&nbsp;&nbsp;Submit
                  </a>
                </div>
              </template>
          </Dialog>
        </div>
      </div>
    <div class="flex justify-content-between ">
        <div>
            <Button class="l-2 m-3 mx-4 shadow-4 p-button-lg"
            icon="pi pi-step-backward"
            label="BACK&nbsp;"
            id="submitButton"
            @click.prevent="$router.back()" />
        </div>
        <div v-if="riskRating.sumScoreDescription === 'Low' && fica.hasTimFile === true && fica.hasPepFile === true">
            <Button class="l-2 m-3 mx-4 shadow-4 p-button-lg "
            icon="pi pi-file"
            label="UPLOADS COMPLETE"
            style="background-color: #014f7e!important; text-decoration: none; justify-content: center;"
            @click.prevent="completeRiskMatrixChecks" />
        </div>
        <div v-else-if="riskRating.sumScoreDescription === 'Medium' && fica.hasTimFile === true && fica.hasPepFile === true && fica.hasSourceOfFundsFile === true">
            <Button class="l-2 m-3 mx-4 shadow-4 p-button-lg "
            icon="pi pi-file"
            label="UPLOADS COMPLETE"
            style="background-color: #014f7e!important; text-decoration: none; justify-content: center;"
            @click.prevent="completeRiskMatrixChecks" />
        </div>
        <div v-else-if="riskRating.sumScoreDescription === 'High' && fica.hasTimFile === true && fica.hasPepFile === true
                        && fica.hasSourceOfFundsFile === true && fica.hasManagementSignOffFile === true">
            <Button class="l-2 m-3 mx-4 shadow-4 p-button-lg "
            icon="pi pi-file"
            label="UPLOADS COMPLETE"
            style="background-color: #014f7e!important; text-decoration: none; justify-content: center;"
            @click.prevent="completeRiskMatrixChecks" />
        </div>
        <div v-else>
          <Button class="l-2 m-3 mx-4 shadow-4 p-button-lg "
            icon="pi pi-file"
            label="UPLOADS COMPLETE"
            disabled
            style="background-color: rgba(4, 88, 137, 0.7)!important; text-decoration: none; justify-content: center;" />
        </div>
         <div>
            <Button class="l-2 m-3 mx-4 shadow-4 p-button-lg "
            icon="pi pi-upload"
            label="RESUBMIT"
            id="backButton"
            @click.prevent="openSanctionsCheck" />
        </div>
    </div>
  <br />
</template>

<script>
import {
  getRiskMatrix,
  redoRiskMatrix,
  redoSanctionsCheckResult,
  getClientFica,
  uploadFicaFile,
  ficaDownload,
  completeRiskMatrix
} from '@/gateway'

export default {
  name: 'ClientRiskMatrix',
  inheritAttrs: false,
  data () {
    return {
      riskRating: {},
      fica: [],
      riskMatrixSuccess: false,
      sanctionsCheckSuccess: false,
      displayConfirmation: true,
      displaySanctionsCheck: false,
      updateRiskSuccess: false,
      riskMatrixCompleted: false,
      sanctionsScreens: [
        { value: 'None', text: 'None' },
        { value: 'Minor', text: 'Minor' },
        { value: 'PEP', text: 'PEP' },
        { value: 'Sanctions', text: 'Sanctions' }
      ],
      selectedFileIdentityDocument: null,
      selectedFileProofOfResidence: null,
      loadingIdDoc: false,
      timSuccess: false,
      pepSuccess: false,
      sofSuccess: false,
      signSuccess: false,
      loadingTim: false,
      loadingPep: false,
      loadingSof: false,
      loadingSign: false
    }
  },
  methods: {
    async init () {
      const response = await getRiskMatrix({ id: this.id })
      if (response) { this.riskRating = response.result }
      const ficaResponse = await getClientFica({ id: this.id })
      if (ficaResponse) { this.fica = ficaResponse.result
      }
    },
    openSanctionsCheck () {
      this.displaySanctionsCheck = true
    },
    closeSanctionsCheck () {
      this.displaySanctionsCheck = false
    },
    async submitSanctionsScreen () {
      const response = await redoRiskMatrix({ riskRating: this.riskRating })
      if (response) {
        this.sanctionsCheckSuccess = false
        this.updateRiskSuccess = true
        this.init()
      }
    },
    async saveSanctionsCheck () {
      this.displaySanctionsCheck = false
      const response = await redoSanctionsCheckResult({ riskRating: this.riskRating })
      if (response) {
        this.sanctionsCheckSuccess = true
      }
    },
    onFileChanged (event) {
      this.selectedFile = event.target.files[0]
    },
    onFileChangedTim (event) {
      this.selectedFileTim = event.target.files[0]
    },
    onFileChangedPep (event) {
      this.selectedFilePep = event.target.files[0]
    },
    onFileChangedSof (event) {
      this.selectedFileSof = event.target.files[0]
    },
    onFileChangedSignOff (event) {
      this.selectedFileSignOff = event.target.files[0]
    },
    async onFileUpload () {
      if (this.selectedFileTim) {
        this.fica.hasTimFile = false
        const response = await uploadFicaFile({
          selectedFile: this.selectedFileTim,
          fileName: this.fica.timFileName,
          fileType: 'Identity Check',
          id: this.id
        })
        if (response) {
          this.init()
        }
      }
      if (this.selectedFilePep) {
        this.fica.hasPepFile = false
        const response = await uploadFicaFile({
          selectedFile: this.selectedFilePep,
          fileName: this.fica.pepFileName,
          fileType: 'Pep-Sanctions Check',
          id: this.id
        })
        if (response) {
          this.init()
        }
      }
      if (this.selectedFileSof) {
        this.fica.hasSourceOfFundsFile = false
        const response = await uploadFicaFile({
          selectedFile: this.selectedFileSof,
          fileName: this.fica.sourceOfFundsFileName,
          fileType: 'Source of Funds Check',
          id: this.id
        })
        if (response) {
          this.init()
        }
      }
      if (this.selectedFileSignOff) {
        this.fica.hasManagementSignOffFile = false
        const response = await uploadFicaFile({
          selectedFile: this.selectedFileSignOff,
          fileName: this.fica.managementSignOffFileName,
          fileType: 'Sign Off Check',
          id: this.id
        })
        if (response) {
          this.init()
        }
      }
    },
    base64ToArrayBuffer (base64) {
      var binaryString = window.atob(base64)
      var binaryLen = binaryString.length
      var bytes = new Uint8Array(binaryLen)
      for (var i = 0; i < binaryLen; i++) {
        var ascii = binaryString.charCodeAt(i)
        bytes[i] = ascii
      }
      return bytes
    },
    async downloadFica (formType) {
      if (formType === 'Identity Check') { this.loadingTim = true }
      else if (formType === 'Pep-Sanctions Check') { this.loadingPep = true }
      else if (formType === 'Source of Funds Check') { this.loadingSof = true }
      else if (formType === 'Sign Off Check') { this.loadingSign = true }
      const response = await ficaDownload({
        formType: formType,
        id: this.fica.id
      })
      if (response) {
        var byteArray = this.base64ToArrayBuffer(response.data)
        const url = window.URL.createObjectURL(
          new Blob([byteArray], { type: 'application/octet-stream' })
        )
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', response.fileName)
        document.body.appendChild(link)
        this.loadingTim = false
        this.loadingPep = false
        this.loadingSof = false
        this.loadingSign = false
        link.click()
      }
    },
    async completeRiskMatrixChecks () {
      const response = await completeRiskMatrix({ riskRating: this.riskRating })
      if (response) {
        this.riskMatrixCompleted = true
      }
    }
  },
  mounted () {
    this.id = this.$route.params.id
    this.init()
  }
}
</script>

<style lang="scss" scoped>
::v-deep() {
  .p-datatable .p-datatable-thead > tr > th {
    background-color: $middleBlue!important;
    color: $textColor;
    border: 1px solid $borderColour!important;
    font-size: 18px;
    font-weight: 600;
  }
  .p-datatable .p-datatable-tbody > tr > td {
    padding: .4rem .4rem;
    color: $darkTextColour;
    font-family:'Poppins', sans-serif;
    font-weight: 400;
  };
  #thead {
    background: $middleBlue;
    height: 6vh;
    color: $textColor;
    font-size: 18px;
    font-weight: 600;
    font-family: $fontFamily!important
  };
  #tbodyLight {
    height: 6vh;
    background-color: $backgroundColour;
    color: $darkTextColour;
    font-family:'Poppins', sans-serif;
    font-weight: 400;
  };
    #tbodyDark {
    height: 6vh;
    background-color: $whiteSmoke;
    color: $darkTextColour;
    font-family:'Poppins', sans-serif;
    font-weight: 400;
  };
    .confirmation-content {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-y: visible!important;
  };
  #uploadBackground {
  @include uploadBackground($uploadBackgroundColor)
  };
  #fileButton {
    background-color: $midDarkBlue!important;
    color: $textColor;
    font-size: 1.25rem;
    border: 2px solid;
    border-color: $borderColour;
    display: flex;
    justify-content: right;
  };
  #uploadButton {
    background-color: $lightMidBlue!important;
    min-width: 8rem;
    color: $textColor;
    font-size: 1.25rem;
    height: 2.5rem;
    border: solid 1px $borderColour!important;
    display: flex;
    justify-content: right;
  };
  .file_upload {
    position: relative;
    min-width: 8rem;
    height: 2.5rem;
    text-align: center;
    color: $textColor;
    line-height: 25px;
    background-color: $midDarkBlue!important;
    border: solid 1px $borderColour;
    font-family: $fontFamily;
    font-size: 1.25rem;
    font-family: Poppins!important;
    font-weight: 400!important;
    border-radius: 4px;
  }
  a.file_upload {
    display: inline-block;
  }
  .file_upload .btn_lbl {
    position: relative;
    z-index: 2;
    pointer-events: none;
  }
  .file_upload .btn_colorlayer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: $midDarkBlue!important;;
    z-index: 1;
    pointer-events: none;
  }
  @-moz-document url-prefix() {
    .file_upload input[type="file"] {
    position: absolute;
    top: 3px;
    left: -78px!important;
    font-weight: 600;
    margin-left: 100%;
    margin-top: 3px;
    color: $darkTextColour;
    outline: none;
    font-size: 1rem;;
    font-family: $fontFamily;
    height: 2.5rem;
  };
};
  .file_upload input[type="file"] {
    position: absolute;
    top: 3px;
    left: -102px;
    font-weight: 600;
    margin-left: 100%;
    margin-top: 5px;
    color: $darkTextColour;
    outline: none;
    font-size: 1rem;;
    font-family: $fontFamily;
    height: 2.5rem;
  };
  @media screen and (max-width: 767px) {
    .file_upload input[type="file"] {
      color: transparent!important;
    };
  };
};
  #fileCheck {
    color: $successGreen!important;
    font-size: 1.65rem;
    margin-top: 0.15rem;
  };
  #fileCross {
    color: $queuedBackground!important;
    font-size: 1.8rem;
    margin-top: 0.1rem;
  };
  .p-dialog-content {
    overflow-y: visible!important;
  };
  @media screen and (max-width:1024px) {
    #fullScreenView {
      display: none!important;
    };
    .mobileTable {
      font-size: 14px!important;
    };
    #thead {
      font-size: 16px!important;
    };
  };
    @media screen and (min-width:1025px) {
    #mobileView {
      display: none!important;
    }
  };
</style>
