<template>
  <br />
  <br />
  <h2 class="text-center my-2">RETAIL CLIENTS HOME</h2>
  <br />
  <br />
  <div class="fluid grid">
    <div class="col-12 md:col-12 lg:col-4">
        <Card class="shadow-5 text-center d-block mx-auto" id="newClient">
            <template class="text-center" #title>
              <i class="pi pi-plus-circle" id="cardLogo"></i>
            </template>
            <template  #content>
              <router-link to="/newClient" style="color: #e2e7e5; text-decoration: none">
              ADD NEW
              </router-link>
            </template>
        </Card>
    </div>
      <div class="col-12 md:col-12 lg:col-4">
          <Card class="shadow-5 text-center d-block mx-auto" id="fromPartner">
              <template class="text-center" #title>
                <i class="pi pi-link" id="cardLogo"></i>
              </template>
              <template  #content>
                <router-link to="/partnerClientToAddListView" style="color: #e2e7e5; text-decoration: none">
                FROM PARTNER
                </router-link>
              </template>
          </Card>
      </div>
    <div class="col-12 md:col-12 lg:col-4">
        <Card class="shadow-5 text-center d-block mx-auto" id="viewClients">
            <template class="text-center" #title>
              <i class="pi pi-users" id="cardLogo"></i>
            </template>
            <template  #content>
              <router-link to="/clientListView" style="color: #e2e7e5; text-decoration: none">
              VIEW CLIENTS
              </router-link>
            </template>
        </Card>
    </div>
  </div>
  <div>
    <footer class="text-center" style="position: fixed; bottom: 0; width: 100%">
      <hr class="mx-4"/>
      <h5 class="text-center mb-3 mx-2">Copyright © 2022.
        Analytics Consulting FX Solutions is a division of Analytics Consulting 1 (Pty) Ltd,
        an Authorised Financial Services Provider, FSP No: 47564.
        Tel: 021 936 9500.</h5>
    </footer>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'ClientHome',
  inheritAttrs: false
}
</script>

<style lang="scss" scoped>
#newClient {
 @include cardComponent($midDarkBlue)
};
#fromPartner {
 @include cardComponent($middleBlue)
};
#viewClients {
 @include cardComponent($lightMidBlue)
};
.p-card.p-component.p-card-content {
 font-size: 24px;
};
.p-grid {
  margin: 1rem;
  padding: 1rem;
};
</style>
