<template>
  <br />
  <h2 class="text-center my-3">ADD FORM 25 DETAILS FOR : {{ retailClient.firstLastName }}</h2>
  <br />
  <Panel class="mx-4 shadow-4" header="PAYMENT INSTRUCTION DETAILS" :toggleable="true" :collapsed="false">
  <template #end>
      <button class="panel-header-icon link mr-2" @click="toggle"></button>
  </template>
  <h4 class="text-center"><u>PAYMENT DETAILS</u></h4>
  <div class=" formgrid grid">
      <div class="col-12 md:col-6 lg:col-6">
        <h5>Payment Date</h5>
          <Calendar
          p-datepicker
          v-model="form25.paymentDate"
          :showIcon="true"
          appendTo="self"
          class="inputfield w-full"
          dateFormat="yy-mm-dd" />
      </div>
      <div class="col-12 md:col-6 lg:col-6">
          <h5>Client Account Number</h5>
          <InputText
          type="text"
          class="inputfield w-full"
          disabled
          v-model="retailClient.zarAccountNumber"/>
          <InputText
          type="text"
          class="inputfield w-full"
          hidden
          v-model="form25.transferAccountNumber"/>
      </div>
   </div>
   <div class="formgrid grid">
      <div class="col-12 md:col-6 lg:col-6">
        <h5>Amount in ZAR</h5>
            <InputNumber
              v-model="form25.randAmount"
               mode="decimal"
              :minFractionDigits="2"
              :maxFractionDigits="2"
              class="inputfield w-full" />
      </div>
      <div class="col-12 md:col-6 lg:col-6">
          <h5>Amount in words</h5>
          <InputText
          type="text"
          class="inputfield w-full"
          v-model="form25.randAmountInWords"/>
      </div>
  </div>
  <br />
  <hr />
  <h4 class="text-center"><u>BENFICIARY DETAILS</u></h4>
  <div class="formgrid grid">
    <div class="col-12 md:col-12 lg:col-6">
        <div class="field-checkbox">
            <Checkbox id="billerPay"
            v-model="form25.billerPayment"
            :binary="true" />
            <label for="billerPay">Biller Payment</label>
        </div>
      </div>
  </div>
  <div class="formgrid grid">
      <div class="col-12 md:col-6 lg:col-6">
        <h5>Beneficiary Name</h5>
          <InputText
          type="text"
          class="inputfield w-full"
          v-model="form25.beneficiaryName"/>
      </div>
  </div>
   <div class="formgrid grid">
      <div class="col-12 md:col-12 lg:col-12">
        <h5>Beneficiary Address</h5>
          <InputText
          type="text"
          class="inputfield w-full"
          v-model="form25.beneficiaryAddress"/>
      </div>
  </div>
   <div class="formgrid grid">
      <div class="col-12 md:col-6 lg:col-6">
        <h5>Beneficiary Account Number</h5>
          <InputText
          type="text"
          class="inputfield w-full"
          v-model="form25.beneficiaryAccount"/>
      </div>
      <div class="col-12 md:col-6 lg:col-6">
        <h5>Account Type</h5>
          <Dropdown v-model="form25.accountType"
          :options="accountTypes"
          optionLabel="text"
          optionValue="value"
          :showClear="true"
          appendTo="self"
          class="inputfield w-full"
          placeholder="Please Select" />
      </div>
  </div>
   <div class="formgrid grid">
      <div class="col-12 md:col-6 lg:col-6">
        <h5>Bank Name</h5>
          <InputText
          type="text"
          class="inputfield w-full"
          v-model="form25.bankName"/>
      </div>
      <div class="col-12 md:col-6 lg:col-6">
          <h5>Branch Name</h5>
          <InputText
          type="text"
          class="inputfield w-full"
          v-model="form25.branchName"/>
      </div>
  </div>
   <div class="formgrid grid">
      <div class="col-12 md:col-6 lg:col-6">
        <h5>Bank Code</h5>
          <InputText
          type="text"
          class="inputfield w-full"
          v-model="form25.bankCode"/>
      </div>
      <div class="col-12 md:col-6 lg:col-6">
          <h5>Beneficiary Reference</h5>
          <InputText
          type="text"
          class="inputfield w-full"
          v-model="form25.beneficiaryRef"/>
      </div>
  </div>
   <div class="formgrid grid">
      <div class="col-12 md:col-6 lg:col-6">
        <h5>STP</h5>
          <Dropdown v-model="form25.stp"
          :options="stps"
          optionLabel="text"
          optionValue="value"
          :showClear="true"
          appendTo="self"
          class="inputfield w-full"
          placeholder="Please Select" />
      </div>
      <div class="col-12 md:col-6 lg:col-6">
        <h5>Proof of Payment Required?</h5>
          <Dropdown v-model="form25.proofOfPayment"
          :options="proofsOfPayment"
          optionLabel="text"
          optionValue="value"
          :showClear="true"
          appendTo="self"
          class="inputfield w-full"
          placeholder="Please Select" />
      </div>
  </div>
   <transition name="fade">
      <div v-if="form25.proofOfPayment == 'Yes'">
          <div class="formgrid grid">
              <div class="col-12 md:col-6 lg:col-6">
                <h5>Proof of Payment Email Address</h5>
                  <InputText
                  type="text"
                  class="inputfield w-full"
                  v-model="form25.popEmail"/>
              </div>
          </div>
      </div>
   </transition>
  <div class="formgrid grid">
      <div class="col-12 md:col-6 lg:col-6">
        <h5>Signed At</h5>
          <Dropdown v-model="form25.signedAt"
          :options="signedAts"
          optionLabel="text"
          optionValue="value"
          :showClear="true"
          appendTo="self"
          class="inputfield w-full"
          placeholder="Please Select" />
      </div>
      <div class="col-12 md:col-6 lg:col-6">
        <h5>Signatory</h5>
          <Dropdown v-model="form25.signatoryName"
          :options="signatoryNames"
          optionLabel="text"
          optionValue="value"
          :showClear="true"
          appendTo="self"
          class="inputfield w-full"
          placeholder="Please Select" />
      </div>
  </div>
   <br />
    <div class="formgrid grid">
      <div class="col-12 md:col-12 lg:col-6">
          <div class="field-checkbox">
              <Checkbox id="isThird"
              v-model="form25.isThirdPartyPayment"
              :binary="true" />
              <label for="isThird">Is this a ThirdParty Payment?</label>
          </div>
       </div>
    </div>
 </Panel>
 <br />
 <div class="flex justify-content-between ">
    <div>
        <Button class="l-2 mt-1 mb-4 mx-4 shadow-4 p-button-lg"
        icon="pi pi-step-backward"
        label="BACK&nbsp;"
        id="submitButton"
        @click.prevent="$router.back()" />
    </div>
    <div>
        <Button class="l-2 mt-1 mb-4 mx-4 shadow-4 p-button-lg "
        icon="pi pi-upload"
        label="SAVE"
        id="backButton"
        @click.prevent="saveClientForm25" />
    </div>
</div>
<div class="formgrid grid">
    <div class="col-12 md:col-6 lg:col-6">
    <div v-if='form25Success == true'>
    <Dialog class="successModal" header="SUCCESS" id="successModal" v-model:visible="displayConfirmation"  :modal="true">
        <div class="confirmation-content">
            <span>Form 25 Data Added</span>
        </div>
        <template #footer>
            <router-link class="p-button d-block mx-3 py-3 px-auto" :to="/form25View/ + retailClient.id"
                      style="color: #e2e7e5; background: #0185b7!important;  text-decoration: none;
                      display: flex; justify-content: center;">
                      <i class="pi pi-list" style="fontSize: 1rem"></i>&nbsp;&nbsp;View Form 25
            </router-link>
        </template>
    </Dialog>
    </div>
    </div>
</div>
</template>

<script>
import { getRetailClient, saveForm25 } from '@/gateway'

export default {
  name: 'Form25Add',
  inheritAttrs: false,
  data () {
    return {
      retailClient: {},
      messages: [],
      form25Success: false,
      displayConfirmation: true,
      form25: {
        id: 0,
        clientId: 0,
        fullNames: '',
        firstLastName: '',
        cifNumber: '',
        paymentDate: null,
        transferAccountNumber: '',
        randAmount: '',
        randAmountInWords: '',
        beneficiaryName: '',
        billerPayment: false,
        beneficiaryAddress: '',
        beneficiaryAccount: '',
        accountType: '',
        bankName: '',
        branchName: '',
        bankCode: '',
        beneficiaryRef: '',
        stp: '',
        proofOfPayment: '',
        popEmail: '',
        signedAt: '',
        signatoryName: '',
        signatoryCapacity: 'Intermediary',
        signatoryIdNumber: '',
        isThirdPartyPayment: false
      },
      accountTypes: [
        { value: 'Savings', text: 'Savings' },
        { value: 'Current', text: 'Current' },
        { value: 'Trading', text: 'Trading' }
      ],
      stps: [
        { value: 'Yes', text: 'Yes' },
        { value: 'No', text: 'No' }
      ],
      proofsOfPayment: [
        { value: 'Yes', text: 'Yes' },
        { value: 'No', text: 'No' }
      ],
      signedAts: [
        { value: 'Cape Town', text: 'Cape Town' },
        { value: 'Johannesburg', text: 'Johannesburg' }
      ],
      signatoryNames: [
        { value: 'Alison Anne Barker', text: 'Alison' },
        { value: 'Amanda Colette Sampson', text: 'Amanda' },
        { value: 'Catherine Digby', text: 'Cathy' },
        { value: 'Lindy-Lee Davies', text: 'Lindy' }
      ]
    }
  },
  methods: {
    async init () {
      const response = await getRetailClient({ id: this.id })
      if (response) this.retailClient = response.result
    },
    async saveClientForm25 () {
      const response = await saveForm25({ form25: this.form25, retailClient: this.retailClient })
      if (response) {
        this.form25Success = true
      }
    }
  },
  mounted () {
    this.id = this.$route.params.id
    this.init()
  }
}
</script>
