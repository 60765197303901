<template>
<br />
  <h2 class="text-center my-2">Corporate section still under construction</h2>
  <br />
  <p class="text-center my-2">Please continue to use the FxApp for Corporate/Institutional Clients</p>

</template>

<script>
// @ is an alias to /src

export default {
  name: 'Corporate',
  inheritAttrs: false
}
</script>
