<template>
 <br />
  <h2 class="text-center my-2">FAQ</h2>
 <br />
<Panel class="mx-4 shadow-4" header="GETTING STARTED" :toggleable="true" :collapsed="true">
<template #end>
    <button class="panel-header-icon link mr-2" @click="toggle"></button>
</template>
<div class="mx-3">
<p>Thank you for contacting us with regards to assisting you with your foreign exchange (FX) transfer.
    To onboard you as a client, we request that you send us the following information: </p>
    <ul>
    <li>Copy of green bar coded ID or your Smart Card ID (both sides)</li>
    <li>Proof of physical/residential address (e.g. utility bill not older than 3 months)</li>
    <li>Tax number and/or offshore tax number</li>
    <li>Contact cell phone number and email address</li>
    <li>Profession and nature of business/industry</li>
    <li>Source of funds</li>
    <li>Selfie of the client holding up ID next to face (for face to face verification purposes)</li>
    </ul>
<p>Analytics Consulting FX Solutions, together with its Partners, seeks to provide the most efficient and cost effective currency solutions for clients.
    For a South African resident to transfer funds offshore through us, they will need to open an FX transmission account in their own name, and sign our
    mandate to give us the authority to execute the FX transfer at our preferential rates. Once funds have been deposited into this transmission account,
    there is no time limit in which the funds must be converted and the funds will earn and attract top market-related interest rates, with full liquidity.
    There is no cost to open or hold this account.</p>
<p>With the above information, you will receive prepopulated account opening forms for your signature. Once you have signed these forms and after these
    have been verified by the bank, you will receive an account number, normally within 24 hours, for your FX transfer.</p>
<p>When you are ready to convert, the rate is quoted and once agreed by you or your financial advisor, the trade is executed with the Authorised Dealer
    at our preferential rates and charges. AC FX Solutions is able to leverage its Group institutional scale to obtain superior pricing and reduce all
    costs associated with FX transfers. We are also able to assist with any Tax Clearance approval you may require for amounts greater than R1 million. </p>
<p>Funds are transferred on Value Date (Trade Date plus 2 days for SPOT trades). You will receive a Swift Confirmation note the day after Value Date which
    confirms the funds have arrived safely at your requested destination.</p>
</div>
</Panel>
<br />
<Panel class="mx-4 shadow-4" header="RSA TAX NUMBER" :toggleable="true" :collapsed="true">
<template #end>
    <button class="panel-header-icon link mr-2" @click="toggle"></button>
</template>
<div class="mx-3">
<p>CLIENTS REQUIRING AN SA TAX NUMBER</p>
<p>Clients with an SA ID book or smart card must approach SARS to get a tax number and remit funds against his/her foreign capital allowance -
    AC FX Solutions can assist with this process and it can be completed same day</p>
<p>This may apply to SA clients that have lived abroad but never applied for SA tax number, minors with investment savings that are being moved offshore,
    inheritances, or SA clients living abroad, born in South Africa and never formally emigrated.</p>
    <p>We provide this service (outsourced) at a cost of R1000 excl. VAT.</p>
    <p>We only require the following information:</p>
    <ol>
    <li>SARS Power of Attorney</li>
    <li>Proof of Address</li>
    <li>Copy of ID</li>
    <li>Proof of Banking Details; (we would onboard the client and provide the these account details) </li>
    <li>Contact Information (Cell, telephone and email)</li>
    <li>Selfie holding up your ID</li>
    </ol>
<p>If the client has not been onboarded with AC FX Solutions and Capitec Bank Limited ("Capitec"), we would require the following additional information:</p>
    <ol>
    <li>Profession and nature of industry</li>
    <li>Any foreign tax number and country</li>
    <li>Source of funds </li>
    </ol>
</div>
</Panel>
<br />
<Panel class="mx-4 shadow-4" header="AC FX TRANSMISSION ACCOUNTS" :toggleable="true" :collapsed="true">
<template #end>
    <button class="panel-header-icon link mr-2" @click="toggle"></button>
</template>
<div class="mx-3">
<p>Thank you for contacting us in regard to your client’s FX transfer. For a SA resident to transfer funds offshore through us, we need the following to onboard the client:</p>
    <ol>
    <li>Client FICA and contact details</li>
    <li>Signed bank account opening form</li>
    <li>Mandate for AC FX solutions to transact at preferential rates </li>
    </ol>
<p>To make the process as effortless as possible for the client, please could you forward us the following client information to prepopulate the onboarding forms for signature:</p>
    <ul>
    <li>Copy of green bar coded ID or your Smart Card ID both sides</li>
    <li>Proof of physical/residential address (not older than 3 months)</li>
    <li>SA Tax number and offshore (if applicable)</li>
    <li>Contact cell phone number and email address</li>
    <li>Profession and nature of business/industry</li>
    <li>Source of funds</li>
    </ul>
<p>We will send your client forms for signature via the Bank’s online signing platform (this is for security purposes). Upon receipt of the signed forms, your client will be in a position to transact almost immediately (or no later than 24 hours).
   There is no cost to open or hold this account.</p>
<p>Bank account opening forms in SA have to be signed by a client directly and cannot be signed by a third party, investment mandate or simple power of attorney.</p>
<p>We look forward to working with you and your client.</p>
</div>
</Panel>
<br />
<Panel class="mx-4 shadow-4" header="FOREIGN CURRENCY ACCOUNT" :toggleable="true" :collapsed="true">
<template #end>
    <button class="panel-header-icon link mr-2" @click="toggle"></button>
</template>
<div class="mx-3">
<p>Foreign currency accounts (“FCA”) can be opened at Capitec Bank Limited, (Capitec). These accounts are conducted in the nostro
    administration of an Authorised Dealer. Such accounts are held onshore and represent a local asset denominated in foreign currency.</p>
    <ol>
    <li>There are no costs to open and hold the account</li>
    <li>These accounts do not earn interest</li>
    </ol>
<p>Foreign Currency Accounts are used for three purposes only:</p>
    <ol>
    <li>To buy and hold foreign currency ultimately for investment purposes only, i.e. for onward payment offshore to your own bank account offshore /
        an investment account in your own name or sold back into Rands into own bank account</li>
    <li>To receive and hold funds from abroad in foreign currency into SA to exchange into Rands at a time that is convenient. The client may receive
        own capital introduced and income earned while physically abroad into the FCA. These funds are eligible for retransfer from the FCA without conversion
        to ZAR (excluding any growth on the funds introduced) with documentary evidence. Refer Section B.2 (B) (ii) (a) of the Authorised Deal Manual</li>
    <li>Gift payments and inheritances cannot be received into an FCA account and must be converted into ZAR within 30 days. The client may, thereafter,
        transfer such funds to the FCA account under his foreign capital allowance (for authorised transactions)</li>
    </ol>
<p>Please note the following:</p>
    <ol>
    <li>FCAs may not be used to send payments to a 3rd party</li>
    <li>Please do not transfer Rands into FCA accounts. Rands received into this foreign currency denominated account will automatically be returned to sender</li>
    <li>Clients may use this account to park foreign currency if their investment or bank account offshore is not open yet for ultimate transfer for these accounts offshore.
        Client may bulk transactions and foreign currency in this account for ultimate offshore investment transfer</li>
    <li>Funds that are exchanged and placed in the FCA may be redeemed back into your ZAR trading account should you no longer require the foreign currency held in the FCA</li>
    <li>Foreign funds received into an FCA as disinvestment may be reinvested offshore to clients own account without conversion to Rands and a requirement for a new TCS PIN. </li>
    <li>Clients receiving foreign currency from abroad (own capital or income earned) are advised to use this account number in same currency to ensure receipt and control
        over the exchange to Rands. When foreign currency is sent to a ZAR account number, exchange control requires that is exchanged within 30 days. By using this account,
        funds may be held for as long as required and exchanged at a time and nominal amount that is suitable to you</li>
    <li>For receiving foreign currency, please ensure that the sending bank uses your full names (not initials) in their instruction</li>
    <li>On receipt of the inward swift, you may instruct conversion / transfer at any time which will be subject to the Bank’s transaction charges.
        Mercantile, the authorised dealer, charge R350 for inward transactions </li>
    <li>Gifts must be received directly into the ZAR account. Given that gift payments are not eligible for retransfer, such funds must be converted to ZAR and credited to the ZAR account.
        The client may, thereafter, transfer such funds to the FCA account under his foreign capital allowance (for authorised transactions)</li>
    </ol>
<p>On receipt of your FCA details, please use the bank account number to open and view your bank statements which will be e-mailed to you by Capitec. The CIF number will be required to open your IT3(b) certificate.</p>
<p>All foreign exchange transaction details will confirmed by yourself or your nominated authorised signatory. You or your financial advisor will receive a swift confirmation note confirming the funds have arrived safely at
    your requested destination the day after Value Date.</p>
</div>
</Panel>
<br />
<Panel class="mx-4 shadow-4" header="ACCOUNT ACTIVE &amp; TRADING" :toggleable="true" :collapsed="true">
<template #end>
    <button class="panel-header-icon link mr-2" @click="toggle"></button>
</template>
<div class="mx-3">
<p>Once your account is activated, you will be in a position to request a quote and confirm the FX rate for your FX transfer.
    Please note these rates are quoted live and cannot be held for an extended period of time. The FX rate quoted is subject to market movements.
    Funds deposited for trade must be cleared before value date (trade day plus 2 at 9am) for timeous settlement.</p>
<p>For outward transactions, please EFT Rands to your ZAR FX trading account. If you have opened a Foreign Currency Account in USD or other (FCA),
    pls do not send Rands to this account number as it will be returned to sender. This is used for incoming foreign currency.</p>
<p>The trade confirmation note serves as an agreement by the client to the FX rate and amount traded. Please provide beneficiary details and purpose of
    payment at the time of trade in writing:  </p>
    <ul>
    <li>Beneficiary name</li>
    <li>Beneficiary residential address</li>
    <li>Bank name</li>
    <li>Bank account or IBAN number</li>
    <li>Branch name / sort code</li>
    <li>Bank city and country</li>
    <li>Bank SWIFT/ BIC code</li>
    <li>Nature of payment (investment / gift / donation / travel, etc)</li>
    </ul>
<p>On trade execution, funds must have a nominated destination by the value date. Converted funds will not be held onshore beyond the value date and the
    trade will be automatically rolled. This entails selling the currency back and re-purchasing it for the next value date, at a possible cost to the client.
    Alternatively, the trade may be cancelled and the cost to the client will be the change in the market rate.</p>
<p>You may also place an order in the market that, when the currency reaches a pre-specified level, a certain nominal amount is converted. This order must
    be confirmed by you in writing and holds in a 24 hour market and over weekends. The order remains in place as “good until cancelled” in writing by you.
    Orders may only be placed for a minimum foreign currency equivalent of $25 000.</p>
<p>All funds must be cleared and reflect in the client’s bank account before value date of the trade. If the client fails to fund the account and have cleared
    funds reflecting before value date, the trade will be automatically rolled.</p>
<p>When payment of ZAR is made to the ZAR trading account, there is no time limit in which the client must convert the funds and entrusted funds will earn a
    top market related interest rate, accrued monthly, until such time as the trade is settled. Incoming funds must be converted into Rands within 30 days unless
    they are sent in foreign currency to the client’s foreign currency account number at Capitec Bank Limited.</p>
<p><b>VALUE DATE:</b></p>
<p>All FX trades will automatically be booked at SPOT (trade date + 2 business days) unless otherwise requested. TOM is trade date + 1 business day. Same day
    trades must be done before 11am.</p>
<p><b>CHARGES:</b></p>
<p>All FX trades will have bank transaction charges deducted at the local bank and the foreign bank. The trade BOP form specifies these charges. Local ZAR
    transfers incur their own EFT charge. </p>
<p>The charges for Outgoing funds are as follows:</p>
    <ul>
    <li><b>OUR</b> - R500 (all charges deducted at source, at Capitec)</li>
    <li><b>SHA</b> - R250 (SHARE local bank charge and the foreign bank deduct own fee form the nominal)</li>
    <li><b>BEN</b> - R0 (all charges deducted at foreign bank, at Beneficiary in foreign currency)</li>
    </ul>
<p>The charges for Incoming funds are as follows: </p>
    <ul>
    <li><b>SHA/BEN</b> - R350 for charges on the incoming SWIFT </li>
    <li><b>OUR</b> – R250 if charges on the incoming SWIFT at source</li>
    </ul>
</div>
</Panel>
<br />
<Panel class="mx-4 shadow-4" header="PLACING A TRIGGER / MARKET ORDER" :toggleable="true" :collapsed="true">
<template #end>
    <button class="panel-header-icon link mr-2" @click="toggle"></button>
</template>
<div class="mx-3">
<p><b>Desired levels of the exchange rate to move currency can be placed </b></p>
<p>If you have a desired target exchange rate at which you wish to buy or sell your currency, you may place an order in the market.</p>
    <ul>
    <li>This avoids having to monitor movements in the exchange rate continuously</li>
    <li>It is live 24/7. If the exchange rate reaches the desired target level when SA market hours are closed, and other markets are open, for example the US and Asia, the deal will
        be concluded at the desired rate</li>
    <li>Accounts do not have to be pre-funded to set orders, but it represents a firm commitment in the event that the trade is concluded and the account must be funded within 48 hours</li>
    <li>There is no time limit, the order can be set Good Till Cancelled or it can expire on a certain date</li>
    <li>Orders must be confirmed in writing or via the FxLink Query page (for Advisors only)</li>
    <li>Deal confirmations are sent immediately or at next market open</li>
    <li>Please note the bank rate will need to move lower on a buy order (or higher on a sell order) to reach a client rate based on margin agreed in the client mandate.
        The deal is triggered at the bank rate.</li>
<p><b>Example order placed as follows:</b></p>
    <li>Client Name</li>
    <li>R1,000,000 to USD</li>
    <li>Client rate 14.95</li>
    <li>Good till cancelled</li>
    </ul>
</div>
</Panel>
<br />
<Panel class="mx-4 shadow-4" header="SINGLE DISCRETIONARY ALLOWANCE" :toggleable="true" :collapsed="true">
<template #end>
    <button class="panel-header-icon link mr-2" @click="toggle"></button>
</template>
<div class="mx-3">
<p><b>Refer Section B.4 (A) of the Currency and Exchanges Manual for Authorised Dealers</b></p>
    <ul>
    <li>Residents (natural persons) who are 18 years and older may be permitted to avail of a single discretionary allowance within an overall limit of R1 million per individual per
        calendar year without the requirement to obtain a Tax Clearance Certificate (TCS) PIN letter, which may be used for any legal purpose abroad. This dispensation may be utilised solely
        at the discretion of the resident without any documentary evidence having to be produced to the Authorised Dealer except for travel purposes outside the CMA where a passenger ticket
        needs to be produced.</li>
    <li>The single discretionary allowance may be transferred abroad in Rand, however, transfers of a capital nature must be converted to foreign currency through an Authorised Dealer.</li>
    <li>The resident individual must produce a valid green bar-coded South African identity document or Smart identity document card for identification purposes and the identity number
        is mandatory when reporting the transaction in terms of the Reporting System. </li>
    <li>Private individuals who do not have a tax reference number will have to register at their local SARS branch for foreign investment purposes.</li>
    <li>Resident importers making import payments under this dispensation must have a valid customs client number (CCN) issued by Customs which must be recorded on the Reporting System.</li>
    <li>Payments for current account transactions may be effected abroad against the presentation of documentary evidence and such payments will not be deducted from an individual’s
        single discretionary allowance limit.</li>
    <li>Resident individuals may transfer monetary gifts and loans within the single discretionary allowance limit of R1 million per applicant during a calendar year to non-resident
        individuals and to resident individuals who are overseas temporarily, excluding those residents who are abroad on holiday or business travel.</li>
    <li>A travel allowance within the single discretionary allowance limit of R1 million per calendar year may be availed of by residents. The travel allowance may also be transferred
        abroad to the traveller’s own bank account and/or spouse accounts, but not to the account of a third party. Individuals may not avail of a travel allowance more than 60 days prior
        to their departure and must present a valid passenger ticket and passport copy when travelling by air, bus, rail or ship.</li>
    <li>Minors travelling with parents may have their travel allowances transferred to their parents’ bank account abroad. Residents (natural persons) who are under the age of 18 years
        may not be accorded single discretionary allowance but may be accorded a travel allowance not exceeding an amount of R200 000 per calendar year.</li>
    <li>Tuition may be regarded as a payment outside the client’s SDA.</li>
    <li>The Rand equivalent of income earned abroad and own foreign capital introduced by private individuals resident in South Africa, may be retransferred abroad (excluding any
        growth on the funds introduced), provided that the Authorised Dealer concerned is satisfied that the income and/or capital had previously been converted to Rand, by viewing
        documentary evidence confirming the amounts involved. The sale proceeds of South African assets received from non-residents and export proceeds are therefore not eligible for
        retransfer abroad by private individuals resident in South Africa.</li>
    </ul>
</div>
</Panel>
<br />
<Panel class="mx-4 shadow-4" header="EMIGRATION TRANSFERS IN SHORT" :toggleable="true" :collapsed="true">
<template #end>
    <button class="panel-header-icon link mr-2" @click="toggle"></button>
</template>
<div class="mx-3">
<p><b>For Private individuals who cease to be SA residents for tax purposes</b></p>
<p>Non-resident clients that were<u>born in South Africa</u> cannot access funds in SA without proof of formal emigration / proof that they have ceased to be a tax resident in SA.
   The client will need to declare his/her South African remaining assets and liabilities with SARS. Depending on declared remaining assets, the client is issued with
   <u>proof of registration with SARS</u></p>
<p><b>AC FX Solutions has partnered with Eton Nominees in assisting our clients with this process.</b></p>
<p>Herewith the link to submit an application: <a href="http://www.etonnominees.co.za/application/index.html" target="_blank">Eton Nominees</a></p>
<p><u>Transfer of funds under R1million:</u></p>
    <ul>
        <li>Proof of registration from SARS only</li>
        <li>AC FX solutions FX emigrant trading account</li>
    </ul>
<p><u>Transfer of funds over R1 million – R10 million:</u></p>
    <ul>
        <li>Emigrant SARS Tax Compliance Status (TCS) Pin Letter</li>
        <li>AC FX Solutions FX emigrant trading account and transfer under code 530</li>
        <li>Submission of the TCS Pin Letter to the Bank for verification through SARS Verification System</li>
    </ul>
<p><u>Transfer of funds over R10 million:</u></p>
    <ul>
    <li>Emigrant SARS Tax Compliance Status (TCS) Pin Letter</li>
    <li>AC FX Solutions FX emigrant trading account and transfer under code 530</li>
    <li>Submission of the TCS Pin Letter to the Bank for verification through SARS Verification System.</li>
    <li>SARB approval facilitated by AC FX Solutions and the Bank using the TCS PIN letter – this is a written application which may take 4 to 6 weeks
        before authority is received</li>
    </ul>
<p>Please do not hesitate to contact us or your financial advisor with any queries. We have a more detailed document should you need more information.</p>
</div>
</Panel>
<br />
<Panel class="mx-4 shadow-4" header="EMIGRATION PIN LETTERS REQUIRED FOR OFFSHORE PAYMENTS" :toggleable="true" :collapsed="true">
<template #end>
    <button class="panel-header-icon link mr-2" @click="toggle"></button>
</template>
<div class="mx-3">
<p><b>Any emigrant account must have a valid SARS PIN letter to accompany an offshore payment</b></p>
<p>If your customer Emigrated before 1 March 2021, they should have been furnished with SARS TCS Pin <b>Letter of Emigration</b>
   which Pin Letter is valid for a period of one year from date of issue.</p>
<p>Should your customer still have remaining assets in South Africa, they now apply for SARS TCS Pin <b>Letter of Foreign Investment</b>
   for remaining assets in South Africa and this Pin Letter of FIA is valid for a period of one year from date of issue.</p>
<p>Emigration Pin is not renewable but the FIA Pin Letter is renewable on yearly basis.</p>
</div>
</Panel>
<br />
<Panel class="mx-4 shadow-4" header="USD INCOMING PAYMENTS" :toggleable="true" :collapsed="true">
<template #end>
    <button class="panel-header-icon link mr-2 right" @click="toggle"></button>
</template>
<div class="mx-3">
<p><b>Correct capture for incoming USD payments</b></p>
<p>All incoming USD payments must stipulate CABLZAJJ as the beneficiary bank Capitec Bank Limited, and the correspondent bank is PNBPUS3NNYC
   (Wells Fargo).</p>
<p>When sending USD from your offshore Internet Banking into your account at Capitec, here are possible options to select to ensure the transaction is completed:</p>
<p>Account Name: Client own name</p>
<p>Account number: Client account number</p>
<p>Bank Name:</p>
    <ul>
        <li>Capitec Bank Limited</li>
    </ul>
<p>Branch number: select one of</p>
    <ul>
        <li>450105 Universal</li>
        <li>450905 Capitec Business</li>
    </ul>
  <p>Beneficiary Bank Address</p>
  <ul>
    <li>5 Neutron Road Techno Park Ikhaya Stellenbosch</li>
  </ul>
<p>Beneficiary Bank:</p>
    <ul>
        <li>CABLZAJJ</li>
        <li>ZACABLZAJJ</li>
    </ul>
<p>Correspondent Bank: PNBPUS3NNYC</p>
  <ul>
    <li>Swift Code: PNBPUS3NNYC</li>
    <li>Routing Code: 026005092</li>
    <li>CHIPS ABA: 0509</li>
  </ul>
<p>Currency: USD</p>
<p>The currency must be captured as USD and not default to ZAR when capturing the beneficiary country as South Africa.
   Please check the funds are sent in USD to allow you more options on receipt of the funds as well as better conversion rates.</p>
<p>If the online banking does not allow for a correspondent bank field to be captured, the client may capture the beneficiary
   swift code as CABLZAJJ alone or as CABLZAJJ (PNBPUS3NNYC).</p>
<p>Please do not hesitate to contact us or your financial advisor with any queries you may have.</p>
</div>
</Panel>
<br />
<Panel class="mx-4 shadow-4" header="GIFT PAYMENTS" :toggleable="true" :collapsed="true">
<template #end>
    <button class="panel-header-icon link mr-2 right" @click="toggle"></button>
</template>
<div class="mx-3">
<p><b>Outward Payments</b></p>
    <ul>
        <li>Resident individuals may transfer monetary <b>gifts</b> within the single discretionary allowance limit of R1 million per applicant during a
            calendar year to <b>non-resident</b> individuals and to resident individuals who are overseas temporarily, excluding those residents who are
            abroad on holiday or business travel.</li>
        <li>The third party offshore must have an <b>offshore residential address</b> for the BOP form</li>
        <li>Donations tax will apply</li>
    </ul>
<p><b>Inward Payments</b></p>
    <ul>
        <li>Any foreign assets received by a resident from a non-resident as a gift or donation must be declared to an Authorised Dealer and be repatriated
            to South Africa. Alternatively, a fully motivated application to retain the assets abroad must be submitted via an Authorised Dealer to the
            Financial Surveillance Department.</li>
        <li>Gift payments cannot be received into a Foreign Currency Account (“FCA”). Gift payments are not eligible for retransfer, such funds must be
            converted to ZAR and credited to the ZAR account. </li>
    </ul>
</div>
</Panel>
<br />
<Panel class="mx-4 shadow-4" header="CRYPTOCURRENCY TRANSFERS" :toggleable="true" :collapsed="true">
<template #end>
    <button class="panel-header-icon link mr-2 right" @click="toggle"></button>
</template>
<div class="mx-3">
<p><b>Every Cryptocurrency transaction must be supported by the following:</b></p>
<p>Please be advised that the following process in respect of submission of single discretionary allowance (R1m) and/or foreign investment allowance (up to R10m)
    FIA investments in Cryptocurrency, begins with immediate effect.</p>
<p>In view of the recent reviews conducted by the SARB on Crypto investments by individuals in respect of SDAs and FIA, directives were issued as a guideline
    to be implemented with immediate effect.</p>
<p>Therefore, in consideration of the high risks noted with crypto transactions that have been coming through for processing, every crypto transaction must be
    supported by the following:</p>
    <ol>
    <li>Original proof of crypto cycle funding and where additional funding to the cycle has been done, we need to see the trail of the additional funding</li>
    <li>All funding must be from the client effecting the transaction/ investment</li>
    <li>A bank statement must be provided to show source of funds / or cycle of funds</li>
    <li>No third party funding will be allowed unless between spouses and funds form part of joint assets resources</li>
    <li>Client must confirm that all profit(s), risk(s) of transaction being effected and losses are their own</li>
    <li>Client must confirm that they have not exceeded their SDA or/and FIA limits as stipulated in the Exchange Control manual</li>
    </ol>
<p>Please be guided accordingly.</p>
<p><i>Source South Africa Reserve Bank:</i></p>
    <ul>
    <li>Individuals may purchase crypto assets (CAs) from abroad through utilisation of his/her single discretionary allowance (SDA) (R1 million) and/or individual foreign capital
        allowance (FCA) (R10 million with a Tax Clearance Certificate), per calendar year, as outlined in the above-mentioned manuals, which a local Authorised Dealer in foreign
        exchange (AD) (local commercial bank) will be able to assist individuals with.</li>
    <li>Furthermore, the use of another individual's SDA or FCA, whether through the granting of a 'loan' to such an individual or any other similar agreement, is regarded as a
        simulated transaction for the purpose of circumventing the provisions of the Exchange Control Regulations and therefore an illegal activity. In this regard, we refer you to
        Exchange Control Regulation 10(1)(c), read with Exchange Control Regulation 22.</li>
    <li>Exchange Control Regulation 10(1)(c) prohibits the entering into a transaction whereby capital or the right to capital is, without the permission granted by the Treasury,
        directly or indirectly exported from the RSA. Therefore, if an individual purchases CAs in RSA which is used to externalise 'any right to capital', such an individual will
        be in contravention of the Exchange Control Regulations. A contravention of  Exchange Control Regulation 10(1)(c) is, in terms of Exchange Control Regulation 22, a criminal offence.</li>
    <li>It should also be pointed out that the repatriation to the RSA of value through CAs is not acknowledged as a repatriation of an individual’s SDA and/or individual FCA, due to the
        nature of CAs and the fact that such transaction is currently not reportable on the FinSurv Reporting system.</li>
    <li>Similarly, non-residents who have introduced CAs to RSA for sale locally and who want to transfer the sale proceeds abroad will be unable to do so. The applicable exchange control
        policy is outlined in section G.(C)(i) of the Currency and Exchanges Manual for Authorised Dealers.</li>
    </ul>
</div>
</Panel>
<br />
<Panel class="mx-4 shadow-4" header="ZAR TO ZAR EFT PAYMENTS TO CAPITEC BUSINESS" :toggleable="true" :collapsed="true">
<template #end>
    <button class="panel-header-icon link mr-2 right" @click="toggle"></button>
</template>
<div class="mx-3">
    <p><b>Here are some possible solves for online banking ZAR transactions to Capitec Bank Limited (Capitec)</b></p>
    <p>Once your account is activated, for outward transactions, please EFT Rands to your ZAR FX trading account. When processing EFT’s from your Internet Banking
    into this account, here are possible options to select to ensure the transaction is completed:</p>
    <p>This is a normal Rand transfer to a Rand account (EFT):</p>
    <p>Account Name: Client own name</p>
    <p>Account number: Client 400xxxx number</p>
    <p>Bank Name:</p>
        <ul>
            <li>Capitec Business</li>
        </ul>
    <p>Branch number:</p>
        <ul>
            <li>450105 Universal</li>
            <li>5 Neutron Road Techno Park Ikhaya Stellenbosch</li>
        </ul>
    <p>Account type: select one of</p>
        <ul>
            <li>Current / Transmission / Call / Savings</li>
        </ul>
    <p>Swift Code for incoming funds from offshore:</p>
        <ul>
            <li>CABLZAJJ</li>
        </ul>
    <p>Currency: ZAR</p>
    <p>Importantly, please do not EFT Rands to any Foreign Currency Account (FCA) with Capitec. These accounts start with a 510xxxx. It will be returned to sender.
    The FCA accounts are used for holding foreign currency for investment purposes or used for incoming foreign currency payments</p>
    <p>Please do not hesitate to contact us or your appointed financial advisor with any queries you may have.</p>
</div>
</Panel>
<br />
<Panel class="mx-4 shadow-4" header="ZAR TO ZAR PAYMENTS OFFSHORE" :toggleable="true" :collapsed="true">
<template #end>
    <button class="panel-header-icon link mr-2 right" @click="toggle"></button>
</template>
<div class="mx-3">
<p><b>Clients may not transfer their SDA offshore in Rands, it must be converted to foreign currency through an Authorised Dealer (Bank)</b></p>
    <ul>
    <li>Transfers of capital nature cannot be transferred abroad in ZAR</li>
    <li>Clients are required to convert in South Africa</li>
    <li>A client cannot speculate on the exchange rate</li>
    <li>We refer to the Currency Exchange Manual sub-section B.4(A)(iii)
        <ul>
            <li>(iii) The single discretionary allowance may be transferred abroad in Rand, however, transfers of a capital nature must be converted to
                      foreign currency through an Authorised Dealer.</li>
        </ul>
    </li>
    </ul>
</div>
</Panel>
<br />
<Panel class="mx-4 shadow-4" header="DOCUSIGN FORMS TO EXPECT" :toggleable="true" :collapsed="true">
<template #end>
    <button class="panel-header-icon link mr-2 right" @click="toggle"></button>
</template>
<div class="mx-3">
<p>We have sent the client’s onboarding forms for signature via Docusign which is the Bank’s approved digital signing platform. Please inform your client to please check their inbox and Junk mail,
    the attached is an example of what they can expect to find in their inbox</p>
<p>We encourage clients may to make use of electronic signatures, given face to face meetings may not be possible.
    To assist you, we will send the documents to you to sign via the digital signing platform that has been approved by the Bank. If you accept this method of transacting,
    then no further action is required and the forms signed via the digital signing platform will be regarded as original. A scanned image of a handwritten signature embedded into a document
    qualifies as an electronic signature as defined in the Electronic Communications and Transactions Act, No. 25 of 2002 (“ECT”) Act.  If you choose to print, sign and scan the forms back to us,
    we request that you post or deliver the original copies of the forms and all supporting documents to us for audit purposes.</p>
</div>
</Panel>
<br />
<div>
<Button class="l-2 m-4 mx-4 shadow-4 p-button-lg"
icon="pi pi-step-backward"
label="BACK&nbsp;"
id="submitButton"
@click.prevent="$router.back()" />
</div>
<br />
<hr class="mx-4"/>
<h5 class="text-center my-2 mx-4">Copyright © 2022. All rights reserved.
    Analytics Consulting FX Solutions is a division of Analytics Consulting 1 (Pty) Ltd,
    an Authorised Financial Services Provider. FSP: 47564</h5>
<p class="text-light mx-5" style="color: #C8C8C8; font-size: 12px">This communication does not constitute an offer or the solicitation of an offer for the sale or purchase of any currency, security or any other financial product. The information
and opinions contained in this communication are recorded and expressed in good faith and in reliance on sources believed to be credible. The information is of a general nature only
and does not take into account any investor’s objectives, financial situations or needs. The information does not constitute advice and it should not be used, relied upon or treated
as a substitute for specific, professional advice. It is, therefore, recommended that investors obtain the appropriate legal, tax, investment and/or other professional advice and
formulate an investment strategy that would suit the investor’s risk profile prior to acting on such information and to consider whether any recommendation is appropriate considering
the investor’s own objectives and particular needs. Past performance discussed is not necessarily a guide to future performance. Forecasts are not guaranteed and provided for
illustrative purposes only. Although the information provided and statements of fact are obtained from sources that we consider reliable, no representation, warranty, undertaking or
guarantee is given on the accuracy and/or completeness of such information or the correctness of such opinions. Analytics Consulting 1 will have no liability of whatever nature and
however arising in respect of any claim, damages, loss or expenses suffered directly or indirectly by the investor acting on the information contained in this communication.
We recommend that you take particular care to consider whether any information contained in this communication is appropriate given your objectives, financial situation and
particular needs in view of the fact that there may be limitations on the appropriateness of the advice provided. No guarantee of investment performance or capital protection
should be inferred from any of the information contained in this document.</p>
<br />
</template>

<script>
// @ is an alias to /src

export default {
  name: 'FAQ',
  inheritAttrs: false
}
</script>

<style lang="scss" scoped>
li {
    font-family:'Poppins', sans-serif;
    color: $darkTextColour;
};
.p-panel .p-panel-header .p-panel-title {
  font-family: $fontFamily;
}
</style>
