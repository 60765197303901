<template>
<h2 class="text-center my-2">QUERY FROM : {{ query.userName }}</h2>
<br />
<div class="fluid grid">
        <Card class="shadow-5" id="chatCard">
            <template #content>
                <section id="chatArea">
                  <div id="chatScroll" ref="chatArea">
                    <p v-for="(q, index) in query.queryMessages" v-bind:key="index"
                      :class="{ 'messageAdmin': q.sentBy === 'isAdmin',
                              'messagePartner': q.sentBy === 'isPartner'}">
                      <span :class="{ 'adminTimestamp': q.sentBy === 'isAdmin',
                              'partnerTimestamp': q.sentBy === 'isPartner'}">
                              <u>{{q.updatedAt}}</u></span>
                              <br />
                              {{q.message}}
                    </p>
                  </div>
                </section>
            </template>
            <template #footer>
            <div class="flex justify-content-between ">
                <div>
                    <Textarea
                    id="textInput"
                    :autoResize="true"
                    rows="2"
                    class="inputfield w-full"
                    v-model="newQueryMessage.message"/>
                </div>
                <div>
                    <Button class="shadow-3"
                            id="querySendButton"
                            label="Send"
                            icon="pi pi-send"
                            @click.prevent="replyToQuery(newQueryMessage)"/>
                </div>
            </div>
            </template>
        </Card>
        </div>
    <div class="mx-2">
    <Button class="l-2 m-4 mx-3 shadow-3 p-button-lg"
    icon="pi pi-step-backward"
    label="BACK&nbsp;"
    id="submitButton"
    @click.prevent="$router.back()" />
  </div>

</template>

<script>
import { getUserQuery, adminReplyToQuery } from '@/gateway'

var date = new Date()
var isoDateTime = new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString().substring(0, 16).replace('T', ' ~ ')

export default ({
  name: 'QueryReply',
  inheritAttrs: false,
  data () {
    return {
      query: [],
      newQueryMessage: {
        message: '',
        sentBy: 'isAdmin',
        updatedAt: isoDateTime
      },
      replySuccess: false
    }
  },
  methods: {
    async init () {
      const response = await getUserQuery({ id: this.id })
      if (response) {
        this.query = response.result
      }
      await this.$nextTick()
      const messageDisplay = this.$refs.chatArea
      messageDisplay.scrollTop = messageDisplay.scrollHeight
    },
    async replyToQuery (newMessage) {
      this.query.queryMessages.push(newMessage)
      this.newQueryMessage = {
        message: '',
        sentBy: '',
        updatedAt: ''
      }
      const response = await adminReplyToQuery({ query: this.query })
      if (response) {
        await this.$nextTick()
        const messageDisplay = this.$refs.chatArea
        messageDisplay.scrollTop = messageDisplay.scrollHeight
        this.$router.push({ name: 'QueryChat', params: { id: this.query.id } })
      }
    }
  },
  mounted () {
    this.id = this.$route.params.id
    this.init()
  }
})
</script>

<style lang="scss" scoped>
::v-deep() {
  @media screen and (max-width: 1366px) {
    #chatArea {
      background: $backgroundColour;
      min-height: 52vh;
      border: solid 1px;
      overflow: auto;
      border-color: $borderColour;
      font-size: 16px;
      color: $darkTextColour;
      border-radius: 4px;
      padding: 0.2rem 0.8rem 0.2rem 0.8rem;
    };
    #chatArea:focus {
      outline: solid 0.5px;
      outline-color: $lightBlue;
    };
    #chatCard {
      display: block;
      margin: auto;
      overflow: auto;
      border: solid 1px!important;
      background-color: $lightDarkBlue!important;
      border-color: $midDarkBlue!important;
      width: 85%;
      min-height: 55vh;
    };
    #chatScroll {
      max-height: 55vh;
      width: 100%;
      overflow-x: hidden;
      overflow-y: auto;
    };
    .messagePartner {
      background: $uploadBackgroundColor;
      color: $darkTextColour;
      margin-left: 15%;
      border-radius: 4px;
      padding: .4rem;
    };
    .messageAdmin {
      background: $isAdminChatBackground;
      color: $darkTextColour;
      border-radius: 4px;
      padding: .4rem;
      width: 85%;
    };
    .adminTimestamp {
      color: $blackTextColor;
      font-size: 11px;
      padding: 0.2rem;
    };
    .partnerTimestamp {
      color: $blackTextColor;
      font-size: 11px;
      padding: 0.2rem;
      margin-left: 86%;
    };
    #textInput {
      width: 70vw!important;
    };
    #querySendButton {
      min-height: 3.75rem!important;
      background: $light!important;
      color: $lightDarkBlue!important;
      font-size: 20px;
      border: solid 1px!important;
      border-color: $midDarkBlue!important;
    };
  };
@media screen and (min-width: 1367px) {
  #chatArea {
      background: $backgroundColour;
      min-height: 52vh;
      border: solid 1px;
      overflow: auto;
      border-color: $borderColour;
      font-size: 16px;
      color: $darkTextColour;
      border-radius: 4px;
      padding: 0.2rem 0.8rem 0.2rem 0.8rem;
    };
    #chatArea:focus {
      outline: solid 0.5px;
      outline-color: $lightBlue;
    };
    #chatCard {
      display: block;
      margin: auto;
      overflow: auto;
      border: solid 1px!important;
      background-color: $lightDarkBlue!important;
      border-color: $midDarkBlue!important;
      width: 85%;
      min-height: 60vh;
    };
    #chatScroll {
      max-height: 60vh;
      width: 100%;
      overflow-x: hidden;
      overflow-y: auto;
    };
    .messagePartner {
      background: $uploadBackgroundColor;
      color: $darkTextColour;
      margin-left: 20%;
      border-radius: 4px;
      padding: .4rem;
    };
    .messageAdmin {
      background: $isAdminChatBackground;
      color: $darkTextColour;
      border-radius: 4px;
      padding: .4rem;
      width: 80%;
    };
    .adminTimestamp {
      color: $blackTextColor;
      font-size: 11px;
      padding: 0.2rem;
    };
    .partnerTimestamp {
      color: $blackTextColor;
      font-size: 11px;
      padding: 0.2rem;
      margin-left: 88%;
    };
    #textInput {
      width: 70vw!important;
    };
    #querySendButton {
      min-height: 3.75rem!important;
      background: $light!important;
      color: $lightDarkBlue!important;
      font-size: 20px;
      border: solid 1px!important;
      border-color: $midDarkBlue!important;
    };
  };
};
.p-grid {
  margin: 1rem;
  padding: 1rem;
};
</style>
