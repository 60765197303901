<template>
  <br />
  <h2 class="text-center my-2">DOWNLOAD CLIENT FORMS FOR : {{ retailClient.firstLastName }}</h2>
  <br />
  <div class=" fluid mx-1" id="mobileView">
    <Button class="p-button d-block mx-3 py-3 px-auto" :loading="loadingForm90" @click.prevent="downloadForm('Form 90D')"
              style="color: #e2e7e5; background: #062d5d!important; text-decoration: none;
              display: flex; justify-content: center; width: 93%;"
              icon="pi pi-cloud-download"
              label="&nbsp;Form 90D"/>
    <br />
    <Button class="p-button d-block mx-3 py-3 px-auto" :loading="loadingForm90B" @click.prevent="downloadForm('Form 90B')"
            style="color: #e2e7e5; background: #062d5d!important; text-decoration: none;
              display: flex; justify-content: center; width: 93%;"
            icon="pi pi-cloud-download"
            label="&nbsp;Form 90B"/>
    <br />
    <Button class="p-button d-block mx-3 py-3 px-auto" @click.prevent="openMandateDialog"
              style="color: #e2e7e5; background: #8D1109!important; text-decoration: none;
              display: flex; justify-content: center; width: 93%"
              icon="pi pi-cloud-download"
              label="&nbsp;Mandate"/>
     <br />
     <Button class="p-button d-block mx-3 py-3 px-auto" :loading="loadingForm250T" @click.prevent="downloadForm('Form 250T')"
              style="color: #e2e7e5; background: #5D0658!important; text-decoration: none;
              display: flex; justify-content: center; width: 93%;"
              icon="pi pi-cloud-download"
              label="&nbsp;Form 250T"/>
     <br />
     <Button class="p-button d-block mx-3 py-3 px-auto" :loading="loadingForm436I" @click.prevent="downloadForm('Form 436I')"
              style="color: #e2e7e5; background: #5D3606!important; text-decoration: none;
              display: flex; justify-content: center; width: 93%;"
              icon="pi pi-cloud-download"
              label="&nbsp;Form 436I"/>
    <br />
    <div v-if="retailClient.hasForm25Data == false">
     <Button class="p-button d-block mx-3 py-3 px-auto"
              @click.prevent="$router.push( {name: 'Form25Add', params: { id: retailClient.id }})"
              style="color: #e2e7e5; background: #065D0B!important;  text-decoration: none;
              display: flex; justify-content: center; width: 93%;"
              icon="pi pi-cloud-download"
              label="&nbsp;Form 25"/>
    </div>
    <div v-else>
     <Button class="p-button d-block mx-3 py-3 px-auto"
              @click.prevent="$router.push( {name: 'Form25View', params: { id: retailClient.id }})"
              style="color: #e2e7e5; background: #065D0B!important;  text-decoration: none;
              display: flex; justify-content: center; width: 93%"
              icon="pi pi-cloud-download"
              label="&nbsp;Form 25" />
    </div>
    <br />
    <Button class="p-button d-block mx-3 py-3 px-auto" :loading="loadingZarWL" @click.prevent="downloadForm('ZAR Welcome Letter')"
              style="color: #e2e7e5; background: #09448D!important; text-decoration: none;
              display: flex; justify-content: center; width: 93%;"
              icon="pi pi-cloud-download"
              label="&nbsp;ZAR Welcome"/>
    <br />
    <Button class="p-button d-block mx-3 py-3 px-auto" :loading="loadingFcaWL" @click.prevent="downloadForm('FCA Welcome Letter')"
              style="color: #e2e7e5; background: #BD170C!important; text-decoration: none;
              display: flex; justify-content: center; width: 93%"
              icon="pi pi-cloud-download"
              label="&nbsp;FCA Welcome"/>
     <br />
     <Button class="p-button d-block mx-3 py-3 px-auto" :loading="loadingBrokerDL" @click.prevent="downloadForm('Broker Declaration Letter')"
              style="color: #e2e7e5; background: #8D0985!important; text-decoration: none;
              display: flex; justify-content: center; width: 93%;"
              icon="pi pi-cloud-download"
              label="&nbsp;Broker Dec"/>
     <br />
     <Button class="p-button d-block mx-3 py-3 px-auto" :loading="loadingBrokerDLTim" @click.prevent="downloadForm('Broker Declaration Letter TIM')"
              style="color: #e2e7e5; background: #8D5209!important; text-decoration: none;
              display: flex; justify-content: center; width: 93%;"
              icon="pi pi-cloud-download"
              label="&nbsp;Broker Dec TIM"/>
    <br />
     <Button class="p-button d-block mx-3 py-3 px-auto" :loading="loadingResignL" @click.prevent="downloadForm('Resignation Letter')"
              style="color: #e2e7e5; background: #098D11!important;  text-decoration: none;
              display: flex; justify-content: center; width: 93%;"
              icon="pi pi-cloud-download"
              label="&nbsp;Resignation Letter"/>
  </div>

<div id="fullScreenView">
  <div class="flex flex-wrap mx-4 my-3 shadow-2 md:align-items-center sm:align-items-center" style="background: #AAAAAF; max-width: 100vw">
    <table class="">
        <thead id="thead">
            <tr>
                <th class="text-center px-2" style="width: 20vw"> FORM 90D</th>
                <th class="text-center px-2" style="width: 20vw"> FORM 90B</th>
                <th class="text-center px-2" style="width: 20vw"> MANDATE</th>
                <th class="text-center px-2" style="width: 20vw"> FORM 250 T</th>
                <th class="text-center px-2" style="width: 20vw"> FORM 436I</th>
                <th class="text-center px-2" style="width: 20vw"> FORM 25</th>
            </tr>
        </thead>
            <tbody id="tbodyDark">
                <tr>
                    <td>
                      <Button class="p-button d-block mx-3 py-3 px-3" :loading="loadingForm90" @click.prevent="downloadForm('Form 90D')"
                                style="color: #e2e7e5; background: #062d5d!important; text-decoration: none;
                                display: flex; align-items: center; justify-content: center; width: 85%;"
                                icon="pi pi-cloud-download"
                                label="&nbsp;Form 90D"/>
                    </td>
                    <td>
                      <Button class="p-button d-block mx-3 py-3 px-auto" :loading="loadingForm90B" @click.prevent="downloadForm('Form 90B')"
                            style="color: #e2e7e5; background: #062d5d!important; text-decoration: none;
                                display: flex; align-items: center; justify-content: center; width: 85%;"
                            icon="pi pi-cloud-download"
                            label="&nbsp;Form 90B"/>
                    </td>
                    <td>
                        <a class="p-button d-block mx-3 py-3 px-auto" @click.prevent="openMandateDialog"
                                  style="color: #e2e7e5; background: #8D1109!important;  text-decoration: none;
                                  display: flex; align-items: center; justify-content: center; width: 85%;">
                               <i class="pi pi-cloud-download" style="fontSize: 1rem"></i>&nbsp;Mandate
                       </a>
                    </td>
                    <td>
                      <Button class="p-button d-block mx-3 py-3 px-auto" :loading="loadingForm250T" @click.prevent="downloadForm('Form 250T')"
                                style="color: #e2e7e5; background: #5D0658!important; text-decoration: none;
                                display: flex; align-items: center; justify-content: center; width: 85%;"
                                icon="pi pi-cloud-download"
                                label="&nbsp;Form 250T"/>
                    </td>
                    <td>
                      <Button class="p-button d-block mx-3 py-3 px-auto" :loading="loadingForm436I" @click.prevent="downloadForm('Form 436I')"
                                style="color: #e2e7e5; background: #5D3606!important; text-decoration: none;
                                display: flex; align-items: center; justify-content: center; width: 85%;"
                                icon="pi pi-cloud-download"
                                label="&nbsp;Form 436I"/>
                    </td>
                     <td v-if="retailClient.hasForm25Data == false">
                      <router-link class="p-button d-block mx-3 py-3 px-auto" :to="/form25Add/ + retailClient.id"
                                style="color: #e2e7e5; background: #065D0B!important;  text-decoration: none;
                                display: flex; align-items: center; justify-content: center; width: 85%;">
                               <i class="pi pi-cloud-download" style="fontSize: 1rem"></i>&nbsp;&nbsp;Form 25
                      </router-link>
                    </td>
                    <td v-else>
                      <router-link class="p-button d-block mx-3 py-3 px-auto" :to="/form25View/ + retailClient.id"
                                style="color: #e2e7e5; background: #065D0B!important;  text-decoration: none;
                                display: flex; align-items: center; justify-content: center; width: 85%;">
                               <i class="pi pi-cloud-download" style="fontSize: 1rem"></i>&nbsp;&nbsp;Form 25
                      </router-link>
                    </td>
                </tr>
            </tbody>
    </table>
</div>
<br />
<br />
<div class="flex flex-wrap mx-4 my-3 shadow-2 md:align-items-center sm:align-items-center" style="background: #AAAAAF; max-width: 100vw">
    <table class="">
        <thead id="thead" >
            <tr>
                <th class="text-center px-2" style="width: 20vw"> ZAR WELCOME</th>
                <th class="text-center px-2" style="width: 20vw"> FCA WELCOME</th>
                <th class="text-center px-2" style="width: 20vw"> BROKER DECLARATION</th>
                <th class="text-center px-2" style="width: 20vw"> BROKER DECLARATION - TIM</th>
                <th class="text-center px-2" style="width: 20vw"> RESIGNATION LETTER</th>
            </tr>
        </thead>
            <tbody id="tbodyDark">
                <tr>
                    <td>
                      <Button class="p-button d-block mx-3 py-3 px-auto" :loading="loadingZarWL" @click.prevent="downloadForm('ZAR Welcome Letter')"
                                style="color: #e2e7e5; background: #09448D!important; text-decoration: none;
                                display: flex; align-items: center; justify-content: center; width: 85%;"
                                icon="pi pi-cloud-download"
                                label="&nbsp;ZAR Welcome"/>
                    </td>
                    <td>
                      <Button class="p-button d-block mx-3 py-3 px-auto" :loading="loadingFcaWL" @click.prevent="downloadForm('FCA Welcome Letter')"
                                style="color: #e2e7e5; background: #BD170C!important; text-decoration: none;
                                display: flex; align-items: center; justify-content: center; width: 85%;"
                                icon="pi pi-cloud-download"
                                label="&nbsp;FCA Welcome"/>
                    </td>
                    <td>
                      <Button class="p-button d-block mx-3 py-3 px-auto" :loading="loadingBrokerDL" @click.prevent="downloadForm('Broker Declaration Letter')"
                                style="color: #e2e7e5; background: #8D0985!important; text-decoration: none;
                                display: flex; align-items: center; justify-content: center; width: 85%;"
                                icon="pi pi-cloud-download"
                                label="&nbsp;Broker Dec"/>
                    </td>
                    <td>
                      <Button class="p-button d-block mx-3 py-3 px-auto" :loading="loadingBrokerDLTim" @click.prevent="downloadForm('Broker Declaration Letter TIM')"
                                style="color: #e2e7e5; background: #8D5209!important; text-decoration: none;
                                display: flex; align-items: center; justify-content: center; width: 85%;"
                                icon="pi pi-cloud-download"
                                label="&nbsp;Broker Dec TIM"/>
                    </td>
                    <td>
                      <Button class="p-button d-block mx-3 py-3 px-auto" :loading="loadingResignL" @click.prevent="downloadForm('Resignation Letter')"
                                style="color: #e2e7e5; background: #098D11!important; text-decoration: none;
                                display: flex; align-items: center; justify-content: center; width: 85%;"
                                icon="pi pi-cloud-download"
                                label="&nbsp;Resignation Letter"/>
                    </td>
                </tr>
            </tbody>
      </table>
  </div>
</div>
<Dialog class="successModal" header="SELECT MANDATE" v-model:visible="displayMandate" :modal="true">
    <div class="confirmation-content">
        <br />
         <Button class="p-button d-block mx-3 py-3 px-auto" :loading="loadingMandate" @click.prevent="downloadForm('Mandate')"
                        style="color: #e2e7e5; background: #5D0B06!important; text-decoration: none;
                        display: flex; justify-content: center; width: 93%;"
                        icon="pi pi-cloud-download"
                        label="&nbsp;AC1 Mandate"/>
        <br />
         <Button class="p-button d-block mx-3 py-3 px-auto" :loading="loadingCCMandate" @click.prevent="downloadForm('CC Mandate')"
                        style="color: #e2e7e5; background: #8D1109!important; text-decoration: none;
                        display: flex; justify-content: center; width: 93%;"
                        icon="pi pi-cloud-download"
                        label="&nbsp;CC Mandate"/>
        <br />
         <Button class="p-button d-block mx-3 py-3 px-auto" :loading="loadingNRMandate" @click.prevent="downloadForm('Non-Resident Mandate')"
                        style="color: #e2e7e5; background: #BD170C!important; text-decoration: none;
                        display: flex; justify-content: center; width: 93%;"
                        icon="pi pi-cloud-download"
                        label="&nbsp;Non-Resident"/>
        <br />
    </div>
    <template #footer>
      <div class="confirmation-content">
        <a class="p-button d-block mx-3 py-3 px-auto" @click.prevent="closeMandateDialog"
          style="color: #e2e7e5; background: #014f7e!important;  text-decoration: none;
          display: flex; justify-content: center; width: 87%; height: 3rem;">
            <i class="pi pi-times-circle" style="fontSize: 1rem"></i>&nbsp;&nbsp;Close
        </a>
      </div>
    </template>
</Dialog>
<div>
  <Button class="l-2 m-3 mx-4 shadow-3 p-button-lg"
  icon="pi pi-step-backward"
  label="BACK&nbsp;"
  id="submitButton"
  @click.prevent="$router.push( {name: 'ClientView', params: { id: retailClient.id }})"/>
</div>
</template>

<script>
import { getRetailClient, formsDownload } from '@/gateway'

export default {
  name: 'ClientFormsView',
  inheritAttrs: false,
  data () {
    return {
      retailClient: {},
      displayMandate: false,
      loadingForm90: false,
      loadingForm90B: false,
      loadingForm250T: false,
      loadingForm436I: false,
      loadingZarWL: false,
      loadingFcaWL: false,
      loadingBrokerDL: false,
      loadingBrokerDLTim: false,
      loadingResignL: false,
      loadingMandate: false,
      loadingCCMandate: false,
      loadingNRMandate: false
    }
  },
  methods: {
    async init () {
      const response = await getRetailClient({ id: this.id })
      if (response) {
        this.retailClient = response.result
      }
    },
    base64ToArrayBuffer (base64) {
      var binaryString = window.atob(base64)
      var binaryLen = binaryString.length
      var bytes = new Uint8Array(binaryLen)
      for (var i = 0; i < binaryLen; i++) {
        var ascii = binaryString.charCodeAt(i)
        bytes[i] = ascii
      }
      return bytes
    },
    async downloadForm (formType) {
      if (formType === 'Form 90D') { this.loadingForm90 = true }
      else if (formType === 'Form 90B') { this.loadingForm90B = true }
      else if (formType === 'Form 250T') { this.loadingForm250T = true }
      else if (formType === 'Form 436I') { this.loadingForm436I = true }
      else if (formType === 'ZAR Welcome Letter') { this.loadingZarWL = true }
      else if (formType === 'FCA Welcome Letter') { this.loadingFcaWL = true }
      else if (formType === 'Broker Declaration Letter') { this.loadingBrokerDL = true }
      else if (formType === 'Broker Declaration Letter TIM') { this.loadingBrokerDLTim = true }
      else if (formType === 'Resignation Letter') { this.loadingResignL = true }
      else if (formType === 'Mandate') { this.loadingMandate = true }
      else if (formType === 'CC Mandate') { this.loadingCCMandate = true }
      else if (formType === 'Non-Resident Mandate') { this.loadingNRMandate = true }
      const response = await formsDownload({
        formType: formType,
        id: this.retailClient.id
      })
      if (response) {
        var byteArray = this.base64ToArrayBuffer(response.data)
        const url = window.URL.createObjectURL(
          new Blob([byteArray], { type: 'application/octet-stream' })
        )
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', response.fileName)
        document.body.appendChild(link)
        this.loadingForm90 = false
        this.loadingForm90B = false
        this.loadingForm250T = false
        this.loadingForm436I = false
        this.loadingZarWL = false
        this.loadingFcaWL = false
        this.loadingBrokerDL = false
        this.loadingBrokerDLTim = false
        this.loadingResignL = false
        this.loadingMandate = false
        this.loadingCCMandate = false
        this.loadingNRMandate = false
        link.click()
      }
    },
    async openMandateDialog () {
      this.displayMandate = true
    },
    async closeMandateDialog () {
      this.displayMandate = false
    }
  },
  async mounted () {
    this.id = this.$route.params.id
    await this.init()
  }
}
</script>

<style lang="scss" scoped>
::v-deep() {
  .p-button-label {
    flex: none;
  }
  .p-datatable .p-datatable-thead > tr > th {
    background-color: $middleBlue!important;
    color: $textColor;
    border: 1px solid $borderColour!important;
    font-size: 18px;
    font-weight: 600;
  }
  .p-datatable .p-datatable-tbody > tr > td {
    padding: .4rem .4rem;
    color: $darkTextColour;
    font-family:'Poppins', sans-serif;
    font-weight: 400;
  };
  #thead {
    background: $middleBlue;
    height: 9vh;
    color: $textColor;
    font-size: 18px;
    font-weight: 600;
    font-family: $fontFamily!important;
  };
  #tbodyLight {
    height: 6vh;
    background-color: $backgroundColour;
    color: $darkTextColour;
    font-family:'Poppins', sans-serif;
    font-weight: 400;
  };
    #tbodyDark {
    height: 12vh;
    background-color: $whiteSmoke;
    color: $darkTextColour;
    font-family:'Poppins', sans-serif;
    font-weight: 400;
  };
};
  @media screen and (max-width:1024px) {
    #fullScreenView {
      display: none!important;
    }
  };
    @media screen and (min-width:1025px) {
    #mobileView {
      display: none!important;
    }
  };
</style>
