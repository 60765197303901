<template>
  <br />
  <h2 class="text-center my-2 mx-2">POST TRADE DETAILS FOR : {{tradeQuotes.firstLastName}}</h2>
  <br />
  <div class="mainView">
    <div class="fluid grid" id="mainView">
      <div class="col-12 md:col-12 lg:col-6">
        <Card class="shadow-5" id="replyCard">
          <template #content>
            <transition name="fadequick">
                <div v-if="tradeQuotes.tradeType === 'Buy ZAR'">
                    <table class="shadow-3">
                        <thead id="thead" >
                            <tr>
                                <th class="text-left pl-2" style="width: 50vw"></th>
                                <th class="text-left pl-2" style="width: 50vw"></th>
                            </tr>
                        </thead>
                        <tbody id="tbodyDark">
                            <tr>
                                <td class="text-left pl-2">Quote Status</td>
                                <td class="text-center py-1"><span class="mx-2" :class="'retailClient-badge status-' +
                                           tradeQuotes.quoteStatus">{{tradeQuotes.quoteStatus}}</span></td>
                            </tr>
                        </tbody>
                        <tbody id="tbodyLight">
                            <tr>
                                <td class="text-left pl-2">Quote Accepted At </td>
                                <td class="text-left pl-2"> {{tradeQuotes.quoteAcceptedTime}} </td>
                            </tr>
                        </tbody>
                        <tbody id="tbodyDark">
                            <tr>
                                <td class="text-left pl-2">Trade Type </td>
                                <td class="text-left pl-2"> {{tradeQuotes.tradeType}} </td>
                            </tr>
                        </tbody>
                        <tbody id="tbodyLight">
                            <tr>
                                <td class="text-left pl-2">Trade Settlement </td>
                                <td class="text-left pl-2">{{tradeQuotes.tradeTiming}} </td>
                            </tr>
                        </tbody>
                        <tbody id="tbodyDark">
                            <tr>
                                <td class="text-left pl-2">Commission Rate </td>
                                <td class="text-left pl-2"> {{formatPercentage(tradeQuotes.commissionRate, "0.00%")}}</td>
                            </tr>
                        </tbody>
                        <tbody id="tbodyLight">
                            <tr>
                                <td class="text-left pl-2">Buy Currency </td>
                                <td class="text-left pl-2"> ZAR </td>
                            </tr>
                        </tbody>
                        <tbody id="tbodyDark">
                            <tr>
                                <td class="text-left pl-2">Sell Currency </td>
                                <td class="text-left pl-2"> {{tradeQuotes.crossCurrency}} </td>
                            </tr>
                        </tbody>
                        <tbody id="tbodyLight">
                            <tr>
                                <td class="text-left pl-2">Traded Rate </td>
                                <td class="text-left pl-2"> {{tradeQuotes.actualTradedRate}} </td>
                            </tr>
                        </tbody>
                        <tbody id="tbodyDark">
                            <tr>
                                <td class="text-left pl-2">Buy ZAR Value </td>
                                <td class="text-left pl-2"> {{formatCurrency(tradeQuotes.tradeValue, "0,0.00")}} </td>
                            </tr>
                        </tbody>
                        <tbody id="tbodyLight">
                            <tr>
                                <td class="text-left pl-2">Sell {{tradeQuotes.crossCurrency}} Value </td>
                                <td class="text-left pl-2"> {{formatCurrency(tradeQuotes.crossValue, "0,0.00")}}</td>
                            </tr>
                        </tbody>
                        <tbody id="tbodyDark">
                            <tr>
                                <td class="text-left pl-2">Account Number</td>
                                <td class="text-left pl-2"> {{tradeQuotes.tradeAccountNumber}} </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
<!----------------------------------------------------------------------------------------------------------------------------------------------------------------->
<!----------------------------------------------------------------------------------------------------------------------------------------------------------------->
                <div v-else-if="tradeQuotes.tradeType === 'Sell ZAR'">
                    <table class="shadow-3">
                        <thead id="thead" >
                            <tr>
                                <th class="text-left pl-2" style="width: 50vw"></th>
                                <th class="text-left pl-2" style="width: 50vw"></th>
                            </tr>
                        </thead>
                        <tbody id="tbodyLight">
                            <tr>
                                <td class="text-left pl-2">Quote Accepted At </td>
                                <td class="text-left pl-2"> {{tradeQuotes.quoteAcceptedTime}} </td>
                            </tr>
                        </tbody>
                        <tbody id="tbodyDark">
                            <tr>
                                <td class="text-left pl-2">Trade Type </td>
                                <td class="text-left pl-2"> {{tradeQuotes.tradeType}} </td>
                            </tr>
                        </tbody>
                        <tbody id="tbodyLight">
                            <tr>
                                <td class="text-left pl-2">Trade Settlement </td>
                                <td class="text-left pl-2">{{tradeQuotes.tradeTiming}} </td>
                            </tr>
                        </tbody>
                        <tbody id="tbodyDark">
                            <tr>
                                <td class="text-left pl-2">Commission Rate </td>
                                <td class="text-left pl-2"> {{formatPercentage(tradeQuotes.commissionRate, "0.00%")}}</td>
                            </tr>
                        </tbody>
                        <tbody id="tbodyLight">
                            <tr>
                                <td class="text-left pl-2">Buy Currency </td>
                                <td class="text-left pl-2"> {{tradeQuotes.crossCurrency}} </td>
                            </tr>
                        </tbody>
                        <tbody id="tbodyDark">
                            <tr>
                                <td class="text-left pl-2">Sell Currency </td>
                                <td class="text-left pl-2"> ZAR </td>
                            </tr>
                        </tbody>
                        <tbody id="tbodyLight">
                            <tr>
                                <td class="text-left pl-2">Traded Rate </td>
                                <td class="text-left pl-2"> {{tradeQuotes.actualTradedRate}} </td>
                            </tr>
                        </tbody>
                        <tbody id="tbodyDark">
                            <tr>
                                <td class="text-left pl-2">Buy {{tradeQuotes.crossCurrency}} Value </td>
                                <td class="text-left pl-2"> {{formatCurrency(tradeQuotes.crossValue, "0,0.00")}} </td>
                            </tr>
                        </tbody>
                        <tbody id="tbodyLight">
                            <tr>
                                <td class="text-left pl-2">Sell ZAR Value </td>
                                <td class="text-left pl-2"> {{formatCurrency(tradeQuotes.tradeValue, "0,0.00")}}</td>
                            </tr>
                        </tbody>
                        <tbody id="tbodyDark">
                            <tr>
                                <td class="text-left pl-2">Account Number</td>
                                <td class="text-left pl-2"> {{tradeQuotes.tradeAccountNumber}} </td>
                            </tr>
                        </tbody>
                        <tbody id="tbodyLight">
                            <tr>
                                <td class="text-left pl-2">Account Cash Balance</td>
                                <td class="text-left pl-2"> {{formatCurrency(tradeQuotes.currentTradeCashBalance, "0,0.00")}}</td>
                            </tr>
                        </tbody>
                        <tbody id="tbodyDark">
                            <tr>
                                <td class="text-left pl-2">Sufficient Cash to Trade</td>
                                <td class="text-center py-1"><span class="mx-2" :class="'retailClient-badge status-' +
                                           tradeQuotes.hasSufficientCashBalance">{{tradeQuotes.hasSufficientCashBalance}}</span></td>
                            </tr>
                        </tbody>
                        <tbody id="tbodyLight">
                            <tr>
                                <td class="text-left pl-2">SDA Remaining ZAR Balance</td>
                                <td class="text-left pl-2"> {{formatCurrency(tradeQuotes.sdaRemainder, "0,0.00")}} </td>
                            </tr>
                        </tbody>
                         <tbody id="tbodyDark">
                            <tr>
                                <td class="text-left pl-2">Sufficient SDA Balance</td>
                                <td class="text-center py-1"><span class="mx-2" :class="'retailClient-badge status-' +
                                           tradeQuotes.hasSufficientSdaBalance">{{tradeQuotes.hasSufficientSdaBalance}}</span></td>
                            </tr>
                        </tbody>
                        <tbody v-if="tradeQuotes.hasClientTcc" id="tbodyLight">
                            <tr>
                                <td class="text-left pl-2">TCC Remaining ZAR Balance</td>
                                <td class="text-left pl-2"> {{formatCurrency(tradeQuotes.tccRemainder, "0,0.00")}} </td>
                            </tr>
                        </tbody>
                        <tbody v-if="tradeQuotes.hasClientTcc" id="tbodyDark">
                            <tr>
                                <td class="text-left pl-2">Sufficient TCC Balance</td>
                                <td class="text-center py-1"><span class="mx-2" :class="'retailClient-badge status-' +
                                           tradeQuotes.hasSufficientTccBalance">{{tradeQuotes.hasSufficientTccBalance}}</span></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
<!----------------------------------------------------------------------------------------------------------------------------------------------------------------->
<!----------------------------------------------------------------------------------------------------------------------------------------------------------------->
                <div v-else-if="tradeQuotes.tradeType === 'Cross'">
                    <table class="shadow-3">
                        <thead id="thead" >
                            <tr>
                                <th class="text-left pl-2" style="width: 50vw"></th>
                                <th class="text-left pl-2" style="width: 50vw"></th>
                            </tr>
                        </thead>
                        <tbody id="tbodyLight">
                            <tr>
                                <td class="text-left pl-2">Quote Accepted At </td>
                                <td class="text-left pl-2"> {{tradeQuotes.quoteAcceptedTime}} </td>
                            </tr>
                        </tbody>
                        <tbody id="tbodyDark">
                            <tr>
                                <td class="text-left pl-2">Trade Type </td>
                                <td class="text-left pl-2"> {{tradeQuotes.tradeType}} </td>
                            </tr>
                        </tbody>
                        <tbody id="tbodyLight">
                            <tr>
                                <td class="text-left pl-2">Buy Currency </td>
                                <td class="text-left pl-2"> {{tradeQuotes.tradeCurrency}} </td>
                            </tr>
                        </tbody>
                        <tbody id="tbodyDark">
                            <tr>
                                <td class="text-left pl-2">Sell Currency </td>
                                <td class="text-left pl-2"> {{tradeQuotes.crossCurrency}} </td>
                            </tr>
                        </tbody>
                        <tbody id="tbodyLight">
                            <tr>
                                <td class="text-left pl-2">Trade Settlement </td>
                                <td class="text-left pl-2">{{tradeQuotes.tradeTiming}} </td>
                            </tr>
                        </tbody>
                        <tbody id="tbodyDark">
                            <tr>
                                <td class="text-left pl-2">Commission Rate </td>
                                <td class="text-left pl-2"> {{formatPercentage(tradeQuotes.commissionRate, "0.00%")}}</td>
                            </tr>
                        </tbody>
                        <tbody id="tbodyLight">
                            <tr>
                                <td class="text-left pl-2">Traded Rate </td>
                                <td class="text-left pl-2"> {{tradeQuotes.actualTradedRate}} </td>
                            </tr>
                        </tbody>
                        <tbody id="tbodyDark">
                            <tr>
                                <td class="text-left pl-2">Buy {{tradeQuotes.tradeCurrency}} Value </td>
                                <td class="text-left pl-2"> {{formatCurrency(tradeQuotes.tradeValue, "0,0.00")}} </td>
                            </tr>
                        </tbody>
                        <tbody id="tbodyLight">
                            <tr>
                                <td class="text-left pl-2">Sell {{tradeQuotes.crossCurrency}} Value </td>
                                <td class="text-left pl-2"> {{formatCurrency(tradeQuotes.crossValue, "0,0.00")}}</td>
                            </tr>
                        </tbody>
                        <tbody v-if="tradeQuotes.tradeAccountNumber === ''" id="tbodyDark">
                            <tr>
                                <td class="text-left pl-2">Account Number</td>
                                <td class="text-left pl-2">No FCA Account Found</td>
                            </tr>
                        </tbody>
                        <tbody v-else id="tbodyDark">
                            <tr>
                                <td class="text-left pl-2">Account Number</td>
                                <td class="text-left pl-2"> {{tradeQuotes.tradeAccountNumber}} </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </transition>
          </template>
        </Card>
    </div>
    <transition name="fade">
        <div class="col-12 md:col-12 lg:col-6">
          <div v-if="tradeQuotes.hasChatEnabled === true">
            <Card class="shadow-5" id="chatCard" >
                  <template #content>
                      <section id="chatArea">
                      <div id="chatScroll" ref="chatArea">
                      <p v-for="(q, index) in tradeQuotes.tradeQuoteMessages" v-bind:key="index"
                          :class="{ 'messageAdmin': q.sentBy === 'isAdmin',
                                  'messagePartner': q.sentBy === 'isPartner'}">
                      <span :class="{ 'adminTimestamp': q.sentBy === 'isAdmin',
                                  'partnerTimestamp': q.sentBy === 'isPartner'}">
                                  <u>{{q.updatedAt}}</u></span>
                                  <br />
                                  {{q.message}}
                      </p>
                      </div>
                      </section>
                  </template>
              </Card>
            </div>
            <div v-else>
                <h4>&nbsp;&nbsp;Chat Not Selected</h4>
            </div>
          </div>
      </transition>
    </div>
    <div class="fluid grid" id="decisionView">
        <div class="col-4 md:col-6 lg:col-6">
            <div class="flex justify-content-left">
                <Button class="l-1 shadow-3 p-button-lg"
                icon="pi pi-step-backward"
                label="BACK&nbsp;"
                id="submitButton"
                @click.prevent="$router.back()" />
            </div>
      </div>
    </div>
  </div>
  <br />
    <div v-if='quoteDeclineSuccess'>
    <Dialog class="successModal" header="SUCCESS" v-model:visible="displayConfirmationAccept">
        <div class="confirmation-content">
            <span>Trade Quote Declined</span>
        </div>
        <template #footer>
          <div class="flex justify-content-between">
            <router-link class="p-button d-block mr-1 px-1" :to="'/quotesHome'"
                        style="background-color: #014f7e!important;
                                text-decoration: none; width: 45%; justify-content: center;">
              <i class="pi pi-step-backward" style="fontSize: 1rem"></i>&nbsp;Back
            </router-link>
            <router-link class="p-button d-block mx-1 px-1" :to="'/quotesView'"
                        style="background-color: #0185b7!important;
                                text-decoration: none; width: 45%; justify-content: center;">
              <i class="pi pi-globe" style="fontSize: 1rem"></i>&nbsp;View
            </router-link>
          </div>
        </template>
    </Dialog>
  </div>
    <div v-if='quoteAcceptSuccess'>
    <Dialog class="successModal" header="SUCCESS" v-model:visible="displayConfirmationDecline">
        <div class="confirmation-content">
            <span>Trade Quote Accepted</span>
        </div>
        <template #footer>
          <div class="flex justify-content-between">
            <router-link class="p-button d-block mr-1 px-1" :to="'/quotesHome'"
                        style="background-color: #014f7e!important;
                                text-decoration: none; width: 45%; justify-content: center;">
              <i class="pi pi-step-backward" style="fontSize: 1rem"></i>&nbsp;Back
            </router-link>
            <router-link class="p-button d-block mx-1 px-1" :to="'/quotesView'"
                        style="background-color: #0185b7!important;
                                text-decoration: none; width: 45%; justify-content: center;">
              <i class="pi pi-globe" style="fontSize: 1rem"></i>&nbsp;View
            </router-link>
          </div>
        </template>
    </Dialog>
  </div>
    <div v-if="displayDisclaimer === true">
        <Dialog class="popiModal" header="Analytics Consulting 1 Fx Rate Disclaimer" v-model:visible="displayDisclaimer" :modal="true">
            <div class="confirmation-content">
                <span>
                   <p>
                    Our FX rates are quoted using live market rates.
                    Please request an updated rate if there is a time lapse between
                    receipt of quote and acceptance.</p>
                    <p>
                      You will receive a notification after the trade has been completed.
                      The relevant documentation will be forwarded to you in due course.
                    </p>
                </span>
            </div>
            <template #footer>
            <div>
              <Button class="l-2 m-3 mx-4 shadow-4 p-button-lg"
              style="float: right"
              icon="pi pi-upload"
              label="PROCEED"
              id="backButton"
              @click.prevent="acceptToAc1" />
            </div>
            </template>
        </Dialog>
        </div>
</template>

<script>
import { getClientTradeQuote, partnerReplyToTradeQuote, partnerAcceptTradeQuote, partnerDeclineTradeQuote } from '@/gateway'
import numeral from 'numeral'

var date = new Date()
var isoDateTime = new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString().substring(0, 16).replace('T', ' ~ ')

export default {
  name: 'TradeQuoteHasCompleted',
  inheritAttrs: false,
  data () {
    return {
      tradeQuotes: [],
      newTradeQuoteMessage: {
        message: '',
        sentBy: 'isPartner',
        updatedAt: isoDateTime
      },
      quoteAcceptSuccess: false,
      quoteDeclineSuccess: false,
      displayConfirmationAccept: true,
      displayConfirmationDecline: true,
      displayDisclaimer: false
    }
  },
  methods: {
    async init () {
      const response = await getClientTradeQuote({ id: this.id })
      if (response) {
        this.tradeQuotes = response.result
      }
      await this.$nextTick()
      const messageDisplay = this.$refs.chatArea
      messageDisplay.scrollTop = messageDisplay.scrollHeight
    },
    async replyToAc1 (newMessage) {
      this.tradeQuotes.tradeQuoteMessages.push(newMessage)
      this.newTradeQuoteMessage = {
        message: '',
        sentBy: '',
        updatedAt: ''
      }
      const response = await partnerReplyToTradeQuote({
        tradeQuotes: this.tradeQuotes
      })
      if (response) {
        await this.$nextTick()
        const messageDisplay = this.$refs.chatArea
        messageDisplay.scrollTop = messageDisplay.scrollHeight
      }
    },
    async acceptToAc1 (newMessage) {
      this.displayDisclaimer = false
      const response = await partnerAcceptTradeQuote({
        tradeQuotes: this.tradeQuotes
      })
      if (response) {
        this.quoteAcceptSuccess = true
      }
    },
    async declineToAc1 (newMessage) {
      const response = await partnerDeclineTradeQuote({
        tradeQuotes: this.tradeQuotes
      })
      if (response) {
        this.quoteDeclineSuccess = true
      }
    },
    formatCurrency (val, fmt) {
      if (fmt) return numeral(val).format(fmt)
      else return numeral(val).format('0,0.00a')
    },
    formatPercentage (val, fmt) {
      if (fmt) return numeral(val / 100).format(fmt)
      else return numeral(val / 100).format('0.00%')
    },
    openDisclaimerCheck () {
      this.displayDisclaimer = true
    },
    closeDisclaimerCheck () {
      this.displayDisclaimer = false
    }
  },
  mounted () {
    this.id = this.$route.params.id
    this.init()
  }
}
</script>

<style lang="scss" scoped>
::v-deep() {
  .p-datatable .p-datatable-thead > tr > th {
    background-color: $middleBlue!important;
    color: $textColor;
    border: 1px solid $borderColour!important;
    font-size: 18px;
    font-weight: 600;
  }
  .p-datatable .p-datatable-tbody > tr > td {
    padding: .4rem .4rem;
    color: $darkTextColour;
    font-family:'Poppins', sans-serif;
    font-weight: 400;
  };
  #thead {
    background: $midLightBlue;
    height: 0vh;
    color: $textColor;
    font-size: 18px;
    font-weight: 600;
    font-family: $fontFamily;
  };
  #tbodyLight {
    height: 4vh;
    background-color: $backgroundColour;
    color: $darkTextColour;
    font-family:'Poppins', sans-serif;
    font-weight: 400;
  };
    #tbodyDark {
    height: 4vh;
    background-color: $whiteSmoke;
    color: $darkTextColour;
    font-family:'Poppins', sans-serif;
    font-weight: 400;
  };
  #replyCard {
    display: block;
    margin: auto;
    border: solid 1px!important;
    background-color: $midLightBlue!important;
    border-color: $midDarkBlue!important;
    width: 100%;
    height: auto;
    };
  #chatCard {
    display: block;
    margin: auto;
    border: solid 1px!important;
    background-color: $lightDarkBlue!important;
    border-color: $midDarkBlue!important;
    width: 98%;
    height: auto;
    };
  .retailClient-badge {
    @include statusBadge
     };
  #enableChatLabel {
    font-family: $fontFamily!important;
    color: $darkTextColour;
    margin-block: 0.90rem;
    font-size: 18px;
    font-weight: 600;
    outline: none;
    };
@media screen and (max-width: 600px) {
  #mainView {
    margin: 0rem!important;
  };
  #decisionView {
    margin-right: 0rem!important;
  };
  #tradesCard {
    display: block;
    margin-left: auto;
    margin-right: auto;
    border: solid 1px!important;
    border-color: $midDarkBlue!important;
    width: 100%
    };
  #chatArea {
    background: $backgroundColour;
    min-height: 60vh;
    border: solid 1px;
    overflow: auto;
    border-color: $borderColour;
    font-size: 16px;
    color: $darkTextColour;
    border-radius: 4px;
    padding: 0.1rem;
    margin-top: 0.4rem;
    };
    #chatArea:focus {
    outline: solid 0.5px;
    outline-color: $lightBlue;
    };
    #chatCard {
    display: block;
    margin: auto;
    overflow: auto;
    border: solid 1px!important;
    background-color: $lightDarkBlue!important;
    border-color: $midDarkBlue!important;
    width: 100%;
    min-height: 60vh;
    padding-bottom: 1rem!important;
    };
    #chatScroll {
    max-height: 60vh;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    };
    .messagePartner {
    background: $uploadBackgroundColor;
    color: $darkTextColour;
    margin-left: 10%;
    margin-right: 0.3rem;
    border-radius: 4px;
    padding: 0.4rem;
    };
    .messageAdmin {
    background: $isAdminChatBackground;
    color: $darkTextColour;
    border-radius: 4px;
    padding: .6rem;
    width: 90%;
    };
    .adminTimestamp {
    color: $blackTextColor;
    font-size: 11px;
    padding: 0.2rem;
    };
    .partnerTimestamp {
    color: $blackTextColor;
    font-size: 11px;
    padding: 0.2rem;
    margin-left: 58%;
    };
    #textInput {
    width: 50vw!important;
    };
    #querySendButton {
    min-height: 3.75rem!important;
    background: $light!important;
    color: $lightDarkBlue!important;
    font-size: 20px;
    border: solid 1px!important;
    border-color: $midDarkBlue!important;
    };
    #acceptButton {
    background-color: $successGreen!important;
    border: 1px solid!important;
    font-size: 16px;
    border-color: $darkTextColour!important;
    padding: 0.5rem 0.5rem;
    margin-right: 0.15rem;
    };
    #declineButton {
    background-color: $queuedBackground!important;
    border: 1px solid!important;
    border-color: $darkTextColour!important;
    font-size: 16px;
    padding: 0.5rem 0.5rem;
    margin-right: 0.25rem;
    };
    #quoteFooter {
    margin-top: 0.5rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    };
  };
 @media screen and (min-width:601px) and (max-width:991px) {
   #mainView {
    margin: 0.25rem!important;
    };
   #decisionView {
    margin: 0.25rem!important;
   };
   #tradesCard {
    display: block;
    margin-left: auto;
    margin-right: auto;
    border: solid 1px!important;
    border-color: $midDarkBlue!important;
    width: 95%
    };
  #chatArea {
    background: $backgroundColour;
    min-height: 48vw;
    border: solid 1px;
    overflow: auto;
    border-color: $borderColour;
    font-size: 16px;
    color: $darkTextColour;
    border-radius: 4px;
    padding: 0.15rem;
    };
  #chatArea:focus {
    outline: solid 0.5px;
    outline-color: $lightBlue;
    };
    #chatCard {
    display: block;
    margin: auto;
    overflow: auto;
    border: solid 1px!important;
    background-color: $lightDarkBlue!important;
    border-color: $midDarkBlue!important;
    width: 100%;
    min-height: 48vw;
    padding-bottom: 0.5rem!important;
    };
    #chatScroll {
    max-height: 48vw;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    };
    .messagePartner {
    background: $uploadBackgroundColor;
    color: $darkTextColour;
    margin-left: 10%;
    margin-right: 0.4rem;
    border-radius: 4px;
    padding: 0.8rem;
    };
    .messageAdmin {
    background: $isAdminChatBackground;
    color: $darkTextColour;
    border-radius: 4px;
    padding: .6rem;
    width: 90%;
    };
    .adminTimestamp {
    color: $blackTextColor;
    font-size: 11px;
    padding: 0.2rem;
    };
    .partnerTimestamp {
    color: $blackTextColor;
    font-size: 11px;
    padding: 0.2rem;
    margin-left: 80%;
    };
    #textInput {
    width: 70vw!important;
    };
    #querySendButton {
    min-height: 3.75rem!important;
    background: $light!important;
    color: $lightDarkBlue!important;
    font-size: 20px;
    border: solid 1px!important;
    border-color: $midDarkBlue!important;
    };
    #acceptButton {
    background-color: $successGreen!important;
    border: 1px solid!important;
    border-color: $darkTextColour!important;
    padding: 0.5rem 0.7rem;
    float: right;
    };
    #declineButton {
    background-color: $queuedBackground!important;
    border: 1px solid!important;
    border-color: $darkTextColour!important;
    padding: 0.5rem 0.7rem;
    float: left;
    };
    #quoteFooter {
    margin-top: 0.25rem;
    };
  };
  @media screen and (min-width: 992px) and (max-width:1080px) {
  #mainView {
    margin: 0.25rem!important;
   };
  #decisionView {
    margin: 0.25rem!important;
  };
   #tradesCard {
    display: block;
    margin-left: auto;
    margin-right: auto;
    border: solid 1px!important;
    border-color: $midDarkBlue!important;
    width: 100%
    };
  #chatArea {
    background: $backgroundColour;
    min-height: 58vh;
    border: solid 1px;
    overflow: auto;
    border-color: $borderColour;
    font-size: 16px;
    color: $darkTextColour;
    border-radius: 4px;
    padding: 0.2rem;
    margin-top: 0.4rem;
    };
  #chatArea:focus {
    outline: solid 0.5px;
    outline-color: $lightBlue;
    };
    #chatCard {
    display: block;
    margin: auto;
    overflow: auto;
    border: solid 1px!important;
    background-color: $lightDarkBlue!important;
    border-color: $midDarkBlue!important;
    width: 100%;
    min-height: 58vh;
    padding-bottom: 1rem!important;
    };
    #chatScroll {
    max-height: 58vh;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    };
    .messagePartner {
    background: $uploadBackgroundColor;
    color: $darkTextColour;
    margin-left: 10%;
    margin-right: 0.3rem;
    border-radius: 4px;
    padding: 0.4rem;
    };
    .messageAdmin {
    background: $isAdminChatBackground;
    color: $darkTextColour;
    border-radius: 4px;
    padding: .6rem;
    width: 90%;
    };
    .adminTimestamp {
    color: $blackTextColor;
    font-size: 11px;
    padding: 0.2rem;
    };
    .partnerTimestamp {
    color: $blackTextColor;
    font-size: 11px;
    padding: 0.2rem;
    margin-left: 58%;
    };
    #textInput {
    width: 70vw!important;
    };
    #querySendButton {
    min-height: 3.75rem!important;
    background: $light!important;
    color: $lightDarkBlue!important;
    font-size: 20px;
    border: solid 1px!important;
    border-color: $midDarkBlue!important;
    };
    #acceptButton {
    background-color: $successGreen!important;
    border: 1px solid!important;
    border-color: $darkTextColour!important;
    padding: 0.5rem 0.7rem;
    float: right;
    };
    #declineButton {
    background-color: $queuedBackground!important;
    border: 1px solid!important;
    border-color: $darkTextColour!important;
    padding: 0.5rem 0.7rem;
    float: left;
    };
    #quoteFooter {
    margin-top: 0.5rem;
    };
 };
@media screen and (min-width: 1081px)  and (max-width: 1366px) {
    #mainView {
    margin: 0.5rem!important;
    };
    #decisionView {
    margin: 0.5rem!important;
    };
    #tradesCard {
    display: block;
    margin-left: 2.5rem;
    margin-right: auto;
    border: solid 1px!important;
    border-color: $midDarkBlue!important;
    };
    #chatArea {
    background: $backgroundColour;
    min-height: 53vh;
    border: solid 1px;
    overflow: auto;
    border-color: $borderColour;
    font-size: 16px;
    color: $darkTextColour;
    border-radius: 4px;
    padding: 0.2rem;
    };
    #chatArea:focus {
    outline: solid 0.5px;
    outline-color: $lightBlue;
    };
    #chatCard {
    display: block;
    margin: auto;
    overflow: auto;
    border: solid 1px!important;
    background-color: $lightDarkBlue!important;
    border-color: $midDarkBlue!important;
    width: 98%;
    min-height: 53vh;
    };
    #chatScroll {
    max-height: 53vh;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    };
    .messagePartner {
    background: $uploadBackgroundColor;
    color: $darkTextColour;
    margin-left: 10%;
    margin-right: 0.4rem;
    border-radius: 4px;
    padding: .4rem;
    };
    .messageAdmin {
    background: $isAdminChatBackground;
    color: $darkTextColour;
    border-radius: 4px;
    padding: .4rem;
    width: 90%;
    };
    .adminTimestamp {
    color: $blackTextColor;
    font-size: 11px;
    padding: 0.2rem;
    };
    .partnerTimestamp {
    color: $blackTextColor;
    font-size: 11px;
    padding: 0.2rem;
    margin-left: 75%;
    };
    #textInput {
    width: 32vw!important;
    };
    #querySendButton {
    min-height: 3.75rem!important;
    background: $light!important;
    color: $lightDarkBlue!important;
    font-size: 20px;
    border: solid 1px!important;
    border-color: $midDarkBlue!important;
    };
    #acceptButton {
    background-color: $successGreen!important;
    border: 1px solid!important;
    border-color: $darkTextColour!important;
    padding: 0.5rem 0.7rem;
    margin-right: 0.5rem!important;
    };
    #declineButton {
    background-color: $queuedBackground!important;
    border: 1px solid!important;
    border-color: $darkTextColour!important;
    padding: 0.5rem 0.7rem;
    margin-left: 0.25rem!important;
    };
  };
@media screen and (min-width: 1367px) {
  #mainView {
    margin: 0.75rem!important;
    };
  #decisionView {
    margin: 0.75rem!important;
    };
  #tradesCard {
    display: block;
    margin-left: 2.5rem;
    margin-right: auto;
    border: solid 1px!important;
    border-color: $midDarkBlue!important;
    };
  #chatArea {
    background: $backgroundColour;
    min-height: 51vh;
    border: solid 1px;
    overflow: auto;
    border-color: $borderColour;
    font-size: 16px;
    color: $darkTextColour;
    border-radius: 4px;
    padding: 0.2rem;
    };
  #chatArea:focus {
    outline: solid 0.5px;
    outline-color: $lightBlue;
    };
  #chatCard {
    display: block;
    margin: auto;
    overflow: auto;
    border: solid 1px!important;
    background-color: $lightDarkBlue!important;
    border-color: $midDarkBlue!important;
    width: 98%;
    min-height: 51vh;
    };
    #chatScroll {
    max-height: 51vh;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    };
    .messagePartner {
    background: $uploadBackgroundColor;
    color: $darkTextColour;
    margin-left: 15%;
    margin-right: 0.4rem;
    border-radius: 4px;
    padding: .4rem;
    };
    .messageAdmin {
    background: $isAdminChatBackground;
    color: $darkTextColour;
    border-radius: 4px;
    padding: .4rem;
    width: 85%;
    };
    .adminTimestamp {
    color: $blackTextColor;
    font-size: 11px;
    padding: 0.2rem;
    };
    .partnerTimestamp {
    color: $blackTextColor;
    font-size: 11px;
    padding: 0.2rem;
    margin-left: 82%;
    };
    #textInput {
    width: 37vw!important;
    };
    #querySendButton {
    min-height: 3.75rem!important;
    background: $light!important;
    color: $lightDarkBlue!important;
    font-size: 20px;
    border: solid 1px!important;
    border-color: $midDarkBlue!important;
    };
    #acceptButton {
    background-color: $successGreen!important;
    border: 1px solid!important;
    border-color: $darkTextColour!important;
    padding: 0.5rem 0.7rem;
    margin-right: 0.5rem!important;
    };
    #declineButton {
    background-color: $queuedBackground!important;
    border: 1px solid!important;
    border-color: $darkTextColour!important;
    padding: 0.5rem 0.7rem;
    margin-left: 0.5rem!important;
    };
  };
};
.p-grid {
  margin: 1rem;
  padding: 1rem;
};
</style>
