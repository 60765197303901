<template>
  <div style="width: 100%; height: 100%; padding: 50px 0 0 0; overflow-y: scroll; display: flex; flex-direction: column; align-items: center; justify-items: center;">
    <div class="chart-container">
      <Chart
        type="bar"
        title="ZAR Nominal per Month"
        :show-legend="true"
        y-axis-title="ZAR"
        :data="znpm"
      />
    </div>
    <div class="chart-container">
      <Chart
        type="bar"
        title="ZAR Nominal per Year"
        :show-legend="false"
        y-axis-title="ZAR"
        :data="znpy"
      />
    </div>
  </div>
</template>

<script setup>
import { store } from '@/store'
import { computed } from 'vue'
import Chart from '@/components/Chart.vue'

// const props = defineProps({
//   zarNominalPerMonth: Array,
//   zarNominalPerYear: Array,
//   zarNominalThreeMonthMovingAverage: Array
// })

const znpm = computed(() => {
  return {
    labels: store.getters.zarNominalPerMonth.map(x => new Date(x.date).toLocaleDateString('en-GB', { month: 'short', year: 'numeric' })),
    datasets: [
      {
        type: 'line',
        label: '3 month - MA',
        borderColor: '#FF0000',
        backgroundColor: '#FF0000',
        borderWidth: 2,
        fill: false,
        data: store.getters.zarNominalThreeMonthMovingAverage.map(x => x.value)
      },
      {
        type: 'bar',
        label: 'ZAR Nominal',
        backgroundColor: '#0185b7',
        data: store.getters.zarNominalPerMonth.map(x => x.value)
      }
    ]
  }
})
const znpy = computed(() => {
  return {
    labels: store.getters.zarNominalPerYear.map(x => new Date(x.date).toLocaleDateString('en-GB', { month: 'short', year: 'numeric' })),
    datasets: [
      {
        label: 'ZAR Nominal',
        backgroundColor: '#0185b7',
        data: store.getters.zarNominalPerYear.map(x => x.value)
      }
    ]
  }
})
</script>

<script>
export default {
  name: 'ZarFlows',
  inheritAttrs: false
}
</script>

<style scoped>
.chart-container {
  margin-bottom: 150px;
  width: 1220px;
  height: 560px;
}
</style>
