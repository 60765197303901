<template>
  <br />
  <h2 class="text-center my-2">PARTNER CLIENTS</h2>
  <br />
  <div v-if="displayEmptyClients == false">
  <div>
    <Button icon="pi pi-info" class="p-button-rounded p-button-lg mx-4 shadow-5" @click="toggle" />
    <OverlayPanel ref="op" appendTo="body" :showCloseIcon="true" id="overlay_panel" style="width: 20rem" :breakpoints="{'960px': '75vw'}">
      <p>Use the <i class="pi pi-sort-alt"></i> to filter results.</p>
      <p>Use <i class="pi pi-sort-amount-up-alt"></i> or <i class="pi pi-sort-amount-down-alt"></i> to change the sort order.</p>
      <p>To filter by multiple columns use Ctrl + Click on the second filter column.</p>
      <p>Use the <i class="pi pi-filter"></i> to search for a specific client. You can search on multiple columns.</p>
      </OverlayPanel>
  </div>
  <br />
    <div class="fluid mx-3 shadow-2">
        <DataTable :value="retailClient"
                    dataKey="id" :rowHover="true"
                    stripedRows responsiveLayout="stack"
                    showGridlines
                    breakpoint="1024px"
                    :paginator="true"
                    :rows="8"
                    v-model:filters="filters1"
                    filterDisplay="menu"
                    class="datatable-retailClient">
            <Column field="id" header="ID" headerStyle="width: 1vw"></Column>
            <Column field="partnerName" header="Partner" headerStyle="width: 6vw"></Column>
            <Column field="firstLastName" header="Client Name" :sortable="true" headerStyle="width: 9vw">
              <template #filter="{filterModel}">
                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by Name"/>
              </template>
            </Column>
            <Column field="emailAddress" header="Email" headerStyle="width: 11vw"></Column>
            <Column field="idNumber" header="ID Number" headerStyle="width: 6vw">
              <template #filter="{filterModel}">
                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by ID No"/>
              </template>
            </Column>
            <Column field="contactNumber1" header="Contact" headerStyle="width: 6vw"></Column>
            <Column field="status" header="Status" :sortable="true" headerStyle="width: 5vw">
            <template #body="{data}">
                <span :class="'retailClient-badge status-' + data.status">{{data.status}}</span>
            </template>
            </Column>
            <Column header="Profile" headerStyle="width: 5vw;">
                <template #body="{data}">
                <div v-if="data.status === 'queued'">
                <a class="p-button d-block mx-1 px-1 shadow-1" id="disabled" :disabled="true"
                    style="background-colour:#E6E6F0; color: #045889; text-decoration: none;
                            display: flex; justify-content: center;">
                    <i class="pi pi-user" style="fontSize: 1rem"></i>&nbsp;&nbsp;PROFILE
                </a>
                </div>
                <div v-else>
                <router-link class="p-button d-block mx-1 px-1 shadow-1" :to="'/partnersClientView/' + data.id"
                                style="color: #E6E6F0; text-decoration: none;
                                    display: flex; justify-content: center;">
                    <i class="pi pi-user" style="fontSize: 1rem"></i>&nbsp;&nbsp;PROFILE
                </router-link>
                </div>
                </template>
            </Column>
        </DataTable>
     </div>
  </div>
   <div v-else>
    <h3 class="text-center my-2">NO CLIENTS FOUND FOR THIS PARTNER</h3>
   </div>
  <div>
    <Button class="l-2 m-4 mx-3 shadow-4 p-button-lg"
    icon="pi pi-step-backward"
    label="BACK&nbsp;"
    id="submitButton"
    @click.prevent="$router.back()" />
  </div>
</template>

<script>
import { getPartnerRetailClients } from '@/gateway'
import { FilterMatchMode, FilterOperator } from 'primevue/api'

export default {
  name: 'PartnerClientListView',
  inheritAttrs: false,
  data () {
    return {
      retailClient: [],
      filters1: null,
      displayEmptyClients: false
    }
  },
  methods: {
    async init () {
      const response = await getPartnerRetailClients({ id: this.id })
      if (response.result.length !== 0) {
        this.retailClient = response.result
      } else {
        this.displayEmptyClients = true
      }
    },
    initFilters1 () {
      this.filters1 = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        firstLastName: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
        idNumber: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] }
      }
    },
    toggle (event) {
      this.$refs.op.toggle(event)
    }
  },
  created () {
    this.initFilters1()
  },
  mounted () {
    this.id = this.$route.params.id
    this.init()
  }
}
</script>

<style lang="scss" scoped>
::v-deep() {
  .p-datatable .p-datatable-thead > tr > th {
    background-color: $middleBlue!important;
    color: $textColor;
    border: 1px solid $darkTextColour!important;
    font-size: 18px;
    font-weight: 600;
  }
  .p-datatable .p-datatable-tbody > tr > td {
    padding: .4rem .4rem;
    color: $darkTextColour;
    font-family:'Poppins', sans-serif;
    font-weight: 400;
  }
  .p-paginator-current {
        margin-right: auto;
    }
  .retailClient-badge {
      @include statusBadge
  };
  #disabled {
    background: $textColor!important;
    color: $midddleBlueDisabled!important;
  };
  #searchtext {
    margin-left: 1rem!important;
    min-width: 40vw;
  };
};
</style>
