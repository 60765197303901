<template>
<br />
    <h2 class="text-center my-2">ALL CLIENTS TCC VIEW</h2>
<br />
  <div>
    <Button icon="pi pi-info" class="p-button-rounded p-button-lg mx-4 shadow-5" @click="toggle" />
    <OverlayPanel ref="op" appendTo="body" :showCloseIcon="true" id="overlay_panel" style="width: 20rem" :breakpoints="{'960px': '75vw'}">
      <p>Use the <i class="pi pi-sort-alt"></i> to filter results.</p>
      <p>Use <i class="pi pi-sort-amount-up-alt"></i> or <i class="pi pi-sort-amount-down-alt"></i> to change the sort order.</p>
      <p>To filter by multiple columns use Ctrl + Click on the second filter column.</p>
      <p>Use the <i class="pi pi-filter"></i> to search for a specific client. You can search on multiple columns.</p>
      </OverlayPanel>
  </div>
  <br />
    <div class="fluid mx-3 shadow-2">
        <DataTable :value="clientTcc"
                    dataKey="id" :rowHover="true"
                    stripedRows responsiveLayout="stack"
                    sortMode="multiple"
                    showGridlines
                    breakpoint="1024px"
                    :paginator="true"
                    :rows="8"
                    v-model:filters="filters1"
                    filterDisplay="menu"
                    class="p-datatable-retailClient">
            <Column field="partnerName" header="Partner" :sortable="true" headerStyle="width: 3vw"></Column>
            <Column field="firstLastName" header="Name" :sortable="true" headerStyle="width: 5vw">
                    <template #filter="{filterModel}">
                        <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by Name"/>
                    </template>
            </Column>
            <Column field="tccValue" header="Value (ZAR)" headerStyle="width: 4vw">
                    <template #body="{data}">
                        {{formatCurrency(data.tccValue)}}
                    </template></Column>
            <Column field="tccPin" header="Pin" headerStyle="width: 3vw"></Column>
            <Column field="tccIssuer" header="Issuer" headerStyle="width: 3vw"></Column>
            <Column field="issueDate" header="Issue Date" :sortable="true" headerStyle="width: 4vw">
                    <template #filter="{filterModel}">
                        <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by Date"/>
                    </template></Column>
            <Column field="expiryDate" header="Expiry Date" :sortable="true" headerStyle="width: 4vw">
                    <template #filter="{filterModel}">
                        <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by Date"/>
                    </template>
            </Column>
            <Column field="applicationStatus" header="Status" headerStyle="width: 4vw" :filterMenuStyle="{'width':'14rem'}" style="min-width:4vw">
                    <template #body="{data}">
                        <span :class="'retailClient-badge status-' + data.applicationStatus">{{data.applicationStatus}}</span>
                    </template>
                    <template #filter="{filterModel}">
                        <Dropdown v-model="filterModel.value" :options="statuses" placeholder="Select Status" class="p-column-filter" :showClear="true">
                        </Dropdown>
                    </template>
            </Column>
            <Column header="Edit" headerStyle="width: 4vw;">
                <template #body="{data}">
                <div v-if="data.applicationStatus == 'processing'">
                    <router-link class="p-button-raised d-block mx-auto px-auto" disabled="disabled" :to="'/ClientView/id'" style="color: #e2e7e5; text-decoration: none;
                                        display: flex; justify-content: center;">
                    <i class="pi pi-user-edit" style="fontSize: 1rem"></i>&nbsp;&nbsp;EDIT
                    </router-link>
                </div>
                <div v-else-if="data.applicationStatus != 'In-Process'">
                    <router-link class="p-button d-block mx-auto px-auto" :to="'/manage/' + data.id"  style="color: #e2e7e5; text-decoration: none;
                                        display: flex; justify-content: center;">
                    <i class="pi pi-user-edit" style="fontSize: 1rem"></i>&nbsp;&nbsp;EDIT
                    </router-link>
                </div>
                </template>
            </Column>
        </DataTable>
    </div>
  <br />
  <div class="flex justify-content-between ">
        <div>
          <Button class="l-2 m-3 mx-3 shadow-3 p-button-lg"
          icon="pi pi-step-backward"
          label="BACK&nbsp;"
          id="submitButton"
          @click.prevent="$router.back()" />
      </div>
      <div>
          <Button class="l-2 m-3 mx-3 shadow-3 p-button-lg "
          icon="pi pi-file-excel"
          label="DOWNLOAD&nbsp;"
          id="excelButton"
          :loading="loadingExcel"
          @click.prevent="downloadTccExcel" />
      </div>
  </div>
</template>

<script>
import { getAllTccs, downloadClientTccToExcel } from '@/gateway'
import { FilterMatchMode, FilterOperator } from 'primevue/api'

export default {
  name: 'AllTccView',
  inheritAttrs: false,
  data () {
    return {
      clientTcc: [],
      clientTccUpdateSuccess: false,
      displayConfirmation: true,
      displayEmptyTcc: false,
      filters1: null,
      loadingExcel: false,
      statuses: [
        'processing',
        'with_Client',
        'with_Issuer',
        'with_Sars',
        'issued',
        'complete',
        'expired',
        'non_Compliant'
      ]
    }
  },
  methods: {
    async init () {
      const response = await getAllTccs()
      if (response.result.length !== 0) {
        this.clientTcc = response.result
      } else {
        this.displayEmptyTcc = true
      }
    },
    formatCurrency (value) {
      return value.toLocaleString('en-US', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 })
    },
    initFilters1 () {
      this.filters1 = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        firstLastName: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
        expiryDate: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
        issueDate: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
        applicationStatus: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] }
      }
    },
    toggle (event) {
      this.$refs.op.toggle(event)
    },
    async downloadTccExcel () {
      this.loadingExcel = true
      const response = await downloadClientTccToExcel()
      if (response) {
        var byteArray = this.base64ToArrayBuffer(response.data)
        const url = window.URL.createObjectURL(
          new Blob([byteArray], { type: 'application/octet-stream' })
        )
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', response.fileName)
        document.body.appendChild(link)
        this.loadingExcel = false
        link.click()
      }
    },
    base64ToArrayBuffer (base64) {
      var binaryString = window.atob(base64)
      var binaryLen = binaryString.length
      var bytes = new Uint8Array(binaryLen)
      for (var i = 0; i < binaryLen; i++) {
        var ascii = binaryString.charCodeAt(i)
        bytes[i] = ascii
      }
      return bytes
    }
  },
  created () {
    this.initFilters1()
  },
  mounted () {
    this.init()
  }
}
</script>

<style lang="scss" scoped>
::v-deep() {
  .p-datatable .p-datatable-thead > tr > th {
    background-color: $middleBlue!important;
    color: $textColor;
    border: 1px solid $darkTextColour!important;
    font-size: 18px;
    font-weight: 600;
  }
  .p-datatable .p-datatable-tbody > tr > td {
    padding: .4rem .4rem;
    color: $darkTextColour;
    font-family:'Poppins', sans-serif;
    font-weight: 400;
  }
  .p-paginator-current {
        margin-right: auto;
  }
    .retailClient-badge {
      @include statusBadge
  };
};
</style>
