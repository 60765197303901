<template>
  <br />
  <h2 class="text-center m-2">UPLOAD FICA DOCUMENTS FOR: {{ fica.clientName }}</h2>
  <br />
  <div class="fluid mx-3">
    <div class="formgrid grid mx-1">
        <div class="col-12 md:col-6 lg:col-6">
          <h5 class="mx-1"><b>Select FICA Upload Type</b></h5>
              <Dropdown v-model="ficaType"
              :options="ficaTypes"
              optionLabel="text"
              optionValue="value"
              :showClear="true"
              appendTo="self"
              class="inputfield w-full"
              placeholder="Please Select" />
        </div>
    </div>
    <br />
    <div class="formgrid grid">
    <transition name="fadeinstant">
      <div v-if="ficaType === 'Id'" class="col-12 md:col-12 lg:col-6">
        <h5 class="mx-2"><b>Upload Identity Document/Passport</b></h5>
            <div class="flex justify-content-between mx-1 shadow-3" id="uploadBackground">
                <button class="file_upload" type="button">
                  <span class="btn_lbl"><i class="pi pi-file"></i>&nbsp;SELECT</span>
                  <span class="btn_colorlayer"></span>
                  <input type="file" name="fileupload" id="file_upload" accept="application/pdf, application/msword, image/*" @change="onFileChangedIdentityDocument" />
                </button>
              <Button
                  id="uploadButton"
                  icon="pi pi-upload"
                  @click="onFileUpload"
                  label="UPLOAD&nbsp;"/>
          </div>
      </div>
    </transition>
    <transition name="fadeinstant">
      <div v-if="ficaType === 'Por'" class="col-12 md:col-12 lg:col-6">
        <h5 class="mx-2"><b>Upload Proof of Residence</b></h5>
            <div class="flex justify-content-between mx-1 shadow-3" id="uploadBackground">
                <button class="file_upload" type="button">
                  <span class="btn_lbl"><i class="pi pi-file"></i>&nbsp;SELECT</span>
                  <span class="btn_colorlayer"></span>
                  <input type="file" name="fileupload" id="file_upload" accept="application/pdf, application/msword, image/*" @change="onFileChangedProofOfResidence" />
                </button>
              <Button
                  id="uploadButton"
                  icon="pi pi-upload"
                  @click="onFileUpload"
                  label="UPLOAD&nbsp;"/>
          </div>
      </div>
    </transition>
    <transition name="fadeinstant">
      <div v-if="ficaType === 'Poa'" class="col-12 md:col-12 lg:col-6">
        <h5 class="mx-2"><b>Upload Power of Attourney</b></h5>
            <div class="flex justify-content-between mx-1 shadow-3" id="uploadBackground">
                <button class="file_upload" type="button">
                  <span class="btn_lbl"><i class="pi pi-file"></i>&nbsp;SELECT</span>
                  <span class="btn_colorlayer"></span>
                  <input type="file" name="fileupload" id="file_upload" accept="application/pdf, application/msword, image/*" @change="onFileChangedPowerOfAttorney" />
                </button>
              <Button
                  id="uploadButton"
                  icon="pi pi-upload"
                  @click="onFileUpload"
                  label="UPLOAD&nbsp;"/>
          </div>
      </div>
    </transition>
    <transition name="fadeinstant">
      <div v-if="ficaType === 'Mc'" class="col-12 md:col-12 lg:col-6">
        <h5 class="mx-2"><b>Upload Marriage Certificate</b></h5>
            <div class="flex justify-content-between mx-1 shadow-3" id="uploadBackground">
                <button class="file_upload" type="button">
                  <span class="btn_lbl"><i class="pi pi-file"></i>&nbsp;SELECT</span>
                  <span class="btn_colorlayer"></span>
                  <input type="file" name="fileupload" id="file_upload" accept="application/pdf, application/msword, image/*" @change="onFileChangedMarriageCertificate" />
                </button>
              <Button
                  id="uploadButton"
                  icon="pi pi-upload"
                  @click="onFileUpload"
                  label="UPLOAD&nbsp;"/>
          </div>
      </div>
    </transition>
    <transition name="fadeinstant">
      <div v-if="ficaType === 'Image'" class="col-12 md:col-12 lg:col-6">
        <h5 class="mx-2"><b>Upload Client Image</b></h5>
            <div class="flex justify-content-between mx-1 shadow-3" id="uploadBackground">
                <button class="file_upload" type="button">
                  <span class="btn_lbl"><i class="pi pi-file"></i>&nbsp;SELECT</span>
                  <span class="btn_colorlayer"></span>
                  <input type="file" name="fileupload" id="file_upload" accept="application/pdf, application/msword, image/*" @change="onFileChangedClientImage" />
                </button>
              <Button
                  id="uploadButton"
                  icon="pi pi-upload"
                  @click="onFileUpload"
                  label="UPLOAD&nbsp;"/>
          </div>
      </div>
    </transition>
    <transition name="fadeinstant">
      <div v-if="ficaType === 'Misc'" class="col-12 md:col-12 lg:col-6">
        <h5 class="mx-2"><b>Upload Miscellaneous Document </b></h5>
            <div class="flex justify-content-between mx-1 shadow-3" id="uploadBackground">
                <button class="file_upload" type="button">
                  <span class="btn_lbl"><i class="pi pi-file"></i>&nbsp;SELECT</span>
                  <span class="btn_colorlayer"></span>
                  <input type="file" name="fileupload" id="file_upload" accept="application/pdf, application/msword, image/*" @change="onFileChangedMiscellaneousOne" />
                </button>
              <Button
                  id="uploadButton"
                  icon="pi pi-upload"
                  @click="onFileUpload"
                  label="UPLOAD&nbsp;"/>
          </div>
      </div>
    </transition>
    </div>
    <br />
    <br />
    <hr class="mx-3 shadow-2"/>
    <div class="formgrid grid">
        <div class="col-12 md:col-12 lg:col-6">
            <h5 class="flex justify-content-between"><span class="mt-1 ml-2">Identity Document/Passport Upload</span>
              <div v-if="fica.hasIdentityDocumentFile" class="flex justify-content-between mx-1">
                <span class="mt-1 mr-2">File Uploaded On: {{ fica.identityDocumentFileUploadedDate}}</span>
                <i class="pi pi-check-circle mx-3" id="fileCheck"></i>
                  </div>
                <div v-else class="flex justify-content-between mx-1">
                  <span class="mt-1 mr-2">No File Uploaded</span>
                  <i class="pi pi-times-circle mx-3" id="fileCross"></i>
                </div>
            </h5>
        </div>
        <div class="col-12 md:col-12 lg:col-6">
            <h5 class="flex justify-content-between mx-2"><span class="mt-1 ml-2">Proof of Residence Upload</span>
              <div v-if="fica.hasProofOfResidenceFile" class="flex justify-content-between mx-1">
                <span class="mt-1 mr-2">File Uploaded On: {{ fica.proofOfResidenceFileUploadedDate}}</span>
                <i class="pi pi-check-circle mx-3" id="fileCheck"></i>
                  </div>
                <div v-else class="flex justify-content-between mx-1">
                  <span class="mt-1 mr-2">No File Uploaded</span>
                  <i class="pi pi-times-circle mx-3" id="fileCross"></i>
                </div>
            </h5>
        </div>
    </div>
    <hr class="mx-3 shadow-2"/>
    <div class="formgrid grid">
        <div class="col-12 md:col-12 lg:col-6">
            <h5 class="flex justify-content-between"><span class="mt-1 ml-2">Power of Attourney Upload</span>
              <div v-if="fica.hasPowerOfAttorneyFile" class="flex justify-content-between mx-1">
                <span class="mt-1 mr-2">File Uploaded On: {{ fica.powerOfAttorneyFileUploadedDate}}</span>
                <i class="pi pi-check-circle mx-3" id="fileCheck"></i>
                  </div>
                <div v-else class="flex justify-content-between mx-1">
                  <span class="mt-1 mr-2">No File Uploaded</span>
                  <i class="pi pi-times-circle mx-3" id="fileCross"></i>
                </div>
            </h5>
        </div>
        <div class="col-12 md:col-12 lg:col-6">
            <h5 class="flex justify-content-between mx-2"><span class="mt-1 ml-2">Marriage Certificate Upload</span>
              <div v-if="fica.hasMarriageCertificateFile" class="flex justify-content-between mx-1">
                <span class="mt-1 mr-2">File Uploaded On: {{ fica.marriageCertificateFileUploadedDate}}</span>
                <i class="pi pi-check-circle mx-3" id="fileCheck"></i>
                  </div>
                <div v-else class="flex justify-content-between mx-1">
                  <span class="mt-1 mr-2">No File Uploaded</span>
                  <i class="pi pi-times-circle mx-3" id="fileCross"></i>
                </div>
            </h5>
        </div>
    </div>
    <hr class="mx-3 shadow-2"/>
    <div class="formgrid grid">
        <div class="col-12 md:col-12 lg:col-6">
            <h5 class="flex justify-content-between"><span class="mt-1 ml-2">Client Image Upload</span>
              <div v-if="fica.hasClientImageFile" class="flex justify-content-between mx-1">
                <span class="mt-1 mr-2">File Uploaded On: {{ fica.clientImageFileUploadedDate}}</span>
                <i class="pi pi-check-circle mx-3" id="fileCheck"></i>
                  </div>
                <div v-else class="flex justify-content-between mx-1">
                  <span class="mt-1 mr-2">No File Uploaded</span>
                  <i class="pi pi-times-circle mx-3" id="fileCross"></i>
                </div>
            </h5>
        </div>
        <div class="col-12 md:col-12 lg:col-6">
            <h5 class="flex justify-content-between mx-2"><span class="mt-1 ml-2">Miscellaneous File Upload</span>
              <div v-if="fica.hasMiscellaneousOneFile" class="flex justify-content-between mx-1">
                <span class="mt-1 mr-2">File Uploaded On: {{ fica.miscellaneousOneFileUploadedDate}}</span>
                <i class="pi pi-check-circle mx-3" id="fileCheck"></i>
                  </div>
                <div v-else class="flex justify-content-between mx-1">
                  <span class="mt-1 mr-2">No File Uploaded</span>
                  <i class="pi pi-times-circle mx-3" id="fileCross"></i>
                </div>
            </h5>
        </div>
    </div>
    <hr class="mx-3 shadow-2"/>
  </div>
    <div v-if="formatErrorModal">
      <Dialog class="deleteModal" header="INVALID FILE FORMAT" v-model:visible="displayConfirmation">
          <div class="confirmation-content">
              <span>This file format is not accepted.</span>
              <br />
                    <p>Valid formats include:</p>
                    <p>.pdf, .docx, .doc, .jpg, .jpeg,</p>
                    <p>.jfif, .png, .webp, .heif, .heic and .avif</p>
          </div>
          <template #footer>
              <a class="p-button d-block mr-2 px-auto" @click.prevent="reloadPage"
                style="float: left; background-color: #014f7e!important;
                      text-decoration: none; justify-content: center;">
                <i class="pi pi-upload" style="fontSize: 1rem"></i> &nbsp;&nbsp;Upload Again
              </a>
          </template>
      </Dialog>
    </div>
      <div class="flex justify-content-between mx-2">
        <div>
            <Button class="l-2 m-3 mx-4 shadow-4 p-button-lg"
            icon="pi pi-step-backward"
            label="BACK&nbsp;"
            id="submitButton"
            @click.prevent="$router.push( {name: 'FicaHome', params: { id: fica.clientId }})" />
        </div>
          <div>
            <Button class="l-2 m-3 mx-4 shadow-4 p-button-lg "
            icon="pi pi-download"
            label="DOWNLOADS"
            id="backButton"
            @click.prevent="$router.push( {name: 'FicaDownloads', params: { id: fica.clientId }})" />
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import { getClientFica, uploadFicaFile } from '@/gateway'

export default {
  name: 'FicaUploadsUpdate',
  inheritAttrs: false,
  data () {
    return {
      displayConfirmation: true,
      fica: {
        miscellaneousOneFileDisplayName: ''
      },
      selectedFileIdentityDocument: null,
      selectedFileProofOfResidence: null,
      selectedFilePowerOfAttorney: null,
      selectedFileMarriageCertificate: null,
      selectedFileClientImage: null,
      selectedFileMiscellaneousOne: null,
      ficaType: 'Id',
      ficaTypes: [
        { value: 'Id', text: 'Identity Document/Passport' },
        { value: 'Por', text: 'Proof of Residence' },
        { value: 'Poa', text: 'Power of Attourney' },
        { value: 'Mc', text: 'Marriage Certificate' },
        { value: 'Image', text: 'Client Image' },
        { value: 'Misc', text: 'Miscellaneous' }
      ],
      formatErrorModal: false
    }
  },
  methods: {
    async init () {
      const response = await getClientFica({ id: this.id })
      if (response) {
        this.fica = response.result
      }
    },
    onFileChanged (event) {
      this.selectedFile = event.target.files[0]
    },
    onFileChangedIdentityDocument (event) {
      this.selectedFileIdentityDocument = event.target.files[0]
    },
    onFileChangedProofOfResidence (event) {
      this.selectedFileProofOfResidence = event.target.files[0]
    },
    onFileChangedPowerOfAttorney (event) {
      this.selectedFilePowerOfAttorney = event.target.files[0]
    },
    onFileChangedMarriageCertificate (event) {
      this.selectedFileMarriageCertificate = event.target.files[0]
    },
    onFileChangedClientImage (event) {
      this.selectedFileClientImage = event.target.files[0]
    },
    onFileChangedMiscellaneousOne (event) {
      this.selectedFileMiscellaneousOne = event.target.files[0]
    },
    async onFileUpload () {
      if (this.selectedFileIdentityDocument) {
        const response = await uploadFicaFile({
          selectedFile: this.selectedFileIdentityDocument,
          fileName: this.fica.identityDocumentFileName,
          fileType: 'Identity Document',
          id: this.id
        })
        if (response.message === 'Invalid Format') {
          this.formatErrorModal = true
        } else {
          this.reloadPage()
          this.ficaType = 'Por'
        }
      }
      if (this.selectedFileProofOfResidence) {
        const response = await uploadFicaFile({
          selectedFile: this.selectedFileProofOfResidence,
          fileName: this.fica.proofOfResidenceFileName,
          fileType: 'Proof of Residence',
          id: this.id
        })
        if (response.message === 'Invalid Format') {
          this.formatErrorModal = true
        } else {
          this.reloadPage()
          this.ficaType = 'Poa'
        }
      }
      if (this.selectedFilePowerOfAttorney) {
        const response = await uploadFicaFile({
          selectedFile: this.selectedFilePowerOfAttorney,
          fileName: this.fica.powerOfAttorneyFileName,
          fileType: 'Power of Attorney',
          id: this.id
        })
        if (response.message === 'Invalid Format') {
          this.formatErrorModal = true
        } else {
          this.reloadPage()
          this.ficaType = 'Mc'
        }
      }
      if (this.selectedFileMarriageCertificate) {
        const response = await uploadFicaFile({
          selectedFile: this.selectedFileMarriageCertificate,
          fileName: this.fica.marriageCertificateFileName,
          fileType: 'Marriage Certificate',
          id: this.id
        })
        if (response.message === 'Invalid Format') {
          this.formatErrorModal = true
        } else {
          this.reloadPage()
          this.ficaType = 'Image'
        }
      }
      if (this.selectedFileClientImage) {
        const response = await uploadFicaFile({
          selectedFile: this.selectedFileClientImage,
          fileName: this.fica.clientImageFileName,
          fileType: 'Client Image',
          id: this.id

        })
        if (response.message === 'Invalid Format') {
          this.formatErrorModal = true
        } else {
          this.reloadPage()
          this.ficaType = 'Misc'
        }
      }
      if (this.selectedFileMiscellaneousOne) {
        const response = await uploadFicaFile({
          selectedFile: this.selectedFileMiscellaneousOne,
          fileName: this.fica.miscellaneousOneFileName,
          fileType: 'Miscellaneous One',
          id: this.id
        })
        if (response.message === 'Invalid Format') {
          this.formatErrorModal = true
        } else {
          this.reloadPage()
          this.ficaType = 'Id'
        }
      }
    },
    toggle (event) {
      this.$refs.op.toggle(event)
    },
    async reloadPage () {
      this.$router.push({ name: 'ReloadSpinner', params: { id: this.$route.params.id } })
    }
  },
  mounted () {
    this.id = this.$route.params.id
    this.init()
  }
}
</script>

<style lang="scss" scoped>
#uploadBackground {
 @include uploadBackground($uploadBackgroundColor)
};
#fileButton {
  background-color: $midDarkBlue!important;
  color: $textColor;
  font-size: 1.25rem;
  border: 2px solid;
  border-color: $borderColour;
  display: flex;
  justify-content: right;
};
#uploadButton {
  background-color: $lightMidBlue!important;
  min-width: 8rem;
  color: $textColor;
  font-size: 1.25rem;
  height: 2.5rem;
  border: solid 1px $borderColour!important;
  display: flex;
  justify-content: right;
};
.file_upload {
  position: relative;
  min-width: 8rem;
  height: 2.5rem;
  text-align: center;
  color: $textColor;
  line-height: 25px;
  background-color: $midDarkBlue!important;
  border: solid 1px $borderColour;
  font-family: $fontFamily;
  font-size: 1.25rem;
  font-family: Poppins!important;
  font-weight: 400!important;
  border-radius: 4px;
}
a.file_upload {
  display: inline-block;
}
.file_upload .btn_lbl {
  position: relative;
  z-index: 2;
  pointer-events: none;
}
.file_upload .btn_colorlayer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: $midDarkBlue!important;;
  z-index: 1;
  pointer-events: none;
}
@-moz-document url-prefix() {
    .file_upload input[type="file"] {
    position: absolute;
    top: 3px;
    left: -78px!important;
    font-weight: 600;
    margin-left: 100%;
    margin-top: 3px;
    color: $darkTextColour;
    outline: none;
    font-size: 1rem;;
    font-family: $fontFamily;
    height: 2.5rem;
  };
}
.file_upload input[type="file"] {
  position: absolute;
  top: 3px;
  left: -102px;
  font-weight: 600;
  margin-left: 100%;
  margin-top: 3px;
  color: $darkTextColour;
  outline: none;
  font-size: 1rem;;
  font-family: $fontFamily;
  height: 2.5rem;
};
@media screen and (max-width: 767px) {
  .file_upload input[type="file"] {
     color: transparent!important;
};
}
#fileCheck {
  color: $successGreen!important;
  font-size: 1.65rem;
  margin-top: 0.15rem;
};
#fileCross {
  color: $queuedBackground!important;
  font-size: 1.8rem;
  margin-top: 0.1rem;
};
</style>
