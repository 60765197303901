<template>
<h2 class="text-center my-2">QUERY FROM : {{ query.userName }}</h2>
<div class="fluid grid">
        <Card class="shadow-5" id="chatCard">
            <template #content>
                <section id="chatArea">
                <div id="chatScroll" ref="chatArea">
                  <p v-for="(q, index) in query.queryMessages" v-bind:key="index"
                      :class="{ 'messageAdmin': q.sentBy === 'isAdmin',
                              'messagePartner': q.sentBy === 'isPartner'}">
                      <span :class="{ 'adminTimestamp': q.sentBy === 'isAdmin',
                              'partnerTimestamp': q.sentBy === 'isPartner'}">
                              <u>{{q.updatedAt}}</u></span>
                              <br />
                              {{q.message}}
                  </p>
                </div>
                </section>
            </template>
            <template #footer>
            <div class="flex justify-content-between ">
                <div>
                    <Textarea
                    id="textInput"
                    :autoResize="true"
                    rows="2"
                    class="inputfield w-full"
                    placeholder="Chat..."
                    v-model="newQueryMessage.message"/>
                </div>
                <div>
                    <Button class="shadow-3"
                            id="querySendButton"
                            label="Send"
                            icon="pi pi-send"
                            @click.prevent="replyToQuery(newQueryMessage)"/>
                </div>
            </div>
            </template>
        </Card>
      </div>
      <br />
      <div class="flex justify-content-between mx-2">
        <div>
            <Button class="l-2 m-2 mx-2 shadow-3 p-button-lg"
            icon="pi pi-step-backward"
            label="BACK&nbsp;"
            id="submitButton"
            @click.prevent="$router.push( {name: 'QueryView'})" />
        </div>
        <div>
            <Button class="l-2 m-2 mx-1 shadow-3 p-button-lg"
            icon="pi pi-check-circle"
            label="COMPLETE&nbsp;"
            id="acceptButton"
            @click.prevent="markQueryComplete" />
        </div>
    </div>
    <div v-if='completeSuccess'>
     <Dialog class="successModal" header="SUCCESS" v-model:visible="displayConfirmation">
        <div class="confirmation-content">
            <span>Query Chat marked as Complete</span>
        </div>
        <template #footer>
          <div class="flex justify-content-between">
            <router-link class="p-button d-block mr-1 px-1" :to="'/queryView'"
                        style="background-color: #014f7e!important;
                                text-decoration: none; width: 45%; justify-content: center;">
              <i class="pi pi-step-backward" style="fontSize: 1rem"></i>&nbsp;Back
            </router-link>
            <router-link class="p-button d-block mx-1 px-1" :to="'/queryView'"
                        style="background-color: #0185b7!important;
                                text-decoration: none; width: 45%; justify-content: center;">
              <i class="pi pi-users" style="fontSize: 1rem"></i>&nbsp;View
            </router-link>
          </div>
        </template>
    </Dialog>
  </div>
</template>

<script>
import { getUserQuery, adminQueryChat, completeQueryChat } from '@/gateway'

var date = new Date()
var isoDateTime = new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString().substring(0, 16).replace('T', ' ~ ')

export default ({
  name: 'QueryChat',
  props: ['queryTriggerMount'],
  inheritAttrs: false,
  data () {
    return {
      query: [],
      newQueryMessage: {
        message: '',
        sentBy: 'isAdmin',
        updatedAt: isoDateTime
      },
      replySuccess: false,
      completeSuccess: false,
      displayConfirmation: true
    }
  },
  methods: {
    async init () {
      const response = await getUserQuery({ id: this.id })
      if (response) {
        this.query = response.result
      }
      await this.$nextTick()
      const messageDisplay = this.$refs.chatArea
      messageDisplay.scrollTop = messageDisplay.scrollHeight
    },
    async replyToQuery (newMessage) {
      this.query.queryMessages.push(newMessage)
      this.newQueryMessage = {
        message: '',
        sentBy: 'isAdmin',
        updatedAt: isoDateTime
      }
      const response = await adminQueryChat({ query: this.query })
      if (response) {
        this.init()
      }
    },
    async markQueryComplete () {
      const response = await completeQueryChat({ query: this.query })
      if (response) {
        this.completeSuccess = true
        await this.$nextTick()
        this.$router.push({ name: 'QueryCompleteView', params: { id: this.query.id } })
      }
    }
  },
  watch: {
    queryTriggerMount: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.init()
      }
    }
  },
  mounted () {
    this.id = this.$route.params.id
    this.init()
  }
})
</script>

<style lang="scss" scoped>
::v-deep() {
  @media screen and (max-width: 600px) {
    #chatArea {
    background: $backgroundColour;
    min-height: 60vh;
    border: solid 1px;
    overflow: auto;
    border-color: $borderColour;
    font-size: 16px;
    color: $darkTextColour;
    border-radius: 4px;
    padding: 0.1rem;
    margin-top: 0.4rem;
    };
    #chatArea:focus {
    outline: solid 0.5px;
    outline-color: $lightBlue;
    };
    #chatCard {
    display: block;
    margin: auto;
    overflow: auto;
    border: solid 1px!important;
    background-color: $lightDarkBlue!important;
    border-color: $midDarkBlue!important;
    width: 100%;
    min-height: 60vh;
    padding-bottom: 1rem!important;
    };
    #chatScroll {
    max-height: 60vh;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    };
    .messagePartner {
    background: $uploadBackgroundColor;
    color: $darkTextColour;
    margin-left: 10%;
    margin-right: 0.3rem;
    border-radius: 4px;
    padding: 0.4rem;
    };
    .messageAdmin {
    background: $isAdminChatBackground;
    color: $darkTextColour;
    border-radius: 4px;
    margin-left: 0.2rem;
    padding: .6rem;
    width: 90%;
    };
    .adminTimestamp {
    color: $blackTextColor;
    font-size: 11px;
    padding: 0.2rem;
    };
    .partnerTimestamp {
    color: $blackTextColor;
    font-size: 11px;
    padding: 0.2rem;
    margin-left: 58%;
    };
    #textInput {
    width: 60vw!important;
    };
    #querySendButton {
    min-height: 3.75rem!important;
    background: $light!important;
    color: $lightDarkBlue!important;
    font-size: 20px;
    border: solid 1px!important;
    border-color: $midDarkBlue!important;
    };
    #quoteFooter {
    margin-top: 0.25rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    };
    #acceptButton {
    background-color: $successGreen!important;
    border: 1px solid!important;
    font-size: 16px;
    border-color: $darkTextColour!important;
    padding: 0.5rem 0.5rem;
    margin-right: 0.15rem;
    };
  };
  @media screen and (min-width:601px) and (max-width:991px) {
     #chatArea {
    background: $backgroundColour;
    min-height: 65vw;
    border: solid 1px;
    overflow: auto;
    border-color: $borderColour;
    font-size: 16px;
    color: $darkTextColour;
    border-radius: 4px;
    padding: 0.15rem;
    };
  #chatArea:focus {
    outline: solid 0.5px;
    outline-color: $lightBlue;
    };
    #chatCard {
    display: block;
    margin: auto;
    overflow: auto;
    border: solid 1px!important;
    background-color: $lightDarkBlue!important;
    border-color: $midDarkBlue!important;
    width: 98%;
    min-height: 65vw;
    padding-bottom: 0.5rem!important;
    };
    #chatScroll {
    max-height: 65vw;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    };
    .messagePartner {
    background: $uploadBackgroundColor;
    color: $darkTextColour;
    margin-left: 10%;
    margin-right: 0.4rem;
    border-radius: 4px;
    padding: 0.5rem;
    };
    .messageAdmin {
    background: $isAdminChatBackground;
    color: $darkTextColour;
    border-radius: 4px;
    margin-left: 0.25rem;
    padding: .5rem;
    width: 90%;
    };
    .adminTimestamp {
    color: $blackTextColor;
    font-size: 11px;
    padding: 0.2rem;
    };
    .partnerTimestamp {
    color: $blackTextColor;
    font-size: 11px;
    padding: 0.2rem;
    margin-left: 80%;
    };
    #textInput {
    width: 70vw!important;
    };
    #querySendButton {
    min-height: 3.75rem!important;
    background: $light!important;
    color: $lightDarkBlue!important;
    font-size: 20px;
    border: solid 1px!important;
    border-color: $midDarkBlue!important;
    };
    #quoteFooter {
    margin-bottom: 0.5rem;
    };
    #acceptButton {
    background-color: $successGreen!important;
    border: 1px solid!important;
    border-color: $darkTextColour!important;
    padding: 0.5rem 0.7rem;
    float: right;
    };
  };
  @media screen and (min-width: 992px) and (max-width:1079px) {
    #chatArea {
    background: $backgroundColour;
    min-height: 55vh;
    border: solid 1px;
    overflow: auto;
    border-color: $borderColour;
    font-size: 16px;
    color: $darkTextColour;
    border-radius: 4px;
    padding: 0.2rem;
    margin-top: 0.4rem;
    };
  #chatArea:focus {
    outline: solid 0.5px;
    outline-color: $lightBlue;
    };
    #chatCard {
    display: block;
    margin: auto;
    overflow: auto;
    border: solid 1px!important;
    background-color: $lightDarkBlue!important;
    border-color: $midDarkBlue!important;
    width: 100%;
    min-height: 55vh;
    padding-bottom: 1rem!important;
    };
    #chatScroll {
    max-height: 55vh;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    };
    .messagePartner {
    background: $uploadBackgroundColor;
    color: $darkTextColour;
    margin-left: 10%;
    margin-right: 0.3rem;
    border-radius: 4px;
    padding: 0.4rem;
    };
    .messageAdmin {
    background: $isAdminChatBackground;
    color: $darkTextColour;
    border-radius: 4px;
    padding: .6rem;
    width: 90%;
    };
    .adminTimestamp {
    color: $blackTextColor;
    font-size: 11px;
    padding: 0.2rem;
    };
    .partnerTimestamp {
    color: $blackTextColor;
    font-size: 11px;
    padding: 0.2rem;
    margin-left: 58%;
    };
    #textInput {
    width: 62vw!important;
    };
    #querySendButton {
    min-height: 3.75rem!important;
    background: $light!important;
    color: $lightDarkBlue!important;
    font-size: 20px;
    border: solid 1px!important;
    border-color: $midDarkBlue!important;
    };
    #quoteFooter {
    margin-top: 0.5rem;
    };
    #acceptButton {
    background-color: $successGreen!important;
    border: 1px solid!important;
    border-color: $darkTextColour!important;
    padding: 0.5rem 0.7rem;
    float: right;
    };
  };
 @media screen and (min-width: 1080px)  and (max-width: 1366px) {
    #chatArea {
    background: $backgroundColour;
    min-height: 53vh;
    border: solid 1px;
    overflow: auto;
    border-color: $borderColour;
    font-size: 16px;
    color: $darkTextColour;
    border-radius: 4px;
    padding: 0.2rem;
    };
    #chatArea:focus {
    outline: solid 0.5px;
    outline-color: $lightBlue;
    };
    #chatCard {
    display: block;
    margin: auto;
    overflow: auto;
    border: solid 1px!important;
    background-color: $lightDarkBlue!important;
    border-color: $midDarkBlue!important;
    width: 97%;
    min-height: 52vh;
    };
    #chatScroll {
    max-height: 52vh;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    };
    .messagePartner {
    background: $uploadBackgroundColor;
    color: $darkTextColour;
    margin-left: 10%;
    margin-right: 0.4rem;
    border-radius: 4px;
    padding: .4rem;
    };
    .messageAdmin {
    background: $isAdminChatBackground;
    color: $darkTextColour;
    border-radius: 4px;
    padding: .4rem;
    width: 90%;
    };
    .adminTimestamp {
    color: $blackTextColor;
    font-size: 11px;
    padding: 0.2rem;
    };
    .partnerTimestamp {
    color: $blackTextColor;
    font-size: 11px;
    padding: 0.2rem;
    margin-left: 88%;
    };
    #textInput {
    width: 75vw!important;
    };
    #querySendButton {
    min-height: 3.75rem!important;
    background: $light!important;
    color: $lightDarkBlue!important;
    font-size: 20px;
    border: solid 1px!important;
    border-color: $midDarkBlue!important;
    };
    #acceptButton {
    background-color: $successGreen!important;
    border: 1px solid!important;
    border-color: $darkTextColour!important;
    padding: 0.5rem 0.7rem;
    margin-right: 0.5rem!important;
    };
  };
  @media screen and (min-width: 1367px) {
   #chatArea {
      background: $backgroundColour;
      min-height: 52vh;
      border: solid 1px;
      overflow: auto;
      border-color: $borderColour;
      font-size: 16px;
      color: $darkTextColour;
      border-radius: 4px;
      padding: 0.2rem 0.2rem 0.2rem 0.2rem;
    };
    #chatArea:focus {
      outline: solid 0.5px;
      outline-color: $lightBlue;
    };
    #chatCard {
      display: block;
      margin: auto;
      overflow: auto;
      border: solid 1px!important;
      background-color: $lightDarkBlue!important;
      border-color: $midDarkBlue!important;
      width: 85%;
      min-height: 60vh;
    };
    #chatScroll {
      max-height: 60vh;
      width: 100%;
      overflow-x: hidden;
      overflow-y: auto;
    };
    .messagePartner {
      background: $uploadBackgroundColor;
      color: $darkTextColour;
      margin-left: 15%;
      margin-right: 0.5rem;
      border-radius: 4px;
      padding: .4rem;
    };
    .messageAdmin {
      background: $isAdminChatBackground;
      color: $darkTextColour;
      border-radius: 4px;
      margin-left: 0.2rem;
      padding: .4rem;
      width: 85%;
    };
    .adminTimestamp {
      color: $blackTextColor;
      font-size: 11px;
      padding: 0.2rem;
    };
    .partnerTimestamp {
      color: $blackTextColor;
      font-size: 11px;
      padding: 0.2rem;
      margin-left: 90%;
    };
    #textInput {
      width: 70vw!important;
    };
    #querySendButton {
      min-height: 3.75rem!important;
      background: $light!important;
      color: $lightDarkBlue!important;
      font-size: 20px;
      border: solid 1px!important;
      border-color: $midDarkBlue!important;
    };
    #acceptButton {
    background-color: $successGreen!important;
    border: 1px solid!important;
    border-color: $darkTextColour!important;
    padding: 0.5rem 0.7rem;
    margin-right: 0.5rem!important;
    };
 };
}
.p-grid {
  margin: 1rem;
  padding: 1rem;
};
</style>
