<template>
  <div style="width: 100%; height: 100%; padding: 50px 0 0 0; overflow-y: scroll; display: flex; flex-direction: column; align-items: center; justify-items: center;">
    <div class="chart-container">
      <Chart
        type="bar"
        title="Trades per month"
        :show-legend="false"
        y-axis-title="Count"
        :data="tpm"
      />
    </div>
    <div class="chart-container">
      <Chart
        type="bar"
        title="Trades per year"
        :show-legend="false"
        y-axis-title="Count"
        :data="tpy"
      />
    </div>
    <div class="chart-container">
      <Chart
        type="bar"
        :title="`New trades per partner (${store.getters.reportDate})`"
        :show-legend="false"
        y-axis-title="Count"
        :data="tpppm"
      />
    </div>
  </div>
</template>

<script setup>
import { store } from '@/store'
import { computed } from 'vue'
import Chart from '@/components/Chart.vue'

const tpm = computed(() => {
  return {
    labels: store.getters.tradesPerMonth.map(x => new Date(x.date).toLocaleDateString('en-GB', { month: 'short', year: 'numeric' })),
    datasets: [
      {
        label: 'Trades',
        backgroundColor: '#0185b7',
        data: store.getters.tradesPerMonth.map(x => x.count)
      }
    ]
  }
})
const tpy = computed(() => {
  return {
    labels: store.getters.tradesPerYear.map(x => new Date(x.date).toLocaleDateString('en-GB', { month: 'short', year: 'numeric' })),
    datasets: [
      {
        label: 'Trades',
        backgroundColor: '#0185b7',
        data: store.getters.tradesPerYear.map(x => x.count)
      }
    ]
  }
})
const tpppm = computed(() => {
  return {
    labels: store.getters.tradesPerPartnerPerMonth.at(-1).map(x => x.text),
    datasets: [
      {
        label: 'Trades',
        backgroundColor: '#0185b7',
        data: store.getters.tradesPerPartnerPerMonth.at(-1).map(x => x.countPerPeriod.count)
      }
    ]
  }
})
</script>

<script>
export default {
  name: 'Trades',
  inheritAttrs: false
}
</script>

<style scoped>
.chart-container {
  margin-bottom: 150px;
  width: 1220px;
  height: 560px;
}
</style>
