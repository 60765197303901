<template>
<br />
    <h2 class="text-center my-2">PARTNER TCC VIEW</h2>
<br />
 <div v-if="displayEmptyPartnerTcc == false">
    <div class="fluid mx-3 shadow-2">
        <DataTable :value="clientTcc"
                    dataKey="id" :rowHover="true"
                    stripedRows responsiveLayout="stack"
                    sortMode="multiple"
                    showGridlines
                    breakpoint="1024px"
                    :paginator="true"
                    :rows="8"
                    v-model:filters="filters1"
                    filterDisplay="menu"
                    class="p-datatable-retailClient">
            <Column field="partnerName" header="Partner" headerStyle="width: 3vw"></Column>
            <Column field="firstLastName" header="Name" :sortable="true" headerStyle="width: 5vw">
                    <template #filter="{filterModel}">
                        <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by Name"/>
                    </template>
            </Column>
            <Column field="tccValue" header="Value (ZAR)" headerStyle="width: 4vw">
                    <template #body="{data}">
                        {{formatCurrency(data.tccValue)}}
                    </template></Column>
            <Column field="tccPin" header="Pin" headerStyle="width: 3vw"></Column>
            <Column field="tccIssuer" header="Issuer" headerStyle="width: 3vw"></Column>
            <Column field="issueDate" header="Issue Date" headerStyle="width: 4vw"></Column>
            <Column field="expiryDate" header="Expiry Date" headerStyle="width: 4vw"></Column>
            <Column field="applicationStatus" header="Status" headerStyle="width: 4vw" :filterMenuStyle="{'width':'14rem'}" style="min-width:4vw">
                    <template #body="{data}">
                        <span :class="'retailClient-badge status-' + data.applicationStatus">{{data.applicationStatus}}</span>
                    </template>
                    <template #filter="{filterModel}">
                        <Dropdown v-model="filterModel.value" :options="statuses" placeholder="Select Status" class="p-column-filter" :showClear="true">
                        </Dropdown>
                    </template>
            </Column>
            <Column header="Edit" headerStyle="width: 4vw;">
                <template #body="{data}">
                <div v-if="data.applicationStatus == 'processing'">
                    <router-link class="p-button-raised d-block mx-auto px-auto" disabled="disabled" :to="'/ClientView/id'" style="color: #e2e7e5; text-decoration: none;
                                        display: flex; justify-content: center;">
                    <i class="pi pi-user-edit" style="fontSize: 1rem"></i>&nbsp;&nbsp;EDIT
                    </router-link>
                </div>
                <div v-else-if="data.applicationStatus != 'In-Process'">
                    <router-link class="p-button d-block mx-auto px-auto" :to="'/manage/' + data.id"  style="color: #e2e7e5; text-decoration: none;
                                        display: flex; justify-content: center;">
                    <i class="pi pi-user-edit" style="fontSize: 1rem"></i>&nbsp;&nbsp;EDIT
                    </router-link>
                </div>
                </template>
            </Column>
        </DataTable>
    </div>
  </div>
  <div v-else>
    <h3 class="text-center my-2">NO TCCs FOUND FOR THIS PARTNER</h3>
    <br />
    <hr class="mx-4 shadow-2"/>
   </div>
  <br />
  <div>
    <Button class="l-2 m-4 mx-3 shadow-3 p-button-lg"
    icon="pi pi-step-backward"
    label="BACK&nbsp;"
    id="submitButton"
    @click.prevent="$router.back()" />
  </div>
</template>

<script>
import { getPartnerTccs } from '@/gateway'
import { FilterMatchMode, FilterOperator } from 'primevue/api'

export default {
  name: 'PartnerTccView',
  inheritAttrs: false,
  data () {
    return {
      clientTcc: [],
      clientTccUpdateSuccess: false,
      displayConfirmation: true,
      displayEmptyPartnerTcc: false,
      filters1: null,
      statuses: [
        'processing',
        'with_Client',
        'with_Issuer',
        'with_Sars',
        'issued',
        'complete',
        'expired',
        'non_Compliant'
      ]
    }
  },
  methods: {
    async init () {
      const response = await getPartnerTccs({ id: this.id })
      if (response.result.length !== 0) {
        this.clientTcc = response.result
      } else {
        this.displayEmptyPartnerTcc = true
      }
    },
    formatCurrency (value) {
      return value.toLocaleString('en-US', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 })
    },
    initFilters1 () {
      this.filters1 = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        firstLastName: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
        applicationStatus: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] }
      }
    },
    toggle (event) {
      this.$refs.op.toggle(event)
    }
  },
  created () {
    this.initFilters1()
  },
  mounted () {
    this.id = this.$route.params.id
    this.init()
  }
}
</script>

<style lang="scss" scoped>
::v-deep() {
  .p-datatable .p-datatable-thead > tr > th {
    background-color: $middleBlue!important;
    color: $textColor;
    border: 1px solid $darkTextColour!important;
    font-size: 18px;
    font-weight: 600;
  }
  .p-datatable .p-datatable-tbody > tr > td {
    padding: .4rem .4rem;
    color: $darkTextColour;
    font-family:'Poppins', sans-serif;
    font-weight: 400;
  }
  .p-paginator-current {
        margin-right: auto;
  }
    .retailClient-badge {
      @include statusBadge
  };
};
</style>
