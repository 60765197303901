<template>
  <br />
  <h2 class="text-center my-3">FORM 25 DETAILS FOR : {{ form25.firstLastName }}</h2>
  <br />
  <Panel class="mx-4 shadow-4" header="PAYMENT INSTRUCTION DETAILS" :toggleable="true" :collapsed="true">
  <template #end>
      <button class="panel-header-icon link mr-2" @click="toggle"></button>
  </template>
  <h4 class="text-center"><u>PAYMENT DETAILS</u></h4>
  <div class=" formgrid grid">
      <div class="col-12 md:col-6 lg:col-6">
        <h5>Payment Date</h5>
          <Calendar
          p-datepicker
          disabled
          v-model="form25.paymentDate"
          appendTo="self"
          class="inputfield w-full"
          dateFormat="yy-mm-dd" />
      </div>
      <div class="col-12 md:col-6 lg:col-6">
          <h5>Client Account Number</h5>
          <InputText
          type="text"
          class="inputfield w-full"
          disabled
          v-model="form25.transferAccountNumber"/>
      </div>
   </div>
   <div class="formgrid grid">
      <div class="col-12 md:col-6 lg:col-6">
        <h5>Amount in ZAR</h5>
            <InputNumber
              v-model="form25.randAmount"
               mode="decimal"
              :minFractionDigits="2"
              :maxFractionDigits="2"
              disabled
              class="inputfield w-full" />
      </div>
      <div class="col-12 md:col-6 lg:col-6">
          <h5>Amount in words</h5>
          <InputText
          type="text"
          class="inputfield w-full"
          disabled
          v-model="form25.randAmountInWords"/>
      </div>
  </div>
  <br />
  <hr />
  <h4 class="text-center"><u>BENFICIARY DETAILS</u></h4>
  <div class="formgrid grid">
    <div class="col-12 md:col-12 lg:col-6">
        <div class="field-checkbox">
            <Checkbox id="billerPay"
            v-model="form25.billerPayment"
            disabled="disabled"
            :binary="true" />
            <label for="billerPay">Biller Payment</label>
        </div>
      </div>
  </div>
  <div class="formgrid grid">
      <div class="col-12 md:col-6 lg:col-6">
        <h5>Beneficiary Name</h5>
          <InputText
          type="text"
          class="inputfield w-full"
          disabled
          v-model="form25.beneficiaryName"/>
      </div>
  </div>
   <div class="formgrid grid">
      <div class="col-12 md:col-12 lg:col-12">
        <h5>Beneficiary Address</h5>
          <InputText
          type="text"
          class="inputfield w-full"
          disabled
          v-model="form25.beneficiaryAddress"/>
      </div>
  </div>
   <div class="formgrid grid">
      <div class="col-12 md:col-6 lg:col-6">
        <h5>Beneficiary Account Number</h5>
          <InputText
          type="text"
          class="inputfield w-full"
          disabled
          v-model="form25.beneficiaryAccount"/>
      </div>
      <div class="col-12 md:col-6 lg:col-6">
        <h5>Account Type</h5>
          <Dropdown v-model="form25.accountType"
          :options="accountTypes"
          optionLabel="text"
          optionValue="value"
          disabled
          class="inputfield w-full"
          placeholder="Please Select" />
      </div>
  </div>
   <div class="formgrid grid">
      <div class="col-12 md:col-6 lg:col-6">
        <h5>Bank Name</h5>
          <InputText
          type="text"
          class="inputfield w-full"
          disabled
          v-model="form25.bankName"/>
      </div>
      <div class="col-12 md:col-6 lg:col-6">
          <h5>Branch Name</h5>
          <InputText
          type="text"
          class="inputfield w-full"
          disabled
          v-model="form25.branchName"/>
      </div>
  </div>
   <div class="formgrid grid">
      <div class="col-12 md:col-6 lg:col-6">
        <h5>Bank Code</h5>
          <InputText
          type="text"
          class="inputfield w-full"
          disabled
          v-model="form25.bankCode"/>
      </div>
      <div class="col-12 md:col-6 lg:col-6">
          <h5>Beneficiary Reference</h5>
          <InputText
          type="text"
          class="inputfield w-full"
          disabled
          v-model="form25.beneficiaryRef"/>
      </div>
  </div>
   <div class="formgrid grid">
      <div class="col-12 md:col-6 lg:col-6">
        <h5>STP</h5>
          <Dropdown v-model="form25.stp"
          :options="stps"
          optionLabel="text"
          optionValue="value"
          class="inputfield w-full"
          disabled
          placeholder="Please Select" />
      </div>
      <div class="col-12 md:col-6 lg:col-6">
        <h5>Proof of Payment Required?</h5>
          <Dropdown v-model="form25.proofOfPayment"
          :options="proofsOfPayment"
          optionLabel="text"
          optionValue="value"
          class="inputfield w-full"
          disabled
          placeholder="Please Select" />
      </div>
  </div>
   <transition name="fade">
      <div v-if="form25.proofOfPayment == 'Yes'">
          <div class="formgrid grid">
              <div class="col-12 md:col-6 lg:col-6">
                <h5>Proof of Payment Email Address</h5>
                  <InputText
                  type="text"
                  class="inputfield w-full"
                  disabled
                  v-model="form25.popEmail"/>
              </div>
          </div>
      </div>
   </transition>
    <div class="formgrid grid">
      <div class="col-12 md:col-6 lg:col-6">
        <h5>Signed At</h5>
          <Dropdown v-model="form25.signedAt"
          :options="signedAts"
          optionLabel="text"
          optionValue="value"
          :showClear="true"
          appendTo="self"
          disabled
          class="inputfield w-full"
          placeholder="Please Select" />
      </div>
      <div class="col-12 md:col-6 lg:col-6">
        <h5>Signatory</h5>
          <Dropdown v-model="form25.signatoryName"
          :options="signatoryNames"
          optionLabel="text"
          optionValue="value"
          :showClear="true"
          appendTo="self"
          disabled
          class="inputfield w-full"
          placeholder="Please Select" />
      </div>
  </div>
   <br />
    <div class="formgrid grid">
      <div class="col-12 md:col-12 lg:col-6">
          <div class="field-checkbox">
              <Checkbox id="isThird"
              v-model="form25.isThirdPartyPayment"
              disabled="disabled"
              :binary="true" />
              <label for="isThird">Is this a ThirdParty Payment?</label>
          </div>
       </div>
    </div>
 </Panel>
 <br />
 <Panel class="mx-4 shadow-4" header="DOWNLOAD FORMS" :toggleable="true" :collapsed="true">
  <template #end>
      <button class="panel-header-icon link mr-2" @click="toggle"></button>
  </template>
   <div class="fluid formgrid grid">
      <div class="col-12 md:col-6 lg:col-6">
            <Button class="p-button d-block mx-auto py-3 px-auto" id="form25" :loading="loadingForm25" @click.prevent="downloadForm25('Form 25')"
                  style="color: #e2e7e5; display: flex; justify-content: center; border: 2px solid!important; border-color: #4E4E4E!important"
                  icon="pi pi-cloud-download"
                  label="&nbsp;Form 25"/>
      </div>
       <div class="col-12 md:col-6 lg:col-6">
          <transition name="fade">
            <div v-if="form25.isThirdPartyPayment == true">
                <Button class="p-button d-block mx-auto py-3 px-auto" id="thirdParty" :loading="loadingIndemnity" @click.prevent="downloadForm25('Form 25 Indemnity Form')"
                  style="color: #e2e7e5; display: flex; justify-content: center; border: 2px solid!important; border-color: #4E4E4E!important"
                  icon="pi pi-cloud-download"
                  label="&nbsp;INDEMNITY"/>
          </div>
      </transition>
      </div>
   </div>
 </Panel>
 <br />
<div class="flex justify-content-between ">
    <div>
        <Button class="l-2 m-3 mx-4 shadow-3 p-button-lg"
        icon="pi pi-step-backward"
        label="BACK&nbsp;"
        id="submitButton"
        @click.prevent="$router.push( {name: 'ClientFormsView', params: { id: form25.clientId }})" />
    </div>
    <div>
        <Button class="l-2 m-3 mx-4 shadow-3 p-button-lg"
        icon="pi pi-user-edit"
        label="UPDATE&nbsp;"
        id="backButton"
        @click.prevent="$router.push( {name: 'Form25Edit', params: { id: form25.id }})" />
    </div>
</div>
</template>

<script>
import { getForm25, form25Download } from '@/gateway'

export default {
  name: 'Form25View',
  inheritAttrs: false,
  data () {
    return {
      retailClient: {},
      form25: {},
      loadingForm25: false,
      loadingIndemnity: false,
      accountTypes: [
        { value: 'Savings', text: 'Savings' },
        { value: 'Current', text: 'Current' }
      ],
      stps: [
        { value: 'Yes', text: 'Yes' },
        { value: 'No', text: 'No' }
      ],
      proofsOfPayment: [
        { value: 'Yes', text: 'Yes' },
        { value: 'No', text: 'No' }
      ],
      signedAts: [
        { value: 'Cape Town', text: 'Cape Town' },
        { value: 'Johannesburg', text: 'Johannesburg' }
      ],
      signatoryNames: [
        { value: 'Alison Anne Barker', text: 'Alison' },
        { value: 'Amanda Colette Sampson', text: 'Amanda' },
        { value: 'Catherine Digby', text: 'Cathy' },
        { value: 'Lindy-Lee Davies', text: 'Lindy' }
      ]
    }
  },
  methods: {
    async init () {
      const response = await getForm25({ id: this.id })
      if (response) {
        this.form25 = response.result
      }
    },
    base64ToArrayBuffer (base64) {
      var binaryString = window.atob(base64)
      var binaryLen = binaryString.length
      var bytes = new Uint8Array(binaryLen)
      for (var i = 0; i < binaryLen; i++) {
        var ascii = binaryString.charCodeAt(i)
        bytes[i] = ascii
      }
      return bytes
    },
    async downloadForm25 (formType) {
      if (formType === 'Form 25') { this.loadingForm25 = true }
      else if (formType === 'Form 25 Indemnity Form') { this.loadingIndemnity = true }
      const response = await form25Download({
        formType: formType,
        id: this.form25.clientId
      })
      if (response) {
        var byteArray = this.base64ToArrayBuffer(response.data)
        const url = window.URL.createObjectURL(
          new Blob([byteArray], { type: 'application/octet-stream' })
        )
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', response.fileName)
        document.body.appendChild(link)
        this.loadingForm25 = false
        this.loadingIndemnity = false
        link.click()
      }
    }
  },
  mounted () {
    this.id = this.$route.params.id
    this.init()
  }
}
</script>

<style lang="scss" scoped>
::v-deep() {
  .p-button-label {
    flex: none;
  }
  #form25 {
  @include cardComponent($midDarkBlue)
  };
  #thirdParty {
  @include cardComponent($lightMidBlue)
  };
  .p-card.p-component.p-card-content {
  font-size: 24px;
  };
};

</style>
