<template>
<br />
<h2 class="text-center my-2">ADD NEW AC1 PARTNER </h2>
<br />
<Panel class="mx-4 shadow-4" header="PARTNER DETAILS" :toggleable="true" :collapsed="false">
  <template #end>
      <button class="panel-header-icon link mr-2" @click="toggle"></button>
  </template>
    <h4 class="text-center"><u>PARTNER INFORMATION</u></h4>
    <div class="formgrid grid">
        <div class="col-12 md:col-6 lg:col-6">
            <h5>Partner Name</h5>
            <InputText
            type="text"
            class="inputfield w-full"
            v-model="partners.partnerName"/>
        </div>
        <div class="col-12 md:col-6 lg:col-6">
            <h5>Registration Number</h5>
            <InputText
            type="text"
            class="inputfield w-full"
            v-model="partners.regNo"/>
        </div>
    </div>
    <div class="formgrid grid">
        <div class="col-12 md:col-6 lg:col-6">
            <h5>FSP Number</h5>
            <InputText
            type="text"
            class="inputfield w-full"
            v-model="partners.fspNo"/>
        </div>
        <div class="col-12 md:col-6 lg:col-6">
            <h5>Landobyte Name</h5>
            <InputText
            type="text"
            class="inputfield w-full"
            v-model="partners.landobyteName"/>
        </div>
    </div>
    <div class="formgrid grid">
        <div class="col-12 md:col-6 lg:col-6">
            <h5>Display Name</h5>
            <InputText
            type="text"
            class="inputfield w-full"
            v-model="partners.shortName"/>
        </div>
        <div class="col-12 md:col-6 lg:col-6">
            <h5>Is this a Sub-Advisor?</h5>
                <Dropdown v-model="partners.isSubAdvisor"
                :options="subAdvisors"
                optionLabel="text"
                optionValue="value"
                :showClear="true"
                appendTo="self"
                class="inputfield w-full"
                placeholder="Please Select" />
        </div>
    </div>
    <transition name="fade">
      <div v-if="partners.isSubAdvisor == 'Yes'">
          <div class="formgrid grid">
              <div class="col-12 md:col-6 lg:col-6">
                <h5>Parent Partner Name</h5>
                  <InputText
                  type="text"
                  class="inputfield w-full"
                  v-model="partners.parentPartner"/>
              </div>
          </div>
      </div>
   </transition>
   <br />
   <hr />
   <h4 class="text-center"><u>ADDRESS DETAILS</u></h4>
        <h5><u>Business Address</u></h5>
         <div class=" formgrid grid">
            <div class="col-12 md:col-6 lg:col-6">
              <h5>Street Address</h5>
                <InputText
                type="text"
                class="inputfield w-full"
                v-model="partners.address"/>
            </div>
            <div class="col-12 md:col-6 lg:col-6">
                <h5>Suburb</h5>
                <InputText
                type="text"
                class="inputfield w-full"
                v-model="partners.suburb"/>
            </div>
        </div>
         <div class=" formgrid grid">
            <div class="col-12 md:col-6 lg:col-6">
              <h5>City</h5>
                <InputText
                type="text"
                class="inputfield w-full"
                v-model="partners.city"/>
            </div>
            <div class="col-12 md:col-6 lg:col-6">
              <h5>Province</h5>
                <Dropdown v-model="partners.province"
                :options="provinces"
                optionLabel="text"
                optionValue="value"
                :showClear="true"
                appendTo="self"
                class="inputfield w-full"
                placeholder="Please Select" />
            </div>
        </div>
         <div class=" formgrid grid">
            <div class="col-12 md:col-6 lg:col-6">
                <h5>Postal Code</h5>
                <InputText
                type="text"
                class="inputfield w-full"
                v-model="partners.postalCode"/>
            </div>
             <div class="col-12 md:col-6 lg:col-6">
                <h5>Office/Landline Number</h5>
                <InputText
                type="text"
                placeholder="no spaces i.e. 0211234567"
                class="inputfield w-full"
                v-model="partners.partnerContactNumber"/>
            </div>
        </div>
         <div class=" formgrid grid">
            <div class="col-12 md:col-6 lg:col-6">
                <h5>Partner Website URL</h5>
                <InputText
                type="text"
                class="inputfield w-full"
                v-model="partners.websiteUrl"/>
            </div>
        </div>
        <br />
        <hr />
        <h4 class="text-center"><u>MAIN CONTACT PERSON DETAILS</u></h4>
         <div class=" formgrid grid">
            <div class="col-12 md:col-6 lg:col-6">
                <h5>Main Contact Names</h5>
                <InputText
                type="text"
                class="inputfield w-full"
                v-model="partners.mainContact"/>
            </div>
             <div class="col-12 md:col-6 lg:col-6">
                <h5>Main Contact Email Address</h5>
                <InputText
                type="text"
                class="inputfield w-full"
                v-model="partners.contactEmail"/>
            </div>
        </div>
        <div class=" formgrid grid">
            <div class="col-12 md:col-6 lg:col-6">
                <h5>Main Contact Mobile Number</h5>
                <InputText
                type="text"
                placeholder="no spaces i.e. 0821234567"
                class="inputfield w-full"
                v-model="partners.contactMobile"/>
            </div>
        </div>
        <br />
</Panel>
 <br />
 <div class="flex justify-content-between ">
     <div>
        <Button class="l-2 m-3 mx-4 shadow-3 p-button-lg"
        icon="pi pi-step-backward"
        label="BACK&nbsp;"
        id="submitButton"
        @click.prevent="$router.back()" />
    </div>
    <div>
        <Button class="l-2 m-3 mx-4 shadow-3 p-button-lg "
        icon="pi pi-upload"
        label="CREATE"
        id="backButton"
        @click.prevent="saveNewPartner" />
    </div>
</div>
<div class=" formgrid grid">
    <div class="col-12 md:col-6 lg:col-6">
    <div v-if='partnerSuccess'>
    <Dialog class="successModal" header="SUCCESS" v-model:visible="displayConfirmation" :modal="true">
        <div class="confirmation-content">
            <span>New Partner Created</span>
        </div>
        <template #footer>
          <div class="flex justify-content-between">
            <router-link class="p-button d-block mr-2 px-auto" :to="'/partnerHome'"
                style="background-color: #014f7e!important; width: 40%; justify-content: center; text-decoration: none;">
                <i class="pi pi-home" style="fontSize: 1rem"></i> &nbsp;&nbsp;Back
            </router-link>
            <router-link class="p-button d-block mx-2 px-auto" :to="'/partnerListView'"
                style="background-color: #0185b7!important; width: 40%; justify-content: center; text-decoration: none">
                <i class="pi pi-users" style="fontSize: 1rem"></i>&nbsp;&nbsp;View
            </router-link>
          </div>
        </template>
    </Dialog>
    </div>
    </div>
</div>
</template>

<script>
import { createPartner } from '@/gateway'

export default {
  name: 'NewPartner',
  inheritAttrs: false,
  data () {
    return {
      partnerSuccess: false,
      displayConfirmation: true,
      partners:
          {
            id: 0,
            partnerName: '',
            regNo: '',
            fspNo: '',
            landobyteName: '',
            shortName: '',
            isSubAdvisor: 'No',
            parentPartner: '',
            address: '',
            suburb: '',
            city: '',
            province: '',
            postalCode: '',
            partnerContactNumber: '',
            websiteUrl: '',
            mainContact: '',
            contactEmail: '',
            contactMobile: '',
            partnerLogoUrl: ''
          },
      subAdvisors: [
        { value: 'Yes', text: 'Yes' },
        { value: 'No', text: 'No' }
      ],
      provinces: [
        { value: 'Eastern Cape', text: 'Eastern Cape' },
        { value: 'Free State', text: 'Free State' },
        { value: 'Gauteng', text: 'Gauteng' },
        { value: 'KwaZulu-Natal', text: 'KwaZulu-Natal' },
        { value: 'Limpopo', text: 'Limpopo' },
        { value: 'Mpumalanga', text: 'Mpumalanga' },
        { value: 'Northern Cape', text: 'Northern Cape' },
        { value: 'North West', text: 'North West' },
        { value: 'Western Cape', text: 'Western Cape' }
      ]
    }
  },
  methods: {
    async init () {

    },
    async saveNewPartner () {
      const response = await createPartner({ partners: this.partners })
      if (response) {
        this.partnerSuccess = true
      }
    }
  },
  async mounted () {
    this.init()
  }
}
</script>
