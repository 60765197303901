<template>
  <br />
  <h2 class="text-center my-3">EDIT CLIENT DETAILS FOR : {{ retailClient.firstLastName }}</h2>
  <br />
      <Panel class="mx-4 shadow-4" header="CLIENT DETAILS" :toggleable="true" :collapsed="true">
        <template #end>
            <button class="panel-header-icon link mr-2" @click="toggle"></button>
        </template>
          <h4 class="text-center"><u>CLIENT INFORMATION</u></h4>
          <div class="formgrid grid">
            <div class="col-12 md:col-6 lg:col-6">
              <h5>First Name</h5>
                <InputText
                type="text"
                class="inputfield w-full"
                v-model="retailClient.firstName"/>
            </div>
            <div class="col-12 md:col-6 lg:col-6">
                <h5>Middle Name(s)</h5>
                <InputText
                type="text"
                class="inputfield w-full"
                v-model="retailClient.middleName"/>
            </div>
        </div>
        <div class="formgrid grid">
            <div class="col-12 md:col-6 lg:col-6">
              <h5>Surname</h5>
                <InputText
                type="text"
                class="inputfield w-full"
                v-model="retailClient.lastName"/>
            </div>
            <div v-if ="showFullName == true">
                <InputText
                type="text"
                class="inputfield w-full"
                v-model="retailClient.fullNames"/>
            </div>
            <div class="col-12 md:col-6 lg:col-6">
              <h5>Title</h5>
                <Dropdown v-model="retailClient.title"
                :options="titles"
                optionLabel="text"
                optionValue="value"
                :showClear="true"
                appendTo="self"
                class="inputfield w-full"
                placeholder="Please Select" />
            </div>
        </div>
        <div class=" formgrid grid">
            <div class="col-12 md:col-6 lg:col-6">
              <h5>Gender</h5>
                <Dropdown v-model="retailClient.gender"
                :options="genders"
                optionLabel="text"
                optionValue="value"
                :showClear="true"
                appendTo="self"
                class="inputfield w-full"
                placeholder="Please Select" />
            </div>
            <div class="col-12 md:col-6 lg:col-6">
              <h5>Tax Number</h5>
                <InputText
                type="text"
                class="inputfield w-full"
                v-model="retailClient.taxRefNumber"/>
            </div>
        </div>
         <div class=" formgrid grid">
            <div class="col-12 md:col-6 lg:col-6">
              <h5>Date of Birth</h5>
                <Calendar
                v-model="retailClient.dateOfBirth"
                :showIcon="true"
                :monthNavigator="true"
                :yearNavigator="true"
                yearRange="1920:2021"
                appendTo="self"
                class="inputfield w-full"
                dateFormat="dd-mm-yy" />
            </div>
             <div class="col-12 md:col-6 lg:col-6">
              <h5>Country of Birth</h5>
                  <Dropdown v-model="retailClient.placeOfBirth"
                  :options="countries"
                  optionLabel="text"
                  optionValue="value"
                  :showClear="true"
                  appendTo="self"
                  class="inputfield w-full"
                  placeholder="Please Select" />
            </div>
         </div>
         <div class=" formgrid grid">
            <div class="col-12 md:col-6 lg:col-6">
              <h5>Residency Status</h5>
                  <Dropdown v-model="retailClient.residencyStatus"
                  :options="residencies"
                  optionLabel="text"
                  optionValue="value"
                  :showClear="true"
                  appendTo="self"
                  class="inputfield w-full"
                  placeholder="Please Select" />
            </div>
         </div>
         <div class=" formgrid grid">
          <transition name="fade">
            <div class="col-12 md:col-6 lg:col-6"
                 v-if="retailClient.residencyStatus == 'SaCitizen' || retailClient.residencyStatus == 'RSAResident'">
              <h5>Id Number</h5>
                <InputText
                type="text"
                class="inputfield w-full"
                v-model="retailClient.idNumber"/>
            </div>
            </transition>
        </div>
         <transition name="fade">
          <div v-if="retailClient.residencyStatus != 'SaCitizen' || retailClient.residencyStatus != 'RSAResident'">
            <div class=" formgrid grid">
                <div class="col-12 md:col-6 lg:col-6">
                  <h5>Nationality</h5>
                      <Dropdown v-model="retailClient.nationality"
                      :options="countries"
                      optionLabel="text"
                      optionValue="value"
                      :showClear="true"
                      appendTo="self"
                      class="inputfield w-full"
                      placeholder="Please Select" />
                </div>
                <div class="col-12 md:col-6 lg:col-6">
                  <h5>Country of Residence</h5>
                      <Dropdown v-model="retailClient.countryOfResidence"
                      :options="countries"
                      optionLabel="text"
                      optionValue="value"
                      :showClear="true"
                      appendTo="self"
                      class="inputfield w-full"
                      placeholder="Please Select" />
                </div>
            </div>
            <div class=" formgrid grid">
                <div class="col-12 md:col-6 lg:col-6">
                  <h5>Passport Number</h5>
                    <InputText
                    type="text"
                    class="inputfield w-full"
                    v-model="retailClient.passportNumber"/>
                </div>
                 <div class="col-12 md:col-6 lg:col-6">
                  <h5>Country of Issue</h5>
                      <Dropdown v-model="retailClient.passportCountry"
                      :options="countriesAlpha2"
                      optionLabel="text"
                      optionValue="value"
                      :showClear="true"
                      appendTo="self"
                      class="inputfield w-full"
                      placeholder="Please Select" />
                </div>
            </div>
            <div class=" formgrid grid">
                <div class="col-12 md:col-6 lg:col-6">
                    <h5>Passport Issue Date</h5>
                      <Calendar
                      v-model="retailClient.passportIssueDate"
                      :monthNavigator="true"
                      :yearNavigator="true"
                      yearRange="1920:2021"
                      :showIcon="true"
                      appendTo="self"
                      class="inputfield w-full"
                      dateFormat="dd-mm-yy" />
                </div>
                 <div class="col-12 md:col-6 lg:col-6">
                    <h5>Passport Expiry Date</h5>
                      <Calendar
                      v-model="retailClient.passportExpiryDate"
                      :monthNavigator="true"
                      :yearNavigator="true"
                      yearRange="1920:2021"
                      :showIcon="true"
                      appendTo="self"
                      class="inputfield w-full"
                      dateFormat="dd-mm-yy" />
                  </div>
             </div>
          </div>
        </transition>
        <transition name="fade">
          <div v-if="retailClient.residencyStatus == 'TempResident'">
            <div class=" formgrid grid">
                <div class="col-12 md:col-6 lg:col-6">
                  <h5>Temporary Resident Number</h5>
                    <InputText
                    type="text"
                    class="inputfield w-full"
                    v-model="retailClient.tempResNumber"/>
                </div>
                 <div class="col-12 md:col-6 lg:col-6">
                    <h5>Temporary Resident Permit Date</h5>
                      <Calendar
                      v-model="retailClient.tempResDate"
                      :showIcon="true"
                      appendTo="self"
                      class="inputfield w-full"
                      dateFormat="dd-mm-yy" />
                </div>
            </div>
          </div>
        </transition>
        <br />
        <hr />
        <h4 class="text-center"><u>ADDRESS DETAILS</u></h4>
        <h5><u>Residential Address</u></h5>
         <div class=" formgrid grid">
            <div class="col-12 md:col-6 lg:col-6">
              <h5>Street Address</h5>
                <InputText
                type="text"
                autocomplete="nope"
                class="inputfield w-full"
                v-model="retailClient.address"/>
            </div>
            <div class="col-12 md:col-6 lg:col-6">
                <h5>Suburb</h5>
                <InputText
                type="text"
                autocomplete="nope"
                class="inputfield w-full"
                v-model="retailClient.suburb"/>
            </div>
        </div>
         <div class=" formgrid grid">
            <div class="col-12 md:col-6 lg:col-6">
              <h5>City</h5>
                <InputText
                type="text"
                autocomplete="nope"
                class="inputfield w-full"
                v-model="retailClient.city"/>
            </div>
            <div class="col-12 md:col-6 lg:col-6">
                <h5>Province</h5>
                <InputText
                type="text"
                autocomplete="nope"
                class="inputfield w-full"
                v-model="retailClient.province"/>
            </div>
        </div>
         <div class=" formgrid grid">
            <div class="col-12 md:col-6 lg:col-6">
              <h5>Country</h5>
                <Dropdown v-model="retailClient.country"
                :options="countriesAlpha2"
                optionLabel="text"
                optionValue="value"
                :showClear="true"
                appendTo="self"
                class="inputfield w-full"
                placeholder="Please Select" />
            </div>
            <div class="col-12 md:col-6 lg:col-6">
                <h5>Postal Code</h5>
                <InputText
                type="text"
                autocomplete="nope"
                class="inputfield w-full"
                v-model="retailClient.postalCode"/>
            </div>
        </div>
        <br />
        <div class=" formgrid grid">
          <div class="col-12 md:col-6 lg:col-6">
            <h5><u>Postal Address</u></h5>
          </div>
        </div>
        <div class=" formgrid grid">
          <div class="col-12 md:col-12 lg:col-6">
              <div class="field-checkbox">
                  <Checkbox id="postalSame"
                  v-model="retailClient.postalSameAsStreet"
                  :binary="true" />
                  <label for="postalSame">Is postal address same as residential address?</label>
              </div>
            </div>
          </div>
        <br />
        <transition name="fade">
          <div v-if="retailClient.postalSameAsStreet == false">
            <div class="formgrid grid">
                <div class="col-12 md:col-6 lg:col-6">
                  <h5>Address / P.O. Box Number</h5>
                    <InputText
                    type="text"
                    autocomplete="nope"
                    class="inputfield w-full"
                    v-model="retailClient.postalAddress"/>
                </div>
                <div class="col-12 md:col-6 lg:col-6">
                    <h5>Suburb</h5>
                    <InputText
                    type="text"
                    autocomplete="nope"
                    class="inputfield w-full"
                    v-model="retailClient.postalSuburb"/>
                </div>
            </div>
            <div class="formgrid grid">
                <div class="col-12 md:col-6 lg:col-6">
                  <h5>City</h5>
                    <InputText
                    type="text"
                    autocomplete="nope"
                    class="inputfield w-full"
                    v-model="retailClient.postalCity"/>
                </div>
                <div class="col-12 md:col-6 lg:col-6">
                    <h5>Province</h5>
                    <InputText
                    type="text"
                    autocomplete="nope"
                    class="inputfield w-full"
                    v-model="retailClient.postalProvince"/>
                </div>
            </div>
            <div class="formgrid grid">
                <div class="col-12 md:col-6 lg:col-6">
                  <h5>Country</h5>
                    <Dropdown v-model="retailClient.postalCountry"
                    :options="countriesAlpha2"
                    optionLabel="text"
                    optionValue="value"
                    :showClear="true"
                    appendTo="self"
                    class="inputfield w-full"
                    placeholder="Please Select" />
                </div>
                <div class="col-12 md:col-6 lg:col-6">
                    <h5>Postal Code</h5>
                    <InputText
                    type="text"
                    autocomplete="nope"
                    class="inputfield w-full"
                    v-model="retailClient.postalPostalCode"/>
                </div>
            </div>
          </div>
        </transition>
        <br />
        <div class=" formgrid grid">
          <div class="col-12 md:col-6 lg:col-6">
            <h5><u>Trading Address</u>&nbsp;<i>(for Sole Proprietors only)</i></h5>
          </div>
        </div>
        <div class=" formgrid grid">
          <div class="col-12 md:col-12 lg:col-6">
              <div class="field-checkbox">
                  <Checkbox id="soleSame"
                  v-model="retailClient.isSoleProprietor"
                  :binary="true" />
                  <label for="soleSame">Is client opening account as a Sole Proprietor?</label>
              </div>
            </div>
        </div>
        <br />
        <transition name="fade">
        <div v-if="retailClient.isSoleProprietor == true">
          <div class=" formgrid grid">
            <div class="col-12 md:col-12 lg:col-6">
                  <div class="field-checkbox">
                      <Checkbox id="tradingSame"
                        v-model="retailClient.tradingSameAsStreet"
                        :binary="true" />
                        <label for="tradingSame">Is trading address same as residential address?</label>
                  </div>
              </div>
          </div>
            <transition name="fade">
              <div v-if="retailClient.tradingSameAsStreet == false">
                <div class=" formgrid grid">
                    <div class="col-12 md:col-6 lg:col-6">
                      <h5>Address / P.O.Box Number</h5>
                        <InputText
                        type="text"
                        autocomplete="nope"
                        class="inputfield w-full"
                        v-model="retailClient.tradingAddress"/>
                    </div>
                    <div class="col-12 md:col-6 lg:col-6">
                        <h5>Suburb</h5>
                        <InputText
                        type="text"
                        autocomplete="nope"
                        class="inputfield w-full"
                        v-model="retailClient.tradingSuburb"/>
                    </div>
                </div>
                <div class=" formgrid grid">
                    <div class="col-12 md:col-6 lg:col-6">
                      <h5>City</h5>
                        <InputText
                        type="text"
                        autocomplete="nope"
                        class="inputfield w-full"
                        v-model="retailClient.tradingCity"/>
                    </div>
                    <div class="col-12 md:col-6 lg:col-6">
                        <h5>Province</h5>
                        <InputText
                        type="text"
                        autocomplete="nope"
                        class="inputfield w-full"
                        v-model="retailClient.tradingProvince"/>
                    </div>
                </div>
                <div class=" formgrid grid">
                    <div class="col-12 md:col-6 lg:col-6">
                      <h5>Country</h5>
                        <Dropdown v-model="retailClient.tradingCountry"
                        :options="countriesAlpha2"
                        optionLabel="text"
                        optionValue="value"
                        :showClear="true"
                        appendTo="self"
                        class="inputfield w-full"
                        placeholder="Please Select" />
                    </div>
                    <div class="col-12 md:col-6 lg:col-6">
                        <h5>Postal Code</h5>
                        <InputText
                        type="text"
                        autocomplete="nope"
                        class="inputfield w-full"
                        v-model="retailClient.tradingPostalCode"/>
                    </div>
                </div>
              </div>
            </transition>
        </div>
        </transition>
        <br />
        <hr />
        <h4 class="text-center"><u>CONTACT DETAILS</u></h4>
         <div class=" formgrid grid">
            <div class="col-12 md:col-6 lg:col-6">
              <h5>Mobile Number</h5>
                <InputText
                type="text"
                class="inputfield w-full"
                placeholder="no spaces i.e. 0821234567"
                v-model="retailClient.contactNumber1"/>
            </div>
            <div class="col-12 md:col-6 lg:col-6">
                <h5>Home Number</h5>
                <InputText
                type="text"
                class="inputfield w-full"
                v-model="retailClient.contactNumber2"/>
            </div>
        </div>
         <div class=" formgrid grid">
            <div class="col-12 md:col-6 lg:col-6">
              <h5>Work Number</h5>
                <InputText
                type="text"
                class="inputfield w-full"
                v-model="retailClient.contactNumber3"/>
            </div>
            <div class="col-12 md:col-6 lg:col-6">
                <h5>Email Address</h5>
                <InputText
                type="text"
                class="inputfield w-full"
                v-model="retailClient.emailAddress"/>
            </div>
        </div>
        <br />
        <hr />
        <h4 class="text-center"><u>FX ACCOUNT INFORMATION</u></h4>
        <br />
         <div class="formgrid grid">
           <div class="col-12">
              <div class="col-12 md:col-6 lg:col-6">
                <div class="field-checkbox">
                    <Checkbox id="IsZarAccount"
                    v-model="retailClient.isZarAccount"
                    :binary="true" />
                    <label for="IsZarAccount">ZAR based account only?</label>
                </div>
              </div>
              <transition name="fade">
                <div v-if="retailClient.isZarAccount == false">
                  <div class="col-12 md:col-6 lg:col-6">
                    <div class="field-checkbox">
                        <Checkbox id="FcaRequired"
                        v-model="retailClient.fcaRequired"
                        :binary="true" />
                        <label for="FcaRequired">Is an additional Foreign Currency Account (FCA) required?</label>
                    </div>
                  </div>
                  <transition name="fade">
                   <div v-if="retailClient.fcaRequired == true">
                      <div class="formgrid grid">
                        <div class="col-12 md:col-6 lg:col-6">
                          <h5>Currency for FCA</h5>
                          <Dropdown v-model="retailClient.fcaCurrency1"
                            :options="fcaCurrencies"
                            optionLabel="text"
                            optionValue="value"
                            :showClear="true"
                            appendTo="self"
                            class="inputfield w-full"
                            placeholder="Please Select Currency" />
                        </div>
                        <div class="col-12 md:col-6 lg:col-6">
                          <h5>Currency for FCA 2<i>&nbsp;(if applicable)</i></h5>
                          <Dropdown v-model="retailClient.fcaCurrency2"
                            :options="fcaCurrencies"
                            optionLabel="text"
                            optionValue="value"
                            :showClear="true"
                            appendTo="self"
                            class="inputfield w-full"
                            placeholder="Please Select Currency" />
                        </div>
                     </div>
                  </div>
                </transition>
              </div>
            </transition>
          </div>
        </div>
    </Panel>
    <br />
    <Panel class="mx-4 shadow-2" header="KYC : KNOW YOUR CLIENT" :toggleable="true" :collapsed="true">
      <template #end>
          <button class="panel-header-icon link mr-2" @click="toggle"></button>
      </template>
        <h4 class="text-center"><u>EMPLOYMENT INFORMATION</u></h4>
          <div class=" formgrid grid">
            <div class="col-12 md:col-6 lg:col-6">
              <h5>Occupational Field</h5>
                <Dropdown v-model="retailClient.occupation"
                :options="occupations"
                optionLabel="text"
                optionValue="value"
                :showClear="true"
                appendTo="self"
                class="inputfield w-full"
                placeholder="Select Occupation" />
            </div>
             <transition name="fade">
               <div class="col-12 md:col-6 lg:col-6">
                 <div v-if="retailClient.occupation == 'Other (specify)'">
                    <h5>Occupational Field Specify</h5>
                    <InputText
                    type="text"
                    class="inputfield w-full"
                    v-model="retailClient.occupationSpecify"/>
                </div>
              </div>
             </transition>
        </div>
         <div class=" formgrid grid">
            <div class="col-12 md:col-6 lg:col-6">
              <h5>Designation</h5>
                <Dropdown v-model="retailClient.designation"
                :options="designations"
                optionLabel="text"
                optionValue="value"
                :showClear="true"
                appendTo="self"
                class="inputfield w-full"
                placeholder="Select Designation" />
            </div>
             <transition name="fade">
               <div class="col-12 md:col-6 lg:col-6">
                 <div v-if="retailClient.designation == 'Other (specify)'">
                    <h5>Designation Specify</h5>
                    <InputText
                    type="text"
                    class="inputfield w-full"
                    v-model="retailClient.designationSpecify"/>
                </div>
              </div>
             </transition>
        </div>
         <div class=" formgrid grid">
               <div class="col-12 md:col-6 lg:col-6">
                 <div>
                    <h5>Employer Name and Nature of Business</h5>
                    <InputText
                    type="text"
                    class="inputfield w-full"
                    placeholder="i.e. Analytics Consulting 1 - Forex Intermediary Services"
                    v-model="retailClient.businessType"/>
                </div>
              </div>
        </div>
         <div class="col-12 md:col-6 lg:col-6">
            <h5><u>Average monthly income</u></h5>
          </div>
         <div class=" formgrid grid">
           <div class="col-4 md-3 lg-2">
              <div class="field-checkbox">
                  <Checkbox id="less10"
                  v-model="retailClient.lessR10k"
                  :binary="true" />
                  <label for="less10">&lt;R10k</label>
              </div>
            </div>
            <div class="col-4 md-3 lg-2">
              <div class="field-checkbox">
                  <Checkbox id="less20"
                  v-model="retailClient.lessR20k"
                  :binary="true" />
                  <label for="less20">&lt;R20k</label>
              </div>
            </div>
            <div class="col-4 md-3 lg-2">
              <div class="field-checkbox">
                  <Checkbox id="less50"
                  v-model="retailClient.lessR50k"
                  :binary="true" />
                  <label for="less50">&lt;R50k</label>
              </div>
            </div>
            <div class="col-4 md-3 lg-2">
              <div class="field-checkbox">
                  <Checkbox id="less100"
                  v-model="retailClient.lessR100k"
                  :binary="true" />
                  <label for="less100">&lt;R100k</label>
              </div>
            </div>
            <div class="col-4 md-3 lg-2">
                <div class="field-checkbox">
                  <Checkbox id="more100"
                  v-model="retailClient.moreR100k"
                  :binary="true" />
                  <label for="more100">&gt;R100k</label>
              </div>
            </div>
        </div>
        <div class="col-12 md:col-6 lg:col-6">
          <h5><u>Average annual income</u></h5>
        </div>
        <div class=" formgrid grid">
          <div class="col-4 md-3 lg-2">
            <div class="field-checkbox">
              <Checkbox id="lessAnnual100" v-model="retailClient.lessAnnual100k" :binary="true" />
              <label for="lessAnnual100">&lt;R100k</label>
            </div>
          </div>
          <div class="col-4 md-3 lg-2">
            <div class="field-checkbox">
              <Checkbox id="lessAnnual250" v-model="retailClient.lessAnnual250k" :binary="true" />
              <label for="lessAnnual250">&lt;R250k</label>
            </div>
          </div>
          <div class="col-4 md-3 lg-2">
            <div class="field-checkbox">
              <Checkbox id="lessAnnual500" v-model="retailClient.lessAnnual500k" :binary="true" />
              <label for="lessAnnual500">&lt;R500k</label>
            </div>
          </div>
          <div class="col-4 md-3 lg-2">
            <div class="field-checkbox">
              <Checkbox id="lessAnnual1000" v-model="retailClient.lessAnnual1mil" :binary="true" />
              <label for="lessAnnual1000">&lt;R1mil</label>
            </div>
          </div>
          <div class="col-4 md-3 lg-2">
            <div class="field-checkbox">
              <Checkbox id="moreAnnual1000" v-model="retailClient.moreAnnual1mil" :binary="true" />
              <label for="moreAnnual1000">&gt;R1mil</label>
            </div>
          </div>
        </div>
         <div class=" formgrid grid">
            <div class="col-12 md:col-6 lg:col-6">
              <h5>Main Source of Income</h5>
                <Dropdown v-model="retailClient.sourceOfWealth"
                :options="sourcesOfWealth"
                optionLabel="text"
                optionValue="value"
                :showClear="true"
                appendTo="self"
                class="inputfield w-full"
                placeholder="Please Select"/>
            </div>
             <transition name="fade">
               <div class="col-12 md:col-6 lg:col-6">
                 <div v-if="retailClient.sourceOfWealth == 'Other (specify)'">
                    <h5>Source of Income Specify</h5>
                    <InputText
                    type="text"
                    class="inputfield w-full"
                    v-model="retailClient.sourceOfWealthSpecify"/>
                </div>
              </div>
             </transition>
        </div>
         <div class=" formgrid grid">
            <div class="col-12 md:col-6 lg:col-6">
              <h5>Source of Funds</h5>
                <Dropdown v-model="retailClient.sourceOfFunds"
                :options="sourcesOfFunds"
                optionLabel="text"
                optionValue="value"
                :showClear="true"
                appendTo="self"
                class="inputfield w-full"
                placeholder="Please Select"/>
            </div>
             <transition name="fade">
               <div class="col-12 md:col-6 lg:col-6">
                 <div v-if="retailClient.sourceOfFunds == 'Other (specify)'">
                    <h5>Source of Funds Specify</h5>
                    <InputText
                    type="text"
                    class="inputfield w-full"
                    v-model="retailClient.sourceOfFundsSpecify"/>
                </div>
              </div>
             </transition>
        </div>
         <div class=" formgrid grid">
            <div class="col-12 md:col-6 lg:col-6">
              <h5>Third Party Account</h5>
                <Dropdown v-model="retailClient.thirdPartyAccount"
                :options="thirdPartyAccounts"
                optionLabel="text"
                optionValue="value"
                :showClear="true"
                appendTo="self"
                class="inputfield w-full"
                placeholder="Please Select"/>
            </div>
            <div class="col-12 md:col-6 lg:col-6">
                <h5>Account Domicile</h5>
                <Dropdown v-model="retailClient.accountDomicile"
                :options="countries"
                optionLabel="text"
                optionValue="value"
                :showClear="true"
                appendTo="self"
                class="inputfield w-full"
                placeholder="Please Select"/>
            </div>
        </div>
         <div class=" formgrid grid">
            <div class="col-12 md:col-6 lg:col-6">
              <h5>Investment/Transaction Value</h5>
                <Dropdown v-model="retailClient.investmentValue"
                :options="investmentValues"
                optionLabel="text"
                optionValue="value"
                :showClear="true"
                appendTo="self"
                class="inputfield w-full"
                placeholder="Select Investment Value"/>
            </div>
        </div>
        <div class="col-12 md:col-6 lg:col-6">
          <h5>Product Type</h5>
        </div>
        <div class=" formgrid grid">
          <div class="col-4 md-3 lg-2">
            <div class="field-checkbox">
              <Checkbox id="spotForeignExchange" v-model="retailClient.spotForeignExchange" :binary="true" />
              <label for="spotForeignExchange">Spot Foreign Exchange Transaction</label>
            </div>
            <div class="field-checkbox">
              <Checkbox id="forwardExchange" v-model="retailClient.forwardExchange" :binary="true" />
              <label for="forwardExchange">Forward Exchange</label>
            </div>
          </div>
        </div>
        <br />
        <hr />
        <h4 class="text-center"><u>FATCA (Foreign Account Tax Compliance Act) INFORMATION</u></h4>
        <br />
         <div class=" formgrid grid">
           <div class="col-12">
            <div class="col-12 md:col-6 lg:col-6">
                <div class="field-checkbox">
                    <Checkbox id="UsPersonNo"
                    v-model="retailClient.usPersonNo"
                    :binary="true" />
                    <label for="UsPersonNo">I am not a U.S. Person</label>
                </div>
              </div>
              <transition name="fade">
                <div v-if="retailClient.usPersonNo == false">
                  <div class="col-12 md:col-12 lg:col-6">
                    <div class="field-checkbox">
                        <Checkbox id="UsPersonYes"
                        v-model="retailClient.usPersonYes"
                        :binary="true" />
                        <label for="UsPersonYes">I am a U.S Person, (please provide your Tax Identification number)</label>
                    </div>
                  </div>
                    <div class="col-12 md:col-6 lg:col-6">
                        <h5>Tax Identification Number</h5>
                        <InputText
                        type="text"
                        class="inputfield w-full"
                        v-model="retailClient.tinNumber"/>
                    </div>
                </div>
              </transition>
            </div>
         </div>
         <br />
         <hr />
         <h4 class="text-center"><u>COMMON REPORTING STANDARDS</u></h4>
            <h5>Are you a Tax Resident in a Jurisdiction other than South Africa?</h5>
             <div class=" formgrid grid">
               <div class="col-12">
                <div class="col-6 md-4 lg-3">
                  <div class="field-checkbox">
                      <Checkbox id="ExSaTaxResidentNo"
                      v-model="retailClient.exSaTaxResidentNo"
                      :binary="true" />
                      <label for="ExSaTaxResidentNo">No</label>
                  </div>
                </div>
                <div class="col-6 md-4 lg-3">
                  <div class="field-checkbox">
                      <Checkbox id="ExSaTaxResidentYes"
                      v-model="retailClient.exSaTaxResidentYes"
                      :binary="true" />
                      <label for="ExSaTaxResidentYes">Yes</label>
                  </div>
                </div>
            </div>
         </div>
          <transition name="fade">
            <div v-if="retailClient.exSaTaxResidentNo == false">
              <div class=" formgrid grid">
                <div class="col-12 md:col-6 lg:col-6">
                    <h5>Country</h5>
                    <Dropdown v-model="retailClient.country1"
                    :options="countries"
                    optionLabel="text"
                    optionValue="value"
                    :showClear="true"
                    appendTo="self"
                    class="inputfield w-full"
                    placeholder="Select Country"/>
                </div>
                <div class="col-12 md:col-6 lg:col-6">
                    <h5>Tax Identification Number</h5>
                    <InputText
                    type="text"
                    class="inputfield w-full"
                    v-model="retailClient.tinNumber1"/>
                </div>
            </div>
        </div>
      </transition>
      <br />
      <hr />
      <h4 class="text-center"><u>CLIENT SIGNING ARRANGEMENT</u></h4>
       <div class=" formgrid grid">
         <div class="col-12">
          <div class="col-12 md:col-6 lg:col-6">
              <div class="field-checkbox">
               <Checkbox id="multipleSignatories"
                  v-model="retailClient.multipleSignatories"
                  :binary="true" />
                  <label for="ExSaTaxResidentYes">Is more than one signatory required?</label>
              </div>
            </div>
          </div>
        </div>
        <br />
         <transition name="fade">
            <div v-if="retailClient.multipleSignatories == true">
              <div class=" formgrid grid">
                <div class="col-12 md:col-6 lg:col-6">
                    <h5>Full Names of Additional Signatory</h5>
                    <InputText
                    type="text"
                    class="inputfield w-full"
                    v-model="retailClient.clSignFullNames2"/>
                </div>
                <div class="col-12 md:col-6 lg:col-6">
                    <h5>Capacity of Additional Signatory</h5>
                    <InputText
                    type="text"
                    class="inputfield w-full"
                    placeholder="i.e. Power of Attorney"
                    v-model="retailClient.capacity2"/>
                </div>
            </div>
        </div>
      </transition>
        <br />
    </Panel>
    <br />
    <Panel class="mx-4 shadow-2" header="FOR OFFICE USE" :toggleable="true" :collapsed="true">
      <template #end>
          <button class="panel-header-icon link mr-2" @click="toggle"></button>
      </template>
        <div class="col-12 md:col-6 lg:col-6">
            <h5><u>Confirmation of Client on-boarding process:</u></h5>
          </div>
          <div class="formgrid grid">
           <div class="col-12 md:col-6 lg:col-4">
              <div class="field-checkbox">
                  <Checkbox id="face"
                  v-model="retailClient.faceToFace"
                  :binary="true" />
                  <label for="face">Face-to-Face</label>
              </div>
            </div>
            <div class="col-12 md:col-6 lg:col-4">
              <div class="field-checkbox">
                  <Checkbox id="nonFace"
                  v-model="retailClient.nonFaceToFace"
                  :binary="true" />
                  <label for="nonFace">Non Face-to-Face</label>
              </div>
            </div>
            <div class="col-12 md:col-6 lg:col-4">
              <div class="field-checkbox">
                  <Checkbox id="broker"
                  v-model="retailClient.brokerIntroduced"
                  :binary="true" />
                  <label for="broker">Broker Introduced</label>
              </div>
            </div>
         </div>
          <div class="col-12 md:col-6 lg:col-6">
            <h5><u>Account Type:</u></h5>
          </div>
          <div class="formgrid grid">
            <div class="col-12 md:col-6 lg:col-4">
              <div class="field-checkbox">
                  <Checkbox id="trading"
                  v-model="retailClient.tradingAccount"
                  :binary="true" />
                  <label for="trading">Trading Account</label>
              </div>
            </div>
            <div class="col-12 md:col-6 lg:col-4">
                <div class="field-checkbox">
                  <Checkbox id="resident"
                  v-model="retailClient.resident"
                  :binary="true" />
                  <label for="resident">Resident</label>
              </div>
            </div>
             <div class="col-12 md:col-6 lg:col-4">
                <div class="field-checkbox">
                  <Checkbox id="nonResident"
                  v-model="retailClient.nonResident"
                  :binary="true" />
                  <label for="nonResident">Non Resident</label>
              </div>
            </div>
        </div>
         <div class="formgrid grid">
            <div class="col-12 md:col-6 lg:col-4">
              <div class="field-checkbox">
                  <Checkbox id="eba"
                  v-model="retailClient.emigrantBlockedAccount"
                  :binary="true" />
                  <label for="eba">Emigrant blocked account (EBA)</label>
              </div>
            </div>
         </div>
          <transition name="fade">
            <div v-if="retailClient.emigrantBlockedAccount == true">
                <div class="col-12 md:col-12 lg:col-6">
                  <h5>(Please tick one of the below options to indicate the EBA account type that is required)</h5>
                </div>
                <div class="formgrid grid">
                  <div class="col-12 md:col-6 lg:col-4">
                    <div class="field-checkbox">
                        <Checkbox id="savings"
                        v-model="retailClient.ebaSavings"
                        :binary="true" />
                        <label for="trading">Savings</label>
                    </div>
                  </div>
                  <div class="col-12 md:col-6 lg:col-4">
                      <div class="field-checkbox">
                        <Checkbox id="current"
                        v-model="retailClient.ebaCurrent"
                        :binary="true" />
                        <label for="current">Current</label>
                    </div>
                  </div>
                  <div class="col-12 md:col-6 lg:col-4">
                      <div class="field-checkbox">
                        <Checkbox id="ebaTrading"
                        v-model="retailClient.ebaTradingAccount"
                        :binary="true" />
                        <label for="ebaTrading">Trading Account</label>
                    </div>
                  </div>
               </div>
               <div class="col-12 md:col-6 lg:col-6">
               <h5><u>Exchange Control Information (Section 2.3 on Form 90D) :</u></h5>
               </div>
                <div class="formgrid grid">
                  <div class="col-12 md:col-6 lg:col-4">
                      <div class="field-checkbox">
                          <Checkbox id="face"
                          v-model="retailClient.exchangeControl1"
                          :binary="true" />
                          <label for="face">1. I was born in South Africa</label>
                      </div>
                    </div>
                    <div class="col-12 md:col-6 lg:col-4">
                      <div class="field-checkbox">
                          <Checkbox id="nonFace"
                          v-model="retailClient.exchangeControl2"
                          :binary="true" />
                          <label for="nonFace">2. Registered as Permanent Resident</label>
                      </div>
                    </div>
                    <div class="col-12 md:col-6 lg:col-4">
                      <div class="field-checkbox">
                          <Checkbox id="broker"
                          v-model="retailClient.exchangeControl3"
                          :binary="true" />
                          <label for="broker">3. Not registered as Permanent Resident</label>
                      </div>
                    </div>
                </div>
            </div>
          </transition>
          <br />
          <div class="formgrid grid">
            <div class="col-12 md:col-6 lg:col-4">
              <div class="field-checkbox">
                  <Checkbox id="capt"
                  v-model="retailClient.clientAllocatedToPartner"
                  :binary="true" />
                  <label for="capt"><b>Is this Client being allocated to a Partner?</b></label>
              </div>
            </div>
         </div>
         <transition name="fade">
           <div v-if="retailClient.clientAllocatedToPartner == true">
              <div class=" formgrid grid">
                  <div class="col-12 md:col-6 lg:col-6">
                    <h5>Partner</h5>
                      <Dropdown v-model="retailClient.partnerId"
                      :options="partners"
                      optionLabel="text"
                      optionValue="value"
                      :showClear="true"
                      appendTo="self"
                      class="inputfield w-full"
                      placeholder="Select Partner"/>
                  </div>
              </div>
           </div>
          </transition>
        <br />
        <hr />
          <div class="formgrid grid">
              <div class="col-12 md:col-6 lg:col-6">
                <h5>External Reference<i>(for Mercantile API)</i></h5>
                  <InputText
                  type="text"
                  class="inputfield w-full"
                  v-model="retailClient.externalReference"/>
              </div>
              <div class="col-12 md:col-6 lg:col-6">
                <h5>Geocode</h5>
                  <Dropdown v-model="retailClient.geoCode"
                  :options="geoCodes"
                  optionLabel="text"
                  optionValue="value"
                  :showClear="true"
                  appendTo="self"
                  class="inputfield w-full"
                  placeholder="Please Select" />
              </div>
          </div>
            <div class="formgrid grid">
              <div class="col-12 md:col-6 lg:col-6">
                <h5>Ip Introducer</h5>
                  <InputText
                  type="text"
                  class="inputfield w-full"
                  v-model="retailClient.ipIntroducer"/>
              </div>
            </div>
            <br />
            <div class="formgrid grid">
                <div class="col-12 md:col-6 lg:col-4">
                  <div class="field-checkbox">
                      <Checkbox id="switch"
                      v-model="retailClient.isSwitchClient"
                      :binary="true" />
                      <label for="switch">Is this a Switch Client?</label>
                  </div>
                </div>
          </div>
        <transition name="fade">
          <div v-if="retailClient.isSwitchClient == true">
            <div class=" formgrid grid">
                <div class="col-12 md:col-6 lg:col-6">
                  <h5>Previous Intermediary</h5>
                    <InputText
                    type="text"
                    class="inputfield w-full"
                    v-model="retailClient.previousIntermediary"/>
                </div>
                 <div class="col-12 md:col-6 lg:col-6">
                    <h5>Resignation Date</h5>
                      <Calendar
                      v-model="retailClient.resignationDate"
                      :showIcon="true"
                      appendTo="self"
                      class="inputfield w-full"
                      dateFormat="dd-mm-yy" />
                </div>
            </div>
          </div>
        </transition>
        <br />
    </Panel>
    <br />
    <Panel class="mx-4 shadow-4" header="ACCOUNT DETAILS" :toggleable="true" :collapsed="true">
      <template #end>
          <button class="panel-header-icon link mr-2" @click="toggle"></button>
      </template>
      <h4 class="text-center"><u>MERCANTILE ACCOUNT DETAILS</u></h4>
        <div class=" formgrid grid">
          <div class="col-12 md:col-6 lg:col-6">
            <h5>CIF Number</h5>
              <InputText
              type="text"
              class="inputfield w-full"
              v-model="retailClient.cifNumber"/>
          </div>
          <div class="col-12 md:col-6 lg:col-6">
              <h5>ZAR Account Number</h5>
              <InputText
              type="text"
              class="inputfield w-full"
              v-model="retailClient.zarAccountNumber"/>
          </div>
        </div>
        <br />
        <div class="col-12 md:col-6 lg:col-6">
          <div class="field-checkbox">
              <Checkbox id="FcaRequired"
              v-model="retailClient.fcaRequired"
              :binary="true" />
              <label for="FcaRequired">Add Foreign Currency Account (FCA) Detail</label>
          </div>
        </div>
      <transition name="fade">
        <div v-if="retailClient.fcaRequired == true">
          <div class=" formgrid grid">
            <div class="col-12 md:col-6 lg:col-6">
              <h5>FCA Account Number 1</h5>
                <InputText
                type="text"
                class="inputfield w-full"
                v-model="retailClient.fcaAccountNumber1"/>
              </div>
              <div class="col-12 md:col-6 lg:col-6">
                <h5>Currency for FCA</h5>
                <Dropdown v-model="retailClient.fcaCurrency1"
                  :options="fcaCurrencies"
                  optionLabel="text"
                  optionValue="value"
                  :showClear="true"
                  appendTo="self"
                  class="inputfield w-full"
                  placeholder="Please Select Currency" />
              </div>
          </div>
          <div class=" formgrid grid">
            <div class="col-12 md:col-6 lg:col-6">
              <h5>FCA Account Number 2<i>&nbsp;(if applicable)</i></h5>
                <InputText
                type="text"
                class="inputfield w-full"
                v-model="retailClient.fcaAccountNumber2"/>
            </div>
              <div class="col-12 md:col-6 lg:col-6">
                <h5>Currency for FCA 2<i>&nbsp;(if applicable)</i></h5>
                <Dropdown v-model="retailClient.fcaCurrency2"
                  :options="fcaCurrencies"
                  optionLabel="text"
                  optionValue="value"
                  :showClear="true"
                  appendTo="self"
                  class="inputfield w-full"
                  placeholder="Please Select Currency" />
              </div>
          </div>
        </div>
      </transition>
    <br />
    </Panel>
    <br />
    <Panel class="mx-4 shadow-4" header="LANDOBYTE API" :toggleable="true" :collapsed="true">
      <template #end>
          <button class="panel-header-icon link mr-2" @click="toggle"></button>
      </template>
      <div class=" formgrid grid">
        <div class="col-12 md:col-6 lg:col-6">
            <Button class="l-2 m-2 mx-4 shadow-4 p-button-lg "
              icon="pi pi-upload"
              label="&nbsp;UPLOAD TO LANDOBYTE&nbsp;"
              id="submitButton"
              :loading="loadingApi"
              @click.prevent="sumbitLandoApi" />
        </div>
      </div>
    </Panel>
    <br />
    <div class=" formgrid grid">
      <div class="col-12 md:col-6 lg:col-6">
        <div v-if='success'>
        <Dialog class="successModal" header="SUCCESS" v-model:visible="displayConfirmation">
            <div class="confirmation-content">
                <span>Client Details Edited</span>
            </div>
            <template #footer>
              <div class="flex justify-content-between">
                <router-link class="p-button d-block mr-1 px-auto" :to="'/ClientView/' + retailClient.id"  style="background-color: #014f7e!important;
                                    text-decoration: none; width: 45%;">
                  <i class="pi pi-user" style="fontSize: 1rem"></i>&nbsp;&nbsp;View Client
                </router-link>
                <router-link class="p-button d-block mx-1 px-auto" :to="'/clientListView'"  style="background-color: #0185b7!important;
                                    text-decoration: none; width: 45%;">
                  <i class="pi pi-users" style="fontSize: 1rem"></i>&nbsp;&nbsp;View Clients
                </router-link>
              </div>
            </template>
        </Dialog>
        </div>
      </div>
    </div>
      <div v-if='landoMessage'>
        <Dialog class="popiModal" header="LANDOBYTE API RESPONSE" v-model:visible="displayConfirmation">
            <div class="confirmation-content">
                <span>{{ message }}</span>
            </div>
            <template #footer>
                <Button class="l-2 m-2 mx-4 shadow-4 p-button-lg "
                icon="pi pi-save"
                label="SAVE&nbsp;"
                id="backButton"
                @click.prevent="saveClientEdit" />
            </template>
        </Dialog>
      </div>
    <div class="flex" style="float: right;">
          <div>
            <Button class="l-2 m-2 mx-4 shadow-4 p-button-lg "
            icon="pi pi-save"
            label="SAVE&nbsp;"
            id="backButton"
            @click.prevent="saveClientEdit" />
        </div>
    </div>
    <br />
    <br />
</template>

<script>
// @ is an alias to /src
import { editUpdateClient, editClient, getPartners, submitToLandobyte } from '@/gateway'

export default {
  name: 'EditClient',
  inheritAttrs: false,
  data () {
    return {
      retailClient: [],
      partners: [],
      landoByteResponse: {},
      showFullName: false,
      success: false,
      displayConfirmation: true,
      landoMessage: false,
      responseStatus: '',
      loadingApi: false,
      message: '',
      titles: [
        { value: 'Mr', text: 'Mr' },
        { value: 'Mrs', text: 'Mrs' },
        { value: 'Ms', text: 'Ms' },
        { value: 'Dr', text: 'Dr' },
        { value: 'Prof', text: 'Prof' }
      ],
      genders: [
        { value: 'Female', text: 'Female' },
        { value: 'Male', text: 'Male' }
      ],
      residencies: [
        { value: 'SaCitizen', text: 'RSA Citizen' },
        { value: 'RSAResident', text: 'RSA Resident' },
        { value: 'NonResident', text: 'Non-Resident' },
        { value: 'TempResident', text: 'Temporary Resident' }
      ],
      countries: [
        { value: 'South Africa', text: 'South Africa' },
        { value: 'Afghanistan', text: 'Afghanistan' },
        { value: 'Albania', text: 'Albania' },
        { value: 'Algeria', text: 'Algeria' },
        { value: 'Andorra', text: 'Andorra' },
        { value: 'Angola', text: 'Angola' },
        { value: 'Anguilla', text: 'Anguilla' },
        { value: 'Antigua and Barbuda', text: 'Antigua and Barbuda' },
        { value: 'Argentina', text: 'Argentina' },
        { value: 'Armenia', text: 'Armenia' },
        { value: 'Aruba (Kingdom of the Netherlands)', text: 'Aruba (Kingdom of the Netherlands)' },
        { value: 'Australia', text: 'Australia' },
        { value: 'Austria', text: 'Austria' },
        { value: 'Azerbaijan', text: 'Azerbaijan' },
        { value: 'Bahamas', text: 'Bahamas' },
        { value: 'Bahrain', text: 'Bahrain' },
        { value: 'Bangladesh', text: 'Bangladesh' },
        { value: 'Barbados', text: 'Barbados' },
        { value: 'Belarus', text: 'Belarus' },
        { value: 'Belgium', text: 'Belgium' },
        { value: 'Belize', text: 'Belize' },
        { value: 'Benin', text: 'Benin' },
        { value: 'Bermuda', text: 'Bermuda' },
        { value: 'Bhutan', text: 'Bhutan' },
        { value: 'Bolivia', text: 'Bolivia' },
        { value: 'Bosnia and Herzegovina', text: 'Bosnia and Herzegovina' },
        { value: 'Botswana', text: 'Botswana' },
        { value: 'Brazil', text: 'Brazil' },
        { value: 'Brunei Darussalam', text: 'Brunei Darussalam' },
        { value: 'Bulgaria', text: 'Bulgaria' },
        { value: 'Burkina Faso', text: 'Burkina Faso' },
        { value: 'Cabo Verde', text: 'Cabo Verde' },
        { value: 'Cambodia', text: 'Cambodia' },
        { value: 'Cameroon', text: 'Cameroon' },
        { value: 'Canada', text: 'Canada' },
        { value: 'Cayman Islands', text: 'Cayman Islands' },
        { value: 'Central African Republic', text: 'Central African Republic' },
        { value: 'Chad', text: 'Chad' },
        { value: 'Chile', text: 'Chile' },
        { value: 'China', text: 'China' },
        { value: 'Chinese Taipei', text: 'Chinese Taipei' },
        { value: 'Colombia', text: 'Colombia' },
        { value: 'Comoros', text: 'Comoros' },
        { value: 'Congo', text: 'Congo' },
        { value: 'Cook Islands', text: 'Cook Islands' },
        { value: 'Costa Rica', text: 'Costa Rica' },
        { value: 'Cote d\'Ivoire', text: 'Cote d\'Ivoire' },
        { value: 'Croatia', text: 'Croatia' },
        { value: 'Cuba', text: 'Cuba' },
        { value: 'Curacao (Kingdom of the Netherlands)', text: 'Curacao (Kingdom of the Netherlands)' },
        { value: 'Cyprus', text: 'Cyprus' },
        { value: 'Czech Republic', text: 'Czech Republic' },
        { value: 'Democratic People\'s Republic of Korea (DPRK)', text: 'Democratic People\'s Republic of Korea (DPRK)' },
        { value: 'Democratic Republic of the Congo', text: 'Democratic Republic of the Congo' },
        { value: 'Denmark', text: 'Denmark' },
        { value: 'Djibouti', text: 'Djibouti' },
        { value: 'Dominica', text: 'Dominica' },
        { value: 'Dominican Republic', text: 'Dominican Republic' },
        { value: 'Ecuador', text: 'Ecuador' },
        { value: 'Egypt', text: 'Egypt' },
        { value: 'El Salvador', text: 'El Salvador' },
        { value: 'Equatorial Guinea', text: 'Equatorial Guinea' },
        { value: 'Estonia', text: 'Estonia' },
        { value: 'Ethiopia', text: 'Ethiopia' },
        { value: 'European Commission', text: 'European Commission' },
        { value: 'Fiji', text: 'Fiji' },
        { value: 'Finland', text: 'Finland' },
        { value: 'France', text: 'France' },
        { value: 'Gabon', text: 'Gabon' },
        { value: 'Gambia', text: 'Gambia' },
        { value: 'Georgia', text: 'Georgia' },
        { value: 'Germany', text: 'Germany' },
        { value: 'Ghana', text: 'Ghana' },
        { value: 'Gibraltar', text: 'Gibraltar' },
        { value: 'Greece', text: 'Greece' },
        { value: 'Grenada', text: 'Grenada' },
        { value: 'Guatemala', text: 'Guatemala' },
        { value: 'Guernsey', text: 'Guernsey' },
        { value: 'Guinea', text: 'Guinea' },
        { value: 'Guinea Bissau', text: 'Guinea Bissau' },
        { value: 'Gulf Co-operation Council', text: 'Gulf Co-operation Council' },
        { value: 'Guyana', text: 'Guyana' },
        { value: 'Haiti', text: 'Haiti' },
        { value: 'Honduras', text: 'Honduras' },
        { value: 'Hong Kong, China', text: 'Hong Kong, China' },
        { value: 'Hungary', text: 'Hungary' },
        { value: 'Iceland', text: 'Iceland' },
        { value: 'India', text: 'India' },
        { value: 'Indonesia', text: 'Indonesia' },
        { value: 'Iran', text: 'Iran' },
        { value: 'Iraq', text: 'Iraq' },
        { value: 'Ireland', text: 'Ireland' },
        { value: 'Isle of Man', text: 'Isle of Man' },
        { value: 'Israel', text: 'Israel' },
        { value: 'Italy', text: 'Italy' },
        { value: 'Jamaica', text: 'Jamaica' },
        { value: 'Japan', text: 'Japan' },
        { value: 'Jersey', text: 'Jersey' },
        { value: 'Jordan', text: 'Jordan' },
        { value: 'Kazakhstan', text: 'Kazakhstan' },
        { value: 'Kenya', text: 'Kenya' },
        { value: 'Kuwait', text: 'Kuwait' },
        { value: 'Kyrgyzstan', text: 'Kyrgyzstan' },
        { value: 'Lao People\'s Democratic Republic', text: 'Lao People\'s Democratic Republic' },
        { value: 'Latvia', text: 'Latvia' },
        { value: 'Lebanon', text: 'Lebanon' },
        { value: 'Lesotho', text: 'Lesotho' },
        { value: 'Liberia', text: 'Liberia' },
        { value: 'Libya', text: 'Libya' },
        { value: 'Liechtenstein', text: 'Liechtenstein' },
        { value: 'Lithuania', text: 'Lithuania' },
        { value: 'Luxembourg', text: 'Luxembourg' },
        { value: 'Macao (China)', text: 'Macao (China)' },
        { value: 'Madagascar', text: 'Madagascar' },
        { value: 'Malawi', text: 'Malawi' },
        { value: 'Malaysia', text: 'Malaysia' },
        { value: 'Maldives', text: 'Maldives' },
        { value: 'Mali', text: 'Mali' },
        { value: 'Malta', text: 'Malta' },
        { value: 'Marshall Islands', text: 'Marshall Islands' },
        { value: 'Mauritania', text: 'Mauritania' },
        { value: 'Mauritius', text: 'Mauritius' },
        { value: 'Mexico', text: 'Mexico' },
        { value: 'Moldova', text: 'Moldova' },
        { value: 'Monaco', text: 'Monaco' },
        { value: 'Mongolia', text: 'Mongolia' },
        { value: 'Montenegro', text: 'Montenegro' },
        { value: 'Montserrat', text: 'Montserrat' },
        { value: 'Morocco', text: 'Morocco' },
        { value: 'Mozambique', text: 'Mozambique' },
        { value: 'Myanmar', text: 'Myanmar' },
        { value: 'Namibia', text: 'Namibia' },
        { value: 'Nauru', text: 'Nauru' },
        { value: 'Nepal', text: 'Nepal' },
        { value: 'Netherlands', text: 'Netherlands' },
        { value: 'New Zealand', text: 'New Zealand' },
        { value: 'Nicaragua', text: 'Nicaragua' },
        { value: 'Niger', text: 'Niger' },
        { value: 'Nigeria', text: 'Nigeria' },
        { value: 'Niue', text: 'Niue' },
        { value: 'Norway', text: 'Norway' },
        { value: 'Oman', text: 'Oman' },
        { value: 'Pakistan', text: 'Pakistan' },
        { value: 'Palau', text: 'Palau' },
        { value: 'Palestinian Authority', text: 'Palestinian Authority' },
        { value: 'Panama', text: 'Panama' },
        { value: 'Papua New Guinea', text: 'Papua New Guinea' },
        { value: 'Paraguay', text: 'Paraguay' },
        { value: 'Peru', text: 'Peru' },
        { value: 'Philippines', text: 'Philippines' },
        { value: 'Poland', text: 'Poland' },
        { value: 'Portugal', text: 'Portugal' },
        { value: 'Qatar', text: 'Qatar' },
        { value: 'Republic of Korea', text: 'Republic of Korea' },
        { value: 'Republic of North Macedonia', text: 'Republic of North Macedonia' },
        { value: 'Romania', text: 'Romania' },
        { value: 'Russian Federation', text: 'Russian Federation' },
        { value: 'Rwanda', text: 'Rwanda' },
        { value: 'Saint Kitts and Nevis', text: 'Saint Kitts and Nevis' },
        { value: 'Saint Lucia', text: 'Saint Lucia' },
        { value: 'Saint Vincent & the Grenadines', text: 'Saint Vincent & the Grenadines' },
        { value: 'Samoa', text: 'Samoa' },
        { value: 'San Marino', text: 'San Marino' },
        { value: 'Sao Tome and Principe', text: 'Sao Tome and Principe' },
        { value: 'Saudi Arabia', text: 'Saudi Arabia' },
        { value: 'Senegal', text: 'Senegal' },
        { value: 'Serbia', text: 'Serbia' },
        { value: 'Seychelles', text: 'Seychelles' },
        { value: 'Sierra Leone', text: 'Sierra Leone' },
        { value: 'Singapore', text: 'Singapore' },
        { value: 'Sint Maarten (Kingdom of the Netherlands)', text: 'Sint Maarten (Kingdom of the Netherlands)' },
        { value: 'Slovakia', text: 'Slovakia' },
        { value: 'Slovenia', text: 'Slovenia' },
        { value: 'Solomon Islands', text: 'Solomon Islands' },
        { value: 'Somalia', text: 'Somalia' },
        { value: 'Spain', text: 'Spain' },
        { value: 'Sri Lanka', text: 'Sri Lanka' },
        { value: 'Sudan', text: 'Sudan' },
        { value: 'Suriname', text: 'Suriname' },
        { value: 'Swaziland', text: 'Swaziland' },
        { value: 'Sweden', text: 'Sweden' },
        { value: 'Switzerland', text: 'Switzerland' },
        { value: 'Syria', text: 'Syria' },
        { value: 'Tajikistan', text: 'Tajikistan' },
        { value: 'Tanzania', text: 'Tanzania' },
        { value: 'Thailand', text: 'Thailand' },
        { value: 'Timor Leste', text: 'Timor Leste' },
        { value: 'Togo', text: 'Togo' },
        { value: 'Tonga', text: 'Tonga' },
        { value: 'Trinidad and Tobago', text: 'Trinidad and Tobago' },
        { value: 'Tunisia', text: 'Tunisia' },
        { value: 'Turkey', text: 'Turkey' },
        { value: 'Turkmenistan', text: 'Turkmenistan' },
        { value: 'Turks and Caicos Islands', text: 'Turks and Caicos Islands' },
        { value: 'Uganda', text: 'Uganda' },
        { value: 'Ukraine', text: 'Ukraine' },
        { value: 'United Arab Emirates', text: 'United Arab Emirates' },
        { value: 'United Kingdom', text: 'United Kingdom' },
        { value: 'United States of America', text: 'United States of America' },
        { value: 'Uruguay', text: 'Uruguay' },
        { value: 'Uzbekistan', text: 'Uzbekistan' },
        { value: 'Vanuatu', text: 'Vanuatu' },
        { value: 'Venezuela', text: 'Venezuela' },
        { value: 'Vietnam', text: 'Vietnam' },
        { value: 'Virgin Islands (United Kingdom)', text: 'Virgin Islands (United Kingdom)' },
        { value: 'Yemen', text: 'Yemen' },
        { value: 'Zambia', text: 'Zambia' },
        { value: 'Zimbabwe', text: 'Zimbabwe' }
      ],
      countriesAlpha2: [
        { value: 'ZA', text: 'South Africa' },
        { value: 'AF', text: 'Afghanistan' },
        { value: 'AL', text: 'Albania' },
        { value: 'DZ', text: 'Algeria' },
        { value: 'AD', text: 'Andorra' },
        { value: 'AO', text: 'Angola' },
        { value: 'AI', text: 'Anguilla' },
        { value: 'AG', text: 'Antigua and Barbuda' },
        { value: 'AR', text: 'Argentina' },
        { value: 'AM', text: 'Armenia' },
        { value: 'AW', text: 'Aruba (Kingdom of the Netherlands)' },
        { value: 'AU', text: 'Australia' },
        { value: 'AT', text: 'Austria' },
        { value: 'AZ', text: 'Azerbaijan' },
        { value: 'BS', text: 'Bahamas' },
        { value: 'BH', text: 'Bahrain' },
        { value: 'BD', text: 'Bangladesh' },
        { value: 'BB', text: 'Barbados' },
        { value: 'BY', text: 'Belarus' },
        { value: 'BE', text: 'Belgium' },
        { value: 'BZ', text: 'Belize' },
        { value: 'BJ', text: 'Benin' },
        { value: 'BM', text: 'Bermuda' },
        { value: 'BT', text: 'Bhutan' },
        { value: 'BO', text: 'Bolivia' },
        { value: 'BA', text: 'Bosnia and Herzegovina' },
        { value: 'BW', text: 'Botswana' },
        { value: 'BR', text: 'Brazil' },
        { value: 'BN', text: 'Brunei Darussalam' },
        { value: 'BG', text: 'Bulgaria' },
        { value: 'BF', text: 'Burkina Faso' },
        { value: 'CV', text: 'Cabo Verde' },
        { value: 'KH', text: 'Cambodia' },
        { value: 'CM', text: 'Cameroon' },
        { value: 'CA', text: 'Canada' },
        { value: 'KY', text: 'Cayman Islands' },
        { value: 'CF', text: 'Central African Republic' },
        { value: 'TD', text: 'Chad' },
        { value: 'CL', text: 'Chile' },
        { value: 'CN', text: 'China' },
        { value: 'TW', text: 'Chinese Taipei' },
        { value: 'CO', text: 'Colombia' },
        { value: 'KM', text: 'Comoros' },
        { value: 'CG', text: 'Congo' },
        { value: 'CK', text: 'Cook Islands' },
        { value: 'CR', text: 'Costa Rica' },
        { value: 'CI', text: 'Cote d\'Ivoire' },
        { value: 'HR', text: 'Croatia' },
        { value: 'CU', text: 'Cuba' },
        { value: 'CW', text: 'Curacao (Kingdom of the Netherlands)' },
        { value: 'CY', text: 'Cyprus' },
        { value: 'CZ', text: 'Czech Republic' },
        { value: 'KP', text: 'Democratic People\'s Republic of Korea (DPRK)' },
        { value: 'CD', text: 'Democratic Republic of the Congo' },
        { value: 'DK', text: 'Denmark' },
        { value: 'DJ', text: 'Djibouti' },
        { value: 'DM', text: 'Dominica' },
        { value: 'DO', text: 'Dominican Republic' },
        { value: 'EC', text: 'Ecuador' },
        { value: 'EG', text: 'Egypt' },
        { value: 'SV', text: 'El Salvador' },
        { value: 'GQ', text: 'Equatorial Guinea' },
        { value: 'EE', text: 'Estonia' },
        { value: 'ET', text: 'Ethiopia' },
        { value: 'EU', text: 'European Commission' },
        { value: 'FJ', text: 'Fiji' },
        { value: 'FI', text: 'Finland' },
        { value: 'FR', text: 'France' },
        { value: 'GA', text: 'Gabon' },
        { value: 'GM', text: 'Gambia' },
        { value: 'GE', text: 'Georgia' },
        { value: 'DE', text: 'Germany' },
        { value: 'GH', text: 'Ghana' },
        { value: 'GI', text: 'Gibraltar' },
        { value: 'GR', text: 'Greece' },
        { value: 'GD', text: 'Grenada' },
        { value: 'GT', text: 'Guatemala' },
        { value: 'GG', text: 'Guernsey' },
        { value: 'GN', text: 'Guinea' },
        { value: 'GW', text: 'Guinea Bissau' },
        { value: 'GC', text: 'Gulf Co-operation Council' },
        { value: 'GY', text: 'Guyana' },
        { value: 'HT', text: 'Haiti' },
        { value: 'HN', text: 'Honduras' },
        { value: 'HK', text: 'Hong Kong, China' },
        { value: 'HU', text: 'Hungary' },
        { value: 'IS', text: 'Iceland' },
        { value: 'IN', text: 'India' },
        { value: 'ID', text: 'Indonesia' },
        { value: 'IR', text: 'Iran' },
        { value: 'IQ', text: 'Iraq' },
        { value: 'IE', text: 'Ireland' },
        { value: 'IM', text: 'Isle of Man' },
        { value: 'IL', text: 'Israel' },
        { value: 'IT', text: 'Italy' },
        { value: 'JM', text: 'Jamaica' },
        { value: 'JP', text: 'Japan' },
        { value: 'JE', text: 'Jersey' },
        { value: 'JO', text: 'Jordan' },
        { value: 'KZ', text: 'Kazakhstan' },
        { value: 'KE', text: 'Kenya' },
        { value: 'KW', text: 'Kuwait' },
        { value: 'KG', text: 'Kyrgyzstan' },
        { value: 'LA', text: 'Lao People\'s Democratic Republic' },
        { value: 'LV', text: 'Latvia' },
        { value: 'LB', text: 'Lebanon' },
        { value: 'LS', text: 'Lesotho' },
        { value: 'LR', text: 'Liberia' },
        { value: 'LY', text: 'Libya' },
        { value: 'LI', text: 'Liechtenstein' },
        { value: 'LT', text: 'Lithuania' },
        { value: 'LU', text: 'Luxembourg' },
        { value: 'LA', text: 'Macao (China)' },
        { value: 'MG', text: 'Madagascar' },
        { value: 'MW', text: 'Malawi' },
        { value: 'MY', text: 'Malaysia' },
        { value: 'MV', text: 'Maldives' },
        { value: 'ML', text: 'Mali' },
        { value: 'MT', text: 'Malta' },
        { value: 'MH', text: 'Marshall Islands' },
        { value: 'MR', text: 'Mauritania' },
        { value: 'MU', text: 'Mauritius' },
        { value: 'MX', text: 'Mexico' },
        { value: 'MD', text: 'Moldova' },
        { value: 'MC', text: 'Monaco' },
        { value: 'MN', text: 'Mongolia' },
        { value: 'ME', text: 'Montenegro' },
        { value: 'MS', text: 'Montserrat' },
        { value: 'MA', text: 'Morocco' },
        { value: 'MZ', text: 'Mozambique' },
        { value: 'MM', text: 'Myanmar' },
        { value: 'NA', text: 'Namibia' },
        { value: 'NR', text: 'Nauru' },
        { value: 'NP', text: 'Nepal' },
        { value: 'NL', text: 'Netherlands' },
        { value: 'NZ', text: 'New Zealand' },
        { value: 'NI', text: 'Nicaragua' },
        { value: 'NE', text: 'Niger' },
        { value: 'NG', text: 'Nigeria' },
        { value: 'NU', text: 'Niue' },
        { value: 'NO', text: 'Norway' },
        { value: 'OM', text: 'Oman' },
        { value: 'PK', text: 'Pakistan' },
        { value: 'PW', text: 'Palau' },
        { value: 'PS', text: 'Palestinian Authority' },
        { value: 'PA', text: 'Panama' },
        { value: 'PG', text: 'Papua New Guinea' },
        { value: 'PY', text: 'Paraguay' },
        { value: 'PE', text: 'Peru' },
        { value: 'PH', text: 'Philippines' },
        { value: 'PL', text: 'Poland' },
        { value: 'PT', text: 'Portugal' },
        { value: 'QA', text: 'Qatar' },
        { value: 'KR', text: 'Republic of Korea' },
        { value: 'MK', text: 'Republic of North Macedonia' },
        { value: 'RO', text: 'Romania' },
        { value: 'RU', text: 'Russian Federation' },
        { value: 'RW', text: 'Rwanda' },
        { value: 'KN', text: 'Saint Kitts and Nevis' },
        { value: 'LC', text: 'Saint Lucia' },
        { value: 'VC', text: 'Saint Vincent & the Grenadines' },
        { value: 'WS', text: 'Samoa' },
        { value: 'SM', text: 'San Marino' },
        { value: 'ST', text: 'Sao Tome and Principe' },
        { value: 'SA', text: 'Saudi Arabia' },
        { value: 'SN', text: 'Senegal' },
        { value: 'RS', text: 'Serbia' },
        { value: 'SC', text: 'Seychelles' },
        { value: 'SL', text: 'Sierra Leone' },
        { value: 'SG', text: 'Singapore' },
        { value: 'SX', text: 'Sint Maarten (Kingdom of the Netherlands)' },
        { value: 'SK', text: 'Slovakia' },
        { value: 'SI', text: 'Slovenia' },
        { value: 'SB', text: 'Solomon Islands' },
        { value: 'SO', text: 'Somalia' },
        { value: 'ES', text: 'Spain' },
        { value: 'LK', text: 'Sri Lanka' },
        { value: 'SD', text: 'Sudan' },
        { value: 'SR', text: 'Suriname' },
        { value: 'SZ', text: 'Swaziland' },
        { value: 'SE', text: 'Sweden' },
        { value: 'CH', text: 'Switzerland' },
        { value: 'SY', text: 'Syria' },
        { value: 'TJ', text: 'Tajikistan' },
        { value: 'TZ', text: 'Tanzania' },
        { value: 'TH', text: 'Thailand' },
        { value: 'TL', text: 'Timor Leste' },
        { value: 'TG', text: 'Togo' },
        { value: 'TO', text: 'Tonga' },
        { value: 'TT', text: 'Trinidad and Tobago' },
        { value: 'TN', text: 'Tunisia' },
        { value: 'TR', text: 'Turkey' },
        { value: 'TM', text: 'Turkmenistan' },
        { value: 'TC', text: 'Turks and Caicos Islands' },
        { value: 'UG', text: 'Uganda' },
        { value: 'UA', text: 'Ukraine' },
        { value: 'AE', text: 'United Arab Emirates' },
        { value: 'GB', text: 'United Kingdom' },
        { value: 'US', text: 'United States of America' },
        { value: 'UY', text: 'Uruguay' },
        { value: 'UZ', text: 'Uzbekistan' },
        { value: 'VU', text: 'Vanuatu' },
        { value: 'VE', text: 'Venezuela' },
        { value: 'VN', text: 'Vietnam' },
        { value: 'VG', text: 'Virgin Islands (United Kingdom)' },
        { value: 'YE', text: 'Yemen' },
        { value: 'ZM', text: 'Zambia' },
        { value: 'ZW', text: 'Zimbabwe' }
      ],
      fcaCurrencies: [
        { value: 'USD', text: 'USD' },
        { value: 'EUR', text: 'EUR' },
        { value: 'GBP', text: 'GBP' },
        { value: 'JPY', text: 'JPY' },
        { value: 'CNY', text: 'CNY' },
        { value: 'AUD', text: 'AUD' },
        { value: 'CAD', text: 'CAD' },
        { value: 'CHF', text: 'CHF' },
        { value: 'NZD', text: 'NZD' },
        { value: 'HKD', text: 'HKD' },
        { value: 'MUR', text: 'MUR' },
        { value: 'DKK', text: 'DKK' },
        { value: 'NOK', text: 'NOK' },
        { value: 'SGD', text: 'SGD' },
        { value: 'SEK', text: 'SEK' },
        { value: 'BWP', text: 'BWP' },
        { value: 'LSL', text: 'LSL' },
        { value: 'SZL', text: 'SZL' }
      ],
      occupations: [
        { value: 'Agriculture, Food and Natural Resources', text: 'Agriculture, Food and Natural Resources' },
        { value: 'Architecture and Construction', text: 'Architecture and Construction' },
        { value: 'Arts, Audio/Video Technology and Communications', text: 'Arts, Audio/Video Technology and Communications' },
        { value: 'Business Management and Administration', text: 'Business Management and Administration' },
        { value: 'Education and Training', text: 'Education and Training' },
        { value: 'Financial Services', text: 'Financial Services' },
        { value: 'Government and Public Administration', text: 'Government and Public Administration' },
        { value: 'Health Sciences', text: 'Health Sciences' },
        { value: 'Hospitality and Tourism', text: 'Hospitality and Tourism' },
        { value: 'Information Technology', text: 'Information Technology' },
        { value: 'Law, Public Safety, Corrections and Security', text: 'Law, Public Safety, Corrections and Security' },
        { value: 'Manufacturing', text: 'Manufacturing' },
        { value: 'Mining', text: 'Mining' },
        { value: 'Marketing, Sales and Service', text: 'Marketing, Sales and Service' },
        { value: 'Retail/Services', text: 'Retail/Services' },
        { value: 'Science, Technology, Engineering and Mathematics', text: 'Science, Technology, Engineering and Mathematics' },
        { value: 'Senior Government Position', text: 'Senior Government Position' },
        { value: 'Transportation, Distribution and Logistics', text: 'Transportation, Distribution and Logistics' },
        { value: 'Home Executive', text: 'Home Executive' },
        { value: 'Retired', text: 'Retired' },
        { value: 'Self Employed', text: 'Self Employed' },
        { value: 'Student', text: 'Student' },
        { value: 'Unemployed', text: 'Unemployed' },
        { value: 'Other (specify)', text: 'Other (specify)' }
      ],
      designations: [
        { value: 'Administrator', text: 'Administrator' },
        { value: 'Consultant', text: 'Consultant' },
        { value: 'Director', text: 'Director' },
        { value: 'Executive', text: 'Executive' },
        { value: 'Manager', text: 'Manager' },
        { value: 'Owner', text: 'Owner' },
        { value: 'Professional', text: 'Professional' },
        { value: 'Home Executive', text: 'Home Executive' },
        { value: 'Retired', text: 'Retired' },
        { value: 'Student', text: 'Student' },
        { value: 'Other (specify)', text: 'Other (specify)' }
      ],
      sourcesOfWealth: [
        { value: 'Annuity', text: 'Annuity' },
        { value: 'Dividends', text: 'Dividends' },
        { value: 'Pension', text: 'Pension' },
        { value: 'Rental Income', text: 'Rental Income' },
        { value: 'Salary', text: 'Salary' },
        { value: 'Trust Distributions', text: 'Trust Distributions' },
        { value: 'Other (specify)', text: 'Other (specify)' }
      ],
      sourcesOfFunds: [
        { value: 'Business Income/Profits', text: 'Business Income/Profits' },
        { value: 'Capital/Savings', text: 'Capital/Savings' },
        { value: 'Cryptocurrency Profits', text: 'Cryptocurrency Profits' },
        { value: 'Gift/Donations', text: 'Gift/Donations' },
        { value: 'Inheritance', text: 'Inheritance' },
        { value: 'Investment Proceeds/Dividends', text: 'Investment Proceeds/Dividends' },
        { value: 'Retirement Funds/Pension', text: 'Retirement Funds/Pension' },
        { value: 'Salary/Bonus', text: 'Salary/Bonus' },
        { value: 'Sale of Property/Assets', text: 'Sale of Property/Assets' },
        { value: 'Other (specify)', text: 'Other (specify)' }
      ],
      thirdPartyAccounts: [
        { value: 'Yes', text: 'Yes' },
        { value: 'No', text: 'No' }
      ],
      investmentValues: [
        { value: '< R 1,000,000', text: '< R 1,000,000' },
        { value: 'R 1,000,000 <> R 10,000,000', text: 'R 1,000,000 <> R 10,000,000' },
        { value: 'R 10,000,000 <> R 50,000,000', text: 'R 10,000,000 <> R 50,000,000' },
        { value: '> R 50,000,000', text: '> R 50,000,000' }
      ],
      geoCodes: [
        { value: 'EasternCape', text: 'Eastern Cape' },
        { value: 'FreeState', text: 'Free State' },
        { value: 'Gauteng', text: 'Gauteng' },
        { value: 'KwazuluNatal', text: 'Kwazulu Natal' },
        { value: 'Limpopo', text: 'Limpopo' },
        { value: 'Mpumalanga', text: 'Mpumalanga' },
        { value: 'NorthernCape', text: 'Northern Cape' },
        { value: 'NorthWest', text: 'North West' },
        { value: 'WesternCape', text: 'Western Cape' },
        { value: 'National', text: 'National' },
        { value: 'Offshore', text: 'Offshore' }
      ],
      sanctionScreens: [
        { value: 'None', text: 'None' },
        { value: 'Minor', text: 'Minor' },
        { value: 'PEP', text: 'PEP' },
        { value: 'Sanctions', text: 'Sanctions' }
      ]
    }
  },
  methods: {
    toggle (event) {
      this.$refs.menu.toggle(event)
    },
    toggled (event) {
      this.$refs.op.toggle(event)
    },
    async saveClientEdit () {
      const response = await editUpdateClient({ retailClient: this.retailClient })
      if (response) {
        this.success = true
      }
    },
    async sumbitLandoApi () {
      this.loadingApi = true
      const response = await submitToLandobyte({ retailClient: this.retailClient, landoByteResponse: this.landoByteResponse })
      if (response) {
        this.loadingApi = false
        this.message = response.message
        this.landoMessage = true
      }
    },
    async init () {
      const cr = await getPartners()
      this.partners = []
      if (cr) {
        cr.result.forEach(item => {
          this.partners.push({
            key: item.id,
            text: item.shortName,
            value: item.id
          })
        })
      }
      const response = await editClient({ id: this.id })
      if (response) {
        this.retailClient = response.result
      }
    },
    confirmLeave () {
      return window.confirm('Please select Cancel and then SAVE before leaving this page, even if you have not made any changes. If you click OK without saving then all users will be blocked from Editing this client. In the event of this happening, please contact the system administrator')
    },
    beforeWindowUnload (e) {
      e.preventDefault()
      // Chrome requires returnValue to be set
      e.returnValue = ''
    }
  },
  created () {
    window.addEventListener('beforeunload', this.beforeWindowUnload)
  },
  beforeUnmount () {
    window.removeEventListener('beforeunload', this.beforeWindowUnload)
  },
  beforeRouteLeave (to, from, next) {
    if (to.name === 'ClientView' || to.name === 'ClientListView') {
      next()
    }
    else {
      const answer = window.confirm('Please select Cancel and then SAVE before leaving this page, even if you have not made any changes. If you click OK without saving then all users will be blocked from Editing this client. In the event of this happening, please contact the system administrator')
      if (answer) {
        next()
      } else {
        next(false)
      }
    }
  },
  mounted () {
    this.id = this.$route.params.id
    this.init()
  }
}
</script>

<style lang="scss" scoped>
::v-deep() {
  .p-fileupload .p-fileupload-buttonbar {
    padding: 1rem 1rem;
  };
  .p-fileupload .p-fileupload-buttonbar .p-button {
    margin: 0.5rem;
  };
  .p-fileupload .p-fileupload-content {
    padding-top: 0.5rem!important;
    padding-bottom: 0.5rem!important;
  };
};
</style>
