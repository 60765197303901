<template>
  <br />
  <h2 class="text-center my-2">PARTNER CLIENT'S TCC BALANCES</h2>
  <br />
  <div v-if="displayEmptyRemainder == false">
  <div>
    <Button icon="pi pi-info" class="p-button-rounded p-button-lg mx-4 shadow-5" @click="toggle" />
    <OverlayPanel ref="op" appendTo="body" :showCloseIcon="true" id="overlay_panel" style="width: 20rem" :breakpoints="{'960px': '75vw'}">
      <p>Use the <i class="pi pi-sort-alt"></i> to filter results.</p>
      <p>Use <i class="pi pi-sort-amount-up-alt"></i> or <i class="pi pi-sort-amount-down-alt"></i> to change the sort order.</p>
      <p>To filter by multiple columns use Ctrl + Click on the second filter column.</p>
      <p>Use the <i class="pi pi-filter"></i> to search for a specific client. You can search on multiple columns.</p>
      </OverlayPanel>
  </div>
  <br />
    <div class="fluid mx-3 shadow-3">
        <DataTable :value="tccRemainder"
                    dataKey="id" :rowHover="true"
                    stripedRows responsiveLayout="stack"
                    sortMode="multiple"
                    showGridlines
                    breakpoint="1024px"
                    :paginator="true"
                    :rows="8"
                    v-model:filters="filters1"
                    filterDisplay="menu"
                    class="datatable-retailClient">
            <Column field="partnerName" header="Partner" headerStyle="width: 3vw"></Column>
            <Column field="clientId" header="ID" headerStyle="width: 1vw"></Column>
            <Column field="firstLastName" header="Client" :sortable="true" headerStyle="width: 4vw">
              <template #filter="{filterModel}">
                  <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by Name"/>
              </template>
            </Column>
            <Column field="cifNumber" header="CIF No" headerStyle="width: 3vw">
              <template #filter="{filterModel}">
                  <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by CIF No"/>
              </template>
            </Column>
            <Column field="tccValue" header="Total TCC Value" dataType="numeric" headerStyle="width: 4vw">
               <template #body="{data}">
                    {{formatCurrency(data.tccValue)}}
                </template>
            </Column>
            <Column field="tccRemainders" header="TCC Remainder" dataType="numeric" headerStyle="width: 4vw">
               <template #body="{data}">
                    {{formatCurrency(data.tccRemainders)}}
                </template>
            </Column>
             <Column field="applicationStatus" header="TCC Status" :sortable="true" headerStyle="width: 3vw" :filterMenuStyle="{'width':'14rem'}" style="min-width:4vw">
                <template #body="{data}">
                    <span :class="'retailClient-badge status-' + data.applicationStatus">{{data.applicationStatus}}</span>
                </template>
                <template #filter="{filterModel}">
                    <Dropdown v-model="filterModel.value" :options="applicationStatuses" placeholder="Select TCC Status" class="p-column-filter" :showClear="true">
                    </Dropdown>
                </template>
            </Column>
            <Column field="daRemainder" header="SDA Remainder" :sortable="true" dataType="numeric" headerStyle="width: 3vw">
              <template #body="{data}">
                <div v-if="data.expiredPreviousYear === true">
                      <span>0.00</span>
                </div>
                <div v-else>
                      {{formatCurrency(data.daRemainder)}}
                </div>
              </template>
            </Column>
        </DataTable>
     </div>
  </div>
   <div v-else>
    <h3 class="text-center my-2">NO CLIENT TCC BALANCES FOUND FOR THIS PARTNER</h3>
    <br />
    <hr class="mx-4 shadow-2"/>
   </div>
   <br />
  <div>
    <Button class="l-2 mt-2 mb-3 mx-3 shadow-4 p-button-lg"
    icon="pi pi-step-backward"
    label="BACK&nbsp;"
    id="submitButton"
    @click.prevent="$router.back()" />
  </div>
</template>

<script>
import { getPartnerTccRemainder } from '@/gateway'
import { FilterMatchMode, FilterOperator } from 'primevue/api'

export default {
  name: 'PartnerTccRemainder',
  inheritAttrs: false,
  data () {
    return {
      tccRemainder: [],
      filters1: null,
      displayEmptyRemainder: false,
      applicationStatuses: [
        'complete',
        'emigration',
        'expired',
        'issued'
      ]
    }
  },
  methods: {
    async init () {
      const response = await getPartnerTccRemainder({ id: this.id })
      if (response.result.length !== 0) {
        this.tccRemainder = response.result
      } else {
        this.displayEmptyRemainder = true
      }
    },
    formatCurrency (value) {
      return value.toLocaleString('en-US', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 })
    },
    initFilters1 () {
      this.filters1 = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        firstLastName: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        cifNumber: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        applicationStatus: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] }
      }
    },
    toggle (event) {
      this.$refs.op.toggle(event)
    }
  },
  created () {
    this.initFilters1()
  },
  mounted () {
    this.id = this.$route.params.id
    this.init()
  }
}
</script>

<style lang="scss" scoped>
::v-deep() {
  .p-datatable .p-datatable-thead > tr > th {
    background-color: $middleBlue!important;
    color: $textColor;
    border: 1px solid $borderColour!important;
    font-size: 18px;
    font-weight: 600;
  }
  .p-datatable .p-datatable-tbody > tr > td {
    padding: .4rem .4rem;
    color: $darkTextColour;
    font-family:'Poppins', sans-serif;
    font-weight: 400;
  }
  .p-paginator-current {
        margin-right: auto;
    }
  .retailClient-badge {
      @include statusBadge
  };
  #disabled {
    background: $textColor!important;
    color: $middleBlue!important;
  };
  #searchtext {
    margin-left: 1rem!important;
    min-width: 40vw;
  };
};
</style>
