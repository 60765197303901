<template>
  <div style="width: 100%; height: 100%; padding: 50px 0 250px 0; overflow-y: scroll; display: flex; flex-direction: column; align-items: center; justify-items: center; gap: 200px;">
    <div class="chart-container">
      <div>
        <p style="font-size: 20px; color: #4B5563; font-weight: normal; margin: 0;">New Clients</p>
        <div style="width: 100%; height: 50px;">
          <PeriodSelector
            :selected="newPeriod"
            @select="onSelectNewPeriod"
          />
        </div>
      </div>
      <Chart
        type="bar"
        :display-title="false"
        :show-legend="false"
        y-axis-title="Count"
        :data="newClients"
      />
    </div>
    <div class="chart-container">
      <div>
        <p style="font-size: 20px; color: #4B5563; font-weight: normal; margin: 0;">Total Clients</p>
        <div style="width: 100%; height: 50px;">
          <PeriodSelector
            :selected="totalPeriod"
            @select="onSelectTotalPeriod"
          />
        </div>
      </div>
      <Chart
        type="bar"
        :display-title="false"
        :show-legend="false"
        y-axis-title="Count"
        :data="totalClients"
      />
    </div>
    <div
      v-if="store.state.selectedPartner.id === -1"
      class="chart-container"
    >
      <div style="margin-bottom: 10px;">
        <p style="font-size: 20px; color: #4B5563; font-weight: normal; margin: 0;">New Clients Per Partner</p>
        <div style="width: 100%; height: 50px;">
          <PeriodSelector
            :selected="newPerPartnerPeriod"
            @select="onSelectNewPerPartnerPeriod"
          />
        </div>
        <div style="width: 100%">
          <div style="width: 250px; height: 30px; position: relative;">
            <DateSelector
              :date="newClientsDate"
              :min="newPerPartnerMin"
              :max="newPerPartnerMax"
              :period="newPerPartnerPeriod"
              @select="selectNewClientsDate"
            />
          </div>
        </div>
      </div>
      <Chart
        type="bar"
        :display-title="false"
        :show-legend="false"
        y-axis-title="Count"
        :data="newClientsPerPartner"
      />
    </div>
    <div
      v-if="store.state.selectedPartner.id === -1"
      class="chart-container"
    >
      <div style="margin-bottom: 10px;">
        <p style="font-size: 20px; color: #4B5563; font-weight: normal; margin: 0;">Total Clients Per Partner</p>
        <div style="width: 100%; height: 50px;">
          <PeriodSelector
            :selected="totalPerPartnerPeriod"
            @select="onSelectTotalPerPartnerPeriod"
          />
        </div>
        <div style="width: 100%">
          <div style="width: 250px; height: 30px; position: relative;">
            <DateSelector
              :date="totalClientsDate"
              :min="totalPerPartnerMin"
              :max="totalPerPartnerMax"
              :period="totalPerPartnerPeriod"
              @select="selectTotalClientsDate"
            />
          </div>
        </div>
      </div>
      <Chart
        type="bar"
        :display-title="false"
        :show-legend="false"
        y-axis-title="Total"
        :data="totalClientsPerPartner"
      />
    </div>
  </div>
</template>

<script setup>
import { computed, ref, watch } from 'vue'
import Chart from '@/components/Chart.vue'
import { store } from '@/store'
import PeriodSelector from '@/components/PeriodSelector'
import DateSelector from '@/components/DateSelector'
import _ from 'lodash'

const newPeriod = ref('Monthly')
const totalPeriod = ref('Monthly')
const newPerPartnerPeriod = ref('Monthly')
const totalPerPartnerPeriod = ref('Monthly')
const newClientsDate = ref(store.state.reportEndDate)
const totalClientsDate = ref(store.state.reportEndDate)

const totalPerPartnerMin = computed(() => {
  if (totalPerPartnerPeriod.value === 'Monthly') {
    return _.min(store.state.monthlyReportResponse.totalClientsMonthly.map(x => new Date(x[0].date)))
  } else if (totalPerPartnerPeriod.value === 'Quarterly') {
    return _.min(store.state.monthlyReportResponse.totalClientsQuarterly.map(x => new Date(x[0].date)))
  } else {
    return _.min(store.state.monthlyReportResponse.totalClientsYearly.map(x => new Date(x[0].date)))
  }
})

const totalPerPartnerMax = computed(() => {
  if (totalPerPartnerPeriod.value === 'Monthly') {
    return _.max(store.state.monthlyReportResponse.totalClientsMonthly.map(x => new Date(x[0].date)))
  } else if (totalPerPartnerPeriod.value === 'Quarterly') {
    return _.max(store.state.monthlyReportResponse.totalClientsQuarterly.map(x => new Date(x[0].date)))
  } else {
    return _.max(store.state.monthlyReportResponse.totalClientsYearly.map(x => new Date(x[0].date)))
  }
})

const newPerPartnerMin = computed(() => {
  if (newPerPartnerPeriod.value === 'Monthly') {
    return _.min(store.state.monthlyReportResponse.clientsMonthly.map(x => new Date(x[0].date)))
  } else if (newPerPartnerPeriod.value === 'Quarterly') {
    return _.min(store.state.monthlyReportResponse.clientsQuarterly.map(x => new Date(x[0].date)))
  } else {
    return _.min(store.state.monthlyReportResponse.clientsYearly.map(x => new Date(x[0].date)))
  }
})

const newPerPartnerMax = computed(() => {
  if (newPerPartnerPeriod.value === 'Monthly') {
    return _.max(store.state.monthlyReportResponse.clientsMonthly.map(x => new Date(x[0].date)))
  } else if (newPerPartnerPeriod.value === 'Quarterly') {
    return _.max(store.state.monthlyReportResponse.clientsQuarterly.map(x => new Date(x[0].date)))
  } else {
    return _.max(store.state.monthlyReportResponse.clientsYearly.map(x => new Date(x[0].date)))
  }
})

const newClients = computed(() => {
  let data
  let labels
  if (store.state.monthlyReportResponse !== null) {
    if (newPeriod.value === 'Monthly') {
      data = store.state.monthlyReportResponse.clientsMonthly
    } else if (newPeriod.value === 'Quarterly') {
      data = store.state.monthlyReportResponse.clientsQuarterly
    } else {
      data = store.state.monthlyReportResponse.clientsYearly
    }
    if (store.state.selectedPartner.id === -1) {
      data = data.map(x => {
        return {
          id: -1,
          name: 'All',
          date: x[0].date,
          result: x.map(y => y.result).reduce((partialSum, a) => partialSum + a, 0)
        }
      })
    } else {
      data = data.map(x => x.find(y => y.id === store.state.selectedPartner.id))
    }
    labels = data.map(x => new Date(x.date).toLocaleDateString('en-GB', { month: 'short', year: 'numeric' }))
    data = data.map(x => x.result)
  }
  return {
    labels: labels,
    datasets: [
      {
        label: 'New Clients',
        backgroundColor: '#0185b7',
        data: data
      }
    ]
  }
})

const totalClients = computed(() => {
  let labels
  let data
  if (store.state.monthlyReportResponse !== null) {
    if (totalPeriod.value === 'Monthly') {
      data = store.state.monthlyReportResponse.totalClientsMonthly
    } else if (totalPeriod.value === 'Quarterly') {
      data = store.state.monthlyReportResponse.totalClientsQuarterly
    } else {
      data = store.state.monthlyReportResponse.totalClientsYearly
    }
    if (store.state.selectedPartner.id === -1) {
      data = data.map(x => {
        return {
          id: -1,
          name: 'All',
          date: x[0].date,
          result: x.map(y => y.result).reduce((partialSum, a) => partialSum + a, 0)
        }
      })
    } else {
      data = data.map(x => x.find(y => y.id === store.state.selectedPartner.id))
    }
    labels = data.map(x => new Date(x.date).toLocaleDateString('en-GB', { month: 'short', year: 'numeric' }))
    data = data.map(x => x.result)
  }
  return {
    labels: labels,
    datasets: [
      {
        label: 'Total Clients',
        backgroundColor: '#0185b7',
        data: data
      }
    ]
  }
})

const newClientsPerPartner = computed(() => {
  let data = []
  if (store.state.monthlyReportResponse !== null) {
    if (newPerPartnerPeriod.value === 'Monthly') {
      data = store.state.monthlyReportResponse.clientsMonthly
    } else if (newPerPartnerPeriod.value === 'Quarterly') {
      data = store.state.monthlyReportResponse.clientsQuarterly
    } else {
      data = store.state.monthlyReportResponse.clientsYearly
    }
    const index = data.map(x => x[0].date).findIndex(x => new Date(x).toLocaleDateString('en-GB', { month: 'short', year: 'numeric' }) === newClientsDate.value.toLocaleDateString('en-GB', { month: 'short', year: 'numeric' }))
    data = data.at(index).filter(x => x.result > 0).sort((a, b) => a.name.localeCompare(b.name))
  }
  return {
    labels: data.map(x => x.name),
    datasets: [
      {
        label: 'New Clients',
        backgroundColor: '#0185b7',
        data: data.map(x => x.result)
      }
    ]
  }
})

const totalClientsPerPartner = computed(() => {
  let data = []
  if (store.state.monthlyReportResponse !== null) {
    if (totalPerPartnerPeriod.value === 'Monthly') {
      data = store.state.monthlyReportResponse.totalClientsMonthly
    } else if (totalPerPartnerPeriod.value === 'Quarterly') {
      data = store.state.monthlyReportResponse.totalClientsQuarterly
    } else {
      data = store.state.monthlyReportResponse.totalClientsYearly
    }
    const index = data.map(x => x[0].date).findIndex(x => new Date(x).toLocaleDateString('en-GB', { month: 'short', year: 'numeric' }) === totalClientsDate.value.toLocaleDateString('en-GB', { month: 'short', year: 'numeric' }))
    data = data.at(index).filter(x => x.result > 0).sort((a, b) => a.name.localeCompare(b.name))
  }
  return {
    labels: data.map(x => x.name),
    datasets: [
      {
        label: 'New Clients',
        backgroundColor: '#0185b7',
        data: data.map(x => x.result)
      }
    ]
  }
})

function onSelectNewPeriod (payload) {
  newPeriod.value = payload.period
}

function onSelectTotalPeriod (payload) {
  totalPeriod.value = payload.period
}

function onSelectNewPerPartnerPeriod (payload) {
  newPerPartnerPeriod.value = payload.period
}

function onSelectTotalPerPartnerPeriod (payload) {
  totalPerPartnerPeriod.value = payload.period
}

function selectNewClientsDate (payload) {
  newClientsDate.value = payload.date
}

function selectTotalClientsDate (payload) {
  totalClientsDate.value = payload.date
}

watch(newPerPartnerPeriod, () => {
  if (newPerPartnerPeriod.value === 'Monthly') {
    newClientsDate.value = new Date(store.state.monthlyReportResponse.clientsMonthly.at(-1)[0].date)
  } else if (newPerPartnerPeriod.value === 'Quarterly') {
    newClientsDate.value = new Date(store.state.monthlyReportResponse.clientsQuarterly.at(-1)[0].date)
  } else {
    newClientsDate.value = new Date(store.state.monthlyReportResponse.clientsYearly.at(-1)[0].date)
  }
})

watch(totalPerPartnerPeriod, () => {
  if (totalPerPartnerPeriod.value === 'Monthly') {
    totalClientsDate.value = new Date(store.state.monthlyReportResponse.totalClientsMonthly.at(-1)[0].date)
  } else if (totalPerPartnerPeriod.value === 'Quarterly') {
    totalClientsDate.value = new Date(store.state.monthlyReportResponse.totalClientsQuarterly.at(-1)[0].date)
  } else {
    totalClientsDate.value = new Date(store.state.monthlyReportResponse.totalClientsYearly.at(-1)[0].date)
  }
})
</script>

<script>
export default {
  name: 'Clients',
  inheritAttrs: false
}
</script>

<style scoped>
.chart-container {
  width: 1220px;
  height: 560px;
}
</style>
