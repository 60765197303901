<template>
  <br />
  <h2 class="text-center my-2 mx-2">POST TRADE DETAILS FOR : {{tradeQuotes.firstLastName}}</h2>
  <br />
  <div class="mx-2">
    <div class="fluid grid">
      <div class="col-12 md:col-6 lg:col-6">
        <Card class="shadow-5" id="tradesCard">
          <template #content>
            <div class="fluid grid">
                <div class="col-12 md:col-6 lg:col-6">
                    <h5>Partner Name</h5>
                        <InputText
                        v-model="tradeQuotes.partnerName"
                        readonly
                        type="text"
                        class="inputfield w-full"/>
                </div>
                <div class="col-12 md:col-6 lg:col-6">
                    <h5>Quote Accepted At</h5>
                        <InputText
                        v-model="tradeQuotes.quoteAcceptedTime"
                        readonly
                        type="text"
                        class="inputfield w-full"/>
                </div>
            </div>
            <transition name="fadequick">
                <div v-if="tradeQuotes.tradeType === 'Cross'">
                    <div class="fluid grid">
                        <div class="col-12 md:col-6 lg:col-6">
                            <h5>Trade Type</h5>
                                <Dropdown v-model="tradeQuotes.tradeType"
                                :options="tradeTypes"
                                optionLabel="text"
                                optionValue="value"
                                :showClear="true"
                                appendTo="self"
                                class="inputfield w-full" />
                        </div>
                    </div>
                    <div class="fluid grid">
                        <div class="col-12 md:col-6 lg:col-6">
                            <h5>Buy Currency</h5>
                                <Dropdown v-model="tradeQuotes.tradeCurrency"
                                :options="crossCurrencies"
                                optionLabel="text"
                                optionValue="value"
                                appendTo="self"
                                class="inputfield w-full"/>
                        </div>
                        <div class="col-12 md:col-6 lg:col-6">
                            <h5>Sell Currency</h5>
                                <Dropdown v-model="tradeQuotes.crossCurrency"
                                :options="currencies"
                                optionLabel="text"
                                optionValue="value"
                                appendTo="self"
                                class="inputfield w-full" />
                        </div>
                    </div>
                </div>
                <div v-else>
                    <div class="fluid grid">
                        <div class="col-12 md:col-6 lg:col-6">
                            <h5>Trade Type</h5>
                                <Dropdown v-model="tradeQuotes.tradeType"
                                :options="tradeTypes"
                                optionLabel="text"
                                optionValue="value"
                                appendTo="self"
                                class="inputfield w-full" />
                        </div>
                        <div class="col-12 md:col-6 lg:col-6">
                            <h5>Cross Trade Currency</h5>
                            <Dropdown v-model="tradeQuotes.crossCurrency"
                            :options="currencies"
                            optionLabel="text"
                            optionValue="value"
                            appendTo="self"
                            class="inputfield w-full" />
                        </div>
                    </div>
                </div>
            </transition>
            <div class="fluid grid">
                <div class="col-12 md:col-6 lg:col-6">
                    <h5>Trade Settlement</h5>
                    <Dropdown v-model="tradeQuotes.tradeTiming"
                    :options="tradeTimes"
                    optionLabel="text"
                    optionValue="value"
                    appendTo="self"
                    class="inputfield w-full" />
                </div>
                <div class="col-12 md:col-6 lg:col-6">
                    <h5>Commission Rate : &nbsp;&nbsp;<b> {{formatPercentage(tradeQuotes.commissionRate, "0.00%")}} </b></h5>
                    <div class="mt-5">
                        <Slider v-model="tradeQuotes.commissionRate"
                            disabled
                            :step="0.05"
                            :min="0.30"
                            :max="1.00" />
                    </div>
                </div>
            </div>
<!----------------------------------------------------------------------------------------------------------------------------------->
<!----------------------------------------------------------------------------------------------------------------------------------->
            <transition name="fadequick">
                <div v-if="tradeQuotes.tradeType === 'Buy ZAR'">
                    <div class="formgrid grid">
                        <div class="col-12 md:col-6 lg:col-6">
                            <h5><b>Actual Trade Rate</b></h5>
                                <InputNumber
                                mode="decimal"
                                locale="en-US"
                                :minFractionDigits="4"
                                :maxFractionDigits="4"
                                class="inputfield w-full"
                                v-model="mblRate"/>
                        </div>
                    </div>
                    <div v-if="tradeQuotes.buyZarTradeZarValue > 0">
                      <div class="formgrid grid">
                          <div class="col-12 md:col-6 lg:col-6">
                              <h5>Trade Amount&nbsp;in ZAR</h5>
                              <InputNumber
                              mode="decimal"
                              locale="en-US"
                              :minFractionDigits="2"
                              :maxFractionDigits="2"
                              class="inputfield w-full"
                              v-model="tradeQuotes.buyZarTradeZarValue"/>
                          </div>
                          <div class="col-12 md:col-6 lg:col-6">
                              <h5>Trade Amount&nbsp;in {{tradeQuotes.crossCurrency}} </h5>
                              <InputNumber
                              mode="decimal"
                              locale="en-US"
                              :minFractionDigits="2"
                              :maxFractionDigits="2"
                              class="inputfield w-full"
                              v-model.number="buyZarTradeCrossAmount"/>
                          </div>
                      </div>
                    </div>
                    <div v-else>
                       <div class="formgrid grid">
                          <div class="col-12 md:col-6 lg:col-6">
                              <h5>Trade Amount&nbsp;in ZAR</h5>
                              <InputNumber
                              mode="decimal"
                              locale="en-US"
                              :minFractionDigits="2"
                              :maxFractionDigits="2"
                              class="inputfield w-full"
                              v-model="buyZarTradeZarAmount"/>
                          </div>
                          <div class="col-12 md:col-6 lg:col-6">
                              <h5>Trade Amount&nbsp;in {{tradeQuotes.crossCurrency}} </h5>
                                  <InputNumber
                                  mode="decimal"
                                  locale="en-US"
                                  :minFractionDigits="2"
                                  :maxFractionDigits="2"
                                  class="inputfield w-full"
                                  v-model.number="tradeQuotes.buyZarTradeCrossValue"/>
                          </div>
                      </div>
                    </div>
                    <div class="formgrid grid">
                      <div class="col-12 md:col-6 lg:col-6">
                          <h5>Trade Account Number</h5>
                              <InputText
                              class="inputfield w-full"
                              v-model="tradeQuotes.tradeAccountNumber"/>
                      </div>
                  </div>
                  <br />
                  <table class="shadow-3 mx-2">
                          <thead id="thead" >
                              <tr>
                                  <th class="text-left pl-2" style="width: 60vw">Type</th>
                                  <th class="text-left pl-2" style="width: 40vw">Status</th>
                              </tr>
                          </thead>
                          <tbody id="tbodyDark">
                              <tr>
                                  <td class="text-left pl-2">Risk Status</td>
                                  <td class="text-left"><span class="mx-8" :class="'retailClient-badge status-' +
                                            tradeQuotes.riskStatus">{{tradeQuotes.riskStatus}}</span></td>
                              </tr>
                          </tbody>
                    </table>
                </div>
<!----------------------------------------------------------------------------------------------------------------------------------->
<!----------------------------------------------------------------------------------------------------------------------------------->
                <div v-else-if="tradeQuotes.tradeType === 'Sell ZAR'">
                    <div class="formgrid grid">
                        <div class="col-12 md:col-6 lg:col-6">
                            <h5><b>Actual Trade Rate</b></h5>
                                <InputNumber
                                mode="decimal"
                                locale="en-US"
                                :minFractionDigits="4"
                                :maxFractionDigits="4"
                                class="inputfield w-full"
                                v-model.number="mblRate"/>
                        </div>
                    </div>
                    <div v-if="tradeQuotes.sellZarTradeZarValue > 0">
                      <div class="formgrid grid">
                          <div class="col-12 md:col-6 lg:col-6">
                              <h5>Trade Amount&nbsp;in {{tradeQuotes.crossCurrency}}</h5>
                              <InputNumber
                              mode="decimal"
                              locale="en-US"
                              :minFractionDigits="2"
                              :maxFractionDigits="2"
                              class="inputfield w-full"
                              v-model.number="sellZarTradeCrossAmount"/>
                          </div>
                          <div class="col-12 md:col-6 lg:col-6">
                              <h5>Trade Amount&nbsp;in ZAR </h5>
                              <InputNumber
                              mode="decimal"
                              locale="en-US"
                              :minFractionDigits="2"
                              :maxFractionDigits="2"
                              class="inputfield w-full"
                              v-model="tradeQuotes.sellZarTradeZarValue"/>
                          </div>
                      </div>
                    </div>
                    <div v-else>
                       <div class="formgrid grid">
                          <div class="col-12 md:col-6 lg:col-6">
                              <h5>Trade Amount&nbsp;in {{tradeQuotes.crossCurrency}}</h5>
                              <InputNumber
                              mode="decimal"
                              locale="en-US"
                              :minFractionDigits="2"
                              :maxFractionDigits="2"
                              class="inputfield w-full"
                              v-model="tradeQuotes.sellZarTradeCrossValue"/>
                          </div>
                          <div class="col-12 md:col-6 lg:col-6">
                              <h5>Trade Amount&nbsp;in ZAR </h5>
                                  <InputNumber
                                  mode="decimal"
                                  locale="en-US"
                                  :minFractionDigits="2"
                                  :maxFractionDigits="2"
                                  class="inputfield w-full"
                                  v-model.number="sellZarTradeZarAmount"/>
                          </div>
                      </div>
                    </div>
                  <div v-if="this.mblRate > 0">
                    <br />
                    <h5 class="mx-2">Account and Balance Details</h5>
                      <div class="formgrid grid">
                        <div class="col-12 md:col-6 lg:col-6">
                            <h5>Trade Account Number</h5>
                                <InputText
                                class="inputfield w-full"
                                v-model="tradeQuotes.tradeAccountNumber"/>
                        </div>
                        <div class="col-12 md:col-6 lg:col-6">
                            <h5>Current ZAR Cash Balance</h5>
                                <InputNumber
                                mode="decimal"
                                locale="en-US"
                                readonly
                                :minFractionDigits="2"
                                :maxFractionDigits="2"
                                class="inputfield w-full"
                                v-model="tradeQuotes.currentZarCashBalance"/>
                        </div>
                    </div>
                    <div class="formgrid grid">
                        <div class="col-12 md:col-6 lg:col-6">
                            <h5>SDA Balance</h5>
                                <InputNumber
                                mode="decimal"
                                locale="en-US"
                                readonly
                                :minFractionDigits="2"
                                :maxFractionDigits="2"
                                class="inputfield w-full"
                                v-model="tradeQuotes.sdaRemainder"/>
                        </div>
                          <div v-if="tradeQuotes.hasClientTcc" class="col-12 md:col-6 lg:col-6">
                              <h5>TCC Balance</h5>
                                  <InputNumber
                                  mode="decimal"
                                  locale="en-US"
                                  readonly
                                  :minFractionDigits="2"
                                  :maxFractionDigits="2"
                                  class="inputfield w-full"
                                  v-model="tradeQuotes.tccRemainder"/>
                          </div>
                    </div>
                    <br />
                  <h5 class="mx-2">Cash and Balance Status</h5>
                    <table v-if="tradeQuotes.hasClientTcc" class="shadow-3 mx-2">
                          <thead id="thead" >
                              <tr>
                                  <th class="text-left pl-2" style="width: 50vw">Type</th>
                                  <th class="text-left pl-2" style="width: 25vw">TCC Status</th>
                                  <th class="text-left pl-2" style="width: 25vw">Sufficient</th>
                              </tr>
                          </thead>
                          <tbody id="tbodyLight">
                              <tr>
                                  <td class="text-left pl-2">Has Sufficient Cash</td>
                                  <td></td>
                                  <td class="text-left"><span class="mx-8" :class="'retailClient-badge status-' +
                                            sellZarHasSufficientZarCashToTrade">{{sellZarHasSufficientZarCashToTrade}}</span></td>
                              </tr>
                          </tbody>
                          <tbody id="tbodyDark">
                              <tr>
                                  <td class="text-left pl-2">Has Sufficient SDA</td>
                                  <td></td>
                                  <td class="text-left"><span class="mx-8" :class="'retailClient-badge status-' +
                                            sellZarHasSufficientZarSdaToTrade">{{sellZarHasSufficientZarSdaToTrade}}</span></td>
                              </tr>
                          </tbody>
                          <tbody id="tbodyLight">
                              <tr>
                                  <td class="text-left pl-2">Has Sufficient TCC</td>
                                  <td class="text-left"><span class="mx-8" :class="'retailClient-badge status-' +
                                            tradeQuotes.tccApplicationStatus">{{tradeQuotes.tccApplicationStatus}}</span></td>
                                  <td class="text-left"><span class="mx-8" :class="'retailClient-badge status-' +
                                            sellZarHasSufficientZarTccToTrade">{{sellZarHasSufficientZarTccToTrade}}</span></td>
                              </tr>
                          </tbody>
                          <tbody id="tbodyDark">
                              <tr>
                                  <td class="text-left pl-2">Risk Status</td>
                                  <td></td>
                                  <td class="text-left"><span class="mx-8" :class="'retailClient-badge status-' +
                                            tradeQuotes.riskStatus">{{tradeQuotes.riskStatus}}</span></td>
                              </tr>
                          </tbody>
                    </table>
                    <table v-else class="shadow-3 mx-2">
                          <thead id="thead" >
                              <tr>
                                  <th class="text-left pl-2" style="width: 60vw">Type</th>
                                  <th class="text-left pl-2" style="width: 40vw">Sufficient</th>
                              </tr>
                          </thead>
                          <tbody id="tbodyLight">
                              <tr>
                                  <td class="text-left pl-2">Has Sufficient Cash</td>
                                  <td class="text-left"><span class="mx-8" :class="'retailClient-badge status-' +
                                            sellZarHasSufficientZarCashToTrade">{{sellZarHasSufficientZarCashToTrade}}</span></td>
                              </tr>
                          </tbody>
                          <tbody id="tbodyDark">
                              <tr>
                                  <td class="text-left pl-2">Has Sufficient SDA</td>
                                  <td class="text-left"><span class="mx-8" :class="'retailClient-badge status-' +
                                            sellZarHasSufficientZarSdaToTrade">{{sellZarHasSufficientZarSdaToTrade}}</span></td>
                              </tr>
                          </tbody>
                          <tbody id="tbodyLight">
                              <tr>
                                  <td class="text-left pl-2">Risk Status</td>
                                  <td class="text-left"><span class="mx-8" :class="'retailClient-badge status-' +
                                            tradeQuotes.riskStatus">{{tradeQuotes.riskStatus}}</span></td>
                              </tr>
                          </tbody>
                    </table>
                  </div>

                </div>
<!----------------------------------------------------------------------------------------------------------------------------------->
<!----------------------------------------------------------------------------------------------------------------------------------->
                <div v-else-if="tradeQuotes.tradeType === 'Cross'">
                    <div class="formgrid grid">
                        <div class="col-12 md:col-6 lg:col-6">
                            <h5><b>Actual Trade Rate</b></h5>
                                <InputNumber
                                mode="decimal"
                                locale="en-US"
                                :minFractionDigits="4"
                                :maxFractionDigits="4"
                                class="inputfield w-full"
                                v-model.number="mblRate"/>
                        </div>
                    </div>
                    <div v-if="tradeQuotes.crossTradeValue > 0">
                      <div class="formgrid grid">
                            <div class="col-12 md:col-6 lg:col-6">
                                <h5>Trade Amount&nbsp;in {{tradeQuotes.tradeCurrency}}</h5>
                                <InputNumber
                                mode="decimal"
                                locale="en-US"
                                :minFractionDigits="2"
                                :maxFractionDigits="2"
                                class="inputfield w-full"
                                v-model="tradeQuotes.crossTradeValue"/>
                            </div>
                            <div class="col-12 md:col-6 lg:col-6">
                                <h5>Trade Amount&nbsp;in {{tradeQuotes.crossCurrency}} </h5>
                                    <InputNumber
                                    mode="decimal"
                                    locale="en-US"
                                    :minFractionDigits="2"
                                    :maxFractionDigits="2"
                                    class="inputfield w-full"
                                    v-model.number="crossTradeCurrencyAmount"/>
                            </div>
                        </div>
                     </div>
                    <div v-else>
                      <div class="formgrid grid">
                            <div class="col-12 md:col-6 lg:col-6">
                                <h5>Trade Amount&nbsp;in {{tradeQuotes.tradeCurrency}}</h5>
                                <InputNumber
                                mode="decimal"
                                locale="en-US"
                                :minFractionDigits="2"
                                :maxFractionDigits="2"
                                class="inputfield w-full"
                                v-model.number="crossTradeCrossCurrencyAmount"/>
                            </div>
                            <div class="col-12 md:col-6 lg:col-6">
                                <h5>Trade Amount&nbsp;in {{tradeQuotes.crossCurrency}} </h5>
                                    <InputNumber
                                    mode="decimal"
                                    locale="en-US"
                                    :minFractionDigits="2"
                                    :maxFractionDigits="2"
                                    class="inputfield w-full"
                                    v-model="tradeQuotes.crossTradeCrossValue"/>
                            </div>
                        </div>
                     </div>
                <div v-if="this.mblRate > 0">
                  <div v-if="tradeQuotes.fcaAccountNumber2">
                    <div class="formgrid grid">
                      <div class="col-12 md:col-6 lg:col-6">
                          <h5>Trade Account Number</h5>
                              <InputText
                              class="inputfield w-full"
                              v-model="tradeQuotes.tradeAccountNumber"/>
                      </div>
                      <div v-if="tradeQuotes.tradeAccountNumber === tradeQuotes.fcaAccountNumber2" class="col-12 md:col-6 lg:col-6">
                          <h5>Trade Account Balance {{tradeQuotes.fcaCurrency2}}</h5>
                              <InputNumber
                              mode="decimal"
                              locale="en-US"
                              :minFractionDigits="2"
                              :maxFractionDigits="2"
                              class="inputfield w-full"
                              v-model="tradeQuotes.currentFca2CashBalance"/>
                      </div>
                      <div v-else-if="tradeQuotes.tradeAccountNumber === tradeQuotes.fcaAccountNumber1" class="col-12 md:col-6 lg:col-6">
                          <h5>Trade Account Balance {{tradeQuotes.fcaCurrency1}}</h5>
                              <InputNumber
                              mode="decimal"
                              locale="en-US"
                              :minFractionDigits="2"
                              :maxFractionDigits="2"
                              class="inputfield w-full"
                              v-model="tradeQuotes.currentFca1CashBalance"/>
                      </div>
                    </div>
                  <h5 class="mx-2">FCA Account Details</h5>
                      <table class="shadow-3 mx-2">
                            <thead id="thead" >
                                <tr>
                                    <th class="text-left pl-2" style="width: 40vw">Account Number</th>
                                    <th class="text-left pl-2" style="width: 20vw">Currency</th>
                                    <th class="text-left pl-2" style="width: 40vw">Balance</th>
                                </tr>
                            </thead>
                            <tbody id="tbodyLight">
                                <tr>
                                    <td class="text-left pl-2">{{tradeQuotes.fcaAccountNumber1}} </td>
                                    <td class="text-left pl-2">{{tradeQuotes.fcaCurrency1}} </td>
                                    <td class="text-left pl-2"> {{formatCurrency(tradeQuotes.currentFca1CashBalance, "0,0.00")}} </td>
                                </tr>
                            </tbody>
                            <tbody id="tbodyDark">
                                <tr>
                                    <td class="text-left pl-2">{{tradeQuotes.fcaAccountNumber2}} </td>
                                    <td class="text-left pl-2">{{tradeQuotes.fcaCurrency2}} </td>
                                    <td class="text-left pl-2"> {{formatCurrency(tradeQuotes.currentFca2CashBalance, "0,0.00")}} </td>
                                </tr>
                            </tbody>
                      </table>
                      <h5 class="mx-2">Client Risk Status</h5>
                      <table class="shadow-3 mx-2">
                          <thead id="thead" >
                              <tr>
                                  <th class="text-left pl-2" style="width: 60vw">Type</th>
                                  <th class="text-left pl-2" style="width: 40vw">Status</th>
                              </tr>
                          </thead>
                          <tbody id="tbodyDark">
                              <tr>
                                  <td class="text-left pl-2">Risk Status</td>
                                  <td class="text-left"><span class="mx-8" :class="'retailClient-badge status-' +
                                            tradeQuotes.riskStatus">{{tradeQuotes.riskStatus}}</span></td>
                              </tr>
                          </tbody>
                    </table>
                  </div>
                  <div v-else-if="tradeQuotes.fcaAccountNumber1 && tradeQuotes.fcaAccountNumber2 === ''">
                    <div class="formgrid grid">
                      <div class="col-12 md:col-6 lg:col-6">
                          <h5>Trade Account Number</h5>
                              <InputText
                              class="inputfield w-full"
                              v-model="tradeQuotes.tradeAccountNumber"/>
                      </div>
                  </div>
                    <h5 class="mx-2">FCA Account Details</h5>
                      <table class="shadow-3 mx-2">
                            <thead id="thead" >
                                <tr>
                                    <th class="text-left pl-2" style="width: 40vw">Account Number</th>
                                    <th class="text-left pl-2" style="width: 20vw">Currency</th>
                                    <th class="text-left pl-2" style="width: 40vw">Balance</th>
                                </tr>
                            </thead>
                            <tbody id="tbodyDark">
                                <tr>
                                    <td class="text-left pl-2">{{tradeQuotes.fcaAccountNumber1}} </td>
                                    <td class="text-left pl-2">{{tradeQuotes.fcaCurrency1}} </td>
                                    <td class="text-left pl-2"> {{formatCurrency(tradeQuotes.currentFca1CashBalance, "0,0.00")}} </td>
                                </tr>
                            </tbody>
                      </table>
                      <br />
                      <h5 class="mx-2">Client Risk Status</h5>
                      <table class="shadow-3 mx-2">
                          <thead id="thead" >
                              <tr>
                                  <th class="text-left pl-2" style="width: 60vw">Type</th>
                                  <th class="text-left pl-2" style="width: 40vw">Status</th>
                              </tr>
                          </thead>
                          <tbody id="tbodyDark">
                              <tr>
                                  <td class="text-left pl-2">Risk Status</td>
                                  <td class="text-left"><span class="mx-8" :class="'retailClient-badge status-' +
                                            tradeQuotes.riskStatus">{{tradeQuotes.riskStatus}}</span></td>
                              </tr>
                          </tbody>
                    </table>
                  </div>
                  <div v-else-if="tradeQuotes.fcaAccountNumber1 === ''">
                    <br />
                    <h4 class="mx-2">No FCA Accounts Found</h4>
                      <h5 class="mx-2">Client Risk Status</h5>
                      <table class="shadow-3 mx-2">
                          <thead id="thead" >
                              <tr>
                                  <th class="text-left pl-2" style="width: 70vw">Type</th>
                                  <th class="text-left pl-2" style="width: 30vw">Status</th>
                              </tr>
                          </thead>
                          <tbody id="tbodyDark">
                              <tr>
                                  <td class="text-left pl-2">Risk Status</td>
                                  <td class="text-left"><span class="mx-8" :class="'retailClient-badge status-' +
                                            tradeQuotes.riskStatus">{{tradeQuotes.riskStatus}}</span></td>
                              </tr>
                          </tbody>
                    </table>
                  </div>
                </div>
              </div>
        </transition>
      </template>
    </Card>
    </div>
    <transition name="fade">
        <div class="col-12 md:col-6 lg:col-6">
          <div v-if="tradeQuotes.hasChatEnabled === true">
            <Card class="shadow-5" id="chatCard" >
                  <template #content>
                      <section id="chatArea">
                      <div id="chatScroll" ref="chatArea">
                      <p v-for="(q, index) in tradeQuotes.tradeQuoteMessages" v-bind:key="index"
                          :class="{ 'messageAdmin': q.sentBy === 'isAdmin',
                                  'messagePartner': q.sentBy === 'isPartner'}">
                      <span :class="{ 'adminTimestamp': q.sentBy === 'isAdmin',
                                  'partnerTimestamp': q.sentBy === 'isPartner'}">
                                  <u>{{q.updatedAt}}</u></span>
                                  <br />
                                  {{q.message}}
                      </p>
                      </div>
                      </section>
                  </template>
                  <template #footer>
                  <div id="quoteFooter" class="flex justify-content-between">
                      <div>
                          <Textarea
                          ref="textInputArea"
                          id="textInput"
                          :autoResize="true"
                          rows="2"
                          class="inputfield w-full"
                          placeholder="Complete Chat..."
                          v-model="newTradeQuoteMessage.message"/>
                      </div>
                        <div>
                            <Button class="shadow-3"
                                    id="querySendButton"
                                    label="Send"
                                    icon="pi pi-send"
                                    @click.prevent="replyToTradeConfirm(newTradeQuoteMessage)"/>
                      </div>
                  </div>
                </template>
              </Card>
            </div>
            <div v-else>
                <h4>&nbsp;&nbsp;Chat Not Selected</h4>
            </div>
          </div>
      </transition>
    </div>
    <div class="fluid grid mx-1">
        <div class="col-12 md:col-12 lg:col-12">
            <div class="flex justify-content-between">
                <div>
                    <Button class="l-2 m-2 mx-1 shadow-3 p-button-lg"
                    icon="pi pi-step-backward"
                    label="BACK&nbsp;"
                    id="submitButton"
                    @click.prevent="$router.back()" />
                </div>
                <div>
                    <Button class="l-2 m-2 mr-1 shadow-3 p-button-lg"
                    icon="pi pi-check-circle"
                    label="COMPLETE&nbsp;"
                    id="backButton"
                    @click.prevent="markTradeQuoteComplete" />
                </div>
            </div>
        </div>
    </div>
  </div>
    <br />
      <div v-if='acCompleteSuccess'>
          <Dialog class="successModal" header="SUCCESS" v-model:visible="displayConfirmation">
              <div class="confirmation-content">
                  <span>Post Trade Details Sent To Partner and Trade Marked as Complete</span>
              </div>
              <template #footer>
                <div class="flex justify-content-between">
                  <router-link class="p-button d-block mx-1 px-auto" :to="'/quotesView'"
                              style="background-color: #0185b7!important;
                                      text-decoration: none; min-width: 100%; justify-content: center;">
                    <i class="pi pi-globe" style="fontSize: 1rem"></i>&nbsp;View All
                  </router-link>
                </div>
              </template>
          </Dialog>
        </div>
</template>

<script>
import { getClientTradeQuote, acCompleteTradeQuote } from '@/gateway'
import numeral from 'numeral'

var date = new Date()
var isoDateTime = new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString().substring(0, 16).replace('T', ' ~ ')

export default {
  name: 'TradeQuoteHasTraded',
  inheritAttrs: false,
  data () {
    return {
      tradeQuotes: [],
      tradeTypes: [
        { value: 'Sell ZAR', text: 'Sell ZAR' },
        { value: 'Buy ZAR', text: 'Buy ZAR' },
        { value: 'Cross', text: 'Cross Trade' }
      ],
      currencies: [
        { value: 'USD', text: 'USD' },
        { value: 'EUR', text: 'EUR' },
        { value: 'GBP', text: 'GBP' },
        { value: 'JPY', text: 'JPY' },
        { value: 'CNY', text: 'CNY' },
        { value: 'AUD', text: 'AUD' },
        { value: 'CAD', text: 'CAD' },
        { value: 'CHF', text: 'CHF' },
        { value: 'NZD', text: 'NZD' },
        { value: 'HKD', text: 'HKD' }
      ],
      crossCurrencies: [
        { value: 'USD', text: 'USD' },
        { value: 'EUR', text: 'EUR' },
        { value: 'GBP', text: 'GBP' },
        { value: 'JPY', text: 'JPY' },
        { value: 'CNY', text: 'CNY' },
        { value: 'AUD', text: 'AUD' },
        { value: 'CAD', text: 'CAD' },
        { value: 'CHF', text: 'CHF' },
        { value: 'NZD', text: 'NZD' },
        { value: 'HKD', text: 'HKD' }
      ],
      tradeTimes: [
        { value: 'Spot', text: 'Spot (T+2)' },
        { value: 'Value Tomorrow', text: 'Value Tomorrow' },
        { value: 'Value Today', text: 'Value Today' },
        { value: 'Forward Cover', text: 'Forward Cover' }
      ],
      newTradeQuoteMessage: {
        message: '',
        sentBy: 'isAdmin',
        updatedAt: isoDateTime
      },
      mblRate: 0,
      displayConfirmation: true,
      acCompleteSuccess: false,
      messageDisplay: 0
    }
  },
  methods: {
    async init () {
      const response = await getClientTradeQuote({ id: this.id })
      if (response) {
        this.tradeQuotes = response.result
      }
      await this.$nextTick()
      const messageDisplay = this.$refs.chatArea
      messageDisplay.scrollTop = messageDisplay.scrollHeight
    },
    async markTradeQuoteComplete () {
      const response = await acCompleteTradeQuote({
        tradeQuotes: this.tradeQuotes,
        mblRate: this.mblRate,
        buyZarTradeZarAmount: this.buyZarTradeZarAmount,
        buyZarTradeCrossAmount: this.buyZarTradeCrossAmount,
        buyCrossTradeCurrencyAmount: this.buyCrossTradeCurrencyAmount,
        sellZarTradeZarAmount: this.sellZarTradeZarAmount,
        sellZarTradeCrossAmount: this.sellZarTradeCrossAmount,
        sellZarHasSufficientZarCashToTrade: this.sellZarHasSufficientZarCashToTrade,
        sellZarHasSufficientZarSdaToTrade: this.sellZarHasSufficientZarSdaToTrade,
        sellZarHasSufficientZarTccToTrade: this.sellZarHasSufficientZarTccToTrade,
        crossTradeCurrencyAmount: this.crossTradeCurrencyAmount,
        crossTradeCrossCurrencyAmount: this.crossTradeCrossCurrencyAmount
      })
      if (response) {
        this.acCompleteSuccess = true
      }
    },
    async replyToTradeConfirm (newMessage) {
      this.tradeQuotes.tradeQuoteMessages.push(newMessage)
      this.newTradeQuoteMessage = {
        message: '',
        sentBy: '',
        updatedAt: ''
      }
      const response = await acCompleteTradeQuote({
        tradeQuotes: this.tradeQuotes,
        mblRate: this.mblRate,
        buyZarTradeZarAmount: this.buyZarTradeZarAmount,
        buyZarTradeCrossAmount: this.buyZarTradeCrossAmount,
        buyCrossTradeCurrencyAmount: this.buyCrossTradeCurrencyAmount,
        sellZarTradeZarAmount: this.sellZarTradeZarAmount,
        sellZarTradeCrossAmount: this.sellZarTradeCrossAmount,
        sellZarHasSufficientZarCashToTrade: this.sellZarHasSufficientZarCashToTrade,
        sellZarHasSufficientZarSdaToTrade: this.sellZarHasSufficientZarSdaToTrade,
        sellZarHasSufficientZarTccToTrade: this.sellZarHasSufficientZarTccToTrade,
        crossTradeCurrencyAmount: this.crossTradeCurrencyAmount,
        crossTradeCrossCurrencyAmount: this.crossTradeCrossCurrencyAmount
      })
      if (response) {
        this.acCompleteSuccess = true
      }
      await this.$nextTick()
      const messageDisplay = this.$refs.chatArea
      messageDisplay.scrollTop = messageDisplay.scrollHeight
    },
    formatPercentage (val, fmt) {
      if (fmt) return numeral(val / 100).format(fmt)
      else return numeral(val / 100).format('0.00%')
    },
    formatCurrency (val, fmt) {
      if (fmt) return numeral(val).format(fmt)
      else return numeral(val).format('0,0.00a')
    }
  },
  computed: {
    quotedRateInCents: function () {
      if (this.mblRate === 0)
      { return parseFloat(0.00) }
      else
      { return parseFloat(this.mblRate) - parseFloat(this.mblRate * (1 - this.tradeQuotes.commissionRate)) }
    },
    quotedBuyRate: function () {
      if (this.mblRate === 0)
      { return parseFloat(0.0000) }
      else
      { return parseFloat(this.mblRate) - parseFloat(this.quotedRateInCents / 100) }
    },
    quotedCrossRate: function () {
      if (this.mblRate === 0)
      { return parseFloat(0.0000) }
      else
      { return parseFloat(this.mblRate) - parseFloat(this.quotedRateInCents / 100) }
    },
    quotedSellRate: function () {
      if (this.mblRate === 0)
      { return parseFloat(0.0000) }
      else
      { return Number((this.mblRate) + parseFloat(this.quotedRateInCents / 100)) }
    },
    buyZarTradeZarAmount: function () {
      if (this.mblRate === 0)
      { return parseFloat(0.00) }
      else
      { return parseFloat(this.tradeQuotes.buyZarTradeCrossValue) * parseFloat((this.mblRate)) }
    },
    buyZarTradeCrossAmount: function () {
      if (this.mblRate === 0)
      { return parseFloat(0.00) }
      else
      { return parseFloat(this.tradeQuotes.buyZarTradeZarValue) / parseFloat((this.mblRate)) }
    },
    sellZarTradeZarAmount: function () {
      if (this.mblRate === 0)
      { return 0 }
      else
      { return parseFloat((this.tradeQuotes.sellZarTradeCrossValue) * parseFloat(this.mblRate)) }
    },
    sellZarTradeCrossAmount: function () {
      if (this.mblRate === 0)
      { return 0 }
      else
      { return parseFloat((this.tradeQuotes.sellZarTradeZarValue) / parseFloat(this.mblRate)) }
    },
    crossTradeCurrencyAmount: function () {
      if (this.mblRate === 0)
      { return parseFloat(0.00) }
      else
      { return parseFloat(this.tradeQuotes.crossTradeValue) * parseFloat((this.mblRate)) }
    },
    crossTradeCrossCurrencyAmount: function () {
      if (this.mblRate === 0)
      { return parseFloat(0.00) }
      else
      { return parseFloat(this.tradeQuotes.crossTradeCrossValue) / parseFloat((this.mblRate)) }
    },
    sellZarHasSufficientZarCashToTrade: function () {
      if (this.tradeQuotes.sellZarTradeCrossValue > 0)
      {
        if (this.tradeQuotes.currentZarCashBalance > this.sellZarTradeZarAmount)
        { return 'Yes' }
        else
        { return 'No' }
      }
      else
      {
        if (this.tradeQuotes.currentZarCashBalance < this.tradeQuotes.sellZarTradeZarValue)
        { return 'No' }
        else
        { return 'Yes' }
      }
    },
    sellZarHasSufficientZarSdaToTrade: function () {
      if (this.tradeQuotes.sellZarTradeCrossValue > 0)
      {
        if (this.tradeQuotes.sdaRemainder > this.sellZarTradeZarAmount)
        { return 'Yes' }
        else
        { return 'No' }
      }
      else
      {
        if (this.tradeQuotes.sdaRemainder < this.tradeQuotes.sellZarTradeZarValue)
        { return 'No' }
        else
        { return 'Yes' }
      }
    },
    sellZarHasSufficientZarTccToTrade: function () {
      if (this.tradeQuotes.sellZarTradeCrossValue > 0)
      {
        if (this.tradeQuotes.tccRemainder > this.sellZarTradeZarAmount)
        { return 'Yes' }
        else
        { return 'No' }
      }
      else
      {
        if (this.tradeQuotes.tccRemainder < this.tradeQuotes.sellZarTradeZarValue)
        { return 'No' }
        else
        { return 'Yes' }
      }
    }
  },
  mounted () {
    this.id = this.$route.params.id
    this.init()
  }
}
</script>

<style lang="scss" scoped>
::v-deep() {
  #textArea {
    border: solid 1px;
    border-color: $lightBorderColor;
    font-family: $fontFamily!important;
    font-size: 16px;
    color: $darkTextColour;
    border-radius: 4px;
  };
  #textArea:focus {
    outline: solid 0.5px;
    outline-color: #0d6efd;
  };
  #thead {
    background: $midLightBlue;
    height: 4vh;
    color: $textColor;
    font-size: 16px;
    font-weight: 500;
    font-family: $fontFamily;
  };
  #tbodyLight {
    height: 3vh;
    background-color: $backgroundColour;
    color: $darkTextColour;
    font-family:'Poppins', sans-serif;
    font-weight: 400;
  };
    #tbodyDark {
    height: 3vh;
    background-color: $whiteSmoke;
    color: $darkTextColour;
    font-family:'Poppins', sans-serif;
    font-weight: 400;
  };
  #replyCard {
    display: block;
    margin: auto;
    border: solid 1px!important;
    background-color: $midLightBlue!important;
    border-color: $midDarkBlue!important;
    width: 98%;
    height: auto;
    };
  .retailClient-badge {
    @include statusBadge
  };
  .p-inputnumber-input {
    max-width: 100%!important;
  }
  @media screen and (min-width: 768px) and (max-width:1024px) {
  #fullScreenView {
    display: none!important;
    };
   #tradesCard {
    display: block;
    margin-left: auto;
    margin-right: auto;
    border: solid 1px!important;
    border-color: $midDarkBlue!important;
    width: 100%
    };
  #chatArea {
    background: $backgroundColour;
    min-height: 40vh;
    border: solid 1px;
    overflow: auto;
    border-color: $borderColour;
    font-size: 16px;
    color: $darkTextColour;
    border-radius: 4px;
    padding: 0.2rem 0.2rem 0.2rem 0.8rem;
    margin-top: 0.4rem;
    };
  #chatArea:focus {
    outline: solid 0.5px;
    outline-color: $lightBlue;
    };
    #chatCard {
    display: block;
    margin: auto;
    overflow: auto;
    border: solid 1px!important;
    background-color: $lightDarkBlue!important;
    border-color: $midDarkBlue!important;
    width: 98%;
    min-height: 55vh;
    padding-bottom: 0rem!important;
    };
    #chatScroll {
    max-height: 65vh;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    };
    .messagePartner {
    background: $uploadBackgroundColor;
    color: $darkTextColour;
    margin-left: 10%;
    margin-right: 0.4rem;
    border-radius: 4px;
    padding: 0.8rem;
    };
    .messageAdmin {
    background: $isAdminChatBackground;
    color: $darkTextColour;
    border-radius: 4px;
    padding: .6rem;
    width: 90%;
    };
    .adminTimestamp {
    color: $blackTextColor;
    font-size: 11px;
    padding: 0.2rem;
    };
    .partnerTimestamp {
    color: $blackTextColor;
    font-size: 11px;
    padding: 0.2rem;
    margin-left: 80%;
    };
    #textInput {
    width: 125%!important;
    };
    #querySendButton {
    min-height: 3.75rem!important;
    background: $light!important;
    color: $lightDarkBlue!important;
    font-size: 20px;
    border: solid 1px!important;
    border-color: $midDarkBlue!important;
    };
};
@media screen and (min-width: 1025px) and (max-width:1080px)  {
   #tradesCard {
    display: block;
    margin-left: auto;
    margin-right: auto;
    border: solid 1px!important;
    border-color: $midDarkBlue!important;
    width: 100%
    };
  #chatArea {
    background: $backgroundColour;
    min-height: 40vh;
    border: solid 1px;
    overflow: auto;
    border-color: $borderColour;
    font-size: 16px;
    color: $darkTextColour;
    border-radius: 4px;
    padding: 0.2rem 0.2rem 0.2rem 0.8rem;
    };
  #chatArea:focus {
    outline: solid 0.5px;
    outline-color: $lightBlue;
    };
    #chatCard {
    display: block;
    margin: auto;
    overflow: auto;
    border: solid 1px!important;
    background-color: $lightDarkBlue!important;
    border-color: $midDarkBlue!important;
    width: 95%;
    min-height: 55vh;
    padding-bottom: 0rem!important;
    };
    #chatScroll {
    max-height: 65vh;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    };
    .messagePartner {
    background: $uploadBackgroundColor;
    color: $darkTextColour;
    margin-left: 10%;
    margin-right: 0.4rem;
    border-radius: 4px;
    padding: 0.8rem;
    };
    .messageAdmin {
    background: $isAdminChatBackground;
    color: $darkTextColour;
    border-radius: 4px;
    padding: .6rem;
    width: 90%;
    };
    .adminTimestamp {
    color: $blackTextColor;
    font-size: 11px;
    padding: 0.2rem;
    };
    .partnerTimestamp {
    color: $blackTextColor;
    font-size: 11px;
    padding: 0.2rem;
    margin-left: 80%;
    };
    #textInput {
    width: 350%!important;
    };
    #querySendButton {
    min-height: 3.75rem!important;
    background: $light!important;
    color: $lightDarkBlue!important;
    font-size: 20px;
    border: solid 1px!important;
    border-color: $midDarkBlue!important;
    };
};
@media screen and (min-width: 1081px)  and (max-width: 1366px){
    #tradesCard {
    display: block;
    margin-left: 0.5rem;
    margin-right: auto;
    border: solid 1px!important;
    border-color: $midDarkBlue!important;
    };
    #chatArea {
    background: $backgroundColour;
    min-height: 83vh;
    border: solid 1px;
    overflow: auto;
    border-color: $borderColour;
    font-size: 16px;
    color: $darkTextColour;
    border-radius: 4px;
    padding: 0.2rem 0.2rem 0.2rem 0.2rem;
    };
    #chatArea:focus {
    outline: solid 0.5px;
    outline-color: $lightBlue;
    };
    #chatCard {
    display: block;
    margin: auto;
    overflow: auto;
    border: solid 1px!important;
    background-color: $lightDarkBlue!important;
    border-color: $midDarkBlue!important;
    width: 98%;
    min-height: 83vh;
    };
    #chatScroll {
    max-height: 83vh;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    };
    .messagePartner {
    background: $uploadBackgroundColor;
    color: $darkTextColour;
    margin-left: 10%;
    margin-right: 0.4rem;
    border-radius: 4px;
    padding: .4rem;
    };
    .messageAdmin {
    background: $isAdminChatBackground;
    color: $darkTextColour;
    border-radius: 4px;
    padding: .4rem;
    width: 90%;
    };
    .adminTimestamp {
    color: $blackTextColor;
    font-size: 11px;
    padding: 0.2rem;
    };
    .partnerTimestamp {
    color: $blackTextColor;
    font-size: 11px;
    padding: 0.2rem;
    margin-left: 75%;
    };
    #textInput {
    width: 225%!important;
    };
    #querySendButton {
    min-height: 3.75rem!important;
    background: $light!important;
    color: $lightDarkBlue!important;
    font-size: 20px;
    border: solid 1px!important;
    border-color: $midDarkBlue!important;
    }
};
@media screen and (min-width: 1367px) {
  #tradesCard {
    display: block;
    margin-left: 0.5rem;
    margin-right: auto;
    border: solid 1px!important;
    border-color: $midDarkBlue!important;
    };
  #chatArea {
    background: $backgroundColour;
    min-height: 58vh;
    border: solid 1px;
    overflow: auto;
    border-color: $borderColour;
    font-size: 16px;
    color: $darkTextColour;
    border-radius: 4px;
    padding: 0.2rem 0.2rem 0.2rem 0.8rem;
    };
  #chatArea:focus {
    outline: solid 0.5px;
    outline-color: $lightBlue;
    };
  #chatCard {
    display: block;
    margin: auto;
    overflow: auto;
    border: solid 1px!important;
    background-color: $lightDarkBlue!important;
    border-color: $midDarkBlue!important;
    width: 98%;
    min-height: 58vh;
    };
    #chatScroll {
    max-height: 58vh;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    };
    .messagePartner {
    background: $uploadBackgroundColor;
    color: $darkTextColour;
    margin-left: 15%;
    margin-right: 0.4rem;
    border-radius: 4px;
    padding: .4rem;
    };
    .messageAdmin {
    background: $isAdminChatBackground;
    color: $darkTextColour;
    border-radius: 4px;
    padding: .4rem;
    width: 85%;
    };
    .adminTimestamp {
    color: $blackTextColor;
    font-size: 11px;
    padding: 0.2rem;
    };
    .partnerTimestamp {
    color: $blackTextColor;
    font-size: 11px;
    padding: 0.2rem;
    margin-left: 82%;
    };
    #textInput {
    width: 350%!important;
    };
    #querySendButton {
    min-height: 3.75rem!important;
    background: $light!important;
    color: $lightDarkBlue!important;
    font-size: 20px;
    border: solid 1px!important;
    border-color: $midDarkBlue!important;
    };
  };
};
.p-grid {
  margin: 1rem;
  padding: 1rem;
};
</style>
