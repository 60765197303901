<template>
  <PrimeVueChart :type="props.type" :data="props.data" :options="options"/>
</template>

<script setup>
import { defineProps, ref } from 'vue'

const props = defineProps({
  type: String,
  data: Object,
  title: String,
  displayTitle: Boolean,
  showLegend: Boolean,
  yAxisTitle: String
})

const options = ref({
  responsive: true,
  maintainAspectRatio: true,
  aspectRatio: 2,
  // onResize: (chart, size) => {
  //   console.log(size)
  //   chart.canvas.parentNode.style.height = `${size.height}px`
  //   chart.canvas.parentNode.style.width = `${size.width}px`
  // },
  plugins: {
    title: {
      text: props.title,
      display: props.displayTitle,
      font: {
        size: 20,
        family: 'Poppins, sans-serif'
      }
    },
    legend: {
      display: props.showLegend,
      position: 'bottom'
    }
  },
  scales: {
    x: {
      grid: {
        display: false
      },
      border: {
        width: 2
      },
      ticks: {
        autoSkip: false,
        maxRotation: 90,
        minRotation: 90
      }
    },
    y: {
      border: {
        width: 2
      },
      grid: {
        display: true
      },
      title: {
        display: true,
        text: props.yAxisTitle,
        font: {
          size: 12,
          family: 'Poppins, sans-serif'
        }
      }
    }
  }
})
</script>

<script>
export default {
  name: 'Chart',
  inheritAttrs: false
}
</script>

<style scoped>

</style>
