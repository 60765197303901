<template>
  <br />
  <h2 class="text-center my-2">ADD NEW RESEARCH FILE</h2>
  <br />
  <div class="fluid mx-4">
    <h4 class="text-center"><u>Add New File Details</u></h4>
      <div class="formgrid grid">
        <div class="col-12 md:col-6 lg:col-6">
            <h5>File Category</h5>
              <Dropdown v-model="research.fileCategory"
              :options="fileCategories"
              optionLabel="text"
              optionValue="value"
              :showClear="true"
              appendTo="self"
              class="inputfield w-full"
              placeholder="Please Select" />
          </div>
        <div class="col-12 md:col-6 lg:col-6">
            <h5>File Name</h5>
            <InputText
            type="text"
            class="inputfield w-full"
            v-model="research.fileDisplayName"/>
        </div>
    </div>
    <div class="formgrid grid">
        <div class="col-12 md:col-6 lg:col-6">
            <h5>Published Date</h5>
            <Calendar
            v-model="research.fileDate"
            :showIcon="true"
            appendTo="self"
            class="inputfield w-full"
            dateFormat="dd-mm-yy" />
        </div>
    </div>
    <br />
    <h5 class="mx-2">Upload Research File</h5>
    <br />
    <div class="col-12 md:col-6 lg:col-6 flex justify-content-between mx-1" id="uploadBackground">
          <button class="file_upload" type="button">
            <span class="btn_lbl"><i class="pi pi-file-pdf"></i>&nbsp;&nbsp;SELECT FILE</span>
            <span class="btn_colorlayer"></span>
            <input type="file" name="fileupload" id="file_upload" @change="onFileSelected" />
          </button>
        <Button
            id="uploadButton"
            icon="pi pi-upload"
            @click="onFileUpload"
            label="&nbsp;UPLOAD FILE&nbsp;&nbsp;"/>
    </div>
    <br />
    <div class="formgrid grid">
      <div class="col-12 md:col-6 lg:col-6">
        <div v-if="researchSuccess">
        <Dialog class="successModal" header="SUCCESS" id="successModal" v-model:visible="displayConfirmation">
            <div class="confirmation-content">
                <span>New Research File Added</span>
            </div>
            <template #footer>
              <div class="flex justify-content-between">
                <router-link class="p-button d-block mr-2 px-auto" :to="'/researchHome'"  style="background-color: #014f7e!important; text-decoration: none;
                                    text-decoration: none; width: 40%; justify-content: center;">
                  <i class="pi pi-home" style="fontSize: 1rem"></i> &nbsp;Back
                </router-link>
                <router-link class="p-button d-block mx-2 px-auto" :to="'/researchView'"  style="background-color: #0185b7!important; text-decoration: none
                                    text-decoration: none; width: 40%; justify-content: center;">
                  <i class="pi pi-users" style="fontSize: 1rem"></i>&nbsp;View
                </router-link>
              </div>
            </template>
        </Dialog>
        </div>
        <Dialog class="deleteModal" header="UNAUTHORISED ACCESS" v-model:visible="openErrorModal" :modal="true">
        <div class="confirmation-content">
            <span>User not authorised to view this information.</span>
            <br />
                  Please contact the system administrator.
        </div>
        <template #footer>
            <a class="p-button d-block mr-2 px-auto" @click.prevent="$router.push( {name: 'ResearchHome' })"
              style="float: left; background-color: #014f7e!important;
                     text-decoration: none; justify-content: center;">
              <i class="pi pi-step-backward" style="fontSize: 1rem"></i> &nbsp;&nbsp;Back to Research Home
            </a>
        </template>
    </Dialog>
      </div>
    </div>
  </div>
  <hr class="mx-4 shadow-2"/>
    <div>
    <Button class="l-2 m-4 mx-3 shadow-3 p-button-lg"
    icon="pi pi-step-backward"
    label="BACK&nbsp;"
    id="submitButton"
    @click.prevent="$router.back()" />
  </div>
</template>

<script>
// @ is an alias to /src
import { uploadResearchFile } from '@/gateway'

export default {
  name: 'ResearchAdd',
  inheritAttrs: false,
  data () {
    return {
      researchSuccess: false,
      displayConfirmation: true,
      openErrorModal: false,
      research: {
        id: 0,
        fileName: '',
        fileCategory: '',
        fileDisplayName: '',
        fileDate: null,
        fileUploadedOn: null,
        selectedFile: null
      },
      fileCategories: [
        { value: 'Decoder', text: 'Decoder' },
        { value: 'Exchange', text: 'Exchange' },
        { value: 'Macro Papers', text: 'Macro Papers' },
        { value: 'Swift File', text: 'Swift File' }
      ]
    }
  },
  methods: {
    async init () {

    },
    getFriendlyDate (val) {
      var d = new Date(val)
      var month = '' + (d.getMonth() + 1)
      var day = '' + d.getDate()
      var year = d.getFullYear()
      if (month.length < 2) {
        month = '0' + month
      }
      if (day.length < 2) {
        day = '0' + day
      }
      return [year, month, day].join('-')

    //  return val.getFullYear() + '-' + (val.getMonth() + 1) + '-' + val.getDate()
    },
    onFileSelected (event) {
      this.selectedFile = event.target.files[0]
    },
    async onFileUpload () {
      // eslint-disable-next-line no-unused-vars
      const response = await uploadResearchFile(
        {
          selectedFile: this.selectedFile,
          fileName: this.research.fileName,
          fileCategory: this.research.fileCategory,
          fileDisplayName: this.research.fileDisplayName,
          fileDate: this.getFriendlyDate(this.research.fileDate)
        })
      if (response.message === 'Unauthorised User') {
        this.openErrorModal = true
      } else if (response) {
        this.researchSuccess = true
      }
    }
  },
  async mounted () {
    this.init()
  }
}
</script>

<style lang="scss" scoped>
#uploadBackground {
 @include uploadBackground($uploadBackgroundColor)
};
#fileButton {
  background-color: $midDarkBlue!important;
  color: $textColor;
  font-size: 1.25rem;
  border: 2px solid;
  border-color: $borderColour;
  display: flex;
  justify-content: right;
};
#uploadButton {
  background-color: $lightDarkBlue!important;
  color: $textColor;
  font-size: 1.25rem;
  height: 2.5rem;
  border: solid 1px $borderColour!important;
  display: flex;
  justify-content: right;
};
.file_upload {
  position: relative;
  min-width: 11rem;
  height: 2.5rem;
  text-align: center;
  color: $textColor;
  line-height: 25px;
  background-color: $midDarkBlue!important;
  border: solid 1px $borderColour;
  font-family: $fontFamily;
  font-size: 1.25rem;
  font-family: Poppins!important;
  font-weight: 400!important;
  border-radius: 4px;
}
a.file_upload {
  display: inline-block;
}
.file_upload .btn_lbl {
  position: relative;
  z-index: 2;
  pointer-events: none;
}
.file_upload .btn_colorlayer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: $midDarkBlue!important;;
  z-index: 1;
  pointer-events: none;
}
.file_upload input[type="file"] {
  position: absolute;
  top: 3px;
  left: -102px;
  font-weight: 600;
  margin-left: 100%;
  margin-top: 5px;
  color: $darkTextColour;
  outline: none;
  font-size: 1rem;;
  font-family: $fontFamily;
  height: 2.5rem;
}
</style>
