<template>
  <div style="width: 100%; height: 100%; display: flex; flex-direction: row; align-items: center; justify-content: start; gap: 10px; padding-top: 10px; padding-bottom: 10px;">
    <div
      :class="classes[0]"
      @click="onClick('Monthly')"
    >
      Monthly
    </div>
    <div
      :class="classes[1]"
      @click="onClick('Quarterly')"
    >
      Quarterly
    </div>
    <div
      :class="classes[2]"
      @click="onClick('Yearly')"
    >
      Yearly
    </div>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, computed } from 'vue'

const props = defineProps({
  selected: String
})

const emit = defineEmits(['select'])

const classes = computed(() => {
  if (props.selected === 'Monthly') {
    return ['active-selector', 'inactive-selector', 'inactive-selector']
  } else if (props.selected === 'Quarterly') {
    return ['inactive-selector', 'active-selector', 'inactive-selector']
  } else {
    return ['inactive-selector', 'inactive-selector', 'active-selector']
  }
})

function onClick (period) {
  emit('select', { period: period })
}
</script>

<script>
export default {
  name: 'PeriodSelector',
  inheritAttrs: false
}
</script>

<style scoped>
.inactive-selector, .active-selector {
  height: 100%;
  width: 80px;
  font-size: 12px;
  color: #4B5563;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 5px;
  border-radius: 0.25rem;
}

.inactive-selector:hover {
  background-color: #E5E7EB;
  cursor: pointer;
}

.active-selector {
  background-color: #E5E7EB;
}

.active-selector:hover {
  cursor: pointer;
}
</style>
