<template>
  <div
    :class="c"
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseLeave"
  >
    <slot name="left-icon"/>
    <input
      v-model="text"
      type="text"
      :title="text"
      class="input"
      :readonly="props.readOnly"
      placeholder="Text here..."
      @focusin="onFocusIn"
      @focusout="onFocusOut"
      @input="onInput"
    />
    <slot name="right-icon"/>
  </div>
</template>

<script setup>
import { computed, ref, defineEmits, defineProps, watch } from 'vue'

const props = defineProps({
  text: String,
  readOnly: Boolean,
  maintainFocus: Boolean
})

const emit = defineEmits(['input', 'fi', 'fo'])

const focus = ref(false)
const hover = ref(false)
const text = ref(props.text)

const c = computed(() => {
  if (hover.value || focus.value || props.maintainFocus) {
    return 'active-div'
  }
  return 'inactive-div'
})

function onMouseEnter () {
  hover.value = true
}

function onMouseLeave () {
  hover.value = false
}

function onFocusIn () {
  focus.value = true
  emit('fi')
}

function onFocusOut () {
  focus.value = false
  emit('fo')
}

function onInput () {
  emit('input', { text: text.value })
}

watch(() => { return props.text }, () => {
  text.value = props.text
})
</script>

<script>
export default {
  name: 'TextInput',
  inheritAttrs: false
}
</script>

<style scoped>
.input {
  width: 100%;
  height: 98%;
  border: none;
  border-radius: 0.25rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  color: #4B5563;
}

.input:focus {
  outline: none;
}

.active-div, .inactive-div {
  background-color: white;
  height: 100%;
  width: 100%;
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 5px;
  padding-right: 5px;
  flex-direction: row;
  gap: 4px;
}

.active-div {
  border: solid 1px #9CA3AF;
}

.inactive-div {
  border: solid 1px #E5E7EB;
}
</style>
