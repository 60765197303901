<template>
<br />
<h2 class="text-center my-2">ADD NEW TCC APPLICATION </h2>
<br />
<div class="fluid grid mx-4">
  <div class="col-12 md:col-6 lg:col-6">
    <h5 class="text-left">TCC Amount in ZAR</h5>
      <InputNumber
      mode="decimal"
      :minFractionDigits="2"
      v-model="clientTcc.tccValue"
      class="inputfield w-full"/>
  </div>
    <div class="col-12 md:col-6 lg:col-6">
      <h5 class="text-left">TCC Issuer</h5>
        <Dropdown v-model="clientTcc.tccIssuer"
        :options="issuers"
        optionLabel="text"
        optionValue="value"
        :showClear="true"
        appendTo="self"
        class="inputfield w-full"
        placeholder="Please Select" />
    </div>
</div>
<hr class="mx-5 shadow-2"/>
<div class="flex justify-content-between mx-2">
    <div>
        <Button class="l-2 m-2 mx-4 shadow-3 p-button-lg"
        icon="pi pi-step-backward"
        label="BACK&nbsp;"
        id="submitButton"
        @click.prevent="$router.back()" />
    </div>
    <div>
        <Button class="l-2 m-2 mx-4 shadow-3 p-button-lg "
        icon="pi pi-save"
        label="SAVE&nbsp;"
        id="backButton"
        @click.prevent="saveClientTcc" />
    </div>
</div>
<div class="formgrid grid">
  <div class="col-12 md:col-6 lg:col-6">
    <div v-if='tccSuccess'>
    <Dialog class="successModal" header="SUCCESS" id="successModal" v-model:visible="displayConfirmation" :modal="true">
        <div class="confirmation-content">
            <span>TCC Application Process Initiated</span>
        </div>
        <template #footer>
          <div class="flex justify-content-between">
            <router-link class="p-button d-block mr-4 px-auto" :to="'/tccClientView/' + retailClient.id"  style="background-color: #014f7e!important; text-decoration: none;
                                text-decoration: none; width: 40%; justify-content: center;">
              <i class="pi pi-step-backward" style="fontSize: 1rem"></i> &nbsp;&nbsp;BACK
            </router-link>
            <router-link class="p-button d-block mx-2 px-auto" :to="'/tccView/' + retailClient.id"  style="background-color: #0185b7!important; text-decoration: none
                                text-decoration: none; width: 40%; justify-content: center;">
              <i class="pi pi-user-edit" style="fontSize: 1rem"></i>&nbsp;&nbsp;VIEW TCC
            </router-link>
          </div>
        </template>
    </Dialog>
    </div>
  </div>
</div>

</template>

<script>
import { createClientTcc, getRetailClient } from '@/gateway'

export default {
  name: 'Add',
  inheritAttrs: false,
  data () {
    return {
      tccSuccess: false,
      displayConfirmation: true,
      clientTcc: {
        id: 0,
        clientId: 0,
        fullNames: '',
        firstLastName: '',
        partnerId: 0,
        partnerName: '',
        applicationStatus: '',
        tccIssuer: '',
        tccPin: '',
        tccValue: 0,
        issueDate: null,
        expiryDate: null,
        charges: 0,
        chargesInvoice: '',
        chargesDate: null,
        chargesType: '',
        comments: '',
        initialSend: null,
        updatedAt: null,
        timeElapsed: 0,
        hasTccDocs: false
      },
      issuers: [
        { value: 'Acredo', text: 'Acredo' },
        { value: 'Eton', text: 'Eton' },
        { value: 'Jaco', text: 'Jaco' },
        { value: 'Independent', text: 'Independent' }
      ],
      retailClient: {}
    }
  },
  methods: {
    async init () {
      const response = await getRetailClient({ id: this.id })
      if (response) this.retailClient = response.result
    },
    async saveClientTcc () {
      const request = { clientTcc: this.clientTcc, retailClient: this.retailClient }
      console.log(request)
      const response = await createClientTcc(request)
      if (response) {
        this.tccSuccess = true
      }
    }
  },
  mounted () {
    this.id = this.$route.params.id
    this.init()
  }
}
</script>
