<template>
  <br />
  <h2 class="text-center my-2">PARTNER CLIENTS TO COMPLETE</h2>
  <br />
  <div>
    <Button icon="pi pi-info" class="p-button-rounded p-button-lg mx-4 shadow-5" @click="toggle" />
    <OverlayPanel ref="op" appendTo="body" :showCloseIcon="true" id="overlay_panel" style="width: 20rem" :breakpoints="{'960px': '75vw'}">
      <p>Use the <i class="pi pi-sort-alt"></i> to filter results.</p>
      <p>Use <i class="pi pi-sort-amount-up-alt"></i> or <i class="pi pi-sort-amount-down-alt"></i> to change the sort order.</p>
      <p>To filter by multiple columns use Ctrl + Click on the second filter column.</p>
      <p>Use the <i class="pi pi-filter"></i> to search for a specific client. You can search on multiple columns.</p>
      </OverlayPanel>
  </div>
  <br />
  <div class="fluid mx-3 shadow-3">
    <DataTable :value="retailClient"
                dataKey="id" :rowHover="true"
                stripedRows responsiveLayout="stack"
                sortMode="multiple"
                showGridlines
                breakpoint="1024px"
                :paginator="true"
                :rows="8"
                v-model:filters="filters1"
                filterDisplay="menu"
                class="datatable-retailClient"
                :globalFilterFields="['partnerName','firstLastName','emailAddress', 'idNumber']">
        <Column field="id" header="ID" headerStyle="width: 1vw"></Column>
        <Column field="partnerName" header="Partner" :sortable="true" headerStyle="width: 5vw">
          <template #filter="{filterModel}">
              <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by Partner"/>
          </template>
        </Column>
        <Column field="firstLastName" header="Client Name" :sortable="true" headerStyle="width: 10vw">
          <template #filter="{filterModel}">
              <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by Name"/>
          </template>
        </Column>
        <Column field="emailAddress" header="Email" headerStyle="width: 10vw">
          <template #filter="{filterModel}">
              <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by Email"/>
          </template>
        </Column>
        <Column field="idNumber" header="ID Number" headerStyle="width: 6vw">
          <template #filter="{filterModel}">
              <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by Id Number"/>
          </template>
        </Column>
        <Column field="contactNumber1" header="Mobile" headerStyle="width: 5vw"></Column>
        <Column field="status" header="Status" headerStyle="width: 5vw">
          <template #body="{data}">
            <div v-if="data.status === 'processing' && data.progressOne === true && data.progressTwo === true && data.progressThree === true">
              <span :class="'retailClient-badge status-' + data.status"  v-tooltip.bottom="{value:'75% : Forms Received', class: 'progress'}">{{data.status}}</span>
            </div>
            <div v-else-if="data.status === 'processing' && data.progressOne === true && data.progressTwo === true">
              <span :class="'retailClient-badge status-' + data.status"  v-tooltip.bottom="{value:'50% : Risk Rating', class: 'progress'}">{{data.status}}</span>
            </div>
             <div v-else-if="data.status === 'processing' && data.progressOne === true">
              <span :class="'retailClient-badge status-' + data.status"  v-tooltip.bottom="{value:'25% : Data Entered', class: 'progress'}">{{data.status}}</span>
            </div>
            <div v-else>
              <span :class="'retailClient-badge status-' + data.status">{{data.status}}</span>
            </div>
          </template>
        </Column>
        <Column header="Action" headerStyle="width: 5vw;">
            <template #body="{data}">
              <div>
               <router-link class="p-button d-block mx-auto px-auto" :to="'/completePartnerClient/' + data.id"  style="color: #e2e7e5; text-decoration: none;
                                   display: flex; justify-content: center;">
                 <i class="pi pi-user-edit" style="fontSize: 1rem"></i>&nbsp;&nbsp;COMPLETE
              </router-link>
              </div>
            </template>
        </Column>
    </DataTable>
  </div>
    <Dialog class="deleteModal" header="UNAUTHORISED ACCESS" v-model:visible="openErrorModal" :modal="true">
        <div class="confirmation-content">
            <span>User not authorised to view this information.</span>
            <br />
                  Please contact the system administrator.
        </div>
        <template #footer>
            <a class="p-button d-block mr-2 px-auto" @click.prevent="$router.push( {name: 'ClientHome' })"
              style="float: left; background-color: #014f7e!important;
                     text-decoration: none; justify-content: center;">
              <i class="pi pi-step-backward" style="fontSize: 1rem"></i> &nbsp;&nbsp;Back to Client Home
            </a>
        </template>
    </Dialog>
  <div>
    <Button class="l-2 m-4 mx-3 shadow-4 p-button-lg"
    icon="pi pi-step-backward"
    label="BACK&nbsp;"
    id="submitButton"
    @click.prevent="$router.back()" />
  </div>
</template>

<script>
import { getPartnerClients } from '@/gateway'
import { FilterMatchMode, FilterOperator } from 'primevue/api'

export default {
  name: 'PartnerClientToAddListView',
  inheritAttrs: false,
  data () {
    return {
      retailClient: [],
      filters1: null,
      openErrorModal: false
    }
  },
  methods: {
    async init () {
      const response = await getPartnerClients()
      if (response.message === 'Unauthorised User') {
        this.openErrorModal = true
      } else {
        this.retailClient = response.result
      }
    },
    initFilters1 () {
      this.filters1 = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        partnerName: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
        firstLastName: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
        emailAddress: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
        idNumber: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] }
      }
    },
    toggle (event) {
      this.$refs.op.toggle(event)
    }
  },
  created () {
    this.initFilters1()
  },
  mounted () {
    this.init()
  }
}
</script>

<style lang="scss" scoped>
::v-deep() {
  .p-datatable .p-datatable-thead > tr > th {
    background-color: $middleBlue!important;
    color: $textColor;
    border: 1px solid $borderColour!important;
    font-size: 18px;
    font-weight: 600;
  }
  .p-datatable .p-datatable-tbody > tr > td {
    padding: .4rem .4rem;
    color: $darkTextColour;
    font-family:'Poppins', sans-serif;
    font-weight: 400;
  }
  .p-paginator-current {
        margin-right: auto;
    }
    .retailClient-badge {
      @include statusBadge
  };
#disabled {
  background: $textColor!important;
  color: $middleBlue!important;
}
};
#searchtext {
  margin-left: 1rem!important;
  min-width: 40vw;
};
</style>
