<template>
  <br />
  <h2 class="text-center my-2">ADD NEW ORDER</h2>
  <br />
  <div class="fluid mx-4">
      <div class="formgrid grid">
        <div class="col-12 md:col-12 lg:col-6">
            <div class="field-checkbox mx-1">
                <Checkbox id="isCorporate"
                v-model="orderBook.isCorporateOrder"
                :binary="true" />
                <label for="isCorporate">Is this a Corporate Order?</label>
            </div>
        </div>
    </div>
      <transition name="fade">
          <div v-if="orderBook.isCorporateOrder == false">
              <div class="formgrid grid">
                <div class="col-12 md:col-6 lg:col-6">
                    <h5>Client Name</h5>
                    <Dropdown v-model="orderBook.clientId"
                      :options="retailClient"
                      optionLabel="text"
                      optionValue="value"
                      :showClear="true"
                      :filter="true"
                      class="inputfield w-full"
                      placeholder="Select Client">
                    </Dropdown>
                </div>
                <div class="col-12 md:col-6 lg:col-6">
                  <h5>Trade Type</h5>
                  <Dropdown v-model="orderBook.orderType"
                    :options="orderTypes"
                    optionLabel="text"
                    optionValue="value"
                    :showClear="true"
                    class="inputfield w-full"
                    placeholder="Select Trade Type">
                  </Dropdown>
              </div>
            </div>
          </div>
          <div v-else>
            <div class="formgrid grid">
              <div class="col-12 md:col-6 lg:col-6">
                <h5>Corporate Name</h5>
                  <InputText
                  type="text"
                  autocomplete="nope"
                  class="inputfield w-full"
                  v-model="orderBook.clientName"/>
              </div>
              <div class="col-12 md:col-6 lg:col-6">
                <h5>Trade Type</h5>
                <Dropdown v-model="orderBook.orderType"
                  :options="orderTypes"
                  optionLabel="text"
                  optionValue="value"
                  :showClear="true"
                  class="inputfield w-full"
                  placeholder="Select Trade Type">
                </Dropdown>
            </div>
          </div>
        </div>
    </transition>
      <div class="formgrid grid">
        <div class="col-12 md:col-6 lg:col-6">
            <h5>Trade Currency</h5>
            <Dropdown v-model="orderBook.currencyType"
              :options="currencyTypes"
              optionLabel="text"
              optionValue="value"
              :showClear="true"
              class="inputfield w-full"
              placeholder="Select Currency">
            </Dropdown>
        </div>
        <div class="col-12 md:col-6 lg:col-6">
            <h5>Trade Value in Trade Currency</h5>
            <InputNumber
              v-model="orderBook.currencyAmount"
               mode="decimal"
              :minFractionDigits="2"
              :maxFractionDigits="2"
              class="inputfield w-full" />
        </div>
    </div>
      <div class="formgrid grid">
        <div class="col-12 md:col-6 lg:col-6">
            <h5>ZAR Nominal</h5>
            <InputNumber
              v-model="orderBook.zarNominal"
               mode="decimal"
              :minFractionDigits="2"
              :maxFractionDigits="2"
              class="inputfield w-full" />
        </div>
        <div class="col-12 md:col-6 lg:col-6">
            <h5>Trader Name</h5>
            <Dropdown v-model="orderBook.traderName"
              :options="traderNames"
              optionLabel="text"
              optionValue="value"
              :showClear="true"
              class="inputfield w-full"
              placeholder="Select Trader">
            </Dropdown>
        </div>
    </div>
      <div class="formgrid grid">
        <div class="col-12 md:col-6 lg:col-6">
            <h5>Bank Rate</h5>
            <InputNumber v-model="orderBook.tradeLevel"
              mode="decimal"
              :minFractionDigits="4"
              :maxFractionDigits="4"
              class="inputfield w-full" />
        </div>
        <div class="col-12 md:col-6 lg:col-6">
            <h5>Client Rate</h5>
            <InputNumber
              v-model="orderBook.clientLevel"
              mode="decimal"
              :minFractionDigits="4"
              :maxFractionDigits="4"
              class="inputfield w-full" />
        </div>
    </div>
    <br />
      <div class="formgrid grid">
        <div class="col-12 md:col-6 lg:col-4">
          <div class="field-checkbox">
              <Checkbox id="hasExpiry"
              v-model="orderBook.hasExpiry"
              :binary="true" />
              <label for="hasExpiry"><h5>Does this Order have an Expiry Date and Time?</h5></label>
          </div>
        </div>
      </div>
      <transition name="fade">
        <div v-if="orderBook.hasExpiry == true">
          <div class=" formgrid grid">
              <div class="col-12 md:col-6 lg:col-6">
                <h5>Expiry Date</h5>
                  <Calendar
                  v-model="orderBook.expiryDate"
                  :showIcon="true"
                  appendTo="self"
                  class="inputfield w-full"
                  dateFormat="dd-mm-yy" />
              </div>
              <div class="col-12 md:col-6 lg:col-6">
                <h5>Expiry Time</h5>
                  <InputText v-model="orderBook.expiryTime"
                    type="time"
                    class="inputfield w-full" />
              </div>
          </div>
        </div>
      </transition>
      <div class="fluid grid">
        <div class="col-12 md:col-12 lg:col-12">
          <h5 class="text-left">Comments</h5>
          <Textarea
              v-model="orderBook.orderComments"
              rows="5"
              cols="30"
              class="inputfield w-full"/>
        </div>
    </div>
    <div class="formgrid grid">
      <div class="col-12 md:col-6 lg:col-6">
        <div v-if='orderSuccess == true'>
        <Dialog class="successModal" header="SUCCESS" id="successModal" v-model:visible="displayConfirmation">
            <div class="confirmation-content">
                <span>New Order Added</span>
            </div>
            <template #footer>
              <div class="flex justify-content-between">
                <router-link class="p-button d-block mr-2 px-auto" :to="'/ordersHome'"  style="background-color: #014f7e!important; text-decoration: none;
                                    text-decoration: none; width: 40%; justify-content: center;">
                  <i class="pi pi-step-backward" style="fontSize: 1rem"></i> &nbsp;Back
                </router-link>
                <router-link class="p-button d-block mx-2 px-auto" :to="'/ordersView'"  style="background-color: #0185b7!important; text-decoration: none
                                    text-decoration: none; width: 40%; justify-content: center;">
                  <i class="pi pi-users" style="fontSize: 1rem"></i>&nbsp;View
                </router-link>
              </div>
            </template>
        </Dialog>
        </div>
        <Dialog class="deleteModal" header="UNAUTHORISED ACCESS" v-model:visible="openErrorModal" :modal="true">
        <div class="confirmation-content">
            <span>User not authorised to view this information.</span>
            <br />
                  Please contact the system administrator.
        </div>
        <template #footer>
            <a class="p-button d-block mr-2 px-auto" @click.prevent="$router.push( {name: 'OrdersHome' })"
              style="float: left; background-color: #014f7e!important;
                     text-decoration: none; justify-content: center;">
              <i class="pi pi-step-backward" style="fontSize: 1rem"></i> &nbsp;&nbsp;Back to Order Book Home
            </a>
        </template>
    </Dialog>
      </div>
    </div>
  </div>
  <hr class="mx-5 shadow-2"/>
    <div class="flex justify-content-between ">
        <div>
            <Button class="l-2 m-3 mx-5 shadow-4 p-button-lg"
            icon="pi pi-step-backward"
            label="BACK&nbsp;"
            id="submitButton"
            @click.prevent="$router.back()" />
        </div>
          <div>
            <Button class="l-2 m-3 mx-5 shadow-4 p-button-lg "
            icon="pi pi-upload"
            label="SUBMIT"
            id="backButton"
            @click.prevent="saveOrderBook" />
        </div>
    </div>
</template>

<script>
import { getAllRetailClients, createOrderBook } from '@/gateway'

export default {
  name: 'OrdersHome',
  inheritAttrs: false,
  data () {
    return {
      orderSuccess: false,
      displayConfirmation: true,
      openErrorModal: false,
      orderBook: {
        id: 0,
        clientId: 0,
        clientName: '',
        partnerId: 0,
        partnerName: '',
        datePlaced: null,
        timePlaced: '',
        expiryDate: null,
        expiryTime: '',
        editTime: '',
        currencyType: '',
        tradeLevel: 0,
        clientLevel: 0,
        currencyAmount: 0,
        zarNominal: 0,
        traderName: '',
        orderComments: '',
        orderType: '',
        orderStatus: 'gtc',
        hasExpiry: false,
        isCorporateOrder: false
      },
      orderTypes: [
        { value: 'Import', text: 'Import' },
        { value: 'Export', text: 'Export' }
      ],
      currencyTypes: [
        { value: 'USD', text: 'USD' },
        { value: 'EUR', text: 'EUR' },
        { value: 'GBP', text: 'GBP' },
        { value: 'JPY', text: 'JPY' },
        { value: 'CNY', text: 'CNY' },
        { value: 'AUD', text: 'AUD' },
        { value: 'CAD', text: 'CAD' },
        { value: 'CHF', text: 'CHF' },
        { value: 'NZD', text: 'NZD' },
        { value: 'HKD', text: 'HKD' },
        { value: 'MUR', text: 'MUR' },
        { value: 'DKK', text: 'DKK' },
        { value: 'NOK', text: 'NOK' },
        { value: 'SGD', text: 'SGD' },
        { value: 'SEK', text: 'SEK' },
        { value: 'BWP', text: 'BWP' },
        { value: 'LSL', text: 'LSL' },
        { value: 'SZL', text: 'SZL' }
      ],
      orderStatuses: [
        { value: 'gtc', text: 'GTC' },
        { value: 'filled', text: 'Filled' },
        { value: 'cancelled', text: 'Cancelled' },
        { value: 'expired', text: 'Expired' },
        { value: 'amended', text: 'Amended' }
      ],
      traderNames: [
        { value: 'Enza', text: 'Enza' },
        { value: 'Marius', text: 'Marius' },
        { value: 'Muzi', text: 'Muzi' },
        { value: 'Pitsi', text: 'Pitsi' },
        { value: 'Shongi', text: 'Shongi' },
        { value: 'Wouter', text: 'Wouter' },
        { value: 'Watch Order', text: 'Watch Order' }
      ],
      retailClient: []
    }
  },
  methods: {
    async init () {
      const cr = await getAllRetailClients()
      this.retailClient = []
      if (cr) {
        cr.result.forEach(item => {
          this.retailClient.push({
            key: item.id,
            text: item.fullNames,
            value: item.id
          })
        })
      }
    },
    async saveOrderBook () {
      const response = await createOrderBook({ orderBook: this.orderBook, retailClient: this.retailClient })
      if (response) {
        this.orderSuccess = true
      }
    }
  },
  async mounted () {
    this.init()
  }
}
</script>

<style lang="scss" scoped>

</style>
