<template>
<br />
    <h2 class="text-center my-2">PARTNERS</h2>
<br />
  <div>
    <Button icon="pi pi-info" class="p-button-rounded p-button-lg mx-4 shadow-5" @click="toggle" />
    <OverlayPanel ref="op" appendTo="body" :showCloseIcon="true" id="overlay_panel" style="width: 20rem" :breakpoints="{'960px': '75vw'}">
      <p>Use the <i class="pi pi-sort-alt"></i> to filter results.</p>
      <p>Use <i class="pi pi-sort-amount-up-alt"></i> or <i class="pi pi-sort-amount-down-alt"></i> to change the sort order.</p>
      <p>To filter by multiple columns use Ctrl + Click on the second filter column.</p>
      <p>Use the <i class="pi pi-filter"></i> to search for a specific client. You can search on multiple columns.</p>
      </OverlayPanel>
  </div>
  <br />
  <div class="fluid mx-3 shadow-2">
    <DataTable :value="partners"
                dataKey="id" :rowHover="true"
                stripedRows responsiveLayout="stack"
                showGridlines
                breakpoint="1024px"
                :paginator="true"
                :rows="8"
                v-model:filters="filters1"
                filterDisplay="menu"
                class="datatable-retailClient">
        <Column field="id" header="ID" headerStyle="width: 1vw"></Column>
        <Column field="shortName" header="Partner Name" :sortable="true" headerStyle="width: 4vw">
          <template #filter="{filterModel}">
              <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by Name"/>
          </template>
        </Column>
        <Column field="regNo" header="Registration No" headerStyle="width: 4vw">
          <template #filter="{filterModel}">
              <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by Reg No"/>
          </template>
        </Column>
        <Column field="fspNo" header="FSP No" headerStyle="width: 3vw"></Column>
        <Column field="partnerContactNumber" header="Office No" headerStyle="width: 3vw"></Column>
        <Column field="contactEmail" header="Main Contact" headerStyle="width: 6vw"></Column>
        <Column field="isSubAdvisor" header="Is Advisor" headerStyle="width: 3vw">
          <template #body="{data}">
              <span :class="'retailClient-badge status-' + data.isSubAdvisor">{{data.isSubAdvisor}}</span>
          </template>
        </Column>
        <Column header="Edit" headerStyle="width: 3vw;">
            <template #body="{data}">
               <router-link class="p-button d-block mx-1 px-1 shadow-1" :to="'/partnerEdit/' + data.id"
                            style="color: #E6E6F0; background-color: #063a6a!important; text-decoration: none;
                                   display: flex; justify-content: center;">
                 <i class="pi pi-user-edit" style="fontSize: 1rem"></i>&nbsp;&nbsp;EDIT
              </router-link>
            </template>
        </Column>
         <Column header="View" headerStyle="width: 3vw;">
            <template #body="{data}">
               <router-link class="p-button d-block mx-1 px-1 shadow-1" :to="'/partnerMainView/' + data.id"
                            style="color: #E6E6F0; background-color: #036495!important; text-decoration: none;
                                   display: flex; justify-content: center;">
                 <i class="pi pi-list" style="fontSize: 1rem"></i>&nbsp;&nbsp;VIEW
              </router-link>
            </template>
        </Column>
    </DataTable>
  </div>
    <Dialog class="deleteModal" header="UNAUTHORISED ACCESS" v-model:visible="openErrorModal" :modal="true">
        <div class="confirmation-content">
            <span>User not authorised to view this information.</span>
            <br />
                  Please contact the system administrator.
        </div>
        <template #footer>
            <a class="p-button d-block mr-2 px-auto" @click.prevent="$router.push( {name: 'PartnerHome' })"
              style="float: left; background-color: #014f7e!important;
                     text-decoration: none; justify-content: center;">
              <i class="pi pi-step-backward" style="fontSize: 1rem"></i> &nbsp;&nbsp;Back to Partner Home
            </a>
        </template>
    </Dialog>
  <div>
    <Button class="l-2 m-4 mx-4 shadow-3 p-button-lg"
    icon="pi pi-step-backward"
    label="BACK&nbsp;"
    id="submitButton"
    @click.prevent="$router.push( {name: 'PartnerHome' })" />
 </div>
</template>

<script>
import { getPartners } from '@/gateway'
import { FilterMatchMode, FilterOperator } from 'primevue/api'

export default {
  name: 'PartnerListView',
  inheritAttrs: false,
  data () {
    return {
      partners: [],
      filters1: null,
      openErrorModal: false
    }
  },
  methods: {
    async init () {
      const response = await getPartners()
      if (response.message === 'Unauthorised User') {
        this.openErrorModal = true
      } else {
        this.partners = response.result
      }
    },
    initFilters1 () {
      this.filters1 = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        shortName: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
        regNo: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] }
      }
    },
    toggle (event) {
      this.$refs.op.toggle(event)
    }
  },
  created () {
    this.initFilters1()
  },
  mounted () {
    this.init()
  }
}
</script>

<style lang="scss" scoped>
::v-deep() {
  .p-datatable .p-datatable-thead > tr > th {
    background-color: $middleBlue!important;
    color: $textColor;
    border: 1px solid $borderColour!important;
    font-size: 18px;
    font-weight: 600;
  }
  .p-datatable .p-datatable-tbody > tr > td {
    padding: .4rem .4rem;
    color: $darkTextColour;
    font-family:'Poppins', sans-serif;
    font-weight: 400;
  }
  .p-paginator-current {
        margin-right: auto;
    }
  .retailClient-badge {
      @include statusBadge
  };
  #disabled {
    background: $textColor!important;
    color: $middleBlue!important;
  };
  #searchtext {
    min-width: 40vw;
  };
};
</style>
