<template>
  <br />
  <br />
  <h2 class="text-center my-2">TCC DOCUMENTS FOR: {{ clientTcc.firstLastName }} ~ FOR TCC ID : {{ clientTcc.id }}</h2>
  <br />
  <br />
  <div class="fluid grid">
    <div class="col-12 md:col-6 lg:col-6">
        <div v-if="clientTcc.hasTccDocs == true">
            <Card class="shadow-3 text-center d-block mx-auto" id="newClient">
                <template class="text-center" #title>
                <i class="pi pi-upload" id="cardLogo"></i>
                </template>
                <template  #content>
                <router-link :to="'/tccDocsUploads/' + clientTcc.id" style="color: #e2e7e5; text-decoration: none">
                UPLOADS
                </router-link>
                </template>
            </Card>
        </div>
        <div v-else>
            <Card class="shadow-3 text-center d-block mx-auto" id="newClient">
                <template class="text-center" #title>
                <i class="pi pi-upload" id="cardLogo"></i>
                </template>
                <template  #content>
                <a @click="saveClientTccDocs" style="color: #e2e7e5; text-decoration: none; cursor: pointer;">
                UPLOADS
                </a>
                </template>
            </Card>
        </div>
    </div>
     <div class="col-12 md:col-6 lg:col-6">
        <Card class="shadow-3 text-center d-block mx-auto" id="fromPartner">
            <template class="text-center" #title>
              <i class="pi pi-download" id="cardLogo"></i>
            </template>
            <template  #content>
              <router-link :to="'/tccDocsDownloads/' + clientTcc.id" style="color: #e2e7e5; text-decoration: none">
              DOWNLOADS
              </router-link>
            </template>
        </Card>
    </div>
  </div>
  <hr class="mx-4 shadow-2"/>
    <div>
    <Button class="l-2 m-4 mx-4 shadow-3 p-button-lg"
    icon="pi pi-step-backward"
    label="BACK&nbsp;"
    id="submitButton"
    @click.prevent="$router.push( {name: 'TccView', params: { id: clientTcc.clientId }})"  />
  </div>
</template>

<script>
import { getClientTcc, createClientTccDocs } from '@/gateway'

export default {
  name: 'TccDocsHome',
  inheritAttrs: false,
  data () {
    return {
      clientTcc: {},
      tccDocs: {
        id: 0,
        tccId: 0,
        clientId: 0,
        clientName: '',
        powerOfAttorneyFileName: '',
        powerOfAttorneyFileDisplayName: '',
        powerOfAttorneyFileUploadedDate: null,
        hasPowerOfAttorneyFile: false,
        fia001FileName: '',
        fia001FileDisplayName: '',
        fia001FileUploadedDate: null,
        hasFia001File: false,
        assetsAndLiabilitiesFileName: '',
        assetsAndLiabilitiesFileDisplayName: '',
        assetsAndLiabilitiesFileUploadedDate: null,
        hasAssetsAndLiabilitiesFile: false,
        bankStatementsFileName: '',
        bankStatementsFileDisplayName: '',
        bankStatementsFileUploadedDate: null,
        hasBankStatementsFile: false,
        inheritanceFileName: '',
        inheritanceFileDisplayName: '',
        inheritanceFileUploadedDate: null,
        hasInheritanceFile: false,
        loansFileName: '',
        loansFileDisplayName: '',
        loansFileUploadedDate: null,
        hasLoansFile: false,
        investmentsFileName: '',
        investmentsFileDisplayName: '',
        investmentsFileUploadedDate: null,
        hasInvestmentsFile: false,
        propertyFileName: '',
        propertyFileDisplayName: '',
        propertyFileUploadedDate: null,
        hasPropertyFile: false,
        distributionFileName: '',
        distributionFileDisplayName: '',
        distributionFileUploadedDate: null,
        hasDistributionFile: false,
        donationFileName: '',
        donationFileDisplayName: '',
        donationFileUploadedDate: null,
        hasDonationFile: false,
        miscellaneousFileName: '',
        miscellaneousFileDisplayName: '',
        miscellaneousFileUploadedDate: null,
        hasMiscellaneousFile: false
      }
    }
  },
  methods: {
    async init () {
      const response = await getClientTcc({ id: this.id })
      if (response) {
        this.clientTcc = response.result
      }
    },
    async saveClientTccDocs () {
      const response = await createClientTccDocs({ clientTcc: this.clientTcc })
      if (response) {
        this.$router.push('/tccDocsUploads/' + this.id)
      }
    }
  },
  mounted () {
    this.id = this.$route.params.id
    this.init()
  }
}
</script>

<style lang="scss" scoped>
#newClient {
 @include cardComponent($midDarkBlue)
};
#fromPartner {
 @include cardComponent($lightMidBlue)
};
.p-card.p-component.p-card-content {
 font-size: 24px;
};
.p-grid {
  margin: 1rem;
  padding: 1rem;
};
</style>
