<template>
<div>
    <ProgressSpinner id="spinner"/>
</div>

</template>

<script>
// @ is an alias to /src

export default {
  name: 'ReloadSpinner',
  inheritAttrs: false,
  data () {
    return {
      userId: this.$store.state.user.userId
    }
  },
  methods: {
    async init () {
      this.redirect()
    },
    redirect: function () {
      var v = this
      setTimeout(function () {
        v.$router.push({ name: 'FicaUploadsUpdate', params: { id: v.$route.params.id } })
      }, 1000)
    }
  },
  mounted () {
    this.id = this.$route.params.id
    this.init()
  }
}
</script>

<style lang="scss" scoped>
 #spinner {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
</style>
