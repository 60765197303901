<template>
  <br />
  <h2 class="text-center my-2">ALL CLIENT RISK RATING</h2>
  <br />
  <div>
    <Button icon="pi pi-info" class="p-button-rounded p-button-lg mx-4 shadow-5" @click="toggle" />
    <OverlayPanel ref="op" appendTo="body" :showCloseIcon="true" id="overlay_panel" style="width: 20rem" :breakpoints="{'960px': '75vw'}">
      <p>Use the <i class="pi pi-sort-alt"></i> to filter results.</p>
      <p>Use <i class="pi pi-sort-amount-up-alt"></i> or <i class="pi pi-sort-amount-down-alt"></i> to change the sort order.</p>
      <p>To filter by multiple columns use Ctrl + Click on the second filter column.</p>
      <p>Use the <i class="pi pi-filter"></i> to search for a specific client. You can search on multiple columns.</p>
      </OverlayPanel>
  </div>
  <br />
    <div class="fluid mx-3 shadow-2">
        <DataTable :value="riskRating"
                    dataKey="id" :rowHover="true"
                    stripedRows responsiveLayout="stack"
                    sortMode="multiple"
                    showGridlines
                    breakpoint="1024px"
                    :paginator="true"
                    :rows="8"
                    v-model:filters="filters1"
                    filterDisplay="menu"
                    class="datatable-retailClient">
            <Column field="partnerName" header="Partner" :sortable="true" headerStyle="width: 3vw">
              <template #filter="{filterModel}">
                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by Partner"/>
              </template>
            </Column>
            <Column field="firstLastName" header="Client" :sortable="true" headerStyle="width: 5vw">
              <template #filter="{filterModel}">
                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by Name"/>
              </template>
            </Column>
            <Column field="idNumber" header="ID Number" headerStyle="width: 3vw">
              <template #filter="{filterModel}">
                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by Id Number"/>
              </template>
              </Column>
            <Column field="updatedAt" header="Updated Date" :sortable="true" headerStyle="width: 4vw">
              <template #filter="{filterModel}">
                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by Date"/>
              </template>
            </Column>
            <Column field="sanctionsScreen" header="Screen Result" :sortable="true" headerStyle="width: 4vw">
              <template #filter="{filterModel}">
                <Dropdown type="text" v-model="filterModel.value" :options="sanctionsStatuses"  class="p-column-filter" placeholder="Select Screen Status" :showClear="true">
              </Dropdown>
              </template>
            </Column>
            <Column field="sumScoreDescription" header="Risk Status" :sortable="true" headerStyle="width: 3vw" :filterMenuStyle="{'width':'14rem'}" style="min-width:4vw">
                <template #body="{data}">
                    <span :class="'retailClient-badge status-' + data.sumScoreDescription">{{data.sumScoreDescription}}</span>
                </template>
                <template #filter="{filterModel}">
                    <Dropdown v-model="filterModel.value" :options="descriptionStatuses" placeholder="Select Risk Status" class="p-column-filter" :showClear="true">
                    </Dropdown>
                </template>
            </Column>
            <Column field="sumScore" header="Risk Score" :sortable="true"  headerStyle="width: 3vw">
                <template #body="{data}">
                    <div v-if="data.sumScore < 26">
                        <span style="color: #107E01; font-size: 1.2rem"><b>{{data.sumScore}}</b></span>
                    </div>
                    <div v-else-if="data.sumScore >= 26 && data.sumScore < 51">
                        <span style="color: #FFD600; font-size: 1.2rem"><b>{{data.sumScore}}</b></span>
                    </div>
                    <div v-else-if="data.sumScore > 51">
                        <span style="color: #d2161e; font-size: 1.2rem"><b>{{data.sumScore}}</b></span>
                    </div>
                </template>
            </Column>
            <Column header="View" headerStyle="width: 3vw;">
                <template #body="{data}">
                <router-link class="p-button d-block mx-1 px-1 shadow-1" :to="'/clientRiskMatrix/' + data.clientId"
                                style="color: #E6E6F0; background-color: #036495!important; text-decoration: none;
                                    display: flex; justify-content: center;">
                    <i class="pi pi-exclamation-triangle" style="fontSize: 1rem"></i>&nbsp;&nbsp;VIEW
                </router-link>
                </template>
            </Column>
        </DataTable>
     </div>
   <br />
  <div class="flex justify-content-between ">
        <div>
          <Button class="l-2 m-3 mx-3 shadow-3 p-button-lg"
          icon="pi pi-step-backward"
          label="BACK&nbsp;"
          id="submitButton"
          @click.prevent="$router.back()" />
      </div>
      <div>
          <Button class="l-2 m-3 mx-3 shadow-3 p-button-lg "
          icon="pi pi-file-excel"
          label="DOWNLOAD&nbsp;"
          id="excelButton"
          :loading="loadingExcel"
          @click.prevent="downloadRiskExcel" />
      </div>
  </div>
</template>

<script>
import { getAllRiskMatrix, downloadRiskRatingToExcel } from '@/gateway'
import { FilterMatchMode, FilterOperator } from 'primevue/api'

export default {
  name: 'AllRiskView',
  inheritAttrs: false,
  data () {
    return {
      riskRating: [],
      filters1: null,
      descriptionStatuses: [
        'Low',
        'Medium',
        'High'
      ],
      sanctionsStatuses: [
        'None',
        'Minor',
        'PEP',
        'Sanctions'
      ],
      loadingExcel: false
    }
  },
  methods: {
    async init () {
      const response = await getAllRiskMatrix()
      if (response) {
        this.riskRating = response.result
      }
    },
    initFilters1 () {
      this.filters1 = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        partnerName: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
        firstLastName: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
        idNumber: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
        updatedAt: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
        sanctionsScreen: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
        sumScoreDescription: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] }
      }
    },
    toggle (event) {
      this.$refs.op.toggle(event)
    },
    async downloadRiskExcel () {
      this.loadingExcel = true
      const response = await downloadRiskRatingToExcel()
      if (response) {
        var byteArray = this.base64ToArrayBuffer(response.data)
        const url = window.URL.createObjectURL(
          new Blob([byteArray], { type: 'application/octet-stream' })
        )
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', response.fileName)
        document.body.appendChild(link)
        this.loadingExcel = false
        link.click()
      }
    },
    base64ToArrayBuffer (base64) {
      var binaryString = window.atob(base64)
      var binaryLen = binaryString.length
      var bytes = new Uint8Array(binaryLen)
      for (var i = 0; i < binaryLen; i++) {
        var ascii = binaryString.charCodeAt(i)
        bytes[i] = ascii
      }
      return bytes
    }
  },
  created () {
    this.initFilters1()
  },
  mounted () {
    this.init()
  }
}
</script>

<style lang="scss" scoped>
::v-deep() {
  .p-datatable .p-datatable-thead > tr > th {
    background-color: $middleBlue!important;
    color: $textColor;
    border: 1px solid $borderColour!important;
    font-size: 18px;
    font-weight: 600;
  }
  .p-datatable .p-datatable-tbody > tr > td {
    padding: .4rem .4rem;
    color: $darkTextColour;
    font-family:'Poppins', sans-serif;
    font-weight: 400;
  }
  .p-paginator-current {
        margin-right: auto;
    }
  .retailClient-badge {
      @include statusBadge
  };
  #disabled {
    background: $textColor!important;
    color: $middleBlue!important;
  };
};
</style>
