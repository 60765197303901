<template>
  <br />
  <h2 class="text-center my-2">CLIENT TRADES</h2>
  <br />
  <div v-if="displayEmptyTrades == false">
    <div class="fluid mx-3 shadow-2">
        <DataTable :value="trades"
                    dataKey="id" :rowHover="true"
                    stripedRows responsiveLayout="stack"
                    showGridlines
                    breakpoint="1024px"
                    :paginator="true"
                    :rows="8"
                    class="datatable-retailClient">
            <Column field="firstLastName" header="Client" headerStyle="width: 5vw"></Column>
            <Column field="valueDate" header="Value Date" headerStyle="width: 3vw"></Column>
            <Column field="dealNumber" header="Deal No" headerStyle="width: 3vw"></Column>
            <Column field="buySell" header="Buy/Sell" headerStyle="width: 3vw"></Column>
            <Column field="dealRate" header="Rate" headerStyle="width: 3vw">
                <template #body="{data}">
                    {{formatRate(data.dealRate)}}
                </template>
            </Column>
            <Column field="currency1" header="Currency 1" headerStyle="width: 3vw"></Column>
            <Column field="currency1Amount" header="Amount" dataType="numeric" headerStyle="width: 3vw">
                <template #body="{data}">
                    {{formatCurrency(data.currency1Amount)}}
                </template>
            </Column>
            <Column field="currency2" header="Currency 2" headerStyle="width: 3vw"></Column>
            <Column field="currency2Amount" header="Amount" dataType="numeric" headerStyle="width: 3vw">
                <template #body="{data}">
                    {{formatCurrency(data.currency2Amount)}}
                </template>
            </Column>
        </DataTable>
     </div>
  </div>
   <div v-else>
    <h3 class="text-center my-2">NO TRADES FOUND FOR THIS CLIENT</h3>
    <br />
    <hr class="mx-4 shadow-2"/>
   </div>
   <br />
  <div>
    <Button class="l-2 m-2 mx-3 shadow-3 p-button-lg"
    icon="pi pi-step-backward"
    label="BACK&nbsp;"
    id="submitButton"
    @click.prevent="$router.back()" />
  </div>
</template>

<script>
import { getClientTrades } from '@/gateway'

export default {
  name: 'ClientTradesView',
  inheritAttrs: false,
  data () {
    return {
      trades: [],
      displayEmptyTrades: false
    }
  },
  methods: {
    async init () {
      const response = await getClientTrades({ id: this.id })
      if (response.result.length !== 0) {
        this.trades = response.result
      } else {
        this.displayEmptyTrades = true
      }
    },
    formatCurrency (value) {
      return value.toLocaleString('en-US', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 })
    },
    formatRate (value) {
      return value.toLocaleString('en-US', { style: 'decimal', maximumFractionDigits: 4, minimumFractionDigits: 4 })
    }
  },
  mounted () {
    this.id = this.$route.params.id
    this.init()
  }
}
</script>

<style lang="scss" scoped>
::v-deep() {
  .p-datatable .p-datatable-thead > tr > th {
    background-color: $middleBlue!important;
    color: $textColor;
    border: 1px solid $borderColour!important;
    font-size: 18px;
    font-weight: 600;
  }
  .p-datatable .p-datatable-tbody > tr > td {
    padding: .4rem .4rem;
    color: $darkTextColour;
    font-family:'Poppins', sans-serif;
    font-weight: 400;
  }
  .p-paginator-current {
        margin-right: auto;
    }
  .retailClient-badge {
      @include statusBadge
  };
  #disabled {
    background: $textColor!important;
    color: $middleBlue!important;
  };
  #searchtext {
    margin-left: 1rem!important;
    min-width: 40vw;
  };
};
</style>
