/** @format */

import { JsonServiceClient } from '@servicestack/client'
import { SessionInfo, Authenticate } from '@/authdtos'
import { UploadResearchFile, UploadFicaFile, UploadTccDocs } from '@/dtos'

export const BaseUrl = 'https://fxapiuat.azurewebsites.net' // 'https://api.fxhub.co.za' 'http://localhost:56212/'

// export const BaseUrl = 'http://localhost:56212/'
export const client = new JsonServiceClient(BaseUrl)

export const monthlyReport = async (params) => {
  const response = await client.get('/monthly-report', {
    startDate: params.startDate,
    endDate: params.endDate
  })
  return response
}

export const createRetailClient = async params => {
  const response = await client.postToUrl('/retail_client', {
    retailClient: params.retailClient
  })
  return response
}

export const createPartnerRetailClient = async params => {
  const response = await client.postToUrl('/retail_client/create_partner', {
    retailClient: params.retailClient
  })
  return response
}

export const GetNonPartnerRetailClients = async params => {
  const response = await client.get('/retail_client/non_partner', params)
  return response
}

export const getAllRetailClients = async params => {
  const response = await client.get('/retail_client/all', params)
  return response
}

export const getPartnerRetailClients = async params => {
  const response = await client.get('/retail_client/partners_list/' + params.id)
  return response
}

export const getRetailClient = async params => {
  const response = await client.get('/retail_client/' + params.id)
  return response
}

export const getPartnerClients = async params => {
  const response = await client.get('/retail_client/partner', params)
  return response
}

export const completePartnerClient = async params => {
  const response = await client.get('/retail_client/complete/' + params.id)
  return response
}

export const idNumberCheck = async params => {
  const response = await client.postToUrl('/retail_client/id_check', {
    retailClient: params.retailClient
  })
  return response
}

export const passportNumberCheck = async params => {
  const response = await client.postToUrl('/retail_client/passport_check', {
    retailClient: params.retailClient
  })
  return response
}

export const updatePartnerClient = async params => {
  const response = await client.putToUrl('/retail_client/partner', {
    retailClient: params.retailClient
  })
  return response
}

export const editClient = async params => {
  const response = await client.get('/retail_client/edit/' + params.id)
  return response
}

export const editUpdateClient = async params => {
  const response = await client.putToUrl('/retail_client/edit', {
    retailClient: params.retailClient
  })
  return response
}

export const deactivateClient = async params => {
  const response = await client.putToUrl('/retail_client/deactivate', {
    retailClient: params.retailClient
  })
  return response
}

export const deleteClient = async params => {
  const response = await client.delete('/retail_client/' + params.id)
  return response
}

export const getRiskMatrix = async params => {
  const response = await client.get('/risk_rating/' + params.id)
  return response
}

export const createRiskMatrix = async params => {
  const response = await client.postToUrl('/risk_rating', {
    retailClient: params.retailClient
  })
  return response
}

export const getAllRiskMatrix = async params => {
  const response = await client.get('/risk_rating/all', params)
  return response
}

export const sanctionsCheckResult = async params => {
  const response = await client.postToUrl('/retail_client/sanctions', {
    retailClient: params.retailClient
  })
  return response
}

export const redoRiskMatrix = async params => {
  const response = await client.putToUrl('/risk_rating/redo', {
    riskRating: params.riskRating
  })
  return response
}

export const completeRiskMatrix = async params => {
  const response = await client.putToUrl('/risk_rating/complete', {
    riskRating: params.riskRating
  })
  return response
}

export const redoSanctionsCheckResult = async params => {
  const response = await client.putToUrl('/risk_rating/sanctions', {
    riskRating: params.riskRating
  })
  return response
}

export const formsDownload = async params => {
  const response = await client.get('/forms_download', params)
  return response
}

export const saveForm25 = async params => {
  const response = await client.postToUrl('/form25/save', {
    form25: params.form25,
    retailClient: params.retailClient
  })
  return response
}

export const getForm25 = async params => {
  const response = await client.get('/form25/' + params.id)
  return response
}

export const form25Download = async params => {
  const response = await client.get('/form25', params)
  return response
}

export const getForm25Edit = async params => {
  const response = await client.get('/form25/edit/' + params.id)
  return response
}

export const editForm25 = async params => {
  const response = await client.putToUrl('/form25/edit', {
    form25: params.form25
  })
  return response
}

export const getClientTcc = async params => {
  const response = await client.get('/client_tcc/edit/' + params.id)
  return response
}

export const getClientTccs = async params => {
  const response = await client.get('/client_tcc/list/' + params.id)
  return response
}

export const getPartnerTccs = async params => {
  const response = await client.get('/client_tcc/partner_list/' + params.id)
  return response
}

export const getAllTccs = async params => {
  const response = await client.get('/client_tcc/all_list', params)
  return response
}

export const createClientTcc = async params => {
  const response = await client.postToUrl('/client_tcc/save', {
    clientTcc: params.clientTcc,
    retailClient: params.retailClient
  })
  return response
}

export const updateClientTcc = async params => {
  const response = await client.putToUrl('/client_tcc/edit', {
    clientTcc: params.clientTcc
  })
  return response
}

export const getPartners = async params => {
  const response = await client.get('/partners', params)
  return response
}

export const getPartner = async params => {
  const response = await client.get('/partners/' + params.id)
  return response
}

export const createPartner = async params => {
  const response = await client.postToUrl('/partners', {
    partners: params.partners
  })
  return response
}

export const editPartner = async params => {
  const response = await client.putToUrl('/partners/edit', {
    partners: params.partners
  })
  return response
}

export const getClientTrades = async params => {
  const response = await client.get('/trades/' + params.id)
  return response
}

export const getPartnerTrades = async params => {
  const response = await client.get('/trades/partners_list/' + params.id)
  return response
}

export const getAllTrades = async params => {
  const response = await client.get('/trades/all', params)
  return response
}

export const uploadResearchFile = async params => {
  const formData = new FormData()
  formData.append('description', params.selectedFile.name)
  formData.append('type', 'file')
  formData.append('file', params.selectedFile)

  const response = await client.postBody(
    new UploadResearchFile({
      fileName: params.fileName,
      fileCategory: params.fileCategory,
      fileDisplayName: params.fileDisplayName,
      fileDate: params.fileDate,
      research: params.research
    }),
    formData
  )
  if (response) {
    return response
  }
}

export const uploadFicaFile = async params => {
  const formData = new FormData()
  formData.append('description', params.selectedFile.name)
  formData.append('type', 'file')
  formData.append('file', params.selectedFile)

  const response = await client.putBody(
    new UploadFicaFile({
      fileType: params.fileType,
      fileName: params.fileDisplayName,
      id: params.id,
      fica: params.fica
    }),
    formData
  )
  if (response) {
    return response
  }
}

export const getResearch = async params => {
  const response = await client.get('/research/list', params)
  return response
}

export const downloadResearch = async params => {
  const response = await client.get('/research/download', params)
  return response
}

export const deleteResearch = async params => {
  const response = await client.delete('/research/delete', params)
  return response
}

export const getClientCashBalance = async params => {
  const response = await client.get('/cash_balance/' + params.id)
  return response
}

export const getClientSdaRemainder = async params => {
  const response = await client.get('/sda_remainder/' + params.id)
  return response
}

export const getClientTccRemainder = async params => {
  const response = await client.get('/tcc_remainder/' + params.id)
  return response
}

export const getPartnerCashBalance = async params => {
  const response = await client.get('/cash_balance/partners/' + params.id)
  return response
}

export const getAllCashBalance = async params => {
  const response = await client.get('/cash_balance/all', params)
  return response
}

export const getPartnerSdaRemainder = async params => {
  const response = await client.get('/sda_remainder/partners/' + params.id)
  return response
}

export const getAllSdaRemainder = async params => {
  const response = await client.get('/sda_remainder/all', params)
  return response
}

export const getPartnerTccRemainder = async params => {
  const response = await client.get('/tcc_remainder/partners/' + params.id)
  return response
}

export const getAllTccRemainder = async params => {
  const response = await client.get('/tcc_remainder/all', params)
  return response
}

export const getOrderBookList = async params => {
  const response = await client.get('/order_book', params)
  return response
}

export const getOrderBook = async params => {
  const response = await client.get('/order_book/' + params.id)
  return response
}

export const createOrderBook = async params => {
  const response = await client.postToUrl('/order_book/create', {
    orderBook: params.orderBook,
    retailClient: params.retailClient
  })
  return response
}

export const editOrderBook = async params => {
  const response = await client.putToUrl('/order_book/edit', {
    orderBook: params.orderBook
  })
  return response
}

export const getClientFica = async params => {
  const response = await client.get('/fica/' + params.id)
  return response
}

export const createClientFica = async params => {
  const response = await client.postToUrl('/fica/create', {
    fica: params.fica,
    retailClient: params.retailClient
  })
  return response
}

export const ficaDownload = async params => {
  const response = await client.get('/fica_download', params)
  return response
}

export const downloadClientTccToExcel = async params => {
  const response = await client.get('/client_tcc/download/excel')
  return response
}

export const downloadTradesToExcel = async params => {
  const response = await client.get('/trades/download/excel', params)
  return response
}

export const downloadCashBalancesToExcel = async params => {
  const response = await client.get('/cash_balances/download/excel', params)
  return response
}

export const downloadSdaRemainderToExcel = async params => {
  const response = await client.get('/sda_remainder/download/excel', params)
  return response
}

export const downloadTccRemainderToExcel = async params => {
  const response = await client.get('/tcc_remainder/download/excel', params)
  return response
}

export const downloadRiskRatingToExcel = async params => {
  const response = await client.get('/risk_rating/download/excel', params)
  return response
}

export const createUser = async params => {
  const response = await client.postToUrl('/users/create', {
    user: params.user
  })
  return response
}

export const getPartnerUser = async params => {
  const response = await client.get('/users/' + params.id)
  return response
}

export const getUsers = async params => {
  const response = await client.get('/users', params)
  return response
}

export const updateUser = async params => {
  const response = await client.putToUrl('/users/update', {
    user: params.user
  })
  return response
}

export const deletePartnerUser = async params => {
  const response = await client.postToUrl('/users/delete', {
    email: params.email
  })
  return response
}

export const getClientTradeQuote = async params => {
  const response = await client.get('/trade_quotes/client/' + params.id)
  return response
}

export const getAllTradeQuotes = async params => {
  const response = await client.get('/trade_quotes/all', params)
  return response
}

export const getAllQueries = async params => {
  const response = await client.get('/query/all', params)
  return response
}

export const getUserQuery = async params => {
  const response = await client.get('/query/user/' + params.id)
  return response
}

export const adminReplyToQuery = async params => {
  const response = await client.putToUrl('//query/reply_admin', {
    query: params.query
  })
  return response
}

export const adminQueryChat = async params => {
  const response = await client.putToUrl('/query/chat_admin', {
    query: params.query
  })
  return response
}

export const completeQueryChat = async params => {
  const response = await client.putToUrl('/query/chat_complete', {
    query: params.query
  })
  return response
}

export const acReplyToTradeQuote = async params => {
  const response = await client.putToUrl('/trade_quotes/ac_reply', {
    tradeQuotes: params.tradeQuotes,
    quotedBuyRate: params.quotedBuyRate,
    quotedSellRate: params.quotedSellRate,
    quotedCrossRate: params.quotedCrossRate,
    buyZarTradeZarAmount: params.buyZarTradeZarAmount,
    buyZarTradeCrossAmount: params.buyZarTradeCrossAmount,
    buyCrossTradeCurrencyAmount: params.buyCrossTradeCurrencyAmount,
    sellZarTradeZarAmount: params.sellZarTradeZarAmount,
    sellZarTradeCrossAmount: params.sellZarTradeCrossAmount,
    sellZarHasSufficientZarCashToTrade: params.sellZarHasSufficientZarCashToTrade,
    sellZarHasSufficientZarSdaToTrade: params.sellZarHasSufficientZarSdaToTrade,
    sellZarHasSufficientZarTccToTrade: params.sellZarHasSufficientZarTccToTrade,
    crossTradeCurrencyAmount: params.crossTradeCurrencyAmount,
    crossTradeCrossCurrencyAmount: params.crossTradeCrossCurrencyAmount
  })
  return response
}

export const acCompleteTradeQuote = async params => {
  const response = await client.putToUrl('/trade_quotes/complete', {
    tradeQuotes: params.tradeQuotes,
    mblRate: params.mblRate,
    buyZarTradeZarAmount: params.buyZarTradeZarAmount,
    buyZarTradeCrossAmount: params.buyZarTradeCrossAmount,
    buyCrossTradeCurrencyAmount: params.buyCrossTradeCurrencyAmount,
    sellZarTradeZarAmount: params.sellZarTradeZarAmount,
    sellZarTradeCrossAmount: params.sellZarTradeCrossAmount,
    sellZarHasSufficientZarCashToTrade: params.sellZarHasSufficientZarCashToTrade,
    sellZarHasSufficientZarSdaToTrade: params.sellZarHasSufficientZarSdaToTrade,
    sellZarHasSufficientZarTccToTrade: params.sellZarHasSufficientZarTccToTrade,
    crossTradeCurrencyAmount: params.crossTradeCurrencyAmount,
    crossTradeCrossCurrencyAmount: params.crossTradeCrossCurrencyAmount
  })
  return response
}

export const tradeQuoteMarkedAsTraded = async params => {
  const response = await client.putToUrl('/trade_quotes/has_traded', {
    tradeQuotes: params.tradeQuotes
  })
  return response
}

export const tradeQuoteMarkedAsExpired = async params => {
  const response = await client.putToUrl('/trade_quotes/has_expired', {
    tradeQuotes: params.tradeQuotes
  })
  return response
}

export const partnerDeclineTradeQuote = async params => {
  const response = await client.putToUrl('/trade_quotes/partner_decline', {
    tradeQuotes: params.tradeQuotes
  })
  return response
}

export const submitToLandobyte = async params => {
  const response = await client.postToUrl('/landobyte_submit', {
    retailClient: params.retailClient,
    landoByteResponse: params.landoByteResponse
  })
  return response
}

export const getSessionInfo = async () => {
  try {
    // Converts Session to JWT Token Cookie
    const authResponse = await client.get(new SessionInfo())
    return authResponse
  } catch (e) {
    return null
  }
}

export const logout = async () => {
  localStorage.removeItem('ss-tok')
  const request = new Authenticate()
  request.provider = 'logout'
  // await getSessionInfo();
  await client.post(request)
  return '/'
}

export const GetQueryNotifications = async params => {
  const response = await client.get('/notification/query_notification', params)
  return response
}

export const CompleteQueryNotification = async params => {
  const response = await client.putToUrl('/notification/query_notification_complete', {
    queryNotificationId: params.queryNotificationId
  })
  return response
}

export const AutoCompleteQueryNotification = async params => {
  const response = await client.putToUrl('/notification/auto_query_notification_complete', {
    completeQueryId: params.completeQueryId
  })
  return response
}

export const GetClientNotifications = async params => {
  const response = await client.get('/notification/client_notification', params)
  return response
}

export const CompleteClientNotification = async params => {
  const response = await client.putToUrl('/notification/client_notification_complete', {
    clientNotificationId: params.clientNotificationId
  })
  return response
}

export const GetTradeQuoteNotifications = async params => {
  const response = await client.get('/notification/tradequote_notification', params)
  return response
}

export const CompleteTradeQuoteNotification = async params => {
  const response = await client.putToUrl('/notification/tradequote_notification_complete', {
    tradeQuoteNotificationId: params.tradeQuoteNotificationId
  })
  return response
}

export const AutoCompleteClientNotification = async params => {
  const response = await client.putToUrl('/notification/auto_client_notification_complete', {
    completeClientId: params.completeClientId
  })
  return response
}

export const AutoCompleteTradeQuoteNotification = async params => {
  const response = await client.putToUrl('/notification/auto_tradequote_notification_complete', {
    completeTradeQuoteId: params.completeTradeQuoteId
  })
  return response
}

export const uploadTccDocs = async params => {
  const formData = new FormData()
  formData.append('description', params.selectedFile.name)
  formData.append('type', 'file')
  formData.append('file', params.selectedFile)

  const response = await client.putBody(
    new UploadTccDocs({
      fileType: params.fileType,
      fileName: params.fileDisplayName,
      id: params.id,
      tccDocs: params.tccDocs
    }),
    formData
  )
  if (response) {
    return response
  }
}

export const getClientTccDocs = async params => {
  const response = await client.get('/tccdocs/' + params.id)
  return response
}

export const createClientTccDocs = async params => {
  const response = await client.postToUrl('/tccdocs/create', {
    clientTcc: params.clientTcc
  })
  return response
}

export const tccDocsDownload = async params => {
  const response = await client.get('/tccdocs_download', params)
  return response
}
