<template>
  <div style="width: 100%; height: 100%; padding: 50px 0 0 0; overflow-y: scroll; display: flex; flex-direction: column; align-items: center; justify-items: center;">
    <div class="chart-container">
      <Chart
        type="bar"
        :title="`Profit per Partner - ZAR (${store.getters.reportDate})`"
        :show-legend="false"
        y-axis-title="Profit"
        :data="ppppm"
      />
    </div>
    <div class="chart-container">
      <Chart
        type="bar"
        title="Profit Breakdown Monthly"
        :show-legend="true"
        y-axis-title="Profit"
        :data="pbdpm"
      />
    </div>
    <div class="chart-container">
      <Chart
        type="bar"
        title="Profit Breakdown Yearly"
        :show-legend="true"
        y-axis-title="Profit"
        :data="pbdpy"
      />
    </div>
  </div>
</template>

<script setup>
import { store } from '@/store'
import { computed } from 'vue'
import Chart from '@/components/Chart.vue'

const colors = ['#8B0000', '#00008B', '#006400', '#FFDB58']

const ppppm = computed(() => {
  return {
    labels: store.getters.profitPerPartnerPerMonth.at(-1).map(x => x.text),
    datasets: [
      {
        label: 'Profit',
        backgroundColor: '#0185b7',
        data: store.getters.profitPerPartnerPerMonth.at(-1).map(x => x.valuePerPeriod.value)
      }
    ]
  }
})
const pbdpm = computed(() => {
  return {
    labels: store.getters.profitBreakDownPerMonth.map(x => new Date(x[0].valuePerPeriod.date).toLocaleDateString('en-GB', { month: 'short', year: 'numeric' })),
    datasets: store.getters.profitBreakDownPerMonth[0].map((x, i) => {
      return {
        label: x.text,
        backgroundColor: colors[i],
        data: store.getters.profitBreakDownPerMonth.map(y => y[i].valuePerPeriod.value)
      }
    })
  }
})
const pbdpy = computed(() => {
  return {
    labels: store.getters.profitBreakDownPerYear.map(x => new Date(x[0].valuePerPeriod.date).toLocaleDateString('en-GB', { month: 'short', year: 'numeric' })),
    datasets: store.getters.profitBreakDownPerYear[0].map((x, i) => {
      return {
        label: x.text,
        backgroundColor: colors[i],
        data: store.getters.profitBreakDownPerYear.map(y => y[i].valuePerPeriod.value)
      }
    })
  }
})
</script>

<script>
export default {
  name: 'Profit',
  inheritAttrs: false
}
</script>

<style scoped>
.chart-container {
  margin-bottom: 150px;
  width: 1220px;
  height: 560px;
}
</style>
