import { createRouter, createWebHistory } from 'vue-router'
import ClientHome from '../views/retailClients/ClientHome.vue'
import NewClient from '../views/retailClients/NewClient.vue'
import ClientView from '../views/retailClients/ClientView.vue'
import ClientListView from '../views/retailClients/ClientListView.vue'
import PartnerClientListView from '../views/retailClients/PartnerClientListView.vue'
import PartnerClientToAddListView from '../views/retailClients/PartnerClientToAddListView.vue'
import CompletePartnerClient from '../views/retailClients/CompletePartnerClient.vue'
import EditClient from '../views/retailClients/EditClient.vue'
import ClientRiskMatrix from '../views/retailClients/ClientRiskMatrix.vue'
import ClientFormsView from '../views/retailClients/ClientFormsView.vue'
import Form25View from '../views/form25/Form25View.vue'
import Form25Add from '../views/form25/Form25Add.vue'
import Form25Edit from '../views/form25/Form25Edit.vue'
import TccClientView from '../views/tcc/TccClientView.vue'
import Add from '../views/tcc/Add.vue'
import Manage from '../views/tcc/Manage.vue'
import TccView from '../views/tcc/TccView.vue'
import PartnerTccView from '../views/tcc/PartnerTccView.vue'
import PartnerHome from '../views/partners/PartnerHome.vue'
import PartnerListView from '../views/partners/PartnerListView.vue'
import PartnersClientView from '../views/partners/PartnersClientView.vue'
import PartnerMainView from '../views/partners/PartnerMainView.vue'
import NewPartner from '../views/partners/NewPartner.vue'
import PartnerEdit from '../views/partners/PartnerEdit.vue'
import ClientTradesView from '../views/trades/ClientTradesView.vue'
import PartnerTradesView from '../views/trades/PartnerTradesView.vue'
import ResearchHome from '../views/research/ResearchHome.vue'
import ResearchAdd from '../views/research/ResearchAdd.vue'
import ResearchView from '../views/research/ResearchView.vue'
import DailyCashBalance from '../views/balances/DailyCashBalance.vue'
import BalancesHomeView from '../views/balances/BalancesHomeView.vue'
import ClientSdaRemainder from '../views/balances/ClientSdaRemainder.vue'
import ClientTccRemainder from '../views/balances/ClientTccRemainder.vue'
import PartnerBalancesHomeView from '../views/balances/PartnerBalancesHomeView.vue'
import PartnerDailyCashBalance from '../views/balances/PartnerDailyCashBalance.vue'
import PartnerSdaRemainder from '../views/balances/PartnerSdaRemainder.vue'
import PartnerTccRemainder from '../views/balances/PartnerTccRemainder.vue'
import FAQ from '../views/Faq.vue'
import Corporate from '../views/CorporateHome.vue'
import QuotesHome from '../views/quotes/QuotesHome.vue'
import NewQuote from '../views/quotes/NewQuote.vue'
import QuotesView from '../views/quotes/QuotesView.vue'
import TradeQuoteReply from '../views/quotes/TradeQuoteReply.vue'
import TradeQuoteView from '../views/quotes/TradeQuoteView.vue'
import TradeQuoteEdit from '../views/quotes/TradeQuoteEdit.vue'
import TradeQuoteToTraded from '../views/quotes/TradeQuoteToTraded.vue'
import TradeQuoteHasTraded from '../views/quotes/TradeQuoteHasTraded.vue'
import TradeQuoteDeclinedView from '../views/quotes/TradeQuoteDeclinedView.vue'
import TradeQuoteHasCompleted from '../views/quotes/TradeQuoteHasCompleted.vue'
import TradeQuoteHasExpired from '../views/quotes/TradeQuoteHasExpired.vue'
import QueryView from '../views/queries/QueryView.vue'
import QueryReply from '../views/queries/QueryReply.vue'
import QueryChat from '../views/queries/QueryChat.vue'
import QueryCompleteView from '../views/queries/QueryCompleteView.vue'
import UsersHome from '../views/users/UsersHome.vue'
import UsersAdd from '../views/users/UsersAdd.vue'
import UsersView from '../views/users/UsersView.vue'
import UsersEdit from '../views/users/UsersEdit.vue'
import OrdersHome from '../views/orders/OrdersHome.vue'
import OrdersAdd from '../views/orders/OrdersAdd.vue'
import OrdersView from '../views/orders/OrdersView.vue'
import OrdersEdit from '../views/orders/OrdersEdit.vue'
import FicaHome from '../views/fica/FicaHome.vue'
import FicaUploadsUpdate from '../views/fica/FicaUploadsUpdate.vue'
import FicaDownloads from '../views/fica/FicaDownloads.vue'
import AllHome from '../views/all/AllHome.vue'
import AllTccView from '../views/all/AllTccView.vue'
import AllTradesView from '../views/all/AllTradesView.vue'
import AllBalancesHomeView from '../views/all/AllBalancesHomeView.vue'
import AllDailyCashBalance from '../views/all/AllDailyCashBalance.vue'
import AllSdaRemainder from '../views/all/AllSdaRemainder.vue'
import AllTccRemainder from '../views/all/AllTccRemainder.vue'
import AllRiskView from '../views/all/AllRiskView.vue'
import AllTradesFileDownload from '../views/all/AllTradesFileDownload.vue'
import ReloadSpinner from '../views/ReloadSpinner.vue'
import ReloadSpinnerTccDocs from '../views/ReloadSpinnerTccDocs.vue'
import TccDocsHome from '../views/tcc/TccDocsHome.vue'
import TccDocsUploads from '../views/tcc/TccDocsUploads.vue'
import TccDocsDownloads from '../views/tcc/TccDocsDownloads.vue'
import Report from '../views/Report.vue'
import Clients from '../views/report/Clients.vue'
import Trades from '../views/report/Trades.vue'
import Profit from '../views/report/Profit.vue'
import Tcc from '../views/report/Tcc.vue'
import ZarFlows from '../views/report/ZarFlows.vue'

const routes = [
  {
    path: '/',
    name: 'App',
    component: ClientHome
  },
  {
    path: '/clientHome',
    name: 'ClientHome',
    component: ClientHome
  },
  {
    path: '/newClient',
    name: 'NewClient',
    component: NewClient
  },
  {
    path: '/clientView/:id',
    name: 'ClientView',
    component: ClientView
  },
  {
    path: '/clientListView',
    name: 'ClientListView',
    component: ClientListView
  },
  {
    path: '/partnerClientListView/:id',
    name: 'PartnerClientListView',
    component: PartnerClientListView
  },
  {
    path: '/partnerClientToAddListView',
    name: 'PartnerClientToAddListView',
    component: PartnerClientToAddListView
  },
  {
    path: '/completePartnerClient/:id',
    name: 'CompletePartnerClient',
    component: CompletePartnerClient
  },
  {
    path: '/editClient/:id',
    name: 'EditClient',
    component: EditClient
  },
  {
    path: '/clientRiskMatrix/:id',
    name: 'ClientRiskMatrix',
    component: ClientRiskMatrix
  },
  {
    path: '/clientFormsView/:id',
    name: 'ClientFormsView',
    component: ClientFormsView
  },
  {
    path: '/form25Add/:id',
    name: 'Form25Add',
    component: Form25Add
  },
  {
    path: '/form25View/:id',
    name: 'Form25View',
    component: Form25View
  },
  {
    path: '/form25Edit/:id',
    name: 'Form25Edit',
    component: Form25Edit
  },
  {
    path: '/tccClientView/:id',
    name: 'TccClientView',
    component: TccClientView
  },
  {
    path: '/add/:id',
    name: 'Add',
    component: Add
  },
  {
    path: '/manage/:id',
    name: 'Manage',
    component: Manage
  },
  {
    path: '/tccView/:id',
    name: 'TccView',
    component: TccView
  },
  {
    path: '/partnerTccView/:id',
    name: 'PartnerTccView',
    component: PartnerTccView
  },
  {
    path: '/partnerListView',
    name: 'PartnerListView',
    component: PartnerListView
  },
  {
    path: '/partnerTradesView/:id',
    name: 'PartnerTradesView',
    component: PartnerTradesView
  },
  {
    path: '/partnerEdit/:id',
    name: 'PartnerEdit',
    component: PartnerEdit
  },
  {
    path: '/newPartner',
    name: 'NewPartner',
    component: NewPartner
  },
  {
    path: '/partnerMainView/:id',
    name: 'PartnerMainView',
    component: PartnerMainView
  },
  {
    path: '/partnerHome',
    name: 'PartnerHome',
    component: PartnerHome
  },
  {
    path: '/clientTradesView/:id',
    name: 'ClientTradesView',
    component: ClientTradesView
  },
  {
    path: '/researchHome',
    name: 'ResearchHome',
    component: ResearchHome
  },
  {
    path: '/researchAdd',
    name: 'ResearchAdd',
    component: ResearchAdd
  },
  {
    path: '/researchView',
    name: 'ResearchView',
    component: ResearchView
  },
  {
    path: '/dailyCashBalance/:id',
    name: 'DailyCashBalance',
    component: DailyCashBalance
  },
  {
    path: '/balancesHomeView/:id',
    name: 'BalancesHomeView',
    component: BalancesHomeView
  },
  {
    path: '/clientSdaRemainder/:id',
    name: 'ClientSdaRemainder',
    component: ClientSdaRemainder
  },
  {
    path: '/clientTccRemainder/:id',
    name: 'ClientTccRemainder',
    component: ClientTccRemainder
  },
  {
    path: '/partnerBalancesHomeView/:id',
    name: 'PartnerBalancesHomeView',
    component: PartnerBalancesHomeView
  },
  {
    path: '/partnerDailyCashBalance/:id',
    name: 'PartnerDailyCashBalance',
    component: PartnerDailyCashBalance
  },
  {
    path: '/partnerSdaRemainder/:id',
    name: 'PartnerSdaRemainder',
    component: PartnerSdaRemainder
  },
  {
    path: '/partnerTccRemainder/:id',
    name: 'PartnerTccRemainder',
    component: PartnerTccRemainder
  },
  {
    path: '/partnersClientView/:id',
    name: 'PartnersClientView',
    component: PartnersClientView
  },
  {
    path: '/faq',
    name: 'FAQ',
    component: FAQ
  },
  {
    path: '/corporate',
    name: 'Corporate',
    component: Corporate
  },
  {
    path: '/quotesHome',
    name: 'QuotesHome',
    component: QuotesHome
  },
  {
    path: '/newQuote',
    name: 'NewQuote',
    component: NewQuote
  },
  {
    path: '/quotesView',
    name: 'QuotesView',
    component: QuotesView
  },
  {
    path: '/tradeQuoteReply/:id',
    name: 'TradeQuoteReply',
    component: TradeQuoteReply,
    props: true
  },
  {
    path: '/tradeQuoteView/:id',
    name: 'TradeQuoteView',
    component: TradeQuoteView
  },
  {
    path: '/tradeQuoteEdit/:id',
    name: 'TradeQuoteEdit',
    component: TradeQuoteEdit
  },
  {
    path: '/tradeQuoteToTraded/:id',
    name: 'TradeQuoteToTraded',
    component: TradeQuoteToTraded
  },
  {
    path: '/tradeQuoteHasTraded/:id',
    name: 'TradeQuoteHasTraded',
    component: TradeQuoteHasTraded
  },
  {
    path: '/tradeQuoteDeclinedView/:id',
    name: 'TradeQuoteDeclinedView',
    component: TradeQuoteDeclinedView
  },
  {
    path: '/tradeQuoteHasCompleted/:id',
    name: 'TradeQuoteHasCompleted',
    component: TradeQuoteHasCompleted
  },
  {
    path: '/tradeQuoteHasExpired/:id',
    name: 'TradeQuoteHasExpired',
    component: TradeQuoteHasExpired
  },
  {
    path: '/queryView',
    name: 'QueryView',
    component: QueryView
  },
  {
    path: '/queryReply/:id',
    name: 'QueryReply',
    component: QueryReply
  },
  {
    path: '/queryChat/:id',
    name: 'QueryChat',
    component: QueryChat,
    props: true
  },
  {
    path: '/queryCompleteView/:id',
    name: 'QueryCompleteView',
    component: QueryCompleteView
  },
  {
    path: '/usersHome',
    name: 'UsersHome',
    component: UsersHome
  },
  {
    path: '/usersAdd',
    name: 'UsersAdd',
    component: UsersAdd
  },
  {
    path: '/usersView',
    name: 'UsersView',
    component: UsersView
  },
  {
    path: '/usersEdit/:id',
    name: 'UsersEdit',
    component: UsersEdit
  },
  {
    path: '/ordersHome',
    name: 'OrdersHome',
    component: OrdersHome
  },
  {
    path: '/ordersAdd',
    name: 'OrdersAdd',
    component: OrdersAdd
  },
  {
    path: '/ordersView',
    name: 'OrdersView',
    component: OrdersView
  },
  {
    path: '/ordersEdit/:id',
    name: 'OrdersEdit',
    component: OrdersEdit
  },
  {
    path: '/ficaHome/:id',
    name: 'FicaHome',
    component: FicaHome
  },
  {
    path: '/ficaUploadsUpdate/:id',
    name: 'FicaUploadsUpdate',
    component: FicaUploadsUpdate
  },
  {
    path: '/reloadSpinner/:id',
    name: 'ReloadSpinner',
    component: ReloadSpinner
  },
  {
    path: '/reloadSpinnerTccDocs/:id',
    name: 'ReloadSpinnerTccDocs',
    component: ReloadSpinnerTccDocs
  },
  {
    path: '/ficaDownloads/:id',
    name: 'FicaDownloads',
    component: FicaDownloads
  },
  {
    path: '/allHome',
    name: 'AllHome',
    component: AllHome
  },
  {
    path: '/allTccView',
    name: 'AllTccView',
    component: AllTccView
  },
  {
    path: '/allTradesView',
    name: 'AllTradesView',
    component: AllTradesView
  },
  {
    path: '/allBalancesHomeView',
    name: 'AllBalancesHomeView',
    component: AllBalancesHomeView
  },
  {
    path: '/allDailyCashBalance',
    name: 'AllDailyCashBalance',
    component: AllDailyCashBalance
  },
  {
    path: '/allSdaRemainder',
    name: 'AllSdaRemainder',
    component: AllSdaRemainder
  },
  {
    path: '/allTccRemainder',
    name: 'AllTccRemainder',
    component: AllTccRemainder
  },
  {
    path: '/allRiskView',
    name: 'AllRiskView',
    component: AllRiskView
  },
  {
    path: '/allTradesFileDownload',
    name: 'AllTradesFileDownload',
    component: AllTradesFileDownload
  },
  {
    path: '/tccDocsHome/:id',
    name: 'TccDocsHome',
    component: TccDocsHome
  },
  {
    path: '/tccDocsUploads/:id',
    name: 'TccDocsUploads',
    component: TccDocsUploads
  },
  {
    path: '/tccDocsDownloads/:id',
    name: 'TccDocsDownloads',
    component: TccDocsDownloads
  },
  {
    path: '/report',
    name: 'Report',
    component: Report
  },
  {
    path: '/report?id=clients',
    name: 'Clients',
    component: Clients
  },
  {
    path: '/report?id=trades',
    name: 'Trades',
    component: Trades
  },
  {
    path: '/report?id=profit',
    name: 'Profit',
    component: Profit
  },
  {
    path: '/report?id=tcc',
    name: 'Tcc',
    component: Tcc
  },
  {
    path: '/report?id=zar-flows',
    name: 'ZarFlows',
    component: ZarFlows
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
