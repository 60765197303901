<template>
  <br />
  <br />
  <h2 class="text-center my-2">RESEARCH</h2>
  <br />
  <br />
  <div class="fluid grid">
    <div class="col-12 md:col-6 lg:col-6">
        <Card class="shadow-3 text-center d-block mx-auto" id="newClient">
            <template class="text-center" #title>
              <i class="pi pi-plus-circle" id="cardLogo"></i>
            </template>
            <template  #content>
              <router-link to="/researchAdd" style="color: #e2e7e5; text-decoration: none">
              ADD NEW
              </router-link>
            </template>
        </Card>
    </div>
     <div class="col-12 md:col-6 lg:col-6">
        <Card class="shadow-3 text-center d-block mx-auto" id="fromPartner">
            <template class="text-center" #title>
              <i class="pi pi-list" id="cardLogo"></i>
            </template>
            <template  #content>
              <router-link to="/researchView" style="color: #e2e7e5; text-decoration: none">
              VIEW
              </router-link>
            </template>
        </Card>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'ResearchHome',
  inheritAttrs: false
}
</script>

<style lang="scss" scoped>
#newClient {
 @include cardComponent($midDarkBlue)
};
#fromPartner {
 @include cardComponent($lightMidBlue)
};
.p-card.p-component.p-card-content {
 font-size: 24px;
};
.p-grid {
  margin: 1rem;
  padding: 1rem;
};
</style>
