<template>
    <br />
    <h2 class="text-center my-2">EDIT PARTNER USER</h2>
    {{ user }}
  <br />
  <div class="mx-4">
    <div class="formgrid grid">
        <div class="col-12 md:col-6 lg:col-6">
            <h5>First Name <a style="color: #d2161e">*</a></h5>
            <InputText
            type="text"
            autocomplete="nope"
            class="inputfield w-full"
            v-model="user.firstName"/>
        </div>
        <div class="col-12 md:col-6 lg:col-6">
            <h5>Surname</h5>
            <InputText
            type="text"
            class="inputfield w-full"
            v-model="user.lastName"/>
        </div>
    </div>
    <br />
     <div class=" formgrid grid">
        <div class="col-12 md:col-6 lg:col-6">
          <div class="flex justify-content-between ">
            <div>
            <h5>Email Address</h5>
            </div>
            <div>
              <Button icon="pi pi-info" class="p-button-rounded p-button-lg mx-4 shadow-5" @click="toggle" />
              <OverlayPanel ref="op" appendTo="body" :showCloseIcon="true" id="overlay_panel" style="width: 20rem" :breakpoints="{'960px': '75vw'}">
              <p>In the event of needing to change an email address, please Delete the current User profile and create a new User profile.</p>
              </OverlayPanel>
            </div>
          </div>
            <InputText
            type="text"
            disabled
            autocomplete="nope"
            class="inputfield w-full"
            v-model="user.displayName"/>
        </div>
         <div class="col-12 md:col-6 lg:col-6">
            <h5>Mobile Number</h5>
            <InputText
            type="text"
            autocomplete="nope"
            class="inputfield w-full"
            v-model="user.phoneNumber"/>
        </div>
    </div>
    <br />
    <div class=" formgrid grid">
        <div class="col-12 md:col-6 lg:col-6">
        <h5>Single Partner Name <a style="color: #d2161e">*</a></h5>
            <Dropdown v-model="user.partnerId"
            id="partnerDisplayName"
            :options="partners"
            optionLabel="text"
            optionValue="value"
            :showClear="true"
            appendTo="self"
            class="inputfield w-full"
            placeholder="Select Partner"/>
        </div>
      <div class="col-12 md:col-12 lg:col-6">
      <h5>Multiple Partner Names <a style="color: #d2161e">*</a></h5>
          <MultiSelect v-model="user.partnerIds"
              :options="partners"
              optionLabel="text"
              optionValue="value"
              :showClear="true"
              display="chip"
              appendTo="self"
              class="inputfield w-full"
              placeholder="Select Multiple Partners"/>
      </div>
    </div>
    <div v-if='success'>
      <Dialog class="successModal" header="SUCCESS" id="successModal" v-model:visible="displayConfirmation" :modal="true">
          <div class="confirmation-content">
              <span><p>Partner User edited.</p></span>
          </div>
          <template #footer>
            <div class="flex justify-content-between">
              <router-link class="p-button d-block mr-4 px-auto" :to="'/usersHome'" style="background-color: #014f7e!important; text-decoration: none;
                                  text-decoration: none; width: 40%; justify-content: center;">
                <i class="pi pi-step-backward" style="fontSize: 1rem"></i> &nbsp;&nbsp;BACK
              </router-link>
              <router-link class="p-button d-block mx-2 px-auto" :to="'/usersView'"  style="background-color: #0185b7!important; text-decoration: none
                                  text-decoration: none; width: 40%; justify-content: center;">
                <i class="pi pi-user-edit" style="fontSize: 1rem"></i>&nbsp;&nbsp;USERS
              </router-link>
            </div>
          </template>
      </Dialog>
    </div>
    <br />
    <div class="flex justify-content-between ">
        <div>
            <Button class="l-2 m-3 mx-2 shadow-4 p-button-lg"
            icon="pi pi-step-backward"
            label="BACK&nbsp;"
            id="submitButton"
            @click.prevent="$router.back()" />
        </div>
          <div>
            <Button class="l-2 m-3 mx-2 shadow-4 p-button-lg "
            icon="pi pi-save"
            label="SAVE"
            id="backButton"
            :loading="submitUser"
            @click.prevent="editUser" />
        </div>
    </div>
</div>
</template>

<script>
import { getPartnerUser, getPartners, updateUser } from '@/gateway'

export default {
  name: 'UsersEdit',
  inheritAttrs: false,
  data () {
    return {
      success: false,
      displayConfirmation: true,
      user: [],
      partners: []
    }
  },
  methods: {
    async init () {
      const cr = await getPartners()
      this.partners = []
      if (cr) {
        cr.result.forEach(item => {
          this.partners.push({
            key: item.id,
            text: item.shortName,
            value: item.id
          })
        })
      }
      const response = await getPartnerUser({ id: this.id })
      if (response) {
        this.user = response.result
      }
    },
    async editUser () {
      const response = await updateUser({ user: this.user })
      if (response) {
        this.success = true
      }
    },
    toggle (event) {
      this.$refs.op.toggle(event)
    }
  },
  mounted () {
    this.id = this.$route.params.id
    this.init()
  }
}
</script>

<style>

</style>
