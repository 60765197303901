<template>
    <div style="height: 100%;" v-if="!isAuthenticated">
      <div class="bg">
        </div>
      <div class="loginFrame scale-in-center">
          <Card class="shadow-8" id="loginCard">
                  <template #title>
                      <a id="loginHeader">Welcome to FxHub</a>
                  </template>
                  <template #subtitle>
                      <a id="loginSubHeader">Powered by</a>
                  </template>
                  <template #content>
                    <Image id="loginImage"/>
                  </template>
                  <template #footer>
                      <Button id="loginButton" :loading="loading" label="LOG IN" @click.prevent="submitLogin"
                              class="p-button-lg d-block mx-auto px-auto shadow-5"/>
                  </template>
              </Card>
      </div>
    </div>
    <div v-if="isAuthenticated">
      <Menubar v-if="!['Home'].includes($route.name)" class="p-menubar shadow-4" :model="items">
          <template #end>
                <span v-if="queryBadgeValue > 0">
                  <Badge :value="queryBadgeValue" class="mt-1" @click="toggleQuery"
                          style="float: left; font-size: 1.2rem!important; font-weight:600; line-height: 1.65rem;
                                 margin-right: 0.8rem !important; border: solid 1px; border-color: #4E4E4E;
                                 color: #4E4E4E; background-color: #FFD600;">
                  </Badge>
                     <OverlayPanel ref="queryTable" appendTo="body" :showCloseIcon="true" id="overlay_panel" style="width: 58rem" :breakpoints="{'960px': '75vw'}">
                        <div class="fluid mx-1 shadow-2">
                             <h4 class="text-center" style="background-color: #FFD600; min-height: 2rem;">Client Queries</h4>
                            <DataTable :value="queryNotification"
                                        dataKey="id" :rowHover="true"
                                        stripedRows responsiveLayout="stack"
                                        showGridlines
                                        breakpoint="1024px"
                                        :paginator="true"
                                        :rows="5"
                                        class="datatable-retailClient">
                                        <Column field="sentTime" header="Sent At" headerStyle="width: 3vw"></Column>
                                        <Column field="message" header="Message" headerStyle="width: 6vw"></Column>
                                        <Column field="queryCategory" header="Category" headerStyle="width: 3vw"></Column>
                                        <Column header="Action" headerStyle="width: 1vw;">
                                        <template #body="{data}">
                                        <div v-if="data.notificationType === 'QueryNotification'">
                                          <a class="p-button d-block mx-auto px-1 shadow-1"
                                                @click.prevent="markQueryNotificationAsComplete(data.id)"
                                                style="background-colour:#E6E6F0; color: #E6E6F0; text-decoration: none;
                                                display: flex; justify-content: center;">
                                            <i class="pi pi-arrow-circle-right" style="fontSize: 1rem"></i>&nbsp;GO TO
                                          </a>
                                        </div>
                                        <div v-else-if="data.notificationType === 'NewQueryNotification'">
                                          <a class="p-button d-block mx-auto px-1 shadow-1"
                                                @click.prevent="markNewQueryNotificationAsComplete(data.id)"
                                                style="background-colour:#E6E6F0; color: #E6E6F0; text-decoration: none;
                                                display: flex; justify-content: center;">
                                            <i class="pi pi-arrow-circle-right" style="fontSize: 1rem"></i>&nbsp;GO TO
                                          </a>
                                        </div>
                                    </template>
                                </Column>
                            </DataTable>
                          </div>
                  </OverlayPanel>
                </span>
                <span v-if="quoteBadgeValue > 0">
                  <Badge :value="quoteBadgeValue" class="mt-1" @click="toggleTradeQuote"
                          style="float: left; font-size: 1.2rem!important; font-weight:600; line-height: 1.65rem;
                                 margin-right: 0.8rem !important; border: solid 1px; border-color: #4E4E4E;
                                 color: #E6E6E6; background-color: #d2161e;">
                  </Badge>
                        <OverlayPanel ref="tradeQuoteTable" appendTo="body" :showCloseIcon="true" id="overlay_panel" style="width: 58rem" :breakpoints="{'960px': '75vw'}">
                          <div class="fluid mx-1 shadow-2">
                             <h4 class="text-center m-1" style="color: #E6E6E6; background-color: #d2161e; min-height: 2rem;">Trade Quotes</h4>
                            <DataTable :value="tradeQuoteNotification"
                                        dataKey="id" :rowHover="true"
                                        stripedRows responsiveLayout="stack"
                                        showGridlines
                                        breakpoint="1024px"
                                        :paginator="true"
                                        :rows="5"
                                        class="datatable-retailClient">
                                        <Column field="sentTime" header="Sent At" headerStyle="width: 4vw"></Column>
                                        <Column field="message" header="Message" headerStyle="width: 5vw"></Column>
                                        <Column field="clientName" header="For Client" headerStyle="width: 4vw"></Column>
                                        <Column header="Action" headerStyle="width: 1vw;">
                                        <template #body="{data}">
                                        <div v-if="data.notificationType === 'TradeQuoteNotification'">
                                          <a class="p-button d-block mx-auto px-1 shadow-1"
                                                  @click.prevent="markTradeQuoteNotificationAsComplete(data.id)"
                                                  style="background-colour:#E6E6F0; color: #E6E6F0; text-decoration: none;
                                                  display: flex; justify-content: center;">
                                                    <i class="pi pi-arrow-circle-right" style="fontSize: 1rem"></i>&nbsp;GO TO
                                          </a>
                                        </div>
                                        <div v-else-if="data.notificationType === 'TradeQuotePartnerChatNotification'">
                                            <a class="p-button d-block mx-auto px-1 shadow-1"
                                                  @click.prevent="markTradeQuoteChatNotificationAsComplete(data.id)"
                                                  style="background-colour:#E6E6F0; color: #E6E6F0; text-decoration: none;
                                                  display: flex; justify-content: center;">
                                                    <i class="pi pi-arrow-circle-right" style="fontSize: 1rem"></i>&nbsp;GO TO
                                            </a>
                                        </div>
                                        <div v-else-if="data.notificationType === 'TradeQuoteAcceptByPartnerNotification'">
                                            <a class="p-button d-block mx-auto px-1 shadow-1"
                                                  @click.prevent="markTradeQuoteChatNotificationAsAccepted(data.id)"
                                                  style="background-colour:#E6E6F0; color: #E6E6F0; text-decoration: none;
                                                  display: flex; justify-content: center;">
                                                    <i class="pi pi-arrow-circle-right" style="fontSize: 1rem"></i>&nbsp;GO TO
                                            </a>
                                        </div>
                                        <div v-else-if="data.notificationType === 'TradeQuoteDeclineByPartnerNotification'">
                                            <a class="p-button d-block mx-auto px-1 shadow-1"
                                                  @click.prevent="markTradeQuoteChatNotificationAsDeclined(data.id)"
                                                  style="background-colour:#E6E6F0; color: #E6E6F0; text-decoration: none;
                                                  display: flex; justify-content: center;">
                                                    <i class="pi pi-arrow-circle-right" style="fontSize: 1rem"></i>&nbsp;GO TO
                                            </a>
                                        </div>
                                    </template>
                                </Column>
                            </DataTable>
                          </div>
                      </OverlayPanel>
                </span>
                <span v-if="clientBadgeValue > 0">
                  <Badge :value="clientBadgeValue" class="mt-1" @click="toggleClient"
                          style="float: left; font-size: 1.2rem!important; font-weight:600; line-height: 1.65rem;
                                 margin-right: 0.8rem !important; border: solid 1px; border-color: #4E4E4E;
                                 color: #E6E6E6; background-color: #107E01;">
                 </Badge>
                    <OverlayPanel ref="clientTable" appendTo="body" :showCloseIcon="true" id="overlay_panel" style="width: 58rem" :breakpoints="{'960px': '75vw'}">
                          <div class="fluid mx-1 shadow-2">
                            <h4 class="text-center m-1" style="color: #E6E6E6; background-color: #107E01; min-height: 2rem;">Client Notifications</h4>
                            <DataTable :value="clientNotification"
                                        dataKey="id" :rowHover="true"
                                        stripedRows responsiveLayout="stack"
                                        showGridlines
                                        breakpoint="1024px"
                                        :paginator="true"
                                        :rows="5"
                                        class="datatable-retailClient">
                                        <Column field="sentTime" header="Sent At" headerStyle="width: 4vw"></Column>
                                        <Column field="message" header="Message" headerStyle="width: 5vw"></Column>
                                        <Column field="clientName" header="For Client" headerStyle="width: 4vw"></Column>
                                        <Column header="Action" headerStyle="width: 1vw;">
                                        <template #body="{data}">
                                        <div v-if="data.notificationType === 'ClientFica'">
                                            <a class="p-button d-block mx-auto px-1 shadow-1"
                                              @click.prevent="markClientFicaNotificationAsComplete(data.id)"
                                              style="background-colour:#E6E6F0; color: #E6E6F0; text-decoration: none;
                                              display: flex; justify-content: center;">
                                              <i class="pi pi-arrow-circle-right" style="fontSize: 1rem"></i>&nbsp;GO TO
                                            </a>
                                         </div>
                                        <div v-else-if="data.notificationType === 'ClientNotification'">
                                            <a class="p-button d-block mx-auto px-1 shadow-1"
                                              @click.prevent="markClientNotificationAsComplete(data.id)"
                                              style="background-colour:#E6E6F0; color: #E6E6F0; text-decoration: none;
                                              display: flex; justify-content: center;">
                                              <i class="pi pi-arrow-circle-right" style="fontSize: 1rem"></i>&nbsp;GO TO
                                            </a>
                                        </div>
                                    </template>
                                </Column>
                            </DataTable>
                          </div>
                      </OverlayPanel>
                </span>
                <Button label="SIGN OUT" icon="pi pi-sign-out" class="p-button-link" @click="submitLogout" />
          </template>
      </Menubar>
      <Toast />
   <router-view :quoteTriggerMount="quoteTrigger" , :queryTriggerMount="queryTrigger" />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import {
  login,
  logout,
  BaseUrl,
  GetQueryNotifications,
  CompleteQueryNotification,
  AutoCompleteQueryNotification,
  GetClientNotifications,
  CompleteClientNotification,
  AutoCompleteClientNotification,
  GetTradeQuoteNotifications,
  CompleteTradeQuoteNotification,
  AutoCompleteTradeQuoteNotification
} from '@/gateway'
import { ServerEventsClient } from '@servicestack/client'

const channels = ['home']
const client = new ServerEventsClient('https://api.fxhub.co.za', channels, { // 'http://localhost:56212/'
  handlers: {
    onConnect: (sub) => { // Successful SSE connection
    // console.log("You've connected! welcome " + sub.userId + ' ' + sub.channels)
    },
    onJoin: (msg) => { // User has joined subscribed channel
    //  console.log('Welcome, ' + msg.userId)
    },
    onLeave: (msg) => { // User has left subscribed channel
    //  console.log(msg.displayName + ' has left the building')
    },
    onUpdate: (msg) => { // User channel subscription was changed
    //  console.log(msg.displayName + ' channels subscription were updated')
    },
    onMessage: (msg) => {
    //  console.log('message receivd', msg)
    }
  },
  onException: (e) => {}, // Invoked on each Error
  onReconnect: (e) => {} // Invoked after each auto-reconnect
})
  .addListener('theEvent', (e) => {}) // Add listener for pub/sub event trigger // Start listening for Server Events!
// client.announce('accounced something?')
export default {
  data () {
    return {
      items: [
        {
          label: 'RETAIL',
          icon: 'pi pi-fw pi-user',
          to: '/clientHome'
        },
        /* {
          label: 'CORPORATE',
          icon: 'pi pi-fw pi-users',
          to: '/corporate'
        }, */
        {
          label: 'PARTNERS',
          icon: 'pi pi-fw pi-link',
          to: '/partnerHome'
        },
        {
          label: 'ALL',
          icon: 'pi pi-fw pi-users',
          to: '/allHome'
        },
        {
          label: 'ORDERS',
          icon: 'pi pi-fw pi-book',
          to: '/ordersHome'
        },
        {
          label: 'RESEARCH',
          icon: 'pi pi-fw pi-chart-line',
          to: '/researchHome'
        },
        {
          label: 'FAQ',
          icon: 'pi pi-fw pi-cog',
          to: '/faq'
        },
        {
          label: 'QUERY',
          icon: 'pi pi-question-circle',
          to: '/queryView'
        },
        {
          label: 'QUOTES',
          icon: 'pi pi-fw pi-globe',
          to: '/quotesView'
        },
        {
          label: 'USERS',
          icon: 'pi pi-user-edit',
          to: '/usersHome'
        }
        // {
        //   label: 'REPORT',
        //   icon: 'pi pi-fw pi-chart-bar',
        //   to: '/report?id=clients'
        // }
      ],
      queryNotification: [],
      tradeQuoteNotification: [],
      clientNotification: [],
      query: {},
      navOpen: true,
      clientBadgeValue: 0,
      queryBadgeValue: 0,
      quoteBadgeValue: 0,
      loading: false,
      queryTrigger: 0,
      quoteTrigger: 0,
      messages: []
    }
  },
  methods: {
    toggleQuery (event) {
      this.$refs.queryTable.toggle(event)
    },
    toggleTradeQuote (event) {
      this.$refs.tradeQuoteTable.toggle(event)
    },
    toggleClient (event) {
      this.$refs.clientTable.toggle(event)
    },
    // various Toast popup messages --------------------------------------------------------------------------------
    showNewQueryNotificationToast () {
      this.$toast.add({ severity: 'info', summary: 'New Query Notification', detail: 'New Query Message Received' })
    },
    showQueryNotificationToast () {
      this.$toast.add({ severity: 'info', summary: 'Query Reply Notification', detail: 'Query Chat Reply Received' })
    },
    showClientNotificationToast () {
      this.$toast.add({ severity: 'success', summary: 'Client Notification', detail: 'New Partner Client Created' })
    },
    showClientFicaNotificationToast () {
      this.$toast.add({ severity: 'success', summary: 'Client Notification', detail: 'New Client FICA Uploaded' })
    },
    showTradeQuoteNotificationToast () {
      this.$toast.add({ severity: 'error', summary: 'Trade Quote Notification', detail: 'New Trade Quote Request' })
    },
    showTradeQuoteChatNotificationToast () {
      this.$toast.add({ severity: 'error', summary: 'Trade Quote Notification', detail: 'New Trade Quote Reply' })
    },
    showTradeQuoteAcceptNotificationToast () {
      this.$toast.add({ severity: 'error', summary: 'Trade Quote Notification', detail: 'Trade Quote Accepted' })
    },
    showTradeQuoteDeclineNotificationToast () {
      this.$toast.add({ severity: 'error', summary: 'Trade Quote Notification', detail: 'Trade Quote Declined' })
    },
    // for reading the various messages passed in the Server Sent Event --------------------------------------------
    parseMessage (msg) {
    //  console.log('after parseMessage', msg, msg.message, msg.body)
      // for Query Notifications -----------------------------------------------------------------------------------
      if (msg.message === 'QueryNotification' && this.$route.name === 'QueryChat' &&
          (parseInt(this.$route.params.id) === parseInt(msg.queryId))) {
        var random = Math.random()
        this.queryTrigger += random
        this.autoQueryNotificationAsComplete(this.currentQueryId)
      }
      else if (msg.message === 'QueryNotification' && this.$route.name === 'QueryChat' &&
               (parseInt(this.$route.params.id) !== parseInt(msg.queryId))) {
        this.showQueryNotificationToast()
        this.retrieveExistingQueryNotifictions()
      }
      else if (msg.message === 'QueryNotification' && this.$route.name !== 'QueryChat') {
        this.showQueryNotificationToast()
        this.retrieveExistingQueryNotifictions()
      }
      else if (msg.body === 'NewQueryNotification') {
        this.showNewQueryNotificationToast()
        this.retrieveExistingQueryNotifictions()
      }
      else if (msg.body === 'CompleteQueryNotification') {
        this.retrieveExistingQueryNotifictions()
      }
      // for TradeQuote Notifications ------------------------------------------------------------------------------
      if (msg.body === 'TradeQuoteNotification' && this.$route.name !== 'TradeQuoteView') {
        this.showTradeQuoteNotificationToast()
        this.retrieveExistingTradeQuoteNotifictions()
      }
      else if (msg.body === 'TradeQuoteNotification' && this.$route.name === 'TradeQuoteView' &&
               parseInt(this.$route.params.id) !== parseInt(msg.tradeQuoteId)) {
        this.retrieveExistingTradeQuoteNotifictions()
      }
      if (msg.message === 'TradeQuotePartnerChatNotification' && this.$route.name === 'TradeQuoteView' &&
               parseInt(this.$route.params.id) !== parseInt(msg.tradeQuoteId)) {
        this.retrieveExistingTradeQuoteNotifictions()
      }
      else if (msg.message === 'TradeQuotePartnerChatNotification' && this.$route.name === 'TradeQuoteView' &&
               parseInt(this.$route.params.id) === parseInt(msg.tradeQuoteId)) {
      // console.log(msg.tradeQuoteId)
        var random2 = Math.random()
        this.quoteTrigger += random2
        this.autoTradeQuoteNotificationAsComplete(this.currentTradeQuoteId)
      }
      else if (msg.message === 'TradeQuotePartnerChatNotification' && this.$route.name !== 'TradeQuoteView') {
      //  console.log(msg.tradeQuoteId)
        this.showTradeQuoteChatNotificationToast()
        this.retrieveExistingTradeQuoteNotifictions()
      }
      if (msg.body === 'CompleteTradeQuoteNotification') {
        this.retrieveExistingTradeQuoteNotifictions()
      }
      else if (msg.message === 'TradeQuoteAcceptByPartnerNotification') {
        this.showTradeQuoteAcceptNotificationToast()
        this.retrieveExistingTradeQuoteNotifictions()
      }
      else if (msg.message === 'TradeQuoteDeclineByPartnerNotification') {
        this.showTradeQuoteDeclineNotificationToast()
        this.retrieveExistingTradeQuoteNotifictions()
      }
      // for Client Notifications -----------------------------------------------------------------------------------
      if (msg.body === 'ClientNotification') {
        this.showClientNotificationToast()
        this.retrieveExistingClientNotifictions()
      }
      else if (msg.body === 'ClientFica' && this.$route.name === 'FicaDownloads') {
        var random3 = Math.random()
        this.queryTrigger += random3
        this.autoClientNotificationAsComplete(this.currentClientId)
      }
      else if (msg.body === 'ClientFica' && this.$route.name !== 'FicaDownloads') {
        this.showClientFicaNotificationToast()
        this.retrieveExistingClientNotifictions()
      }
      else if (msg.body === 'CompleteClientNotification') {
        this.retrieveExistingClientNotifictions()
      }
    },
    // Methods for Query Notifications -----------------------------------------------------------------------------------
    async retrieveExistingQueryNotifictions () {
      const response = await GetQueryNotifications()
      if (response) {
        this.queryNotification = response.result
        this.queryBadgeValue = response.totalUnread
      }
    },
    async markQueryNotificationAsComplete (queryNotificationId) {
      const response = await CompleteQueryNotification({
        queryNotificationId: queryNotificationId
      })
      if (response) {
        this.queryNotification = response.result
        if (this.$route.name === 'QueryChat') {
          this.toggleQuery()
          this.retrieveExistingQueryNotifictions()
          this.$router.push({ name: 'QueryView' })
        }
        else {
          this.toggleQuery()
          this.retrieveExistingQueryNotifictions()
          this.$router.push({ name: 'QueryChat', params: { id: response.queryId } })
        }
      }
    },
    async markNewQueryNotificationAsComplete (queryNotificationId) {
      const response = await CompleteQueryNotification({
        queryNotificationId: queryNotificationId
      })
      if (response) {
        this.queryNotification = response.result
        if (this.$route.name === 'QueryChat') {
          this.toggleQuery()
          this.retrieveExistingQueryNotifictions()
          this.$router.push({ name: 'QueryView' })
        }
        else {
          this.toggleQuery()
          this.retrieveExistingQueryNotifictions()
          this.$router.push({ name: 'QueryReply', params: { id: response.queryId } })
        }
      }
    },
    async autoQueryNotificationAsComplete (completeQueryId) {
      const response = await AutoCompleteQueryNotification({
        completeQueryId: completeQueryId
      })
      if (response) {
        this.retrieveExistingQueryNotifictions()
      }
    },
    // Methods for Client Notifications -----------------------------------------------------------------------------------
    async retrieveExistingClientNotifictions () {
      const response = await GetClientNotifications()
      if (response) {
        this.clientNotification = response.result
        this.clientBadgeValue = response.totalUnread
      }
    },
    async markClientNotificationAsComplete (clientNotificationId) {
      const response = await CompleteClientNotification({
        clientNotificationId: clientNotificationId
      })
      if (response) {
        this.clientNotification = response.result
        this.toggleClient()
        this.retrieveExistingClientNotifictions()
        this.$router.push({ name: 'PartnerClientToAddListView' })
      }
    },
    async markClientFicaNotificationAsComplete (clientNotificationId) {
      const response = await CompleteClientNotification({
        clientNotificationId: clientNotificationId
      })
      if (response) {
        this.clientNotification = response.result
        this.toggleClient()
        this.retrieveExistingClientNotifictions()
        this.$router.push({ name: 'FicaDownloads', params: { id: response.clientId } })
      }
    },
    async autoClientNotificationAsComplete (completeClientId) {
      const response = await AutoCompleteClientNotification({
        completeClientId: completeClientId
      })
      if (response) {
        this.retrieveExistingClientNotifictions()
      }
    },
    // Methods for TradeQuote Notifications ------------------------------------------------------------------------------
    async retrieveExistingTradeQuoteNotifictions () {
      const response = await GetTradeQuoteNotifications()
      if (response) {
        this.tradeQuoteNotification = response.result
        this.quoteBadgeValue = response.totalUnread
      }
    },
    async markTradeQuoteNotificationAsComplete (tradeQuoteNotificationId) {
      const response = await CompleteTradeQuoteNotification({
        tradeQuoteNotificationId: tradeQuoteNotificationId
      })
      if (response) {
        if (this.$route.name === 'TradeQuoteReply') {
          this.tradeQuoteNotification = response.result
          this.toggleTradeQuote()
          this.retrieveExistingTradeQuoteNotifictions()
          this.$router.push({ name: 'QuotesView' })
        }
        else {
          this.toggleTradeQuote()
          this.retrieveExistingTradeQuoteNotifictions()
          this.$router.push({ name: 'TradeQuoteReply', params: { id: response.tradeQuoteId } })
        }
      }
    },
    async markTradeQuoteChatNotificationAsComplete (tradeQuoteNotificationId) {
      const response = await CompleteTradeQuoteNotification({
        tradeQuoteNotificationId: tradeQuoteNotificationId
      })
      if (response) {
        if (this.$route.name === 'TradeQuoteView') {
          this.tradeQuoteNotification = response.result
          this.toggleTradeQuote()
          this.retrieveExistingTradeQuoteNotifictions()
          this.$router.push({ name: 'QuotesView' })
        }
        else {
          this.toggleTradeQuote()
          this.retrieveExistingTradeQuoteNotifictions()
          this.$router.push({ name: 'TradeQuoteView', params: { id: response.tradeQuoteId } })
        }
      }
    },
    async markTradeQuoteChatNotificationAsAccepted (tradeQuoteNotificationId) {
      const response = await CompleteTradeQuoteNotification({
        tradeQuoteNotificationId: tradeQuoteNotificationId
      })
      if (response) {
        this.toggleTradeQuote()
        this.retrieveExistingTradeQuoteNotifictions()
        this.$router.push({ name: 'TradeQuoteToTraded', params: { id: response.tradeQuoteId } })
      }
    },
    async markTradeQuoteChatNotificationAsDeclined (tradeQuoteNotificationId) {
      const response = await CompleteTradeQuoteNotification({
        tradeQuoteNotificationId: tradeQuoteNotificationId
      })
      if (response) {
        this.toggleTradeQuote()
        this.retrieveExistingTradeQuoteNotifictions()
        this.$router.push({ name: 'TradeQuoteDeclinedView', params: { id: response.tradeQuoteId } })
      }
    },
    async autoTradeQuoteNotificationAsComplete (completeTradeQuoteId) {
      const response = await AutoCompleteTradeQuoteNotification({
        completeTradeQuoteId: completeTradeQuoteId
      })
      if (response) {
        this.retrieveExistingTradeQuoteNotifictions()
      }
    },
    // Methods for Login
    async submitLogin () {
      this.loading = true
      location.href = BaseUrl + '/auth/auth0'
    },
    async submitLoginAuth () {
      const redirectUrl = await login('auth0', this.userName, this.password)
      location.href = redirectUrl
    },
    async submitLogout () {
      const redirectUrl = await logout()
      location.href = redirectUrl
    }
  },
  computed: {
    user () {
      if (this.$store.state.user) return this.$store.state.user
      else return ''
    },
    currentQueryId: function () {
      var currentQueryId = 0
      var routeId = Number(this.$route.params.id)
      if (this.$route.name === 'QueryChat' || this.$route.name === 'QueryReply') {
        currentQueryId = Number(routeId)
      }
      return parseInt(currentQueryId)
    },
    currentTradeQuoteId: function () {
      var currentTradeQuoteId = 0
      var routeId = Number(this.$route.params.id)
      if (this.$route.name === 'TradeQuoteReply' ||
          this.$route.name === 'TradeQuoteView' ||
          this.$route.name === 'TradeQuoteEdit' ||
          this.$route.name === 'TradeQuoteToTraded' ||
          this.$route.name === 'TradeQuoteHasTraded') {
        currentTradeQuoteId = Number(routeId)
      }
      return parseInt(currentTradeQuoteId)
    },
    currentClientId: function () {
      var currentClientId = 0
      var routeId = Number(this.$route.params.id)
      if (this.$route.name === 'FicaDownloads') {
        currentClientId = Number(routeId)
      }
      return parseInt(currentClientId)
    },
    ...mapState,
    ...mapGetters(['isAuthenticated'])
  },
  async mounted () {
    this.$store.dispatch('loadSettings')
    await this.retrieveExistingQueryNotifictions()
    await this.retrieveExistingClientNotifictions()
    await this.retrieveExistingTradeQuoteNotifictions()
    client.registerHandler('onMessage', (client, e) => this.parseMessage(client))
    client.start()
  },
  beforeMount () {
    // console.log(this.user)
    // if (this.user.roles.includes('Admin')) {
    //   console.log('Yes')
    //   this.items.push({
    //     label: 'REPORT',
    //     icon: 'pi pi-fw pi-chart-bar',
    //     to: '/report?id=clients'
    //   })
    // }
  }
}
</script>

<style lang ="scss">
:root{
  --primary-color:#38719e!important;
}
#app {
  padding: 0rem;
  margin: 0rem;
  height: 100%;
  width: 100%;
  background: $backgroundColour;
  font-family: $fontFamily;
};
html,body{
  position: relative;
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  background: $backgroundColour;
  font-family: $fontFamily;
  -webkit-font-smoothing: antialiased;
  overflow: auto;
  }
#full-size{
  height: 100%;
  width: 100%;
  background: $backgroundColour;
  font-family: $fontFamily;
  position: relative;
};
.page-wrap {
  min-height: 100vh;
  /* equal to footer height */
  margin-bottom: -2.6rem;
}
.flex-wrapper {
  position: relative;
    min-height: 100vh;
    height: auto !important;
    height: 100%;
};
.content-wrap {
  padding-bottom: 2.5rem;
}
.grid {
  display: flex;
  flex-wrap: wrap;
  margin-right: 0rem!important;
  margin-left: 0rem!important;
  margin-top: 0rem!important;
};
#footer {
  height: 2.5rem;
  bottom: 0px;
};
.p-menubar.p-component {
    padding: 0.5rem 0.5rem;
    background-image: $gradientBackground;
    color: $textColor;
    border-bottom-style: solid;
    border-bottom-color: $darkTextColour;
    border-bottom-width: 2px;
    font-weight: 400!important;
    border-radius: 0px;
    flex-direction: row;
};
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-text {
    color: $textColor!important;
};
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-icon {
   color: $textColor!important;
};
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link {
   box-shadow: none!important;
};
.p-button.p-button-text.p-button-plain {
   color: $textColor!important;
};
.p-panel .p-panel-header .p-panel-header-icon {
  color: $textColor!important;
};
.p-button.p-button-link {
  color: $textColor!important;
  background: transparent!important;
  font-weight: 400!important;
};
.p-button-label {
  font-weight: 400!important;
};
.fade-enter-active {
  transition: opacity 0.75s;
}
.fade-leave-active {
  transition: opacity 0.75s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
};
.fadequick-enter-active {
  transition: opacity 0.2s;
}
.fadequick-leave-active {
  transition: opacity 0.2s;
}
.fadequick-enter,
.fadequick-leave-to {
  opacity: 0;
};
.fadeinstant-enter-active {
  transition: none;
}
.fadeinstant-leave-active {
  transition: none;
}
.fadeinstant-enter,
.fadeinstant-leave-to {
  opacity: 0;
};
h1 {
  font-family: $fontFamily;
  color: $darkTextColour;
};
h2 {
  font-family: $fontFamily!important;
  color: $darkTextColour;
  margin-block: 0.75rem;
  font-size: 28px;
  font-weight: 700;
  outline: none;
};
h3 {
  font-family: $fontFamily;
  color: $darkTextColour;
};
h4 {
  font-family: $fontFamily;
  color: $darkTextColour;
  margin-block: 0.75rem;
  font-size: 20px;
  font-weight: 600;
  outline: none;
};
h5 {
  font-family: $fontFamily;
  color: $darkTextColour;
  margin-block: 0.75rem;
  font-size: 16px;
  font-weight: 500;
  outline: none;
  font-family:$fontFamily!important;
};
h6 {
  font-family: $fontFamily;
  color: $darkTextColour;
};
hr {
  background-color: $lightMidBlue!important;
  border-color: $lightMidBlue!important;
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
};
#footerHr {
  background-color: $lightMidBlue!important;
  border-color: $lightMidBlue!important;
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
};
#footerHrBottom {
  background-color: $midDarkBlue!important;
  border-color: $midDarkBlue!important;
  margin-top: 0rem;
  margin-bottom: 0rem;
  padding-bottom: 0.1rem;
};
p {
  font-family:'Poppins', sans-serif;
  color: $darkTextColour;
};
.p-paginator {
  background: $light-silver!important;
};
.p-paginator .p-paginator-first, .p-paginator .p-paginator-prev,
.p-paginator .p-paginator-next, .p-paginator .p-paginator-last {
  color: $middleBlue!important;
};
.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background: $middleBlue!important;
  border-color: $darkBlue!important;
  color: $textColor!important;
  box-shadow: none!important;
};
.p-paginator .p-paginator-pages .p-paginator-page {
  color: $middleBlue!important;
  box-shadow: none!important;
};
.p-inputtext.p-component {
    box-shadow: none!important;
    margin-left: 0rem!important;
};
.p-dropdown.p-component {
    box-shadow: none!important;
};
.p-panel .p-panel-header .p-panel-title {
  color: $textColor;
  font-size: 18px;
  font-weight: 500!important;
};
.p-panel .p-panel-content {
  background: $backgroundColour!important;
};
.p-panel .p-panel-header {
  background-image: $gradientBackground!important;
  border: 1px solid!important;
  border-color: $darkTextColour!important;
  border-radius: 2px!important;
};
.p-datatable .p-sortable-column:focus {
  box-shadow: none!important;
};
.p-datatable .p-sortable-column.p-highlight:hover {
  color: $white!important;
};
.p-datatable .p-sortable-column .p-sortable-column-icon {
  color: $textColor!important;
};
.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
  color: $white!important;
};
.p-datatable .p-sortable-column .p-sortable-column-badge {
  background: $lightBlue!important;
  height: 1.25rem!important;
  min-width: 1.25rem!important;
  line-height: 1.25rem!important;
};
.p-datatable .p-datatable-thead > tr > th {
  background-color: $middleBlue!important;
  color: $textColor!important;
}
.p-column-filter-menu-button {
   color: $textColor!important;
   box-shadow: none!important;
};
.p-column-filter-menu-button.p-column-filter-menu-button-active {
   background: $lightBlue!important;
};
.p-column-filter-overlay-menu .p-column-filter-operator {
   background: $lightBlue!important;
};
.p-column-filter-overlay {
  background: $backgroundColour!important;
};
.p-column-filter-overlay-menu .p-column-filter-add-rule {
  display: none;
};
.p-button-label {
  text-transform: uppercase;
};
.p-button.p-button-sm {
  font-size: 1rem!important;
  padding: 0.5rem 0.7rem!important;
};
.p-column-filter-buttonbar .p-button:not(.p-button-icon-only) {
  min-width: 5.5rem!important;
}
.p-button {
 color: #e2e7e5!important;
 background: $middleBlue!important;
 border: $darkTextColour!important;
 border: 2px solid;
 font-size: 16px;
 font-family: Poppins!important;
 font-weight: 400!important;
};
.p-button.p-button-icon-only.p-button-rounded  {
  background: $lightMidBlue!important;
  box-shadow: none!important;
};
.p-button.p-button-lg .p-button-icon{
  color: $textColor!important;
  font-size: 1.25rem!important;
  font-weight: 500!important;
};
.p-overlaypanel {
  background: $backgroundColour!important;
  color: $darkTextColour!important;
}
.p-overlaypanel .p-overlaypanel-close {
  background: $darkBlue!important;
}
.p-datepicker .p-datepicker-header {
  color: $textColor!important;
  background: $lightBlue!important;
};
.p-datepicker .p-datepicker-header .p-datepicker-prev, .p-datepicker
.p-datepicker-header .p-datepicker-next {
  color: $textColor!important;
};
.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  color: $darkTextColour!important;
};
.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  background: $middleBlue!important;
  color: $textColor!important;
};
.p-field-checkbox > label, .p-field-radiobutton > label {
  color: $darkTextColour!important;
};
.p-checkbox .p-checkbox-box.p-highlight {
  background: $lightBlue!important;
  border-color: $middleBlue!important;
  color: $textColor!important;
};
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
  border-color: $darkBlue!important;
  box-shadow: 0 0 0 0.15rem rgb(182,184,209)!important;
};
.progress .p-tooltip-text {
    background-color: $backgroundColour!important;
    color: $darkTextColour!important;
    font-size: 16px!important;
    font-weight: 500!important;
    min-width: 10vw;
    text-align: center;
    border: 2px solid;
    border-color: $lightBlue;
};
.progress .p-tooltip-text .p-tooltip-arrow {
  border-right-color: $backgroundColour!important
};
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year:hover {
  color: $textColor!important;
};
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
  color: $textColor!important;
};
.successModal {
@include modalSuccessDialog
};
.deleteModal {
@include modalDeleteDialog
};
.popiModal {
@include modalPopiDialog
};
.dateRangeModal {
@include dateRangeDialog
};
.pepModal {
@include modalPepDialog
};
#backButton {
  background-color: $lightBlue!important;
  border: 1px solid!important;
  border-color: $darkTextColour!important;
  padding: 0.5rem 0.7rem;
};
#submitButton {
  background-color: $darkBlue!important;
  border: 1px solid!important;
  border-color: $darkTextColour!important;
  padding: 0.5rem 0.7rem;
};
#excelButton {
  color: $textColor!important;
  background-color: $excelGreen!important;
  border: 1px solid!important;
  border-color: $darkTextColour!important;
  padding: 0.5rem 0.7rem;
};
.p-slider .p-slider-range {
  background-color: $middleBlue!important;
};
.p-slider .p-slider-handle {
  background-color: $lightBlue!important;
  border: 2px solid $middleBlue!important;
  border-radius: 50%!important;
};
#thead {
  background: $midLightBlue;
  height: 0vh;
  color: $textColor;
  font-size: 16px;
  font-weight: 600;
  font-family: $fontFamily;
};
#tbodyLight {
  height: 4vh;
  background-color: $backgroundColour;
  color: $darkTextColour;
  font-family:'Poppins', sans-serif;
  font-weight: 400;
};
  #tbodyDark {
  height: 4vh;
  background-color: $whiteSmoke;
  color: $darkTextColour;
  font-family:'Poppins', sans-serif;
  font-weight: 400;
};
.p-toast .p-toast-message.p-toast-message-info {
  background-color: $queryNotificationBackground!important;
  color: $darkTextColour!important;
  border-style: solid;
  border-width: 1px;
  border-color: $borderColour!important;
  width: 20rem;
  margin-left: 4rem;
};
.p-toast .p-toast-message.p-toast-message-success {
  background-color: $clientNotificationBackground!important;
  color: $textColor!important;
  border-style: solid;
  border-width: 1px;
  border-color: $borderColour!important;
  width: 20rem;
  margin-left: 4rem;
};
.p-toast .p-toast-message.p-toast-message-error {
  background-color: $tradeQuoteNotificationBackground!important;
  color: $textColor!important;
  border-style: solid;
  border-width: 1px;
  border-color: $borderColour!important;
  width: 20rem;
  margin-left: 4rem;
};
.p-toast-top-right {
  top: 4.5rem!important;
};
.p-toast .p-toast-message.p-toast-message-info .p-toast-message-icon, .p-toast .p-toast-message.p-toast-message-info .p-toast-icon-close {
  color: $darkTextColour!important;
};
.p-toast .p-toast-message.p-toast-message-success .p-toast-message-icon, .p-toast .p-toast-message.p-toast-message-success .p-toast-icon-close {
  color: $completedText!important;
};
.p-toast .p-toast-message.p-toast-message-error .p-toast-message-icon, .p-toast .p-toast-message.p-toast-message-error .p-toast-icon-close {
  color: $completedText!important;
};
.p-toast .p-toast-message .p-toast-message-content .p-toast-message-icon {
  display: none!important;
};
.p-badge {
  display: inline-block;
  border-radius: 50%!important;
  text-align: center;
  width: 2rem!important;
  height: 2rem!important;
  padding: 0rem!important;
};
@media screen and (min-width: 320px) and (max-width: 767px) {
.bg {
  background-image: url('~@/assets/FxHubBackgroundImageSmall.jpg');
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  };
.loginFrame {
  width: 18rem;
  height: 25rem!important;
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin-bottom: auto;
  margin-top: auto;
  margin-left: auto;
  margin-right: auto;
};
#loginHeader {
  color: $dark!important;
  font-size: 1.5rem!important;
  font-weight: 500!important;
  font-family: Poppins;
};
#loginCard {
  text-align: center;
  background-color: transparent;
  font-family: $fontFamily;
};
#loginSubHeader {
  color: $dark!important;
  font-size: 1.2rem!important;
  font-weight: 500!important;
  font-family: Poppins;
};
#loginText {
  color: $darkBlue!important;
  font-size: 1.2rem!important;
  font-weight: 300!important;
  font-family: Poppins;
};
#loginImage {
  background-image: url('~@/assets/ACFXSolutionsLogoSmall.png')!important;
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent!important;
  position: relative;
  width: 16rem;
  height: 7rem;
  padding: 3.5rem;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: auto;
  margin-right: auto;
  };
#loginButton {
  color: $white!important;
  background: $lightBlueDisabled!important;
  height: 2.5rem;
  width: 13rem;
  font-size: 1.3rem!important;
  font-weight: 500!important;
  font-family: Raleway;
  border: 1px solid!important;
  border-color: $borderColour!important;
  text-decoration: none;
  display: flex;
  justify-content: center;
};
  .p-card .p-card-title {
    padding-top: 0.25rem!important;
    padding-bottom: 0.25rem!important;
    margin-bottom: 0.25rem!important;
  };
  .p-card .p-card-body {
    padding-top: 0.25rem!important;
  };
  .p-card .p-card-content {
    padding-top: 0.25rem!important;
  };
  h2 {
    font-size: 20px!important;
  };
  .p-button.p-button-icon-only.p-button-rounded {
    display: none!important;
  };
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-text {
    color: $textColor!important;
    font-family: Poppins;
  };
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-icon {
    color: $textColor!important;
  };
  .p-menubar .p-menubar-button {
  color: $textColor!important;
  box-shadow: none!important;
  width: 2rem;
  height: 2rem;
  };
  #backButton {
    background-color: $lightBlue!important;
    border: 1px solid!important;
    border-color: $darkTextColour!important;
    padding: 0.5rem 0.7rem;
    font-size: 1rem!important;
  };
  #submitButton {
    background-color: $darkBlue!important;
    border: 1px solid!important;
    border-color: $darkTextColour!important;
    padding: 0.5rem 0.7rem;
    font-size: 1rem!important;
  };
  #excelButton {
    color: $textColor!important;
    background-color: $excelGreen!important;
    border: 1px solid!important;
    border-color: $darkTextColour!important;
    padding: 0.5rem 0.7rem;
    font-size: 1rem!important;
  };
};
@media screen and (min-width: 480px) and (max-width: 1024px) and (orientation: landscape) {
.bg {
  background-image: url('~@/assets/FxHubBackgroundImageMediumLandscape.jpg');
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  };
.loginFrame {
  width: 18rem;
  height: 20rem!important;
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin-bottom: auto;
  margin-top: auto;
  margin-left: auto;
  margin-right: auto;
};
#loginHeader {
  color: $dark!important;
  font-size: 1.5rem!important;
  font-weight: 500!important;
  font-family: Poppins;
};
#loginCard {
  text-align: center;
  background-color: transparent;
  font-family: $fontFamily;
};
#loginSubHeader {
  color: $dark!important;
  font-size: 1.2rem!important;
  font-weight: 500!important;
  font-family: Poppins;
};
#loginText {
  color: $darkBlue!important;
  font-size: 1.2rem!important;
  font-weight: 300!important;
  font-family: Poppins;
};
#loginImage {
  background-image: url('~@/assets/ACFXSolutionsLogoSmall.png')!important;
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent!important;
  position: relative;
  width: 16rem;
  height: 7rem;
  padding: 3.5rem;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: auto;
  margin-right: auto;
  };
#loginButton {
  color: $white!important;
  background: $lightBlueDisabled!important;
  height: 2.5rem;
  width: 13rem;
  font-size: 1.3rem!important;
  font-weight: 500!important;
  font-family: Raleway;
  border: 1px solid!important;
  border-color: $borderColour!important;
  text-decoration: none;
  display: flex;
  justify-content: center;
};
  .p-card .p-card-title {
    padding-top: 0.25rem!important;
    padding-bottom: 0.25rem!important;
    margin-bottom: 0.25rem!important;
  };
  .p-card .p-card-body {
    padding-top: 0.25rem!important;
  };
  .p-card .p-card-content {
    padding-top: 0.25rem!important;
  };
  h2 {
    font-size: 20px!important;
  };
  .p-button.p-button-icon-only.p-button-rounded {
    display: none!important;
  };
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-text {
    color: $textColor!important;
    font-family: Poppins;
  };
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-icon {
    color: $textColor!important;
  };
  .p-menubar .p-menubar-button {
  color: $textColor!important;
  box-shadow: none!important;
  width: 2rem;
  height: 2rem;
  };
  #backButton {
    background-color: $lightBlue!important;
    border: 1px solid!important;
    border-color: $darkTextColour!important;
    padding: 0.5rem 0.7rem;
    font-size: 1.1rem!important;
  };
  #submitButton {
    background-color: $darkBlue!important;
    border: 1px solid!important;
    border-color: $darkTextColour!important;
    padding: 0.5rem 0.7rem;
    font-size: 1.1rem!important;
  };
  #excelButton {
    color: $textColor!important;
    background-color: $excelGreen!important;
    border: 1px solid!important;
    border-color: $darkTextColour!important;
    padding: 0.5rem 0.7rem;
    font-size: 1.1rem!important;
  };
};
  @media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    .bg {
    background-image: url('~@/assets/FxHubBackgroundImageMedium.jpg');
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    };
    .loginFrame {
    width: 26rem;
    height: 35rem!important;
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin-bottom: auto;
    margin-top: auto;
    margin-left: auto;
    margin-right: auto;
  };
  #loginHeader {
    color: $dark!important;
    font-size: 2.1rem!important;
    font-weight: 500!important;
    font-family: Poppins;
  };
  #loginCard {
    text-align: center;
    background-color: transparent;
    font-family: $fontFamily;
  };
  #loginSubHeader {
    color: $dark!important;
    font-size: 1.3rem!important;
    font-weight: 500!important;
    font-family: Poppins;
  };
  #loginText {
    color: $darkBlue!important;
    font-size: 1.2rem!important;
    font-weight: 500!important;
    font-family: Poppins;
  };
  #loginImage {
    background-image: url('~@/assets/ACFXSolutionsLogoExtraLarge.png')!important;
    background-repeat: no-repeat;
    background-position: center;
    background-color: transparent!important;
    position: relative;
    width: 20rem;
    height: 8rem;
    padding: 4rem;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
  };
  #loginButton {
    color: $white!important;
    background: $lightBlueDisabled!important;
    height: 3rem;
    width: 20rem;
    margin-bottom: 2rem;
    font-size: 1.7rem!important;
    font-weight: 500!important;
    font-family: Raleway;
    border: 1px solid!important;
    border-color: $borderColour!important;
    text-decoration: none;
    display: flex;
    justify-content: center;
  };
  .p-menubar .p-menubar-button {
    color: $textColor!important;
    box-shadow: none!important;
    width: 2.5rem;
    height: 2.5rem;
  };
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-text {
    color: $textColor!important;
    font-family: Poppins;
  };
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-icon {
    color: $textColor!important;
  };
  .p-card .p-card-title {
    padding-top: 0.25rem!important;
    padding-bottom: 0.25rem!important;
    margin-bottom: 0.25rem!important;
  };
  .p-card .p-card-body {
    padding-bottom: 0.15rem!important;
  };
  .p-card .p-card-content {
    padding-top: 0.25rem!important;
  };
  h2 {
    font-size: 20px!important;
  };
  .p-button.p-button-icon-only.p-button-rounded {
    display: none!important;
  };
  #backButton {
    background-color: $lightBlue!important;
    border: 1px solid!important;
    border-color: $darkTextColour!important;
    padding: 0.5rem 0.7rem;
    font-size: 1.1rem!important;
  };
  #submitButton {
    background-color: $darkBlue!important;
    border: 1px solid!important;
    border-color: $darkTextColour!important;
    padding: 0.5rem 0.7rem;
    font-size: 1.1rem!important;
  };
  #excelButton {
    color: $textColor!important;
    background-color: $excelGreen!important;
    border: 1px solid!important;
    border-color: $darkTextColour!important;
    padding: 0.5rem 0.7rem;
    font-size: 1.1rem!important;
  };
};
  @media screen and (min-width: 1025px) and (max-width: 1366px)  {
    .bg {
    background-image: url('~@/assets/FxHubBackgroundImageLarge.jpg');
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    };
    .loginFrame {
    width: 20rem;
    height: 28rem;
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin-bottom: auto;
    margin-top: auto;
    margin-left: auto;
    margin-right: auto;
  };
  #loginHeader {
    color: $dark!important;
    font-size: 1.8rem!important;
    font-weight: 500!important;
    font-family: Poppins;
  };
  #loginCard {
    text-align: center;
    background-color: transparent;
    font-family: $fontFamily;
  };
  #loginSubHeader {
    color: $dark!important;
    font-size: 1.2rem!important;
    font-weight: 500!important;
    font-family: Poppins;
  };
  #loginText {
    color: $darkBlue!important;
    font-size: 1.2rem!important;
    font-weight: 300!important;
    font-family: Poppins;
  };
  #loginImage {
    background-image: url('~@/assets/ACFXSolutionsLogoExtraLarge.png')!important;
    background-repeat: no-repeat;
    background-position: center;
    background-color: transparent!important;
    position: relative;
    width: 16rem;
    height: 7rem;
    padding: 3.5rem;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
  };
  #loginButton {
      color: $white!important;
      background: $lightBlueDisabled!important;
      height: 2.5rem;
      width: 16rem;
      font-size: 1.3rem!important;
      font-weight: 500!important;
      font-family: Raleway;
      border: 1px solid!important;
      border-color: $borderColour!important;
      text-decoration: none;
      display: flex;
      justify-content: center;
  };
};
  @media screen and (min-width: 1025px) and (orientation: landscape)  {
    .bg {
    background-image: url('~@/assets/FxHubBackgroundImageLarge.jpg');
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    };
    .loginFrame {
    width: 20rem;
    height: 28rem;
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin-bottom: auto;
    margin-top: auto;
    margin-left: auto;
    margin-right: auto;
  };
  #loginHeader {
    color: $dark!important;
    font-size: 1.8rem!important;
    font-weight: 500!important;
    font-family: Poppins;
  };
  #loginCard {
    text-align: center;
    background-color: transparent;
    font-family: $fontFamily;
  };
  #loginSubHeader {
    color: $dark!important;
    font-size: 1.2rem!important;
    font-weight: 500!important;
    font-family: Poppins;
  };
  #loginText {
    color: $darkBlue!important;
    font-size: 1.2rem!important;
    font-weight: 300!important;
    font-family: Poppins;
  };
  #loginImage {
    background-image: url('~@/assets/ACFXSolutionsLogoExtraLarge.png')!important;
    background-repeat: no-repeat;
    background-position: center;
    background-color: transparent!important;
    position: relative;
    width: 16rem;
    height: 7rem;
    padding: 3.5rem;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
  };
  #loginButton {
      color: $white!important;
      background: $lightBlueDisabled!important;
      height: 2.5rem;
      width: 16rem;
      font-size: 1.3rem!important;
      font-weight: 500!important;
      font-family: Raleway;
      border: 1px solid!important;
      border-color: $borderColour!important;
      text-decoration: none;
      display: flex;
      justify-content: center;
  };
};
  @media screen and (min-width: 1367px)  {
    .bg {
    background-image: url('~@/assets/FxHubBackgroundImageExtraLarge.jpg')!important;
    background-color: transparent;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    };
    .loginFrame {
    width: 20rem;
    height: 35rem;
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin-bottom: auto;
    margin-top: auto;
    margin-left: auto;
    margin-right: auto;
  };
  #loginHeader {
    color: $dark!important;
    font-size: 1.8rem!important;
    font-weight: 500!important;
    font-family: Poppins;
  };
  #loginCard {
    text-align: center;
    background-color: transparent;
    font-family: $fontFamily;
  };
  #loginSubHeader {
    color: $dark!important;
    font-size: 1.2rem!important;
    font-weight: 500!important;
    font-family: Poppins;
  };
  #loginText {
    color: $darkBlue!important;
    font-size: 1.2rem!important;
    font-weight: 300!important;
    font-family: Poppins;
  };
  #loginImage {
    background-image: url('~@/assets/ACFXSolutionsLogoExtraLarge.png')!important;
    background-repeat: no-repeat;
    background-position: center;
    background-color: transparent!important;
    position: relative;
    width: 16rem;
    height: 7rem;
    padding: 3.5rem;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
  };
  #loginButton {
    color: $white!important;
    background: $lightBlueDisabled!important;
    height: 3rem;
    width: 16rem;
    font-size: 1.5rem!important;
    font-weight: 500!important;
    font-family: Raleway;
    border: 1px solid!important;
    border-color: $borderColour!important;
    text-decoration: none;
    display: flex;
    justify-content: center;
  };
  };
  @media screen and (max-width: 960px) {
.p-menubar.p-menubar-mobile-active .p-menubar-root-list {
    display: flex;
    flex-direction: column;
    top: 100%;
    left: 0;
    background-color: $midddleBlueDisabled;
    z-index: 1;
  }
}
  .scale-in-center {
    -webkit-animation: scale-in-center 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.5s both;
      animation: scale-in-center 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.5s both;
  };
  @-webkit-keyframes scale-in-center {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      opacity: 1;
    }
  }
  @keyframes scale-in-center {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      opacity: 1;
    }
  };
</style>
