<template>
  <div class="side-bar">
    <div
      v-for="(item, index) in props.items"
      :key="index"
      :class="itemClasses[index]"
      @click="onClick(item)"
    >
      {{item.text}}
    </div>
    <div class="date-section">
      <div style="width: 100%; display: flex; flex-direction: column; align-items: start;">
        <p style="font-size: 10px; margin: 0;">Start Date</p>
        <div style="width: 100%; height: 30px; position: relative;">
          <DateSelector
            :date="store.state.reportStartDate"
            :min="new Date(2019, 5)"
            :max="new Date(new Date(Date.now()).getFullYear(), new Date(Date.now()).getMonth())"
            period="Monthly"
            @select="selectReportStartDate"
          />
        </div>
      </div>
      <div style="width: 100%; display: flex; flex-direction: column; align-items: start;">
        <p style="font-size: 10px; margin: 0;">End Date</p>
        <div style="width: 100%; height: 30px; position: relative;">
          <DateSelector
            :date="store.state.reportEndDate"
            :min="new Date(2019, 5)"
            :max="new Date(new Date(Date.now()).getFullYear(), new Date(Date.now()).getMonth())"
            period="Monthly"
            @select="selectReportEndDate"
          />
        </div>
      </div>
      <div style="width: 100%; height: 30px; display: flex; flex-direction: row; align-items: center; justify-content: end;">
        <button
          class="update-button"
          @click="update"
        >
          Update
        </button>
      </div>
    </div>
    <div class="partner-section">
      <div style="width: 100%; display: flex; flex-direction: column; align-items: start;">
        <p style="font-size: 10px; margin: 0;">Partner</p>
        <div style="width: 100%; height: 30px; position: relative;">
          <ComboBox
            :records="store.getters.partners"
            :selected="store.state.selectedPartner"
            @select="onSelect"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { store } from '@/store'
import { defineProps, ref, computed } from 'vue'
import { useRouter } from 'vue-router'
import _ from 'lodash'
import DateSelector from '@/components/DateSelector'
import ComboBox from '@/components/ComboBox'

const props = defineProps({
  items: Array
})

const router = useRouter()
const active = ref(props.items[0])

const itemClasses = computed(() => {
  return props.items.map(x => {
    if (_.isEqual(x, active.value)) {
      return 'active-item'
    }
    return 'inactive-item'
  })
})

function onClick (item) {
  active.value = item
  router.push(item.to)
}

function selectReportStartDate (payload) {
  store.commit('changeReportStartDate', payload.date)
}

function selectReportEndDate (payload) {
  store.commit('changeReportEndDate', payload.date)
}

function update () {
  store.commit('loadReport')
}

function onSelect (payload) {
  store.commit('selectPartner', payload.record)
}
</script>

<script>
export default {
  name: 'SideBar',
  inheritAttrs: false
}
</script>

<style scoped>
.partner-section {
  width: 100%;
  border-bottom: solid;
  border-color: #9CA3AF;
  border-width: 1px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: start;
  position: relative;
}

.update-button:hover {
  background-color: #9CA3AF;
  cursor: pointer;
}

.update-button {
  border: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 30px;
  font-size: 12px;
  border-radius: 0.25rem;
  background-color: #D1D5DB;
}

.date-section {
  width: 100%;
  margin-top: 15px;
  border-top: solid;
  border-bottom: solid;
  border-color: #9CA3AF;
  border-width: 1px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 10px;
}

.side-bar {
  height: 100%;
  width: 300px;
  background-color: #E5E7EB;
  box-shadow: 10px 0 5px -4px #DCDCDC;
  padding-top: 5px;
}

.inactive-item:hover {
  background-color: #D1D5DB;
  cursor: pointer;
}

.inactive-item {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  padding-left: 15px;
}

.active-item {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  padding-left: 15px;
  background-color: #D1D5DB;
}

.active-item:hover {
  cursor: pointer;
}
</style>
