<template>
  <br />
  <h2 class="text-center my-2">CLIENT FICA DOCUMENTS FOR : {{ fica.clientName }}</h2>
  <br />
  <div class=" fluid mx-1" id="mobileView">
      <div v-if="fica.hasIdentityDocumentFile">
            <Button class="p-button d-block mx-3 py-3 px-auto" :loading="loadingIdDoc" @click.prevent="downloadFica('Identity Document')"
                    style="color: #e2e7e5; background: #062d5d!important; text-decoration: none;
                    display: flex; justify-content: center; width: 93%;">
                    <i class="pi pi-cloud-download mx-1" style="fontSize: 1rem"></i>&nbsp;Id/Passport
            </Button>
        </div>
         <div v-else>
            <a class="p-button d-block mx-3 py-3 px-auto" :disabled="true"
                    style="color: #e2e7e5; background: rgba(6, 45, 93, 0.7)!important; text-decoration: none;
                    display: flex; justify-content: center; width: 93%;"
                    icon="pi pi-cloud-download">No File Uploaded
            </a>
         </div>
        <br />
        <div v-if="fica.hasProofOfResidenceFile">
            <Button class="p-button d-block mx-3 py-3 px-auto" :loading="loadingPoR" @click.prevent="downloadFica('Proof of Residence')"
                    style="color: #e2e7e5; background: #045889!important; text-decoration: none;
                    display: flex; justify-content: center; width: 93%">
                    <i class="pi pi-cloud-download mx-1" style="fontSize: 1rem"></i>&nbsp;Residence
            </Button>
        </div>
         <div v-else>
            <a class="p-button d-block mx-3 py-3 px-auto" :disabled="true"
                    style="color: #e2e7e5; background: rgba(4, 88, 137, 0.7)!important; text-decoration: none;
                    display: flex; justify-content: center; width: 93%;"
                    icon="pi pi-cloud-download">No File Uploaded
            </a>
         </div>
        <br />
        <div v-if="fica.hasPowerOfAttorneyFile">
            <Button class="p-button d-block mx-3 py-3 px-auto" :loading="loadingPoA" @click.prevent="downloadFica('Power of Attorney')"
                    style="color: #e2e7e5; background: #0185b7!important; text-decoration: none;
                    display: flex; justify-content: center; width: 93%;">
                    <i class="pi pi-cloud-download mx-1" style="fontSize: 1rem"></i>&nbsp;Attorney
            </Button>
        </div>
         <div v-else>
            <a class="p-button d-block mx-3 py-3 px-auto" :disabled="true"
                    style="color: #e2e7e5; background: rgba(1, 133, 183, 0.7)!important; text-decoration: none;
                    display: flex; justify-content: center; width: 93%;"
                    icon="pi pi-cloud-download">No File Uploaded
            </a>
         </div>
        <br />
        <div v-if="fica.hasMarriageCertificateFile">
            <Button class="p-button d-block mx-3 py-3 px-auto" :loading="loadingMarCert" @click.prevent="downloadFica('Marriage Certificate')"
                    style="color: #e2e7e5; background: #062d5d!important; text-decoration: none;
                    display: flex; justify-content: center; width: 93%;">
                    <i class="pi pi-cloud-download mx-1" style="fontSize: 1rem"></i>&nbsp;Marriage
            </Button>
        </div>
         <div v-else>
            <a class="p-button d-block mx-3 py-3 px-auto" :disabled="true"
                    style="color: #e2e7e5; background: rgba(6, 45, 93, 0.7)!important; text-decoration: none;
                    display: flex; justify-content: center; width: 93%;"
                    icon="pi pi-cloud-download">No File Uploaded
            </a>
         </div>
        <br />
        <div v-if="fica.hasClientImageFile">
            <Button class="p-button d-block mx-3 py-3 px-auto" :loading="loadingImage"  @click.prevent="downloadFica('Client Image')"
                    style="color: #e2e7e5; background: #045889!important;  text-decoration: none;
                    display: flex; justify-content: center; width: 93%;">
                    <i class="pi pi-cloud-download mx-1" style="fontSize: 1rem"></i>&nbsp;Client Image
            </Button>
        </div>
         <div v-else>
            <a class="p-button d-block mx-3 py-3 px-auto" :disabled="true"
                    style="color: #e2e7e5; background: rgba(4, 88, 137, 0.7)!important; text-decoration: none;
                    display: flex; justify-content: center; width: 93%;"
                    icon="pi pi-cloud-download">No File Uploaded
            </a>
         </div>
        <br />
        <div v-if="fica.hasMiscellaneousOneFile">
            <Button class="p-button d-block mx-3 py-3 px-auto" :loading="loadingMisc" @click.prevent="downloadFica('Miscellaneous One')"
                    style="color: #e2e7e5; background: #062d5d!important; text-decoration: none;
                    display: flex; justify-content: center; width: 93%">
                    <i class="pi pi-cloud-download mx-1" style="fontSize: 1rem"></i>&nbsp;{{ fica.miscellaneousOneFileDisplayName }}
            </Button>
        </div>
         <div v-else>
            <a class="p-button d-block mx-3 py-3 px-auto" :disabled="true"
                    style="color: #e2e7e5; background: rgba(1, 133, 183, 0.7)!important; text-decoration: none;
                    display: flex; justify-content: center; width: 93%;"
                    icon="pi pi-cloud-download">No File Uploaded
            </a>
         </div>
     <br />
  </div>

<div id="fullScreenView">
  <div class="flex flex-wrap mx-4 my-3 shadow-2 md:align-items-center sm:align-items-center" style="background: #AAAAAF; max-width: 100vw">
    <table class="">
        <thead id="thead">
            <tr>
                <th class="text-center px-2" style="width: 33vw"> ID/PASSPORT</th>
                <th class="text-center px-2" style="width: 33vw"> RESIDENCE</th>
                <th class="text-center px-2" style="width: 33vw"> ATTORNEY</th>
            </tr>
        </thead>
            <tbody id="tbodyDark">
                <tr>
                    <td>
                      <div v-if="fica.hasIdentityDocumentFile">
                          <a class="p-button d-block mx-3 py-3 px-auto" :loading="loadingIdDoc" @click.prevent="downloadFica('Identity Document')"
                                      style="color: #e2e7e5; background: #062d5d!important;  text-decoration: none;
                                      display: flex; justify-content: center;">
                                  <i class="pi pi-cloud-download" style="fontSize: 1rem"></i>&nbsp;&nbsp;Identity Document
                          </a>
                      </div>
                       <div v-else>
                          <a class="p-button d-block mx-3 py-3 px-auto"
                                      style="color: #e2e7e5; background: rgba(6, 45, 93, 0.7)!important;  text-decoration: none;
                                      display: flex; justify-content: center;">
                                      &nbsp;&nbsp;No File Uploaded
                          </a>
                      </div>
                    </td>
                    <td>
                      <div v-if="fica.hasProofOfResidenceFile">
                        <a class="p-button d-block mx-3 py-3 px-auto" :loading="loadingPoR" @click.prevent="downloadFica('Proof of Residence')"
                                  style="color: #e2e7e5; background: #045889!important;  text-decoration: none;
                                  display: flex; justify-content: center;">
                               <i class="pi pi-cloud-download" style="fontSize: 1rem"></i>&nbsp;&nbsp;Proof of Residence
                        </a>
                      </div>
                       <div v-else>
                          <a class="p-button d-block mx-3 py-3 px-auto"
                                      style="color: #e2e7e5; background: rgba(4, 88, 137, 0.7)!important;  text-decoration: none;
                                      display: flex; justify-content: center;">
                                      &nbsp;&nbsp;No File Uploaded
                          </a>
                      </div>
                    </td>
                    <td>
                      <div v-if="fica.hasPowerOfAttorneyFile">
                          <a class="p-button d-block mx-3 py-3 px-auto" :loading="loadingPoA" @click.prevent="downloadFica('Power of Attorney')"
                                    style="color: #e2e7e5; background: #0185b7!important;  text-decoration: none;
                                    display: flex; justify-content: center;">
                                  <i class="pi pi-cloud-download" style="fontSize: 1rem"></i>&nbsp;&nbsp;Power of Attorney
                          </a>
                      </div>
                       <div v-else>
                          <a class="p-button d-block mx-3 py-3 px-auto"
                                      style="color: #e2e7e5; background: rgba(1, 133, 183, 0.7)!important;  text-decoration: none;
                                      display: flex; justify-content: center;">
                                      &nbsp;&nbsp;No File Uploaded
                          </a>
                      </div>
                    </td>
                </tr>
            </tbody>
    </table>
</div>
<br />
<br />
<div class="flex flex-wrap mx-4 my-3 shadow-2 md:align-items-center sm:align-items-center" style="background: #AAAAAF; max-width: 100vw">
    <table class="">
        <thead id="thead" >
            <tr>
                <th class="text-center px-2" style="width: 33vw"> MARRIAGE CERTIFICATE</th>
                <th class="text-center px-2" style="width: 33vw"> CLIENT IMAGE</th>
                <th class="text-center px-2" style="width: 33vw"> MISCELLANEOUS</th>
            </tr>
        </thead>
            <tbody id="tbodyDark">
                <tr>
                    <td>
                      <div v-if="fica.hasMarriageCertificateFile">
                          <a class="p-button d-block mx-3 py-3 px-auto" :loading="loadingMarCert" @click.prevent="downloadFica('Marriage Certificate')"
                                    style="color: #e2e7e5; background: #062d5d!important;  text-decoration: none;
                                    display: flex; justify-content: center;">
                                  <i class="pi pi-cloud-download" style="fontSize: 1rem"></i>&nbsp;&nbsp;Marriage Certificate
                          </a>
                      </div>
                       <div v-else>
                            <a class="p-button d-block mx-3 py-3 px-auto"
                                        style="color: #e2e7e5; background: rgba(6, 45, 93, 0.7)!important;  text-decoration: none;
                                        display: flex; justify-content: center;">
                                        &nbsp;&nbsp;No File Uploaded
                            </a>
                      </div>
                    </td>
                    <td>
                      <div v-if="fica.hasClientImageFile">
                        <a class="p-button d-block mx-3 py-3 px-auto" :loading="loadingImage" @click.prevent="downloadFica('Client Image')"
                                  style="color: #e2e7e5; background: #045889!important;  text-decoration: none;
                                  display: flex; justify-content: center;">
                                <i class="pi pi-cloud-download" style="fontSize: 1rem"></i>&nbsp;&nbsp;Client Image
                        </a>
                      </div>
                       <div v-else>
                            <a class="p-button d-block mx-3 py-3 px-auto"
                                        style="color: #e2e7e5; background: rgba(4, 88, 137, 0.7)!important;  text-decoration: none;
                                        display: flex; justify-content: center;">
                                        &nbsp;&nbsp;No File Uploaded
                            </a>
                      </div>
                    </td>
                    <td>
                      <div v-if="fica.hasMiscellaneousOneFile">
                          <a class="p-button d-block mx-3 py-3 px-auto" :loading="loadingMisc" @click.prevent="downloadFica('Miscellaneous One')"
                                    style="color: #e2e7e5; background: #0185b7!important;  text-decoration: none;
                                    display: flex; justify-content: center;">
                                <i class="pi pi-cloud-download" style="fontSize: 1rem"></i>&nbsp;&nbsp;{{ fica.miscellaneousOneFileDisplayName }}
                        </a>
                      </div>
                       <div v-else>
                            <a class="p-button d-block mx-3 py-3 px-auto"
                                        style="color: #e2e7e5; background: rgba(1, 133, 183, 0.7)!important;  text-decoration: none;
                                        display: flex; justify-content: center;">
                                        &nbsp;&nbsp;No File Uploaded
                            </a>
                      </div>
                    </td>
                </tr>
            </tbody>
      </table>
  </div>
</div>
<div>
  <Button class="l-2 m-3 mx-4 shadow-3 p-button-lg"
  icon="pi pi-step-backward"
  label="BACK&nbsp;"
  id="submitButton"
  @click.prevent="$router.back()"/>
</div>
</template>

<script>
import { getClientFica, ficaDownload } from '@/gateway'

export default {
  name: 'FicaDownloads',
  props: ['queryTriggerMount'],
  inheritAttrs: false,
  data () {
    return {
      fica: {},
      loadingIdDoc: false,
      loadingPoR: false,
      loadingPoA: false,
      loadingMarCert: false,
      loadingImage: false,
      loadingMisc: false
    }
  },
  methods: {
    async init () {
      const response = await getClientFica({ id: this.id })
      if (response) {
        this.fica = response.result
      }
    },
    base64ToArrayBuffer (base64) {
      var binaryString = window.atob(base64)
      var binaryLen = binaryString.length
      var bytes = new Uint8Array(binaryLen)
      for (var i = 0; i < binaryLen; i++) {
        var ascii = binaryString.charCodeAt(i)
        bytes[i] = ascii
      }
      return bytes
    },
    async downloadFica (formType) {
      if (formType === 'Identity Document') { this.loadingIdDoc = true }
      else if (formType === 'Proof of Residence') { this.loadingPoR = true }
      else if (formType === 'Power of Attorney') { this.loadingPoA = true }
      else if (formType === 'Marriage Certificate') { this.loadingMarCert = true }
      else if (formType === 'Client Image') { this.loadingImage = true }
      else if (formType === 'Miscellaneous One') { this.loadingMisc = true }
      const response = await ficaDownload({
        formType: formType,
        id: this.fica.id
      })
      if (response) {
        var byteArray = this.base64ToArrayBuffer(response.data)
        const url = window.URL.createObjectURL(
          new Blob([byteArray], { type: 'application/octet-stream' })
        )
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', response.fileName)
        document.body.appendChild(link)
        this.loadingIdDoc = false
        this.loadingPoR = false
        this.loadingPoA = false
        this.loadingMarCert = false
        this.loadingImage = false
        this.loadingMisc = false
        link.click()
      }
    }
  },
  watch: {
    queryTriggerMount: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.init()
      }
    }
  },
  mounted () {
    this.id = this.$route.params.id
    this.init()
  }
}
</script>

<style lang="scss" scoped>
::v-deep() {
  .p-datatable .p-datatable-thead > tr > th {
    background-color: $middleBlue!important;
    color: $textColor;
    border: 1px solid $borderColour!important;
    font-size: 18px;
    font-weight: 600;
  }
  .p-datatable .p-datatable-tbody > tr > td {
    padding: .4rem .4rem;
    color: $darkTextColour;
    font-family:'Poppins', sans-serif;
    font-weight: 400;
  };
  #thead {
    background: $middleBlue;
    height: 9vh;
    color: $textColor;
    font-size: 18px;
    font-weight: 600;
    font-family: Segoe UI Semibold;
  };
  #tbodyLight {
    height: 6vh;
    background-color: $backgroundColour;
    color: $darkTextColour;
    font-family:'Poppins', sans-serif;
    font-weight: 400;
  };
    #tbodyDark {
    height: 12vh;
    background-color: $whiteSmoke;
    color: $darkTextColour;
    font-family:'Poppins', sans-serif;
    font-weight: 400;
  };
};
  @media screen and (max-width:1023px) {
    #fullScreenView {
      display: none!important;
    }
  };
    @media screen and (min-width:1024px) {
    #mobileView {
      display: none!important;
    }
  };
</style>
