import { createApp } from 'vue'
import { store } from './store.js'
import App from './App.vue'
import router from './router'

import PrimeVue from 'primevue/config'
import ToastService from 'primevue/toastservice'
import Panel from 'primevue/panel'
import Menubar from 'primevue/menubar'
import Button from 'primevue/button'
import InputText from 'primevue/inputtext'
import InputNumber from 'primevue/inputnumber'
import Dropdown from 'primevue/dropdown'
import Card from 'primevue/card'
import Checkbox from 'primevue/checkbox'
import Calendar from 'primevue/calendar'
import FileUpload from 'primevue/fileupload'
import Toast from 'primevue/toast'
import Divider from 'primevue/divider'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import Message from 'primevue/message'
import OverlayPanel from 'primevue/overlaypanel'
import Dialog from 'primevue/dialog'
import Splitter from 'primevue/splitter'
import SplitterPanel from 'primevue/splitterpanel'
import Textarea from 'primevue/textarea'
import Image from 'primevue/image'
import Tooltip from 'primevue/tooltip'
import Badge from 'primevue/badge'
import Slider from 'primevue/slider'
import ScrollPanel from 'primevue/scrollpanel'
import MultiSelect from 'primevue/multiselect'
import ConfirmationService from 'primevue/confirmationservice'
import ConfirmDialog from 'primevue/confirmdialog'
import Home from './views/Home.vue'
import ProgressSpinner from 'primevue/progressspinner'
import Menu from 'primevue/menu'
import PrimeVueChart from 'primevue/chart'
import Chart from '@/components/Chart.vue'
import Loader from '@/components/Loader.vue'
import SideBar from '@/components/SideBar.vue'
import DateSelector from '@/components/DateSelector.vue'
import TextInput from '@/components/TextInput.vue'
import ComboBox from '@/components/ComboBox'
import PeriodSelector from '@/components/PeriodSelector'

import 'primevue/resources/themes/bootstrap4-light-blue/theme.css'
import 'primevue/resources/primevue.min.css'
import 'primeicons/primeicons.css'
import 'primeflex/primeflex.css'

// Initialize Firebase

createApp(App)
  .use(router)
  .use(store)
  .use(PrimeVue)
  .use(ToastService)
  .use(ConfirmationService)
  .component('Panel', Panel)
  .component('Menubar', Menubar)
  .component('Button', Button)
  .component('InputText', InputText)
  .component('InputNumber', InputNumber)
  .component('Dropdown', Dropdown)
  .component('Card', Card)
  .component('Checkbox', Checkbox)
  .component('Calendar', Calendar)
  .component('FileUpload', FileUpload)
  .component('Toast', Toast)
  .component('Divider', Divider)
  .component('DataTable', DataTable)
  .component('Column', Column)
  .component('Message', Message)
  .component('OverlayPanel', OverlayPanel)
  .component('Dialog', Dialog)
  .component('Splitter', Splitter)
  .component('SplitterPanel', SplitterPanel)
  .component('Textarea', Textarea)
  .component('Image', Image)
  .component('Badge', Badge)
  .component('Slider', Slider)
  .component('ScrollPanel', ScrollPanel)
  .component('MultiSelect', MultiSelect)
  .component('ConfirmDialog', ConfirmDialog)
  .component('Home', Home)
  .component('ProgressSpinner', ProgressSpinner)
  .component('Menu', Menu)
  .component('PrimeVueChart', PrimeVueChart)
  .component('Chart', Chart)
  .component('Loader', Loader)
  .component('SideBar', SideBar)
  .component('DateSelector', DateSelector)
  .component('ComboBox', ComboBox)
  .component('TextInput', TextInput)
  .component('PeriodSelector', PeriodSelector)
  .directive('tooltip', Tooltip)
  .mount('#app')
